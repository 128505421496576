import { createContext, Dispatch } from 'react';
import { ErrorAction, SensitivityClassificationFormErrors } from './formErrorReducer';
import { SensitivityClassificationData } from '../SensitivityClassificationService';

export interface SensitivityClassificationsContextInterface {
  sc: SensitivityClassificationData;
  dispatchError: Dispatch<ErrorAction>;
  errorState: SensitivityClassificationFormErrors;
}

export const SensitivityClassificationsContext = createContext<SensitivityClassificationsContextInterface>(null);
