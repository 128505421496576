import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { isPermitted } from '../../services/userPermissionsService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidCarousel, BigidLink, BigidLoader } from '@bigid-ui/components';
import {
  carouselItems,
  getOptedInToEaProgramValue,
  EA_PROGRAM_USER_PREFERENCES,
  getSharedPrefInterestToJoinEa,
} from './eaProgramModalService';
import { useLocalTranslation } from './translations';
import { EaProgramModalFooter } from './EaProgramModalFooter';
import { docsUrls } from '../../config/publicUrls';

const EA_PROGRAM_CONTENT_COMPONENT_NAME = 'EaProgramModalContent';

const Root = styled('div')({
  display: 'flex',
  minHeight: 450,
  flexDirection: 'column',
  padding: '16px 16px 0 16px',
});

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 8px 16px 8px',
  fontSize: 14,
  gap: '24px',
});

const LinkableItem = styled('div')`
  > a {
    padding-left: 6px;
  }
`;

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.bigid.gray200,
  height: 1,
  marginLeft: '-16px',
  marginRight: '-16px',
}));

const CarouselContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.bigid.gray150,
  color: theme.palette.bigid.gray700,
  borderRadius: 8,
  padding: 24,
  marginBottom: 24,
  minHeight: 450,
}));

const CarouselItemTitle = styled('div')({
  fontSize: 18,
  fontWeight: 700,
  lineHeight: '24px',
  paddingTop: 12,
  paddingBottom: 8,
});

const CarouselBody = styled('div')({
  fontSize: 14,
});

export const EaProgramModalContent: FC = () => {
  const [currentCarouselItem, setCurrentCarouselItem] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isOptedInToProgram, setIsOptedInToProgram] = useState(null);
  const [isDismissed, setIsDismissed] = useState(false);
  const { t } = useLocalTranslation();
  const isAdmin = isPermitted('admin');

  useEffect(() => {
    const updateProgramStatus = async () => {
      const optInStatus = await getOptedInToEaProgramValue();
      const sharedPrefInterestToJoin = await getSharedPrefInterestToJoinEa();
      if (optInStatus && !sharedPrefInterestToJoin) {
        setIsOptedInToProgram(true);
      }
      if (optInStatus === false && sharedPrefInterestToJoin === false) {
        setIsOptedInToProgram(false);
      }
      const { data } = (await userPreferencesService.get(EA_PROGRAM_USER_PREFERENCES)) || {};
      setIsDismissed(!!data?.isDismissed);
      setIsLoading(false);
    };
    updateProgramStatus();
  }, []);

  const onItemChange = (newItemIndex: number) => {
    setCurrentCarouselItem(newItemIndex);
  };

  const moreInfoLink = (
    <BigidLink underline="always" href={docsUrls.EA_PROGRAM_DOC} shouldOpenNewTab={true} text={t('moreInfoLink')} />
  );

  return (
    <Root data-aid={generateDataAid(EA_PROGRAM_CONTENT_COMPONENT_NAME, [])}>
      {isLoading ? (
        <BigidLoader position="relative" />
      ) : (
        <>
          <MainContainer data-aid={generateDataAid(EA_PROGRAM_CONTENT_COMPONENT_NAME, ['main-container'])}>
            {!isOptedInToProgram && (
              <LinkableItem>
                {t('mainContentBody')}
                {moreInfoLink}.
              </LinkableItem>
            )}
            <LinkableItem>
              {t('teaserText')}
              {isOptedInToProgram && moreInfoLink}
            </LinkableItem>
          </MainContainer>
          <CarouselContainer>
            <BigidCarousel
              disablePreview={true}
              hideArrows={true}
              slidesAutoplay={true}
              onChange={onItemChange}
              mediaHeight={300}
              items={carouselItems.map(({ url, type }) => ({ url, type }))}
              slideIntervalDuration={8000}
              animationDuration={1500}
            />
            <CarouselItemTitle>{carouselItems[currentCarouselItem].title}</CarouselItemTitle>
            <CarouselBody>
              <LinkableItem>
                {carouselItems[currentCarouselItem].body}
                <BigidLink
                  underline="always"
                  href={carouselItems[currentCarouselItem].learnMoreUrl}
                  shouldOpenNewTab={true}
                  text={t('learnMoreLink')}
                />
                .
              </LinkableItem>
            </CarouselBody>
          </CarouselContainer>
          <Divider />
          <EaProgramModalFooter isAdmin={isAdmin} isDismissed={isDismissed} isOptedInToProgram={isOptedInToProgram} />
        </>
      )}
    </Root>
  );
};
