import React, { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
  BigidPaper,
  PrimaryButton,
  SecondaryButton,
  BigidHeading4,
  BigidDraggableList,
  DraggableItemData,
  GrabberPosition,
  GrabberColor,
  BigidHeading5,
  TertiaryButton,
  BigidTextField,
  BigidCheckbox,
  GrabberSize,
  BigidButtonIcon,
  BigidBody1,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  ClassificationLevel,
  SensitivityClassificationData,
} from '../../SensitivityClassification/SensitivityClassification';
import { BigidAddIcon, BigidRiskManagementIllustration, BigidXIcon } from '@bigid-ui/icons';
import { v4 as uuid } from 'uuid';
import {
  applySensitivityClassification,
  createSensitivityClassification,
  deleteSensitivityClassification,
  getSensitivityClassification,
  updateSensitivityClassification,
} from '../../SensitivityClassification/SensitivityClassificationService';
import { notificationService } from '../../../services/notificationService';
import {
  createEmptySc,
  createNewLevel,
  deleteLevel,
  isNameValid,
} from '../../SensitivityClassification/classificationLevelsUtils';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { QueryType } from './QuerySelector';
import {
  DraggableListWrapper,
  EmptyStateRoot,
  FormActionsWrapper,
  Header,
  LevelItemRoot,
  LevelList,
  LevelNumber,
  LevelsListPanel,
  LevelsSidePanel,
  LevelsSidePanelField,
  LevelsSidePanelFieldCheckboxes,
} from './SensitivityClassificationFormStyles';
import { getFixedT, useLocalTranslation } from '../translations';
import { SeveritySidePanel } from './SeveritySidePanel';
import { cloneDeep } from 'lodash';
import { SensitivityClassificationsContext } from './SensitivityClassificationsContext';
import { ErrorField, formErrorsReducer } from './formErrorReducer';

const MAX_LEVELS_COUNT = 7;

export enum PriorityChange {
  Increase,
  Decrease,
}

export const MIN_PRIORITY = 6;
export const MAX_PRIORITY = 0;

export const SensitivityClassificationForm: FC = () => {
  const { id } = $stateParams;
  const [sensitivityClassification, setSensitivityClassification] = useState<SensitivityClassificationData>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [errorState, dispatch] = useReducer(formErrorsReducer, { classifications: {} });
  const classifications = useMemo(() => sensitivityClassification?.classifications, [sensitivityClassification]);
  const [isDirty, setIsDirty] = useState(false);

  const { t } = useLocalTranslation('SensitivityClassificationForm');
  const notificationMessages = getFixedT('Notifications');
  const sensitivityGridTranslations = getFixedT('SensitivityClassificationGrid');
  useEffect(() => {
    const fetchSc = async (id: string) => {
      try {
        const sc = processSensitivityClassificationRes(await getSensitivityClassification(id));
        setSensitivityClassification(sc);
      } catch ({
        response: {
          data: { message },
        },
      }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(`An error has occurred: ${message}`);
      }
    };

    if (id) {
      fetchSc(id);
    } else {
      setSensitivityClassification(createEmptySc());
    }
  }, [id]);

  const validateForm = useCallback(() => {
    let result = true;
    if (sensitivityClassification?.name) {
      sensitivityClassification.name = sensitivityClassification?.name.trim();
    }
    if (!isNameValid(sensitivityClassification?.name)) {
      dispatch({ type: ErrorField.NAME, payload: { name: t('LevelsSidePanel.invalid_name') } });
      result = false;
    }
    if (!sensitivityClassification?.name) {
      dispatch({ type: ErrorField.NAME, payload: { name: t('LevelsSidePanel.name_required') } });
      result = false;
    }

    if (sensitivityClassification.classifications.length === 0) {
      result = false;
      notificationService.error(t('LevelsSidePanel.no_classifications_notification'));
    }

    return result;
  }, [sensitivityClassification, t]);

  const handleLevelDelete = async (level?: ClassificationLevel) => {
    const clarificationToDelete = level ?? classifications[selectedIndex];
    const isDeleteConfirmed = await showConfirmationDialog({
      entityNameSingular: '',
      actionName: t('Dialogs.delete_level'),
      actionButtonName: t('actions.delete'),
      cancelButtonName: t('actions.cancel'),
      customDescription: t('Dialogs.delete_confirmation', { levelName: clarificationToDelete?.name }),
      showCloseIcon: false,
    });

    if (isDeleteConfirmed) {
      if (level) {
        setSensitivityClassification({
          ...sensitivityClassification,
          classifications: deleteLevel(level, classifications),
        });
      } else {
        setSelectedIndex(undefined);
        sensitivityClassification.classifications.splice(selectedIndex, 1);
        setSensitivityClassification({ ...sensitivityClassification });
      }
    }
  };

  const handleFormClose = useCallback(async () => {
    let isExitConfirmed = true;
    if (isDirty) {
      isExitConfirmed = await showConfirmationDialog({
        entityNameSingular: '',
        cancelButtonName: t('actions.discard'),
        actionName: t('Dialogs.unsaved_changes'),
        actionButtonName: t('actions.save'),

        customDescription: t('Dialogs.save_confirmation', { levelName: sensitivityClassification?.name }),
      });
    }
    if (!isExitConfirmed) {
      $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION);
    }
  }, [isDirty, sensitivityClassification?.name, t]);

  const processSensitivityClassificationRes = (sc: SensitivityClassificationData) => {
    sc.classifications.forEach(classification => {
      classification.queryExpanded = false;
      classification.id = classification.levelId || uuid();
      if (classification.queryObj) {
        classification.queryType = QueryType.Object;
        classification.queryObject = classification.queryObj;
      } else {
        classification.queryType = QueryType.String;
        classification.queryObject = null;
      }
      classification.queryString = classification.query;
      classification.isQueryTouched = true;
    });
    return sc;
  };

  const handleFormSave = useCallback(async () => {
    try {
      const isFormValid = validateForm();

      if (isFormValid) {
        const isSaveConfirmed = await showConfirmationDialog({
          entityNameSingular: t('LevelsSidePanel.entity_name_singular'),
          actionName: t('actions.save'),
          actionButtonName: t('LevelsSidePanel.action_button_name'),
          customDescription: t('LevelsSidePanel.custom_description'),
        });

        if (isSaveConfirmed) {
          if (id) {
            const updatedSc = processSensitivityClassificationRes(
              await updateSensitivityClassification(sensitivityClassification, id),
            );
            setSensitivityClassification(updatedSc);
          } else {
            const sc = await createSensitivityClassification(sensitivityClassification);
            $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION_EDIT, { id: sc._id });
          }
          notificationService.success(t('LevelsSidePanel.sensitivity_configuration_saved'));
        }
      }
    } catch ({
      response: {
        data: { message },
      },
    }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error(`An error has occurred: ${message}`);
    }
  }, [id, sensitivityClassification, t, validateForm]);

  const handleApply = useCallback(async () => {
    try {
      await applySensitivityClassification(id);
      notificationService.success(t('LevelsSidePanel.apply_sensitivity_classification'));
      $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION);
    } catch ({
      response: {
        data: { message },
      },
    }) {
      console.error(`An error has occurred: ${message}`);
      notificationService.error(`An error has occurred: ${message}`);
    }
  }, [id, t]);

  const handleNewLevel = () => {
    if (classifications.length - 1 === MIN_PRIORITY) {
      return;
    }
    setIsDirty(true);
    setSensitivityClassification(() => {
      const newValues = {
        ...sensitivityClassification,
        classifications: createNewLevel(classifications.filter(item => item.name.length !== 0)),
      };
      console.log(newValues);
      setSelectedIndex(newValues.classifications.length ? newValues.classifications.length - 1 : 0);
      return newValues;
    });
  };

  useEffect(() => {
    pageHeaderService.setTitle({
      rightSideComponentsContainer: (
        <FormActionsWrapper>
          {!sensitivityClassification?.defaultSc && id && (
            <TertiaryButton
              onClick={async () => {
                try {
                  const isClassificationDeletionConfirmed = await showConfirmationDialog({
                    entityNameSingular: sensitivityGridTranslations('Common.pageTitle'),
                    actionName: sensitivityGridTranslations('Common.delete'),
                    actionButtonName: sensitivityGridTranslations('Common.delete'),
                    customDescription: t('Dialogs.delete_confirmation', { levelName: sensitivityClassification.name }),
                  });
                  if (isClassificationDeletionConfirmed) {
                    await deleteSensitivityClassification(id);
                    $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION);
                  }
                } catch (error) {
                  notificationService.error(
                    `${notificationMessages('anErrorHasOccurred')} ${error?.response?.data?.message}`,
                  );
                  console.error(`${notificationMessages('anErrorHasOccurred')} ${error?.response?.data?.message}`);
                  return {};
                }
              }}
              size="large"
              dataAid={'form-delete-button'}
              text={t('actions.delete')}
            />
          )}
          {!id && (
            <TertiaryButton
              onClick={handleFormClose}
              size="large"
              dataAid={'form-discard-button'}
              // startIcon={<BigidResetIcon />}
              text={t('actions.cancel')}
            />
          )}
          {/* 
          do we need it? 
          {!sensitivityClassification?.columnTagging?.isActive && (
            <SecondaryButton
              disabled={!id}
              onClick={handleApply}
              size="large"
              dataAid={'form-apply-button'}
              text={t('actions.apply_now')}
            />
          )} */}
          <PrimaryButton dataAid={'form-save-button'} onClick={handleFormSave} size="large" text={t('actions.save')} />
        </FormActionsWrapper>
      ),
      breadcrumbs: [
        {
          label: t('breadcrumbs.classification_groups'),
          onClick: () => $state.go(CONFIG.states.SENSITIVITY_CLASSIFICATION),
        },
        {
          label:
            sensitivityClassification?.name.length > 0
              ? sensitivityClassification?.name
              : t('breadcrumbs.new_classification_group'),
        },
      ],
      showBackButton: true,
    });
  }, [
    classifications,
    handleApply,
    handleFormClose,
    handleFormSave,
    id,
    notificationMessages,
    selectedIndex,
    sensitivityClassification?.columnTagging?.isActive,
    sensitivityClassification?.defaultSc,
    sensitivityClassification?.name,
    sensitivityGridTranslations,
    t,
  ]);

  const showEmptyState = useMemo(
    () => id === undefined || classifications?.length === 0 || classifications?.every(item => item.name.length === 0),
    [classifications, id],
  );
  return (
    <SensitivityClassificationsContext.Provider
      value={{
        sc: sensitivityClassification,
        errorState,
        dispatchError: dispatch,
      }}
    >
      <BigidPaper
        paperProps={{
          style: {
            height: 'calc( 100% - 12px )',
            display: 'flex',
            flexDirection: 'row',
          },
        }}
      >
        <LevelsSidePanel>
          <LevelsSidePanelField>
            <BigidTextField
              disabled={sensitivityClassification?.defaultSc}
              errorMessage={errorState.name?.name}
              onChange={event => {
                dispatch({ type: ErrorField.NAME, payload: { name: null } });
                const newValue = event.target?.value?.replace(/\s{2,}/g, ' ');
                setSensitivityClassification({ ...sensitivityClassification, name: newValue });
                setIsDirty(true);
              }}
              required
              label={t('LevelsSidePanel.name')}
              value={sensitivityClassification?.name}
              placeholder={t('LevelsSidePanel.name')}
            />
          </LevelsSidePanelField>
          <LevelsSidePanelField>
            <BigidTextField
              multiline
              rows={3}
              onChange={event => {
                setSensitivityClassification({ ...sensitivityClassification, description: event.target.value });
                setIsDirty(true);
              }}
              label={t('LevelsSidePanel.description')}
              value={sensitivityClassification?.description}
              placeholder={t('LevelsSidePanel.description')}
              type="text"
            />
          </LevelsSidePanelField>
          <LevelsSidePanelFieldCheckboxes>
            <BigidCheckbox
              label={t('LevelsSidePanel.checkbox_apply')}
              dataAid="classification-form-apply-to-column-checkbox"
              checked={sensitivityClassification?.columnTagging?.isActive}
              onChange={(_, checked) => {
                setIsDirty(true);
                setSensitivityClassification({
                  ...sensitivityClassification,
                  dsTagging: false,
                  columnTagging: { ...sensitivityClassification.columnTagging, isActive: checked },
                });
              }}
            />
            <BigidCheckbox
              label={t('LevelsSidePanel.checkbox_classification_tables')}
              dataAid="classification-form-classification-tables-checkbox"
              checked={sensitivityClassification?.columnTagging?.shouldPropagateToObject}
              onChange={(_, checked) => {
                setIsDirty(true);
                setSensitivityClassification({
                  ...sensitivityClassification,
                  dsTagging: !checked && false,
                  columnTagging: { ...sensitivityClassification.columnTagging, shouldPropagateToObject: checked },
                });
              }}
            />
            <BigidCheckbox
              label={t('LevelsSidePanel.checkbox_classification_sources')}
              dataAid="classification-form-classification-sources-checkbox"
              checked={sensitivityClassification?.dsTagging}
              disabled={
                sensitivityClassification?.columnTagging?.isActive &&
                !sensitivityClassification.columnTagging?.shouldPropagateToObject
              }
              onChange={(_, checked) => {
                setIsDirty(true);
                setSensitivityClassification({ ...sensitivityClassification, dsTagging: checked });
              }}
            />
          </LevelsSidePanelFieldCheckboxes>
        </LevelsSidePanel>
        <LevelsListPanel>
          <Header>
            <div>
              <BigidHeading4>{t('LevelsListPanel.levels')}</BigidHeading4>
            </div>
            <SecondaryButton
              disabled={sensitivityClassification?.defaultSc || classifications?.length >= MAX_LEVELS_COUNT}
              startIcon={<BigidAddIcon />}
              text={t('LevelsListPanel.add_level')}
              onClick={handleNewLevel}
              size={'medium'}
              dataAid="severity-level-add-button"
            />
          </Header>
          {!showEmptyState && (
            <LevelList>
              <div>
                {classifications
                  ?.filter(item => item.name.length > 0)
                  .map(item => (
                    <LevelNumber key={item.id}>
                      <BigidHeading5>{item.priority + 1}</BigidHeading5>
                    </LevelNumber>
                  ))}
              </div>
              <DraggableListWrapper>
                <BigidDraggableList
                  isDragDisabled={sensitivityClassification?.defaultSc}
                  grabberSize={GrabberSize.SMALL}
                  onDragEnd={(sourceIndex: number, destinationIndex: number) => {
                    setIsDirty(true);
                    const deletedItem = sensitivityClassification?.classifications.splice(sourceIndex, 1);
                    if (deletedItem) {
                      sensitivityClassification?.classifications.splice(destinationIndex, 0, deletedItem[0]);
                    }

                    for (let index = 0; index < sensitivityClassification?.classifications.length; index++) {
                      sensitivityClassification.classifications[index].priority = index;
                    }
                    setSensitivityClassification({ ...sensitivityClassification });
                  }}
                  grabberPosition={GrabberPosition.LEFT}
                  grabberColor={GrabberColor.TRANSPARENT}
                  listCustomStyles={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                  items={classifications
                    ?.filter(item => item.name.length > 0)
                    .map(item => ({ ...item, id: item.id ?? uuid() }))}
                  renderFunction={(item: DraggableItemData, index: number) => (
                    <LevelItem
                      onDelete={() => handleLevelDelete(sensitivityClassification.classifications[index])}
                      index={index}
                      item={item}
                      key={item.id}
                      isDefault={sensitivityClassification?.defaultSc}
                      onClick={index => {
                        setSelectedIndex(index);
                      }}
                    />
                  )}
                />
              </DraggableListWrapper>
            </LevelList>
          )}
          {showEmptyState && (
            <EmptyStateRoot>
              <BigidRiskManagementIllustration />
              <div>
                <BigidBody1>{t('LevelsListPanel.empty_state_string')}</BigidBody1>
              </div>
            </EmptyStateRoot>
          )}
        </LevelsListPanel>
      </BigidPaper>
      {selectedIndex !== undefined && (
        <SeveritySidePanel
          validateNameExist={name => {
            return sensitivityClassification.classifications.some(
              (item, index) => item.name.toLocaleLowerCase() === name.toLocaleLowerCase() && selectedIndex !== index,
            );
          }}
          isDefault={sensitivityClassification?.defaultSc}
          onSave={async classification => {
            setIsDirty(true);
            classifications[selectedIndex] = classification;
            setSensitivityClassification(prevValue => ({
              ...prevValue,
              classifications: cloneDeep(classifications),
            }));
            setSelectedIndex(undefined);
          }}
          onDelete={() => handleLevelDelete()}
          onClose={() => setSelectedIndex(undefined)}
          isOpen
          classifications={
            classifications.length
              ? classifications[selectedIndex]
              : {
                  name: '',
                  priority: 1,
                  queryType: QueryType.Object,
                }
          }
        />
      )}
    </SensitivityClassificationsContext.Provider>
  );
};

export interface LevelItemProps {
  item: DraggableItemData;
  index: number;
  isDefault: boolean;
  onClick: (index: number) => void;
  onDelete: (level: ClassificationLevel) => void;
}

const LevelItem: FC<LevelItemProps> = ({ item, index, isDefault, onClick, onDelete }) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const classification = item as ClassificationLevel;

  return (
    <LevelItemRoot
      onClick={() => onClick(index)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div>
        <BigidHeading5>{classification?.name}</BigidHeading5>
        <div>
          {/* 
          uncomment and change once we have the labeling on a level
          <BigidChip
            variant="outlined"
            outlineColor={theme.vars.palette.bigid.gray200}
            bgColor={theme.vars.tokens.bigid.backgroundPrimary}
            icon={<Microsoft width={14} />}
            label={classification?.name}
          /> */}
        </div>
        {isHover && !isDefault && (
          <BigidButtonIcon
            icon={BigidXIcon}
            size="small"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              onDelete(item as ClassificationLevel);
            }}
            dataAid={'level-delete-button'}
          />
        )}
      </div>
    </LevelItemRoot>
  );
};
