import React, { useCallback, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material';
import {
  BigidBody1,
  BigidDialog,
  BigidForm,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormRenderProps,
  BigidFormValues,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { httpService } from '../../services/httpService';
import { analyticsService } from '../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../config/BigIdBiEvents';
import { useLocalTranslation } from './translations';
import { notificationService } from '../../services/notificationService';

const DialogContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const FieldsContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  marginBottom: '8px',
});

const FieldWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'showErrorBorder',
})<{ showErrorBorder?: boolean }>(({ theme, showErrorBorder }) => ({
  width: '215px',

  fieldset: {
    margin: '0px',
  },

  'fieldset > div': {
    width: '215px',
  },

  'fieldset > div > div > div': {
    border: showErrorBorder ? `1px solid ${theme.vars.tokens.bigid.negativeStrong}` : 'unset',
  },
}));

const ErrorMessage = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.negativeStrong,
  fontWeight: 400,
  lineHeight: '15px',
  marginBottom: '8px',
}));

const DownloadNote = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.foregroundTertiary,
  fontWeight: 400,
  lineHeight: '15px',
}));

export interface LabelerPropagationReportDownloadDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LabelerPropagationReportDownloadDialog: React.FC<LabelerPropagationReportDownloadDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const [showInvalidFutureDateError, setShowInvalidFutureDateError] = useState(false);
  const [showSelectOneFieldError, setShowSelectOneFieldError] = useState(false);
  const renderFormRef = useRef<Partial<BigidFormRenderProps>>();
  const { t } = useLocalTranslation('Dialog');

  const isDateValid = useCallback(
    (date: Date) => {
      if (date) {
        const dateToCheck = new Date(date);
        if (dateToCheck.toString() === t('Errors.invalidDate')) {
          return t('Errors.invalidDateMessage');
        }
        setShowSelectOneFieldError(false);
        return false;
      }
      return false;
    },
    [t],
  );

  const isUntilDateValid = (fromDate: Date, untilDate: Date) => {
    if (!isDateValid(fromDate) && !isDateValid(untilDate)) {
      const selectedFromDate = fromDate?.setHours(23, 59, 59, 0);
      const selectedUntilDate = untilDate?.setHours(23, 59, 59, 0);
      if (selectedUntilDate < selectedFromDate) {
        return false;
      }
    }
    return true;
  };

  const formFields: BigidFormField[] = useMemo(
    () => [
      {
        label: t('Labels.from'),
        name: 'fromDate',
        validate: val => isDateValid(val),
        type: BigidFormFieldTypes.DATEPICKER,
        labelPosition: BigidFormFieldLabelPosition.top,
        fieldProps: {
          placeholder: t('Labels.datePlaceHolder'),
          format: 'dd/MM/yyyy',
          disableFuture: true,
          dataAid: 'from-date-field',
        },
      },
      {
        label: t('Labels.until'),
        name: 'untilDate',
        validate: val => isDateValid(val),
        type: BigidFormFieldTypes.DATEPICKER,
        labelPosition: BigidFormFieldLabelPosition.top,
        fieldProps: {
          placeholder: t('Labels.datePlaceHolder'),
          format: 'dd/MM/yyyy',
          disableFuture: true,
          dataAid: 'until-date-field',
        },
      },
    ],
    [isDateValid, t],
  );

  const onFormChange = async ({ fromDate, untilDate }: BigidFormValues) => {
    if (fromDate && untilDate) {
      const fromDateToCheck = new Date(fromDate);
      const fromYear = fromDateToCheck.getFullYear();
      const untilDateToCheck = new Date(untilDate);
      const untilYear = untilDateToCheck.getFullYear();
      if (fromYear.toString().length === 4 && untilYear.toString().length === 4) {
        setShowInvalidFutureDateError(!isUntilDateValid(fromDate, untilDate));
      }
    } else {
      setShowInvalidFutureDateError(false);
    }
  };

  const getFullDateForUrl = (date: Date) => {
    if (date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      return `${year}-${month < 9 ? 0 : ''}${month + 1}-${day < 9 ? 0 : ''}${day}`;
    }
    return '';
  };

  const onDownloadButtonClick = async () => {
    const noDatesFilled =
      !renderFormRef?.current?.getValues?.()?.fromDate && !renderFormRef?.current?.getValues?.()?.untilDate;
    if (noDatesFilled) {
      setShowSelectOneFieldError(true);
      return;
    } else if (showSelectOneFieldError) {
      setShowSelectOneFieldError(false);
    }
    const validateAndSubmit = renderFormRef?.current?.validateAndSubmit;
    if (validateAndSubmit) {
      validateAndSubmit(async (values: BigidFormValues) => {
        try {
          analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_LABELING_PROPAGATION_REPORT);
          await httpService.downloadFile(
            `file-download/labeler-report?type=User-Friendly${
              values.fromDate ? '&fromDate=' + getFullDateForUrl(values.fromDate) : ''
            }${values.untilDate ? '&untilDate=' + getFullDateForUrl(values.untilDate) : ''}`,
          );
        } catch (err) {
          console.error(err);
          notificationService.error(t('Errors.reportDownloadError'));
        }
      });
    }
  };

  return (
    <BigidDialog
      title={t('Labels.dialogTitle')}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      buttons={[
        {
          text: t('Labels.cancel'),
          component: TertiaryButton,
          onClick: onClose,
        },
        {
          text: t('Labels.download'),
          component: PrimaryButton,
          onClick: onDownloadButtonClick,
          disabled: showInvalidFutureDateError,
          dataAid: 'download-report-button',
        },
      ]}
    >
      <DialogContentWrapper>
        <BigidBody1>{t('Labels.dialogBodyDescription')}</BigidBody1>
        <BigidForm
          fields={formFields}
          controlButtons={false}
          stateAndHandlersRef={renderFormRef}
          initialValues={{ fromDate: '', untilDate: '' }}
          onChange={onFormChange}
          renderForm={({ renderField }) => {
            return (
              <>
                <FieldsContainer>
                  <FieldWrapper>{renderField('fromDate')}</FieldWrapper>
                  <FieldWrapper showErrorBorder={showInvalidFutureDateError}>{renderField('untilDate')}</FieldWrapper>
                </FieldsContainer>
                {showInvalidFutureDateError && (
                  <ErrorMessage size="small">{t('Errors.invalidFutureDateError')}</ErrorMessage>
                )}
                {showSelectOneFieldError && (
                  <ErrorMessage size="small">{t('Errors.fillAtLeastOneDateFieldError')}</ErrorMessage>
                )}
              </>
            );
          }}
        />
        <DownloadNote size="small">{t('Labels.downloadReportNote1')}</DownloadNote>
        <DownloadNote size="small">{t('Labels.downloadReportNote2')}</DownloadNote>
      </DialogContentWrapper>
    </BigidDialog>
  );
};
