import React from 'react';

import { BigidAdvancedToolbarFilterTypes, BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';

import { useDashboardToolbar } from '../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { ExecutiveDashboard, DashboardType } from '../../components/ExecutiveDashboard';
import { getFixedT } from './translations';
import { WidgetWrapper } from './WidgetWrapper';
import { PrivacyExecutiveDashboardContext } from './PrivacyExecutiveDashboardContext';
import {
  PrivacyExecutiveDashboardWidgets,
  FilterToolbarFields,
  PRIVACY_EXECUTIVE_DASHBOARD,
} from './PrivacyExecutiveDashboardTypes';
import { DashboardWrapper } from './widgets/styles/WidgetStyles';

export const PrivacyExecutiveDashboard = () => {
  const { activeFilters, externalAppliedFilters, activeWidgetIds, toolbarActions } = useDashboardToolbar(
    widgetIds,
    toolbarFilters,
    PRIVACY_EXECUTIVE_DASHBOARD,
  );

  return (
    <DashboardWrapper>
      <ExecutiveDashboard
        dashboardId={PRIVACY_EXECUTIVE_DASHBOARD}
        dashboardType={DashboardType.PRIVACY}
        toolbarFilters={toolbarFilters}
        activeFilters={activeFilters}
        toolbarActions={toolbarActions}
        externalAppliedFilters={externalAppliedFilters}
        isSavedFiltersTabsDisplayed={true}
        activeWidgetIds={activeWidgetIds}
        widgetSelectionItems={widgetSelectionItems}
      >
        <PrivacyExecutiveDashboardContext.Provider value={{ activeFilters, activeWidgetIds }}>
          <WidgetWrapper />
        </PrivacyExecutiveDashboardContext.Provider>
      </ExecutiveDashboard>
    </DashboardWrapper>
  );
};

const [tDashboard, tWidgets] = ['dashboard', 'widgets'].map(getFixedT);

const widgetIds = Object.values(PrivacyExecutiveDashboardWidgets);

const widgetSelectionItems = widgetIds.map(widgetId => ({
  id: widgetId,
  label: tWidgets(`${widgetId}.title`),
}));

const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = [
  {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: FilterToolbarFields.LEGAL_ENTITIES,
    field: FilterToolbarFields.LEGAL_ENTITIES,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.LEGAL_ENTITIES}`),
    parentId: FilterToolbarFields.LEGAL_ENTITIES,
    operator: 'in',
    options: [],
  },
  {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: FilterToolbarFields.VENDORS,
    field: FilterToolbarFields.VENDORS,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.VENDORS}`),
    parentId: FilterToolbarFields.VENDORS,
    operator: 'in',
    options: [],
  },
  {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: FilterToolbarFields.ASSETS,
    field: FilterToolbarFields.ASSETS,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.ASSETS}`),
    parentId: FilterToolbarFields.ASSETS,
    operator: 'in',
    options: [],
  },
  {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: FilterToolbarFields.TIME_RANGE,
    field: FilterToolbarFields.TIME_RANGE,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.TIME_RANGE}`),
    parentId: FilterToolbarFields.TIME_RANGE,
    operator: 'in',
    options: [
      { id: '365', value: 365, displayValue: tDashboard('toolbarFilters.lastYear'), isSelected: false },
      { id: '180', value: 180, displayValue: tDashboard('toolbarFilters.lastSixMonths'), isSelected: true },
      { id: '90', value: 90, displayValue: tDashboard('toolbarFilters.lastThreeMonths'), isSelected: false },
      { id: '30', value: 30, displayValue: tDashboard('toolbarFilters.lastMonth'), isSelected: false },
      { id: '7', value: 7, displayValue: tDashboard('toolbarFilters.lastWeek'), isSelected: false },
    ],
  },
];
