import { BigidSelectOption } from '@bigid-ui/components';
import { ReportingEtlFormValues } from '../reportingEtlMonitoringUtils';

export enum AdapterTypeOptions {
  S3 = 'S3',
  BigQuery = 'BigQuery',
  GCS = 'GCS',
  DIS = 'DIS',
  Snowflake = 'Snowflake',
  Azure = 'Azure',
}

export enum AdaptersLabels {
  S3 = 'Amazon S3',
  GCS = 'Google Cloud Storage',
  BigQuery = 'Google BigQuery',
  DIS = 'Data Insights Studio',
  Snowflake = 'Snowflake',
  Azure = 'Azure Blob',
}

export enum S3FileTypes {
  CSV = `CSV`,
  Parquet = `Parquet`,
}

export enum AzureFileTypes {
  CSV = 'CSV',
  Parquet = 'Parquet',
}

export enum S3FileTypesLabels {
  CSV = 'CSV File Format',
  Parquet = 'Parquet File Format',
}

export enum AzureFileTypesLabels {
  CSV = 'CSV File Format',
  Parquet = 'Parquet File Format',
}

export enum S3AuthenticationMethod {
  Credential = `Credential`,
  IAM = `IAM`,
  Role = 'Role',
}

export enum AzureAuthenticationMethod {
  ClientSecret = 'ClientSecret',
  SASToken = 'SASToken',
  AccountKey = 'AccountKey',
  ConnectionString = 'ConnectionString',
  ManagedIdentity = 'Role',
};

export enum AuthenticationMethodLabels {
  Credential = 'IAM User Access Key',
  IAM = 'IAM Role',
  Role = 'Cross-Account Assume Role',
  Basic = 'User Credentials',
  Json = 'JSON Authentication',
  BigidCredential = 'BigID Credentials',
  ClientSecret = 'Client Id and Client Secret',
  SASToken = 'SAS Token',
  AccountKey = 'Account Key',
  ConnectionString = 'Connection String',
}

export const DEFAULT_BULK_SIZE = 5000;
export const ETL_METADATA_URL = 'reporting-etl/settings/metadata/';
export const ETL_SETTINGS_URL = 'reporting-etl/settings/';
export const ETL_TEST_CONNECTION_URL = 'reporting-etl/data-adapter/test-connection';
export const GCP_ADAPTERS = [AdapterTypeOptions.BigQuery, AdapterTypeOptions.GCS];
export const DefaultAdapter: BigidSelectOption = {
  label: AdaptersLabels[AdapterTypeOptions.BigQuery],
  value: AdapterTypeOptions.BigQuery,
};
export const ENCRYPTED_CREDENTIALS_FORMAT = '******';
export const CODE_400 = 'code 400';
export const FIELDS_TO_IGNORE = ['_id', 'select_type', 'updated_at'];
export const GCP_FIELDS_TO_PICK = [
  'authentication_method',
  'credential_id',
  'credentials_enc',
  'container_location',
  'adapterType',
] as const;
export const S3_FIELDS_TO_PICK = [
  'credential_id',
  'credentials_enc',
  'container_location',
  'adapterType',
  'access_key',
  'container_name',
  'role_session_name',
  'role_arn',
  'authentication_method',
] as const;
export const AZURE_FIELDS_TO_PICK = [
  'client_id',
  'azure_tenant_id',
  'account_name',
  'credential_id',
  'credentials_enc',
  'container_location',
  'adapterType',
  'container_name',
  'authentication_method',
  'sas_token',
  'account_key',
  'connection_string',
] as const;
export const FIELDS_TO_PICK_FOR_TEST_CONNECTION: Record<string, (keyof ReportingEtlFormValues)[]> = {
  S3: [...S3_FIELDS_TO_PICK],
  GCS: [...GCP_FIELDS_TO_PICK],
  BigQuery: [...GCP_FIELDS_TO_PICK],
  DIS: ['adapterType'],
  Snowflake: [
    'authentication_method',
    'credential_id',
    'credentials_enc',
    'username',
    'account',
    'role',
    'schema',
    'warehouse',
    'container_name',
    'adapterType',
  ],
  Azure: [...AZURE_FIELDS_TO_PICK],
};
export const SELECT_TYPE_FIELD = 'select_type';
export const DATA_INSIGHTS_STUDIO = 'Data Insights Studio';
export const DATA_PIPELINE = 'Data Pipeline';
