import React from 'react';
import { BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { TooltipContainer, TooltipContainerWrapper } from './WidgetsStyles';

interface ControlTooltipProps {
  title: string;
  description: string;
}

export const ControlTooltip = ({ title, description }: ControlTooltipProps) => {
  return (
    <BigidTooltip
      title={
        <TooltipContainer>
          <TooltipContainerWrapper>
            <BigidBody1 fontWeight={600} size="small">
              {title}
            </BigidBody1>
            <BigidBody1 size="small">{description}</BigidBody1>
          </TooltipContainerWrapper>
        </TooltipContainer>
      }
      placement="top"
    >
      <BigidBody1>{title}</BigidBody1>
    </BigidTooltip>
  );
};
