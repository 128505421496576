import { uniqBy } from 'lodash';
import { httpService } from '../../../../services/httpService';
import { AsyncSourceFieldsMapping, DataSourceTemplateCondition } from '../types';
import { fieldConditionCheck } from './conditionUtils';

type RecordLikeData = Record<string, any>[];

export const mapFeildOptions = (options: RecordLikeData, asyncSourceFieldsMapping?: AsyncSourceFieldsMapping) => {
  if (!asyncSourceFieldsMapping && !options) return options;

  if (asyncSourceFieldsMapping) {
    const { id, label, value } = asyncSourceFieldsMapping;
    return options?.map((item: Record<string, string>) => ({
      id: item[id],
      label: item[label],
      value: item[value],
    }));
  }

  return options?.map((item: any) => (typeof item === 'string' ? { id: item, label: item, value: item } : item));
};

const filterFieldOptions = (options: RecordLikeData, fieldsForfilter?: DataSourceTemplateCondition[]) => {
  if (!fieldsForfilter || !options) return options;
  return options.filter(item =>
    fieldConditionCheck(false, fieldsForfilter, field => ({
      value: item[field],
    })),
  );
};

export const getOptionsAsync = async (
  asyncSource: string,
  asyncSourceFieldsMapping?: AsyncSourceFieldsMapping,
  filter?: DataSourceTemplateCondition[],
) => {
  try {
    const { data } = await httpService.fetch<{ data: RecordLikeData } | RecordLikeData>(asyncSource);
    const items = ((data as any)?.data ?? data).filter((item: any) => item);
    const dataFiltered = filterFieldOptions(items, filter);
    const dataMapped = mapFeildOptions(dataFiltered, asyncSourceFieldsMapping);

    return uniqBy(dataMapped, 'id');
  } catch (error) {
    throw new Error(`Problem with get options for template feild by async source: ${asyncSource}, ${error}`);
  }
};
