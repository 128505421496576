import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { BigidBody1, BigidWidgetList, BigidWidgetContainer, BigidSeverityMarker } from '@bigid-ui/components';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { useTheme } from '@mui/material';
import { useLocalTranslation } from '../../../translations';
import { Content, WidgetContent, WidgetContainerWrapper, SeverityWrapper, ElipsedText } from '../WidgetsStyles';
import { iconsSchema } from '../../../../Frameworks/utils';
import { MenuActions } from '../WidgetsContainer';
import { fetchWidgetData, WidgetBodySubtypes } from '../../../services/dashboardService';
import { CaseSeverityLevel } from '../../../../../types/actionableInsights';
import { activeFiltersParser } from '../../utils';
import { useFiltersActions } from '../../hooks/useFiltersActions';
import { ComplianceDashboardWidget } from '../../ComplianceDashboard';
import { useWidgetEmptyState } from '../../hooks/useWidgetEmptyState';
import { ControlTooltip } from '../ControlTooltip';

interface TopFailedControlsWidgetProps {
  handleLoader: (value: boolean) => void;
  dataAid: string;
}

type PoliciesType = {
  policyName: string;
  policySeverityLevel: CaseSeverityLevel;
};

export interface TopFailedWidgetResponse {
  controlName: string;
  description: string;
  failedControlsCount: number;
  failedPoliciesCount: number;
  frameworkName: string;
  policies: PoliciesType[];
}

export const TopFailedControlsWidget = ({ dataAid, handleLoader }: TopFailedControlsWidgetProps) => {
  const [topFailedControls, setTopFailedControls] = useState<TopFailedWidgetResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useLocalTranslation('compliance.widgets');
  const Theme = useTheme();

  const { handleRedirectAction, handleFilterAction, activeFilters } = useFiltersActions(handleLoader);
  const { filter } = activeFiltersParser(activeFilters);

  const emtyState = useWidgetEmptyState(
    ComplianceDashboardWidget.TOP_FAILED,
    hasError,
    topFailedControls?.length === 0,
    dataAid,
  );

  const widgetList = useMemo(() => {
    return topFailedControls.map((control: TopFailedWidgetResponse) => {
      const expandableList = control.policies.map((control: PoliciesType) => {
        return {
          descriptionIcon: (
            <SeverityWrapper>
              <BigidSeverityMarker level={control.policySeverityLevel} />
            </SeverityWrapper>
          ),
          description: <ElipsedText>{control.policyName}</ElipsedText>,
          value: undefined,
        };
      });
      const Icon = iconsSchema[control.frameworkName];
      const menuItems = [
        {
          id: 1,
          label: MenuActions.OPEN_SECURITY_POSTURE,
          onClick: () => handleRedirectAction(ComplianceDashboardWidget.TOP_FAILED, null, [control.controlName]),
        },
        {
          id: 2,
          label: MenuActions.ADD_FILTERS,
          onClick: () => handleFilterAction(ComplianceDashboardWidget.TOP_FAILED, null, control.controlName),
        },
      ];
      return {
        descriptionIcon: <Icon width={20} />,
        description: <ControlTooltip title={control.controlName} description={control.description} />,
        value: control.failedPoliciesCount,
        actionButtonIcon: BigidMoreActionIcon,
        actionMenuList: menuItems,
        slide: <BigidWidgetList items={expandableList} />,
      };
    });
  }, [topFailedControls]);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { controls } = await fetchWidgetData({ filter, subType: WidgetBodySubtypes.GROUP_BY_CONTROL });
      setTopFailedControls(controls);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <WidgetContainerWrapper dataAid={dataAid}>
      <BigidWidgetContainer
        dataAid={dataAid}
        titleConfig={{
          title: t('topFailed.title'),
        }}
        content={
          <Content>
            <WidgetContent>
              <BigidWidgetList items={widgetList} />
            </WidgetContent>
          </Content>
        }
        isLoading={isLoading}
        contentHeight="190px"
        actionsSlot={<BigidBody1 color={Theme.palette.bigid.gray500}> {t('topFailed.description')}</BigidBody1>}
        emptyStateConfig={emtyState}
      />
    </WidgetContainerWrapper>
  );
};
