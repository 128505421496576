import { BigidBody1 } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import { HighlightedType, HightlightedValueResponse } from '../../../../types';
import { useLocalTranslation } from '../../../../translations';
import { getDataComponent, getShowMoreItemsComponent } from '../Reasons/Reasons';
import { head } from 'lodash';
import { useTheme } from '@mui/styles';
import { getHighlightedValue, replaceCodeWithColon } from '../../../../utils';
import { generateDataAid } from '@bigid-ui/utils';

export const FooterSectionRoot = styled('div')<{ color?: string; paddingLeft?: string }>`
  display: flex;
  align-items: center;
  padding: 16px 4px 16px ${({ paddingLeft }) => paddingLeft || '16px'};
  color: ${({ color }) => color || 'inherit'};
  gap: 6px;
`;

const TooltipContainer = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

type FooterSectionProps = {
  sectionName: HighlightedType;
  searchValue: string;
  highlightValue?: HightlightedValueResponse[];
  isFirstItem?: boolean;
  hideSectionName?: boolean;
};

export const FooterSection = ({
  sectionName,
  highlightValue = [],
  searchValue = '',
  isFirstItem = false,
  hideSectionName = false,
}: FooterSectionProps) => {
  const { t } = useLocalTranslation('reasons');
  const theme = useTheme();
  const ShowMoreComponent = getShowMoreItemsComponent(sectionName);
  const DataComponent = getDataComponent(sectionName, searchValue);

  const dataAid = 'FooterSection';
  const { highlightedValue = [] } = head(highlightValue) || {};

  const noMatch = highlightedValue.length === 0;
  const showMoreItems = highlightedValue.length - 1;

  const content = noMatch ? (
    <BigidBody1 color={theme.vars.tokens.bigid.foregroundDisabled}>{t('noMatch')}</BigidBody1>
  ) : (
    <DataComponent elementToShow={head(highlightedValue) || ''} />
  );

  // Memoize tooltip values to prevent recalculating on each render
  const tooltipValues = useMemo(
    () =>
      highlightedValue
        .slice(1)
        .map(replaceCodeWithColon)
        .map(getHighlightedValue)
        .map(value => <li key={value.toString()}>{value}</li>),
    [highlightedValue],
  );

  return (
    <FooterSectionRoot
      paddingLeft={isFirstItem ? '24px' : undefined}
      color={noMatch ? theme.vars.tokens.bigid.foregroundDisabled : undefined}
      data-aid={generateDataAid(dataAid, ['root'])}
    >
      {!hideSectionName && (
        <BigidBody1 color="inherit" data-aid={generateDataAid(dataAid, ['section', sectionName])}>
          {t(sectionName)}
        </BigidBody1>
      )}
      {content}
      {showMoreItems > 0 && (
        <ShowMoreComponent
          dataAid={generateDataAid(dataAid, ['showMore'])}
          label={`+${showMoreItems}`}
          tooltipProps={{
            title: <TooltipContainer>{tooltipValues}</TooltipContainer>,
            sx: {
              flexShrink: 0,
            },
          }}
        />
      )}
    </FooterSectionRoot>
  );
};
