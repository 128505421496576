import { BigidBody1, BigidToggleButton } from '@bigid-ui/components';
import { BigidListIcon, BigidTableColumnsIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React from 'react';
import { useLocalTranslation } from '../../../../translations';
import {
  CatalogGridViewMode,
  useDataCatalogSearchResultsContext,
} from '../../../../contexts/dataCatalogSearchResultsContext';

const Root = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

export const CatalogGridViewSwitcher = () => {
  const { t } = useLocalTranslation('actions');
  const { dispatchCatalogSearchAction, state } = useDataCatalogSearchResultsContext();
  const { gridConfig } = state;
  const { catalogGridViewMode } = gridConfig;

  const handleToggleButtonChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    dispatchCatalogSearchAction({
      type: 'UPDATE_GRID_CONFIG',
      payload: {
        catalogGridViewMode: value as CatalogGridViewMode,
      },
    });
  };

  return (
    <Root>
      <BigidBody1 marginRight="8px">{t('view')}</BigidBody1>
      <BigidToggleButton
        onChange={handleToggleButtonChange}
        initialSelected={catalogGridViewMode}
        toggleButtons={[
          {
            value: 'list',
            icon: BigidListIcon,
          },
          {
            value: 'table',
            icon: BigidTableColumnsIcon,
          },
        ]}
      />
    </Root>
  );
};
