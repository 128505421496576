import React, { useEffect, useMemo, useState } from 'react';
import { useUpdatedStateParams } from '../../../../hooks/useUpdatedStateParams';
import { SidePanelMetadata } from '../GridComponents/sidePanels/CatalogSidePanel/SidePanelMetadata';
import { useGetObjectDetails } from '../GridComponents/sidePanels/CatalogSidePanel/hooks/useGetObjectDetails';
import { styled } from '@mui/material';
import { BigidPaper, BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import { OverviewTab } from './tabs/OverviewTab';
import { AttributesTab } from './tabs/AttributesTab';
import { pageHeaderService } from '../../../../../common/services/pageHeaderService';
import { ColumnsTab } from './tabs/ColumnsTab';
import { PreviewTab } from './tabs/PreviewTab';
import { isPermitted } from '../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, LINEAGE_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  height: 686px;
`;

const MetadataWrapper = styled('div')`
  position: fixed;
  width: 90%;
  top: 130px;
`;

const TabsWrapper = styled('div')`
  padding: 16px 24px 0 24px;
`;

export const ObjectDetailsPage = () => {
  const { id, name } = useUpdatedStateParams(true);
  const { t } = useLocalTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { isPreviewAvailable, isLineageEnabled, isSimilarTablesEnabled, isDataPreviewDisabled } = useMemo(
    () => ({
      isPreviewAvailable: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
      isLineageEnabled:
        getApplicationPreference('ENABLE_LINEAGE') &&
        isPermitted(LINEAGE_PERMISSIONS.READ.name) &&
        isPermitted(LINEAGE_PERMISSIONS.ACCESS.name),
      isSimilarTablesEnabled: getApplicationPreference('SIMILAR_TABLES_ENABLED'),
      isDataPreviewDisabled: getApplicationPreference('DATA_PREVIEW_DISABLED'),
    }),
    [],
  );

  const shouldShowPreview = isPreviewAvailable && !isDataPreviewDisabled;

  const { data, refetch, isLoading } = useGetObjectDetails(id, true);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: name ?? id,
    });
  }, [id, name]);

  const tabs: BigidTabsItem[] = [
    {
      label: t('tabs.overview'),
    },
    {
      label: t('tabs.attributes'),
    },
    {
      label: t('tabs.columns'),
    },
    ...(shouldShowPreview ? [{ label: t('tabs.preview') }] : []),
  ];

  const tabsContent = [
    <OverviewTab isLoading={isLoading} objectDetails={data?.data} refetchDetails={refetch} key="OverviewTab" />,
    <AttributesTab objectDetails={data?.data} key="AttributesTab" />,
    <ColumnsTab objectDetails={data?.data} key="ColumnsTab" />,
    ...(shouldShowPreview ? [<PreviewTab objectDetails={data?.data} key="PreviewTab" />] : []),
  ];

  return (
    <Root>
      <BigidPaper>
        <MetadataWrapper>
          <SidePanelMetadata fullyQualifiedName={id} />
        </MetadataWrapper>
        <TabsWrapper>
          <BigidTabs tabs={tabs} showBorderBottom onChange={setSelectedTabIndex} selectedIndex={selectedTabIndex} />
        </TabsWrapper>
        {tabsContent[selectedTabIndex]}
      </BigidPaper>
    </Root>
  );
};
