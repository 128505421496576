import React, { FC, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidHeading4 } from '@bigid-ui/components';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { getStatisticsInitialGridColumns } from '../../../../utils/statisticsConfiguration';
import { CorrelationSetStatisticsDialog } from '../../../Dialogs/CorrelationSetStatisticsDialog';
import { StatisticsRow } from '../../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../../translations';
import { STATISTICS_GRID_ID } from '../../../../utils/utils';

const StatisticsContainer = styled('div')`
  margin-top: 24px;
`;

const StatisticsTitle = styled(BigidHeading4)`
  margin-bottom: 16px;
`;

interface StatisticsProps {
  id?: string;
}

export const Statistics: FC<StatisticsProps> = ({ id }) => {
  const [selectedRow, setSelectedRow] = useState<StatisticsRow>(null);
  const initialGridColumns = useMemo(() => getStatisticsInitialGridColumns(), []);
  const { t } = useLocalTranslation('statistics');

  const config: BigidGridWithToolbarProps<StatisticsRow> = useMemo(
    () => ({
      gridId: STATISTICS_GRID_ID,
      entityName: 'Statistics',
      columns: initialGridColumns,
      fetchData: async (_queryComponents: BigidGridQueryComponents) => {
        return {
          data: [
            {
              columnName: 'address',
              attributeName: '',
              categories: [
                {
                  _id: 'c1',
                  color: '#00FF00',
                  display_name: 'Location',
                  unique_name: 'u_Location',
                  description: '',
                  glossary_id: 'g_Location',
                },
              ],
              identifiability: 'weak',
              avgValuesLength: 21.82,
              id: '672764160dd9fd0842d9e4b6',
              _id: '672764160dd9fd0842d9e4b6',
            },
          ],
          totalCount: 1,
        };
      },
      onRowClick: row => {
        setSelectedRow(row);
      },
    }),
    [],
  );

  const BigidGridWithToolbarMemo = useMemo(() => <BigidGridWithToolbar {...config} />, [config]);

  return (
    <StatisticsContainer>
      <StatisticsTitle>{t('title')}</StatisticsTitle>
      <BigidBody1>{t('title')}</BigidBody1>
      {BigidGridWithToolbarMemo}
      <CorrelationSetStatisticsDialog
        onClose={() => setSelectedRow(null)}
        isOpen={Boolean(selectedRow)}
        title={`${t('columnName')}: ${selectedRow?.columnName}`}
        id={selectedRow?.id as string}
      />
    </StatisticsContainer>
  );
};
