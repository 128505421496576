import React, { FC, useEffect, useMemo, useState } from 'react';
import { BigidAddIcon, BigidApplyIcon, BigidCopyIcon, BigidDeleteIcon, BigidDisableIcon } from '@bigid-ui/icons';
import { ActionData, BigidLoader, BigidPaper, BigidSidePanel } from '@bigid-ui/components';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { $state, $stateParams, $translate } from '../../../services/angularServices';
import { httpService } from '../../../services/httpService';
import makeStyles from '@mui/styles/makeStyles';
import { $rootScope } from 'ngimport';
import { CorrelationSetsImportDialog } from '../CorrelationSetsImportDialog/CorrelationSetsImportDialog';
import { CORRELATION_SET_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { notificationService } from '../../../services/notificationService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import {
  correlationSetConnectionDefaultColumns,
  getCorrelationSetConnectionFilterConfig,
} from './CorrelationSetConnectionGridConfiguration';
import { CorrelationSetModel } from './CorrelationSetConnectionTypes';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { fetchCorrelationSetsData } from './correlationSetUtils';
import { MtNotSupportedViews } from '../../../components/MtMigrationEmptyState/mtMigrationEmptyStateUtils';
import { MtMigrationWrapper } from '../../../components/MtMigrationEmptyState/MtMigrationWrapper';
import { CorrelationSetWizardDialog } from '../components/Dialogs/CorrelationSetWizardDialog';
import { isNewCorrelationSetPageEnabled, CORRELATION_SET_CONNECTIONS_GRID_ID } from '../utils/utils';
import { CorrelationSetsSidePanel } from '../components/SidePanel/CorrelationSetsSidePanel';
import { usePermissions } from '../hooks/usePermissions';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
  },
});

const CorrelationSetConnectionsComponent: FC = () => {
  const isNewCorrelationSetPageFFEnabled = isNewCorrelationSetPageEnabled();
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [isWizardOpen, setWizardOpen] = useState(false);
  const [activeName, setActiveName] = useState('');

  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: correlationSetConnectionDefaultColumns,
    getInitialFilterToolbarConfig: getCorrelationSetConnectionFilterConfig,
  });

  const { gridWrapper } = useStyles({});

  useEffect(() => {
    $translate(['SIDEBAR:ADMIN:ENTITY_SOURCES']).then(translations => {
      $rootScope.$broadcast('changePage', translations['SIDEBAR:ADMIN:ENTITY_SOURCES'], false);
    });

    if ($stateParams.wizard) {
      setWizardOpen(true);
    }
  }, []);

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const { isEditPermitted, isCreatePermitted } = usePermissions();
  const isWizardFlowPermitted = isEditPermitted || isCreatePermitted;

  async function updateCorrelationSets(actionData: ActionData, updatedFields: any) {
    let didUpdate = false;
    const { selectedRowIds, allSelected, filter } = actionData;

    try {
      await httpService.put(`correlation-set-connections`, {
        ids: selectedRowIds,
        allSelected,
        query: { filter },
        updatedFields,
      });
      didUpdate = true;
      notificationService.success('Correlation sets updated successfully');
    } catch (e) {
      notificationService.error('Could not update correlation sets. See logs for more information');
    }

    return {
      shouldGridReload: didUpdate,
      shouldClearSelection: didUpdate,
    };
  }

  const gridWithToolbarConfig: BigidGridWithToolbarProps<CorrelationSetModel> = useMemo(
    () => ({
      // TODO: Uncomment those lines when new Correlation set flow is completely ready
      gridId: CORRELATION_SET_CONNECTIONS_GRID_ID,
      onRowClick: row => {
        if (isNewCorrelationSetPageFFEnabled) {
          setActiveName(row.name);
        }
      },
      columns: gridColumns,
      pageSize: 200,
      customRowIdName: 'id',
      entityName: 'Correlation Sets',
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      filterToolbarConfig,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: fetchCorrelationSetsData,
      toolbarActions: [
        {
          label: 'New Correlation Set',
          icon: BigidAddIcon,
          execute: async () => {
            // TODO: Remove FF check and the line above when new Correlation set flow is completely ready.
            if (isNewCorrelationSetPageFFEnabled) {
              setWizardOpen(true);
            } else {
              $state.go(`newEntityConnection`);
            }

            return {
              shouldGridReload: false,
            };
          },
          disable: () => false,
          show: () => isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name),
        },
        {
          label: 'Import',
          execute: async () => {
            setImportModalOpen(true);

            return {
              shouldGridReload: false,
            };
          },
          disable: () => false,
          show: () => isPermitted(CORRELATION_SET_PERMISSIONS.IMPORT.name),
        },
        {
          label: 'Export',
          execute: async ({ selectedRowIds, allSelected }) => {
            try {
              const queryParams = allSelected ? {} : { ids: selectedRowIds };
              httpService.downloadFile(`correlation-set-connections/file-download/export`, queryParams);
            } catch (e) {
              console.error(e);
              notificationService.error('Could not export. See logs for more information');
            }
            return {
              shouldGridReload: false,
            };
          },
          disable: ({ totalRows }) => totalRows === 0,
          show: () =>
            getApplicationPreference('ENTITY_SOURCE_EXPORT_ENABLED') &&
            isPermitted(CORRELATION_SET_PERMISSIONS.EXPORT.name),
        },
        {
          label: 'Enable',
          isGlobal: true,
          icon: BigidApplyIcon,
          execute: async queryComponents => updateCorrelationSets(queryComponents, { enabled: 'yes' }),
          disable: () => false,
          show: ({ selectedRowIds }) => selectedRowIds.length > 0 && isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name),
        },
        {
          label: 'Disable',
          isGlobal: true,
          icon: BigidDisableIcon,
          execute: async queryComponents => updateCorrelationSets(queryComponents, { enabled: 'no' }),
          disable: () => false,
          show: ({ selectedRowIds }) => selectedRowIds.length > 0 && isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name),
        },
        {
          label: 'Duplicate',
          isGlobal: true,
          icon: BigidCopyIcon,
          execute: async ({ selectedRowIds }) => {
            let didDuplicate = false;
            try {
              await httpService.post('correlation-set-connections/duplicate', {
                ids: selectedRowIds,
              });
              didDuplicate = true;
              notificationService.success('Correlation set was duplicated successfully');
            } catch (e) {
              console.error(e);
              notificationService.error('Could not duplicate correlation set. See logs for more information');
            }

            return {
              shouldGridReload: didDuplicate,
              shouldClearSelection: didDuplicate,
            };
          },
          disable: () => false,
          show: ({ selectedRowIds }) =>
            selectedRowIds.length === 1 && isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name),
        },
        {
          label: 'Delete',
          isGlobal: true,
          icon: BigidDeleteIcon,
          execute: async queryComponents => {
            let shouldDelete = false;
            const { selectedRowIds, allSelected, totalRows, filter } = queryComponents;
            try {
              shouldDelete = await showConfirmationDialog({
                entitiesCount: allSelected ? totalRows : selectedRowIds.length,
                entityNameSingular: 'Correlation Set',
                entityNamePlural: 'Correlation Sets',
              });

              if (shouldDelete) {
                await httpService.delete(`correlation-set-connections`, {
                  ids: selectedRowIds,
                  allSelected,
                  query: { filter },
                });
                notificationService.success('Correlation sets deleted successfully');
              }
            } catch (e) {
              notificationService.error('Could not delete correlation sets. See logs for more information');
              console.error(e);
            }

            return {
              shouldGridReload: shouldDelete,
              shouldClearSelection: shouldDelete,
            };
          },
          disable: () => false,
          show: ({ selectedRowIds }) =>
            selectedRowIds.length > 0 && isPermitted(CORRELATION_SET_PERMISSIONS.DELETE.name),
        },
      ],
    }),
    [gridColumns, preferences, filterToolbarConfig, updatePreferences],
  );
  // TODO: Remove FF check for CorrelationSetWizardDialog when new Correlation set flow is completely ready.

  const BigidGridWithToolbarMemo = useMemo(
    () => <BigidGridWithToolbar {...gridWithToolbarConfig} />,
    [gridWithToolbarConfig],
  );

  const CorrelationSetsImportDialogMemo = useMemo(
    () => <CorrelationSetsImportDialog isOpen={isImportModalOpen} onClose={() => setImportModalOpen(false)} />,
    [isImportModalOpen],
  );

  // TODO: Uncomment the CorrelationSetWizardDialog component when new Correlation set flow is completely ready
  return (
    <div className={gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && BigidGridWithToolbarMemo}
      </BigidPaper>
      <CorrelationSetsImportDialog isOpen={isImportModalOpen} onClose={handleImportModalClose} />
      {isNewCorrelationSetPageFFEnabled && isWizardFlowPermitted && (
        <CorrelationSetWizardDialog
          title="New correlation set"
          isOpen={isWizardOpen}
          onClose={() => {
            setWizardOpen(false);
          }}
        />
      )}
      {CorrelationSetsImportDialogMemo}
      {isNewCorrelationSetPageFFEnabled && (
        <CorrelationSetsSidePanel name={activeName} setName={setActiveName} isOpen={Boolean(activeName)} />
      )}
    </div>
  );
};

export const CorrelationSetConnections: FC = () => {
  const mtCorrelation = Boolean(getApplicationPreference('MT_CORRELATION_FF_ENABLED'));
  return (
    <>
      {mtCorrelation && <CorrelationSetConnectionsComponent />}
      {!mtCorrelation && (
        <MtMigrationWrapper viewId={MtNotSupportedViews.CORRELATION_SETS}>
          <CorrelationSetConnectionsComponent />
        </MtMigrationWrapper>
      )}
    </>
  );
};
