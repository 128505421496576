import React, { FC } from 'react';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { SensitivityClassificationForm as SensitivityClassificationFormNew } from './SensitivityClassificationForm/SensitivityClassificationForm';
import { SensitivityClassificationForm } from '../SensitivityClassification/SensitivityClassificationForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SensitivityClassificationFormWrapper: FC<any> = () => {
  return !getApplicationPreference('SENSITIVITY_CLASSIFICATION_NEW_DESIGN_FF') ? (
    <SensitivityClassificationForm />
  ) : (
    <SensitivityClassificationFormNew />
  );
};
