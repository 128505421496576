import React from 'react';
import { BigidWidgetContainerEmptyStateConfig, BigidBody1, BigidLink } from '@bigid-ui/components';
import { BigidDataFrameworksIllustration, BigidNoDataIllustration } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { useLocalTranslation } from '../../translations';
import { ComplianceDashboardWidget } from '../ComplianceDashboard';
import { DescriptionWrapper } from '../Widgets/WidgetsStyles';

export const useWidgetEmptyState = (
  widgetType: ComplianceDashboardWidget,
  hasError: boolean,
  isEmpty: boolean,
  dataAid: string,
): BigidWidgetContainerEmptyStateConfig => {
  const { t } = useLocalTranslation('compliance.widgets');

  const handleRedirect = (value: string) => {
    $state.go(value);
  };

  switch (true) {
    case widgetType === ComplianceDashboardWidget.FAILED_CONTROLS:
      if (hasError) {
        return {
          isEmpty,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper>
              <BigidBody1>{t('emptyState.defaultTextError')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      } else {
        return {
          isEmpty,
          illustration: BigidDataFrameworksIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper>
              <BigidBody1>{t('emptyState.compoundText.part1')}</BigidBody1>
              <BigidLink
                data-aid={generateDataAid(dataAid, ['emptyState', 'frameworkslink'])}
                text={t('emptyState.compoundText.part2')}
                onClick={() => handleRedirect(CONFIG.states.FRAMEWORKS)}
                underline="hover"
              />
              <BigidBody1>{t('emptyState.compoundText.part3')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      }
    case widgetType === ComplianceDashboardWidget.TOP_FAILED:
      if (hasError) {
        return {
          isEmpty,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextError')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      } else {
        return {
          isEmpty,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextTitle')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      }
    case widgetType === ComplianceDashboardWidget.TOP_RISK:
      if (hasError) {
        return {
          isEmpty,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextError')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      } else {
        return {
          isEmpty,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextTitle')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      }
    case widgetType === ComplianceDashboardWidget.DATA_SOURCE:
      if (hasError) {
        return {
          isEmpty,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextError')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      } else {
        return {
          isEmpty,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: (
            <DescriptionWrapper rowDirection="column">
              <BigidBody1>{t('emptyState.defaultTextTitle')}</BigidBody1>
            </DescriptionWrapper>
          ),
        };
      }
    default:
      return {
        isEmpty,
        illustration: BigidNoDataIllustration,
        illustrationSize: 'small',
        description: (
          <DescriptionWrapper>
            <BigidBody1>{t('emptyState.defaultTextError')}</BigidBody1>
          </DescriptionWrapper>
        ),
      };
  }
};
