import { useMemo } from 'react';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../../translations';
import { getDataSourcesListWithSelection } from '../../../../../utilities/dataSourcesUtils';
import { DataSourceType, WizardFormFields } from '../../../types/CorrelationSetsTypes';
import { notificationService } from '../../../../../services/notificationService';
import { getDataSourceOptions, getIsCustomQueryStringValue } from '../../../utils/utils';
import { isCorrelationNameValid, requiredFieldValidator } from './validationUtils';

interface UseDetailsStepFormConfigProps {
  formState: BigidFormValues;
}

export const useDetailsStepFormConfig = ({ formState }: UseDetailsStepFormConfigProps) => {
  const { t } = useLocalTranslation('wizard.detailsStep');
  const isCustomQueryStringValue = getIsCustomQueryStringValue(formState?.isCustomQueryString);

  const { fields } = useMemo(() => {
    const fields: BigidFormField[] = [
      {
        name: WizardFormFields.name,
        type: BigidFormFieldTypes.TEXT,
        label: t('nameLabel'),
        fieldProps: { placeholder: t('namePlaceholder') },
        misc: {
          fullWidth: true,
        },
        isRequired: true,
        validate: (name: string) => {
          const isRequiredValid = requiredFieldValidator(name);
          const isNameValid = isCorrelationNameValid(name);

          if (isRequiredValid) {
            return isRequiredValid;
          }
          if (isNameValid) {
            return isNameValid;
          }

          return false;
        },
      },

      {
        name: WizardFormFields.dsConnection,
        type: BigidFormFieldTypes.SELECT,
        label: t('dataSourceLabel'),
        misc: {
          fullWidth: true,
        },
        isRequired: true,
        fieldProps: {
          isMulti: false,
          isAsync: true,
          shouldLoadInitialOptions: true,
          placeholder: t('dataSourcePlaceholder'),
          loadOptions: async () => {
            try {
              const { availableDsConnections } = await getDataSourcesListWithSelection();
              return getDataSourceOptions(availableDsConnections as unknown as DataSourceType[]);
            } catch (e) {
              notificationService.error('Failed to fetch scopes. See logs for more information.');
            }
          },
        },
        validate: (value: BigidDropdownOption[]) => {
          return requiredFieldValidator(value);
        },
      },

      {
        name: WizardFormFields.isCustomQueryString,
        type: BigidFormFieldTypes.RADIO,
        fieldProps: {
          horizontal: true,
        },
        options: [
          {
            label: t('tableNameRadio'),
            value: false,
          },
          {
            label: t('customQueryRadio'),
            value: true,
          },
        ],
      },

      {
        name: WizardFormFields.db_table,
        type: BigidFormFieldTypes.TEXT,
        fieldProps: { placeholder: t('selectTablePlaceholder') },
        misc: {
          fullWidth: true,
        },
        isRequired: !isCustomQueryStringValue,
        validate: (value: string) => {
          if (!isCustomQueryStringValue) {
            return requiredFieldValidator(value);
          } else {
            return false;
          }
        },
      },

      {
        name: WizardFormFields.custom_query_string,
        type: BigidFormFieldTypes.TEXTAREA,
        fieldProps: {
          multiline: true,
          placeholder: t('customQueryPlaceholder'),
        },
        misc: {
          rows: 5,
        },
        isRequired: isCustomQueryStringValue,
        validate: (value: BigidDropdownOption[]) => {
          if (isCustomQueryStringValue) {
            return requiredFieldValidator(value);
          } else {
            return false;
          }
        },
      },

      {
        name: WizardFormFields.identities_force_sample,
        label: t('useSampleLabel'),
        type: BigidFormFieldTypes.CHECKBOX,
        labelPosition: BigidFormFieldLabelPosition.left,
        fieldProps: {
          dataAid: `${generateDataAid('checkbox', ['is-use-sample'])}`,
        },
      },
      {
        name: WizardFormFields.identities_sample_size,
        type: BigidFormFieldTypes.TEXT,
        label: false,
        fieldProps: { placeholder: t('sampleSizePlaceholder'), helperText: t('sampleSizeHelperText') },
        isRequired: false,
      },

      {
        name: WizardFormFields.skip_sample_start,
        label: t('startSampleLabel'),
        type: BigidFormFieldTypes.CHECKBOX,
        labelPosition: BigidFormFieldLabelPosition.left,
        fieldProps: {
          dataAid: `${generateDataAid('checkbox', ['start-sample'])}`,
        },
      },
      {
        name: WizardFormFields.skip_sample_size,
        type: BigidFormFieldTypes.TEXT,
        label: false,
        isRequired: false,
        disabled: !formState?.[WizardFormFields.skip_sample_start],
      },
      {
        name: WizardFormFields.use_random_sampling,
        label: t('randomSampleLabel'),
        type: BigidFormFieldTypes.CHECKBOX,
        labelPosition: BigidFormFieldLabelPosition.left,
        fieldProps: {
          dataAid: `${generateDataAid('checkbox', ['use-random-sample'])}`,
        },
      },
      {
        name: WizardFormFields.attributes,
      },
    ];

    return {
      fields,
    };
  }, [t, formState, isCustomQueryStringValue]);

  return {
    fields,
  };
};
