import { getFixedT } from '../../../translations';

const t = getFixedT('wizard.formValidationMessages');

export function isCorrelationNameValid(name = '') {
  const pattern = new RegExp(/^[\w\-\s\(\):]+$/);
  const isValid = pattern.test(name);

  if (!isValid) {
    return t('nameFieldNotValid');
  }
}

export const requiredFieldValidator = (fieldValue: string | number | any[]) => {
  if (typeof fieldValue === 'object') {
    return !fieldValue.length && t('required');
  }

  if (typeof fieldValue === 'string') {
    return !fieldValue?.trim() && t('required');
  }

  return !fieldValue && t('required');
};
