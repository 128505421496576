import React, { FC, useMemo, useState } from 'react';
import {
  BigidAdvancedToolbarFilterUnion,
  BigidWidgetContainer,
  capitalizeFirstLetter,
  BigidBody1,
  BigidMenuItemProps,
} from '@bigid-ui/components';
import { BigidLineChartV2DataItem, BigidLineChartV2 } from '@bigid-ui/visualisation';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import { Menu, Dropdown } from './helpers/HelperComponents';
import { RiskWidgetWrapper } from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';
import { RiskColors, Severities } from '../PrivacyExecutiveDashboardTypes';

export interface DataRiskTrendWidgetProps {
  title: string;
}

export const DataRiskTrendWidget: FC<DataRiskTrendWidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading } = useFetchWidgetData(fetchDataRiskTrendsWidgetData);
  const [severity, setSeverity] = useState(Severities.CRITICAL as string);

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  const dataToRender = useMemo(() => {
    const showAll = severity === Severities.ALL;
    return showAll ? data : data?.map(item => ({ category: item.category, [severity]: item[severity] }));
  }, [data, severity]);

  const handleMenuItemSelect = (item: BigidMenuItemProps) => {
    setSeverity(item.id as string);
  };

  return (
    <RiskWidgetWrapper>
      <BigidWidgetContainer
        content={
          <BigidLineChartV2
            legendProps={{ hideLegend: true }}
            colorDataMap={{
              critical: RiskColors.CRITICAL,
              high: RiskColors.HIGH,
              medium: RiskColors.MEDIUM,
              low: RiskColors.LOW,
            }}
            isLineSmoothed
            numberFormat="#."
            valueAxisTitle={t('dataRiskTrend.valueAxisTitle')}
            data={dataToRender}
            seriesLabelFormatter={capitalizeFirstLetter}
          />
        }
        titleConfig={{ title }}
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: data?.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{t('emptyState.defaultText')}</BigidBody1>,
        }}
        actionsSlot={
          <>
            <Dropdown onItemSelect={handleMenuItemSelect} currentItem={severity} />
            <Menu menuItems={menuItems} />
          </>
        }
      />
    </RiskWidgetWrapper>
  );
};

const fetchDataRiskTrendsWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<BigidLineChartV2DataItem[]> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 3000, data);
  });
};

const data = [
  {
    category: 'Jan',
    critical: 37,
    high: 23,
    medium: 11,
    low: 56,
  },
  {
    category: 'Feb',
    critical: 39,
    high: 45,
    medium: 23,
    low: 11,
  },
  {
    category: 'Mar',
    critical: 38,
    high: 56,
    medium: 22,
    low: 67,
  },
  {
    category: 'Apr',
    critical: 34,
    high: 23,
    medium: 11,
    low: 56,
  },
  {
    category: 'May',
    critical: 28,
    high: 34,
    medium: 11,
    low: 7,
  },
  {
    category: 'Jun',
    critical: 60,
    high: 45,
    medium: 23,
    low: 1,
  },
  {
    category: 'Jul',
    critical: 81,
    high: 56,
    medium: 67,
    low: 23,
  },
  {
    category: 'Aug',
    critical: 50,
    high: 34,
    medium: 23,
    low: 23,
  },
  {
    category: 'Sep',
    critical: 78,
    high: 45,
    medium: 67,
    low: 23,
  },
  {
    category: 'Oct',
    critical: 56,
    high: 78,
    medium: 54,
    low: 22,
  },
  {
    category: 'Nov',
    critical: 35,
    high: 45,
    medium: 23,
    low: 11,
  },
  {
    category: 'Dec',
    critical: 40,
    high: 23,
    medium: 67,
    low: 34,
  },
];
