import React from 'react';
import { DataCatalogObjectDetails } from '../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { styled } from '@mui/material';
import { DataExplorerColumns } from '../../GridComponents/sidePanels/CatalogSidePanel/DataExplorerColumns/DataExplorerColumns';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';

export const GridWrapper = styled('div')`
  padding: 24px;
  display: flex;
  flex-grow: 1;
`;

type ColumnsTabProps = {
  objectDetails: DataCatalogObjectDetails;
};

export const ColumnsTab = ({ objectDetails }: ColumnsTabProps) => {
  const { scannerType, dataSourceName, fullyQualifiedName } = objectDetails;
  const isDataPreviewDisabled = getApplicationPreference('DATA_PREVIEW_DISABLED');
  return (
    <GridWrapper>
      <DataExplorerColumns
        id={fullyQualifiedName}
        isExtendedView
        datasource={{ scannerType, value: dataSourceName }}
        isDataPreviewDisabled={isDataPreviewDisabled}
      />
    </GridWrapper>
  );
};
