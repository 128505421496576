import React, { FC, useEffect, useState, useContext, Dispatch, SetStateAction } from 'react';
import { NoTicketConfiguration, Configuration, CreateTicket } from './TicketContent';
import { useLocalTranslation } from '../../../../translations';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { ActionableInsightsContext } from '../../../../hooks/ActionableInsightsContext';
import { BigidLoader } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { ModalContentProps } from '../../caseActionsService';
import { CaseModel } from '../../../../actionableInsightsService';
import { getApplicationPreference } from '../../../../../../services/appPreferencesService';
import { ServiceTicketingType } from './TicketContent/CreateTicket';
import { notificationService } from '../../../../../../services/notificationService';

export enum ContentTypes {
  CONFIGURATION = 'configuration',
  NO_CONFIGURATION_WITHOUT_ACCESS = 'noConfigurationWithoutAccess',
  TICKET_CREATION = 'ticketCreation',
  LOADING = 'loading',
  NO_CONFIGURATION = 'noConfiguration',
}

export enum ServiceTypes {
  JIRA_TYPE = 'jira',
  SERVICE_NOW_TYPE = 'tpa',
}

export interface JiraProps extends Omit<ModalContentProps, 'rowsData'> {
  updateDialogTitle: Dispatch<SetStateAction<string>>;
  rowsData: CaseModel;
}

const LoaderContainer = styled.div({
  paddingTop: 80,
  height: 200,
});

export const TicketModal: FC<JiraProps> = ({ updateDialogTitle, closeModal, rowsData }) => {
  const { jiraConfigurations } = useContext(ActionableInsightsContext);
  const [ticketContent, setTicketContent] = useState(ContentTypes.LOADING);
  const { t } = useLocalTranslation('Action.services');

  const isServiceNowPermitted = getApplicationPreference('DSPM_SERVICE_NOW');
  const premittedListOfServices = jiraConfigurations?.filter((config: any) =>
    config?.type === ServiceTicketingType.SERVICE_NOW && !isServiceNowPermitted ? false : true,
  );

  const isUserWithPermission = isPermitted(ACTION_CENTER_PERMISSIONS.CREATE_CONFIGURATIONS.name);

  useEffect(() => {
    if (jiraConfigurations === undefined) {
      notificationService.error(
        'Ticketing service could not be accessed.\n' +
          'Please check that Action Center and Application Framework are up, or contact your IT administrator.',
      );
      closeModal();
      return;
    } else if (!isUserWithPermission && premittedListOfServices?.length === 0) {
      setTicketContent(ContentTypes.NO_CONFIGURATION_WITHOUT_ACCESS);
    } else {
      if (premittedListOfServices?.length) {
        setTicketContent(ContentTypes.TICKET_CREATION);
      } else {
        setTicketContent(ContentTypes.NO_CONFIGURATION);
      }
    }
  }, [premittedListOfServices]);

  useEffect(() => {
    updateDialogTitle(t(ticketContent));
  }, [ticketContent, t, updateDialogTitle]);

  const getJiraDialogContent = () => {
    switch (ticketContent) {
      case ContentTypes.CONFIGURATION:
        return <Configuration closeModal={closeModal} updateModalContent={setTicketContent} />;
      case ContentTypes.TICKET_CREATION:
        return <CreateTicket closeModal={closeModal} updateModalContent={setTicketContent} rowData={rowsData} />;
      case ContentTypes.NO_CONFIGURATION_WITHOUT_ACCESS:
        return <NoTicketConfiguration withPermission={false} />;
      case ContentTypes.NO_CONFIGURATION:
        return <NoTicketConfiguration withPermission={true} />;
      default:
        return (
          <LoaderContainer>
            <BigidLoader position="relative" />
          </LoaderContainer>
        );
    }
  };

  return <>{getJiraDialogContent()}</>;
};
