import { BigidGridProps, PagingPanelPosition } from '@bigid-ui/grid';
import { CONFIG } from '../../../../../../config/common';
import { $state } from '../../../../../services/angularServices';
import {
  CatalogSearchGridRow,
  DataSourceGridRow,
  PolicyGridRow,
  ResultsEntityType,
  ResultsGridRowTypeMap,
} from '../../../types';
import {
  getCatalogGridCardViewCellValue,
  getCatalogGridTableViewCellValue,
  getDataSourceGridCellValue,
  getPolicyGridCellValue,
} from '../../gridUtils';
import { ReactText, useCallback, useMemo } from 'react';
import {
  CatalogGridViewMode,
  useDataCatalogSearchResultsContext,
} from '../../../contexts/dataCatalogSearchResultsContext';
import { mapFieldValueToBiqlString } from '../../../../../services/filtersToBiqlService';
import { pageSize } from '../../../constants';
import { difference } from 'lodash';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';

export function useGridConfig() {
  const { state, dispatchCatalogSearchAction, entityType, query, rows, totalCount } =
    useDataCatalogSearchResultsContext();
  const { gridConfig: contextGridConfig } = state;
  const { isSelectAll, page, selectedRowsIds, unselectedRowsIds, catalogGridViewMode } = contextGridConfig;

  const isSidePanelEnabled = getApplicationPreference('DATA_EXPLORER_SIDEPANEL_ENABLED');

  const skip = (page - 1) * pageSize;

  const computedSelectedRows = useMemo(() => {
    if (isSelectAll) {
      return difference(
        rows?.map(row => row.id),
        unselectedRowsIds,
      );
    } else {
      return selectedRowsIds;
    }
  }, [selectedRowsIds, unselectedRowsIds, isSelectAll, rows]);

  const handlePagingChanged = useCallback(
    (page: number) => {
      dispatchCatalogSearchAction({
        type: 'UPDATE_GRID_CONFIG',
        payload: { page: page + 1 },
      });
    },
    [dispatchCatalogSearchAction],
  );

  const onSelectedRowsChanged = useCallback(
    (selectedRowIds: ReactText[], lastUnselectedRowId: ReactText[] = []) => {
      dispatchCatalogSearchAction({
        type: 'PROCESS_ROWS_SELECTION',
        payload: {
          selectedRowIds: selectedRowIds.map(id => String(id)),
          lastUnselectedRowId: lastUnselectedRowId.map(id => String(id)),
        },
      });
    },
    [dispatchCatalogSearchAction],
  );

  const gridConfig = useMemo(() => {
    const basicConfig = {
      pageSize: pageSize,
      columns: [] as any,
      rows: rows || [],
      skip,
      totalRowsCount: totalCount,
      onPagingChanged: handlePagingChanged,
      selectedRowIds: computedSelectedRows,
      onSelectedRowIdsChanged: onSelectedRowsChanged,
      showFilteringControls: false,
      pagingMode: true,
      showSortingControls: true,
      gridId: entityType,
      pagingPanelPosition: PagingPanelPosition.Center,
    };

    const catalogCardViewModeConfig = {
      ...basicConfig,
      columns: getCatalogGridCardViewCellValue(query),
      showSelectionCheckboxes: true,
      showSelectionColumn: true,
      cardListMode: true,
      rowClickShouldKeepSelection: true,
      onRowClick: async (row: CatalogSearchGridRow) => {
        if (!isSidePanelEnabled) {
          $state.go(CONFIG.states.CATALOG_PREVIEW, { id: row.id });
          return;
        }

        return new Promise(resolve => {
          dispatchCatalogSearchAction({
            type: 'SET_SELECTED_ITEM',
            payload: {
              selectedItem: row as CatalogSearchGridRow,
              onPanelCloseCallback: resolve,
            },
          });
        });
      },
    } as BigidGridProps<CatalogSearchGridRow>;

    const catalogTableViewModeConfig = {
      ...basicConfig,
      columns: getCatalogGridTableViewCellValue(),

      showSelectionColumn: true,
      onRowClick: async (row: CatalogSearchGridRow) => {
        if (!isSidePanelEnabled) {
          $state.go(CONFIG.states.CATALOG_PREVIEW, { id: row.id });
          return;
        }

        return new Promise(resolve => {
          dispatchCatalogSearchAction({
            type: 'SET_SELECTED_ITEM',
            payload: {
              selectedItem: row as CatalogSearchGridRow,
              onPanelCloseCallback: resolve,
            },
          });
        });
      },
    } as BigidGridProps<CatalogSearchGridRow>;

    const viewModeConfigMap: Record<CatalogGridViewMode, BigidGridProps<CatalogSearchGridRow>> = {
      list: catalogCardViewModeConfig,
      table: catalogTableViewModeConfig,
    };

    switch (entityType) {
      case 'catalog':
        return viewModeConfigMap[catalogGridViewMode];
      case 'datasource':
        return {
          ...basicConfig,
          columns: getDataSourceGridCellValue(query),
          cardListMode: true,
          onRowClick: async ({ id }: DataSourceGridRow) => {
            const filter = mapFieldValueToBiqlString('system', String(id));
            $state.go(CONFIG.states.CATALOG, { filter });
          },
        } as BigidGridProps<DataSourceGridRow>;
      case 'policy':
        return {
          ...basicConfig,
          columns: getPolicyGridCellValue(query),
          cardListMode: true,
          onRowClick: async ({ id }: PolicyGridRow) => {
            $state.go(CONFIG.states.POLICIES, { policyId: encodeURI(id) });
          },
        } as BigidGridProps<PolicyGridRow>;
      default:
        return basicConfig;
    }
  }, [
    computedSelectedRows,
    entityType,
    rows,
    onSelectedRowsChanged,
    skip,
    totalCount,
    handlePagingChanged,
    dispatchCatalogSearchAction,
    isSidePanelEnabled,
    query,
    catalogGridViewMode,
  ]);

  return gridConfig;
}
