import React from 'react';
import { ImportDataSourcesForm } from './ImportDataSourcesForm';
import { openSystemDialog } from '../../services/systemDialogService';
import { noop } from 'lodash';

export const openImportDataSourceFormModal = (onSuccess: () => void) => {
  openSystemDialog({
    title: 'Import DS Connections File',
    onClose: noop,
    content: function ImportDataSourceFormModalContent({ onClose }) {
      return (
        <ImportDataSourcesForm
          onSuccess={() => {
            onSuccess();
            onClose();
          }}
          close={onClose}
        />
      );
    },
    maxWidth: 'sm',
  });
};
