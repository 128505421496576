import React, { FC, useMemo, useState } from 'react';

import {
  BigidAdvancedToolbarFilterUnion,
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidLink,
  BigidMenuItemProps,
  BigidWidgetContainer,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidStackedBar } from '@bigid-ui/visualisation';
import {
  BigidApplicationSetupIcon,
  BigidChevronDownIcon,
  BigidChevronUpIcon,
  BigidGoToIcon,
  BigidIbanIcon,
  BigidNoDataIllustration,
  BigidVendorIcon,
} from '@bigid-ui/icons';

import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { useLocalTranslation } from '../translations';
import { RiskColors, Severities } from '../PrivacyExecutiveDashboardTypes';
import { Dropdown, Menu, WidgetListWithTitle } from './helpers/HelperComponents';
import {
  SectionsWrapper,
  SectionWrapper,
  SectionTitle,
  SectionContent,
  SectionContentWrapper,
  HiddenContentWrapper,
  HiddenContentInnerWrapper,
} from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';
import { omit, pick } from 'lodash';

export interface DataRiskLandscapeWidgetProps {
  title: string;
}

enum Sections {
  ASSETS = 'Assets',
  LEGAL_ENTITIES = 'Legal entities',
  VENDORS = 'Vendors',
}

interface Case {
  name: string;
  count: number;
  severity: Severities;
}

interface DataRiskLandscapeResponse {
  category: Sections;
  totalCount: number;
  cases: Case[];
}

type DataRiskLandscapeWidgetValues = {
  totalCount: number;
  count: number;
  severities: Record<Severities, { count: number; cases: Case[] }>;
};

type DataRiskLandscapeWidgetData = Record<Sections, DataRiskLandscapeWidgetValues>;

const RISK_LANDSCAPE_SECTIONS = {
  [Sections.ASSETS]: {
    icon: BigidApplicationSetupIcon,
    addNewTranslationKey: 'dataRiskLandscape.addNewAsset',
    onClick: () => {
      $state.go(CONFIG.states.APPLICATIONS_SETUP);
    },
  },
  [Sections.LEGAL_ENTITIES]: {
    icon: BigidIbanIcon,
    addNewTranslationKey: 'dataRiskLandscape.addNewLegalEntity',
    onClick: () => {
      $state.go(CONFIG.states.LEGAL_ENTITIES);
    },
  },
  [Sections.VENDORS]: {
    icon: BigidVendorIcon,
    addNewTranslationKey: 'dataRiskLandscape.addNewVendor',
    onClick: () => {
      $state.go(CONFIG.states.VENDORS);
    },
  },
};

export const DataRiskLandscapeWidget: FC<DataRiskLandscapeWidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading, error, fetchData } = useFetchWidgetData(fetchDataRiskLandscapeWidgetData);
  const [severity, setSeverity] = useState(Severities.CRITICAL as string);
  const [isExpanded, setExpanded] = useState(false);
  const isShowingAllSeverities = severity === Severities.ALL;

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: false, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <>
      <BigidWidgetContainer
        content={
          <SectionsWrapper>
            {Object.values(Sections).map(section => {
              const { icon: Icon, addNewTranslationKey, onClick } = RISK_LANDSCAPE_SECTIONS[section];
              const sectionData = data?.[section];
              return (
                <SectionWrapper key={section}>
                  <SectionTitle>
                    <Icon />
                    <BigidBody1>
                      <strong>{section}</strong>
                    </BigidBody1>
                    {!!sectionData && (
                      <BigidButtonIcon size="xsmall" icon={() => <BigidGoToIcon size="small" />} onClick={onClick} />
                    )}
                  </SectionTitle>
                  <SectionContent>
                    {!!sectionData ? (
                      <SectionContentWrapper>
                        <BigidStackedBar
                          legendProps={{
                            hideLegend: true,
                          }}
                          slices={getSlices(severity, sectionData.severities, sectionData.totalCount, section)}
                        ></BigidStackedBar>
                        <BigidBody1 size="small" sx={{ marginLeft: '8px' }}>
                          {t(`dataRiskLandscape.countOfSection${isShowingAllSeverities ? '' : 's'}`, {
                            count: sectionData.severities[severity as Severities].count,
                            totalCount: sectionData.totalCount,
                            section: section.toLowerCase(),
                            severity,
                          })}
                        </BigidBody1>
                        <HiddenContentWrapper isExpanded={isExpanded}>
                          <HiddenContentInnerWrapper>
                            <WidgetListWithTitle
                              items={sectionData.severities[severity as Severities].cases.slice(0, 5).map(c => ({
                                description: c.name,
                                value: c.count,
                              }))}
                              headerTitleOne={
                                isShowingAllSeverities
                                  ? section
                                  : t('dataRiskLandscape.headerTitleOne', { section, severity })
                              }
                              headerTitleTwo={t('dataRiskLandscape.headerTitleTwo')}
                              margin={'0 8px 0 -4px'}
                            />
                          </HiddenContentInnerWrapper>
                        </HiddenContentWrapper>
                      </SectionContentWrapper>
                    ) : (
                      <BigidBody1 size="small">
                        {t(`dataRiskLandscape.noData`, { section: section.toLowerCase() })}{' '}
                        <BigidLink text={t(addNewTranslationKey)} onClick={onClick} />
                      </BigidBody1>
                    )}
                  </SectionContent>
                </SectionWrapper>
              );
            })}
          </SectionsWrapper>
        }
        titleConfig={{
          title,
        }}
        actionsSlot={
          <>
            <Dropdown
              currentItem={severity}
              onItemSelect={(item: BigidMenuItemProps) => {
                setSeverity(item.id as string);
              }}
            />
            <TertiaryButton
              size="small"
              onClick={() => setExpanded(isExpanded => !isExpanded)}
              text={t(`dataRiskLandscape.${isExpanded ? 'showLess' : 'showMore'}`)}
              endIcon={isExpanded ? <BigidChevronUpIcon /> : <BigidChevronDownIcon />}
            />
            <Menu menuItems={menuItems} />
          </>
        }
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: !!error,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{!!error ? t('emptyState.errorText') : t('emptyState.defaultText')}</BigidBody1>,
          actions: [{ show: () => !!error, label: t('emptyState.tryAgain'), execute: fetchData }],
        }}
      />
    </>
  );
};

const fetchDataRiskLandscapeWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<DataRiskLandscapeWidgetData> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 1700, transformData(data as DataRiskLandscapeResponse[]));
  });
};

const getSlices = (
  severity: string,
  severities: DataRiskLandscapeWidgetValues['severities'],
  totalCount: number,
  section: Sections,
) => {
  const allSeverities = severity === Severities.ALL;
  const slices = Object.entries(
    allSeverities
      ? omit(severities, [Severities.ALL])
      : {
          ...pick(severities, severity),
          [Severities.ALL]: {
            count: totalCount - severities[severity as Severities].count,
          },
        },
  ).map(([severity, value]) => ({
    color:
      severity !== Severities.ALL
        ? RiskColors[severity.toUpperCase() as keyof typeof RiskColors]
        : BigidColorsV2.gray[200],
    label: severity,
    value: value.count,
    tooltip: () => (severity === Severities.ALL ? `All ${section.toLowerCase()}: ${totalCount}` : ''),
  }));

  return slices;
};

const data = [
  {
    category: 'Assets',
    totalCount: 10,
    cases: [
      { name: 'Marketing', count: 8, severity: 'critical' },
      { name: 'Backup System', count: 12, severity: 'critical' },
      { name: 'SAP', count: 15, severity: 'critical' },
      { name: 'Billing', count: 6, severity: 'critical' },
      { name: 'CRM', count: 18, severity: 'critical' },
      { name: 'SAP', count: 23, severity: 'high' },
      { name: 'Billing', count: 9, severity: 'high' },
      { name: 'Accounting', count: 9, severity: 'high' },
      { name: 'SAP', count: 23, severity: 'medium' },
      { name: 'Billing', count: 9, severity: 'medium' },
      { name: 'SAP', count: 42, severity: 'low' },
      { name: 'Billing', count: 19, severity: 'low' },
    ],
  },
  {
    category: 'Legal entities',
    totalCount: 10,
    cases: [
      { name: 'Meta', count: 17, severity: 'critical' },
      { name: 'Facebook US', count: 21, severity: 'critical' },
      { name: 'Facebook EMEA', count: 14, severity: 'critical' },
      { name: 'Facebook UK', count: 4, severity: 'critical' },
      { name: 'Instagram', count: 22, severity: 'critical' },
      { name: 'Thread', count: 23, severity: 'high' },
      { name: 'Instagram', count: 9, severity: 'high' },
      { name: 'Thread', count: 21, severity: 'medium' },
      { name: 'Instagram', count: 14, severity: 'medium' },
      { name: 'Thread', count: 23, severity: 'low' },
      { name: 'Instagram', count: 9, severity: 'low' },
    ],
  },
  {
    category: 'Vendors',
    totalCount: 10,
    cases: [
      { name: 'Mailchimp', count: 14, severity: 'critical' },
      { name: 'Microsoft', count: 21, severity: 'critical' },
      { name: 'Drive', count: 6, severity: 'critical' },
      { name: 'Google', count: 17, severity: 'critical' },
      { name: 'ChatGTP', count: 21, severity: 'critical' },
      { name: 'Google', count: 33, severity: 'high' },
      { name: 'ChatGTP', count: 3, severity: 'high' },
      { name: 'Google', count: 18, severity: 'medium' },
      { name: 'ChatGTP', count: 19, severity: 'medium' },
      { name: 'Google', count: 23, severity: 'low' },
      { name: 'ChatGTP', count: 9, severity: 'low' },
    ],
  },
];

function transformData(data: DataRiskLandscapeResponse[]): DataRiskLandscapeWidgetData {
  return data.reduce((acc, item) => {
    const casesBySeverity = item.cases.reduce((acc, curr) => {
      if (!acc[curr.severity]) {
        acc[curr.severity] = {
          count: 0,
          cases: [],
        };
      }

      acc[curr.severity].count += 1;
      acc[curr.severity].cases.push({ name: curr.name, count: curr.count });
      acc[curr.severity].cases.sort((a, b) => b.count - a.count);
      return acc;
    }, {} as Record<Severities, { count: number; cases: Omit<Case, 'severity'>[] }>);

    return {
      ...acc,
      [item.category]: {
        totalCount: item.totalCount,
        severities: {
          ...casesBySeverity,
          [Severities.ALL]: {
            count: new Set(item.cases.map(c => c.name)).size,
            cases: Object.entries(
              item.cases.reduce((acc, c) => {
                if (!acc[c.name]) {
                  acc[c.name] = 0;
                }
                acc[c.name] += c.count;
                return acc;
              }, {} as Record<string, number>),
            )
              .map(([name, count]) => ({ name, count }))
              .sort((a, b) => b.count - a.count),
          },
        },
      },
    };
  }, {} as DataRiskLandscapeWidgetData);
}
