import { httpService } from '../../services/httpService';
import {
  MetadataSearchQuery,
  MetadataSearchResultsPerType,
  MetadataSearchResultEntity,
  MetadataSearchResultEntityFieldName,
  MetadataSearchResultEntityField,
  MetadataSearchResultEntityAsset,
  MetadataSearchTypeIndexingStatus,
  MetadataSearchUserPreferences,
  MetadataSearchEntityTypeConfig,
} from './MetadataSearchTypes';
import {
  MetadataSearchFieldFilter,
  MetadataSearchFilterConfig,
  MetadataSearchFilterConfigValue,
  MetadataSearchFilterFieldName,
  MetadataSearchFilterFieldType,
} from './MetadataSearchFilters/MetadataSearchFiltersTypes';
import { userPreferencesService } from '../../../react/services/userPreferencesService';
import { CONFIG } from '../../../config/common';
import { convertFriendlyNameToEntityType } from './MetadataSearchUtils';

export type MetadataSearchEntityTypeCategory = 'catalog' | string;

export type MetadataSearchFetchQuickResultsPayload = {
  text: MetadataSearchQuery;
  filter?: MetadataSearchFieldFilter[];
  top?: number;
};

export type MetadataSearchFetchQuickResultsResponse = {
  typeResults: MetadataSearchResultsPerType[];
};

export type MetadataSearchFetchFiltersPayload = {
  top: number;
  selectedField?: MetadataSearchFilterFieldName;
  selectedFieldType?: MetadataSearchFilterFieldType;
  selectedFieldValue?: string;
  text: MetadataSearchQuery;
  filter: MetadataSearchFieldFilter[];
};

export type MetadataSearchFetchFiltersResponse = {
  filterFields: MetadataSearchFilterConfig[];
};

export type MetadataSearchFetchFilterSuggestionsResponse = {
  suggestions: MetadataSearchFilterConfigValue[];
  totalValues: number;
};

export type MetadataSearchIndexingStatusResponse = {
  lastIndexed: string;
  nextIndexing: string;
  statusPerType: MetadataSearchTypeIndexingStatus[];
};

export type MetadataSearchFetchCustomEntityTypesResponse = MetadataSearchEntityTypeConfig[];

export const getMetadataSearchMapKeys = <T>(map: T) => Object.keys(map) as Array<keyof T>;

export const getMetadataSearchEntityFieldByName = (
  entity: MetadataSearchResultEntity,
  fieldName: MetadataSearchResultEntityFieldName,
): MetadataSearchResultEntityField => {
  return entity.primary.find(({ name }) => name === fieldName);
};

export const getMetadataSearchEntityAssetByName = (
  entity: MetadataSearchResultEntity,
  assetName: string,
): MetadataSearchResultEntityAsset => {
  return (entity.assets || []).find(({ name }) => assetName === name);
};

export const getTypeSafeEnum = <T>(anEnum: T, value: any): T[keyof T] => {
  return anEnum[value as keyof T];
};

export const getMetadataSearchQuickResults = (payload: MetadataSearchFetchQuickResultsPayload) => {
  return httpService.cancelablePost<MetadataSearchFetchQuickResultsResponse, MetadataSearchFetchQuickResultsPayload>(
    `metadata-search/search/quick`,
    payload,
  );
};

export const getMetadataSearchFilters = (payload: MetadataSearchFetchFiltersPayload) => {
  return httpService.cancelablePost<MetadataSearchFetchFiltersResponse, MetadataSearchFetchFiltersPayload>(
    `metadata-search/filter`,
    payload,
  );
};

export const getMetadataSearchFilterSuggestions = (payload: MetadataSearchFetchFiltersPayload) => {
  return httpService.cancelablePost<MetadataSearchFetchFilterSuggestionsResponse, MetadataSearchFetchFiltersPayload>(
    `metadata-search/filter/suggest`,
    payload,
  );
};

export const getMetadataSearchIndexingStatus = () => {
  return httpService.fetch<MetadataSearchIndexingStatusResponse>(`metadata-search/status`).then(({ data }) => data);
};

export const startMetadataSearchIndexing = (entityTypeCategory: MetadataSearchEntityTypeCategory) => {
  let body;
  if (entityTypeCategory === 'catalog') {
    body = {
      forceIndexingFromOnceUponATime: true,
    };
  }
  return httpService
    .put(`metadata-search/index-task/${convertFriendlyNameToEntityType(entityTypeCategory)}`, body)
    .then(({ data }) => data);
};

export const stopMetadataSearchIndexing = (entityTypeCategory: MetadataSearchEntityTypeCategory) => {
  return httpService.delete(`metadata-search/index-task/${entityTypeCategory}`).then(({ data }) => data);
};

export const updateMetadataSearchPreferences = async (newPreferences: Partial<MetadataSearchUserPreferences>) => {
  const preferences = await userPreferencesService.get<MetadataSearchUserPreferences>(
    CONFIG.states.METADATA_SEARCH_RESULTS,
  );

  if (preferences) {
    await userPreferencesService.update({
      ...preferences,
      data: {
        ...preferences.data,
        ...newPreferences,
      },
    });
  } else {
    await userPreferencesService.add({
      preference: CONFIG.states.METADATA_SEARCH_RESULTS,
      data: newPreferences,
    });
  }
};

export function getEntityTypeConfigs() {
  return httpService
    .fetch<MetadataSearchFetchCustomEntityTypesResponse>(`metadata-search/entity-types`)
    .then(({ data }) => data);
}
