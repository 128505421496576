import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { BigidFieldFilter, BigidFilterToolbar, BigidFilterType, BigidLoader, BigidPaper } from '@bigid-ui/components';
import {
  createHotspotsReport,
  getAttrTags,
  getHotspotsDatasources,
  HotspotChartRow,
} from './HotspotsReportChartService';
import { notificationService } from '../../../services/notificationService';
import {
  createEmptyFilterToolbarConfig,
  createEmptyFilterDs,
  createFilterDsWithPreselectedDatasources,
  createFilterToolbarConfigWithPreselectedDatasources,
  performScan,
} from './utils';
import { isEqual } from 'lodash';
import { HotspotsReportsChart } from './HotspotsReportChart';
import { BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { HotspotsContainer } from './HotspotsContainer';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: (props: { isLoading: boolean }) => ({
    height: props.isLoading ? `calc(100vh - 220px)` : 'auto',
    '& [data-aid="BigidLoader"]': {
      position: 'relative',
      height: '100%',
    },
  }),
});

export const HotspotsReportsChartWrapper: FC = () => {
  const scanResultId = useRef<string>();
  const intervalNumber = useRef<number>();
  const [filterToolbarConfig, setFilterToolbarConfig] =
    useState<BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig']>();

  const [filterDs, setFilterDs] = useState<BigidFilterType[]>();

  const [selectedDatasources, setSelectedDatasources] = useState<BigidFieldFilter>();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({ isLoading });

  const onDatasourcesFiltersChange = useCallback((currentFilters: BigidFieldFilter) => {
    setSelectedDatasources(prevFilters => {
      if (!isEqual(currentFilters, prevFilters)) {
        return currentFilters;
      }
      return prevFilters;
    });
  }, []);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Hotspots Reports',
    });
  }, []);

  useEffect(() => {
    const runWhenDatasourcesChanged = async () => {
      try {
        setIsLoading(true);
        // if selectedDatasources cleared
        if (selectedDatasources === undefined) {
          console.log('if selectedDatasources', selectedDatasources);
          const availableDatasources = (await getHotspotsDatasources()) || [];
          const { attributes, tags } = await getAttrTags();
          setFilterToolbarConfig(createEmptyFilterToolbarConfig(attributes, tags, availableDatasources));
          setFilterDs(createEmptyFilterDs(availableDatasources));
          scanResultId.current = null;
        } else {
          console.log('else selectedDatasources', selectedDatasources);
          const selectedDatasourcesValue = (selectedDatasources?.value as string[]) ?? [];
          await createHotspotsReport({
            data_sources: selectedDatasourcesValue,
          });

          const { scanResult, attributes, tags } = await performScan(intervalNumber, selectedDatasourcesValue);
          setFilterToolbarConfig(
            createFilterToolbarConfigWithPreselectedDatasources(attributes, tags, selectedDatasourcesValue),
          );
          setFilterDs(createFilterDsWithPreselectedDatasources(selectedDatasourcesValue));
          if (scanResult) {
            scanResultId.current = scanResult.id;
          }
        }
      } catch (err) {
        notificationService.error(err ?? 'Something went wrong');
      } finally {
        setIsLoading(false);
      }
    };
    runWhenDatasourcesChanged();
  }, [selectedDatasources]);

  const dataSorceChanged = useCallback((filter: BigidFieldFilter[]) => {
    onDatasourcesFiltersChange(filter?.[0]);
  }, []);

  return (
    <HotspotsContainer.Provider>
      <BigidPaper>
        {filterDs && <BigidFilterToolbar filters={filterDs} onFiltersChange={dataSorceChanged} />}
        <BigidPaper classes={{ root: classes.container }}>
          {isLoading ? (
            <BigidLoader />
          ) : (
            <HotspotsReportsChart
              onDatasourcesFiltersChange={onDatasourcesFiltersChange}
              filterToolbarConfig={filterToolbarConfig}
              scanResultId={scanResultId.current}
            />
          )}
        </BigidPaper>
      </BigidPaper>
    </HotspotsContainer.Provider>
  );
};
