import { ParamTypes } from './CustomAppTypes';
import { CustomAppParam, CredentialTypeParam } from '../views/EditCustomApp/EditCustomApp';
import { isCurrentValueExistsWithinItems } from '../components/ActionCard';

export const getParamsByType = (mandatoryParams: CustomAppParam[]): Record<string, CustomAppParam[]> => {
  const credentialParamRelatedFields: string[] = mandatoryParams.reduce((acc, credParam) => {
    if (credParam.appFieldIdentifiersMap) {
      acc.push(...Object.values(credParam.appFieldIdentifiersMap));
    }
    return acc;
  }, []);

  return mandatoryParams.reduce(
    (acc, param) => {
      if (param.type === ParamTypes.CREDENTIAL || credentialParamRelatedFields.includes(param.name)) {
        acc.credentialParams.push(param);
      } else {
        acc.otherParams.push(param);
      }
      return acc;
    },
    { otherParams: [] as CustomAppParam[], credentialParams: [] as CustomAppParam[] } as Record<
      string,
      CustomAppParam[]
    >,
  );
};

export const validateCredentialParams = (
  credentialParams: CustomAppParam[],
  paramsKeyValues: Record<string, string>,
): boolean => {
  const credentialParamsMap: Record<string, CustomAppParam> = {};
  credentialParams.forEach(credParam => {
    credentialParamsMap[credParam.name] = credParam;
  });

  const credentialTypeParam: Record<string, CredentialTypeParam> = {};
  credentialParams.forEach(credParam => {
    if (credParam.type === ParamTypes.CREDENTIAL && credParam.appFieldIdentifiersMap) {
      const credentialFields: Record<string, CustomAppParam> = {};
      Object.values(credParam.appFieldIdentifiersMap).forEach(credentialFieldKey => {
        if (credentialParamsMap[credentialFieldKey]) {
          credentialFields[credentialFieldKey] = credentialParamsMap[credentialFieldKey];
        }
      });
      credentialTypeParam[credParam.name] = { ...credParam, credentialFields };
    }
  });

  return Object.values(credentialTypeParam).every(credParam => {
    const value = paramsKeyValues[credParam.name] || credParam.value;
    if (isParamValid(credParam, value)) {
      return true;
    }
    if (credParam.credentialFields) {
      return Object.values(credParam.credentialFields).every(param => {
        const fieldValue = paramsKeyValues[param.name] || param.value;
        return param && isParamValid(param, fieldValue);
      });
    }
    return false; // Explicitly return false if none of the conditions are met
  });
};

const isParamValid = (param: CustomAppParam, value: string) => {
  const isValidValue = isCurrentValueExistsWithinItems(param, value);
  return value && isValidValue;
};

const validateOtherParams = (params: CustomAppParam[], paramsKeyValues: Record<string, string>) => {
  return params.every(param => {
    const value = paramsKeyValues[param.name] || param.value;
    return isParamValid(param, value);
  });
};

export const isMissingMandatoryParams = (
  paramsKeyValues: Record<string, string>,
  mandatoryParams: CustomAppParam[],
) => {
  const { credentialParams, otherParams } = getParamsByType(mandatoryParams);
  const isCredentialParamsValid = credentialParams ? validateCredentialParams(credentialParams, paramsKeyValues) : true;
  return !isCredentialParamsValid || !validateOtherParams(otherParams, paramsKeyValues);
};
