import { v4 as uuid } from 'uuid';
import React, { FC, useState, useCallback } from 'react';
import { FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidLink,
  BigidBody1,
  BigidLoader,
  BigidTooltip,
  BigidDropZone,
  PrimaryButton,
  BigidRadioGroup,
  BigidPrimaryCheckbox,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { notificationService } from '../../services/notificationService';
import { dataSourceConnectionsService } from '../../services/angularServices';
import { sendSSERequestWithEventId, subscribeToRepeatedSSEEventById } from '../../services/sseService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../services/userPermissionsService';
import { ImportDataSourceResult } from './ImportDataSourceResult';
import { FailedImportLine } from './ImportDataSourceFailedGrid';
import { httpService } from '../../services/httpService';
import { getApplicationPreference } from '../../services/appPreferencesService';

const FAILED_MESSAGE = 'Data Sources imported failed. See logs for more information';

const useStyles = makeStyles({
  dateSourceImportActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  typeInfoWrapper: {
    paddingBottom: '20px',
  },
  csvInfoWrapper: {
    display: 'inline-flex',
    paddingBottom: '24px',
    alignItems: 'center',
  },
  templateLinkWrapper: {
    paddingRight: '4px',
  },
});

enum SupportedFieldExtensions {
  json = '.json',
  csv = '.csv',
}

const supportedFieldExtensionsOptions = [
  {
    value: SupportedFieldExtensions.json,
    label: 'JSON',
  },
  {
    value: SupportedFieldExtensions.csv,
    label: 'CSV',
  },
];

interface ImportDataSourcesFormData {
  testAfterImport: boolean;
  isNeedToBeEncrypted: boolean;
  importFile: File;
  fileType: SupportedFieldExtensions;
  shouldSendWelcomeEmail: boolean;
}

const INIT_IMPORT_DATA_SOURCES_FORM_STATE: ImportDataSourcesFormData = {
  testAfterImport: false,
  isNeedToBeEncrypted: false,
  importFile: undefined as File,
  fileType: SupportedFieldExtensions.json,
  shouldSendWelcomeEmail: true,
};

interface ImportDataSourcesFormProps {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  close?: () => void;
}

interface ImportDataSourcesResponse extends Record<string, any> {
  message: string;
  success: boolean;
}

interface CsvImportState {
  failedList: FailedImportLine[];
  successCount: number;
  csvImportDone: boolean;
  isLoading: boolean;
}
const CSV_IMPORT_INIT_STATE: CsvImportState = {
  csvImportDone: false,
  failedList: [],
  successCount: 0,
  isLoading: false,
};

const downLoadTemplateFile = () => {
  return httpService.downloadFile(`ds-connections/file-download/csv-template`, { format: 'csv' });
};

export const ImportDataSourcesForm: FC<ImportDataSourcesFormProps> = ({ onSuccess, onError, close }) => {
  const classes = useStyles({});
  const [formState, setFormState] = useState<ImportDataSourcesFormData>(INIT_IMPORT_DATA_SOURCES_FORM_STATE);
  const [{ failedList, successCount, csvImportDone, isLoading }, setCsvImportResult] = useState(CSV_IMPORT_INIT_STATE);

  const handleOnDrop = useCallback((files: File[]) => {
    const importFile = files.length ? files[0] : undefined;
    setFormState(currentState => ({ ...currentState, importFile }));
  }, []);

  const handleAfterSubmit = useCallback(
    (data: ImportDataSourcesResponse) => {
      if (formState.fileType === SupportedFieldExtensions.json) {
        onSuccess && onSuccess();
      } else {
        if (!data.success) {
          notificationService.error(data.message || 'Data Sources import error.');
        }
        setCsvImportResult({
          failedList: data.failedList,
          successCount: data.successCount,
          csvImportDone: true,
          isLoading: false,
        });
      }
    },
    [formState.fileType, onSuccess],
  );

  const importDataSources = useCallback(() => {
    const formData = new FormData();
    formData.append('fileToImport', formState.importFile);
    setCsvImportResult(curState => ({ ...curState, isLoading: true }));
    dataSourceConnectionsService
      .importDSConnectionsFile(formData, formState.isNeedToBeEncrypted, formState.shouldSendWelcomeEmail)
      .then((data: ImportDataSourcesResponse) => {
        if (data.success) {
          notificationService.success(
            data.message ||
              'Data Sources imported successfully! Please make sure to test the connection to the imported data sources.',
          );
        }
        handleAfterSubmit(data);
      })
      .catch((error: Error) => {
        notificationService.error(FAILED_MESSAGE);
        onError && onError(error);
      });
  }, [
    formState.importFile,
    formState.isNeedToBeEncrypted,
    formState.shouldSendWelcomeEmail,
    handleAfterSubmit,
    onError,
  ]);

  const importDataSourcesAndTest = useCallback(() => {
    const uniqueBroadcastEventForImport = `import-ds-${uuid()}`;

    subscribeToRepeatedSSEEventById(uniqueBroadcastEventForImport, ({ errors, message }) => {
      if (errors.length) {
        sessionStorageService.set('uniqueBroadcastEventForImportDataSource', '');
        notificationService.error(message);
      }
    });

    sendSSERequestWithEventId<any>(
      'post',
      'ds_connections/import-from-file-and-test',
      {
        files: { file: formState.importFile },
        data: {
          isNeedToBeEncrypted: String(formState.isNeedToBeEncrypted),
        },
      },
      {},
      uniqueBroadcastEventForImport,
    )
      .then(({ data }) => {
        if (data?.operationStatus === 'BLOCKED') {
          notificationService.error(data?.message);
        } else {
          sessionStorageService.set('uniqueBroadcastEventForImportDataSource', uniqueBroadcastEventForImport);
          notificationService.success('Data Sources import and testing in progress');
          onSuccess && onSuccess();
        }
      })
      .catch(error => {
        notificationService.error(FAILED_MESSAGE);
        onError && onError(error);
      });
  }, [formState.importFile, formState.isNeedToBeEncrypted, onSuccess, onError]);

  const handleImport = useCallback(() => {
    if (formState.testAfterImport && formState.fileType !== SupportedFieldExtensions.csv) {
      importDataSourcesAndTest();
    } else {
      importDataSources();
    }
  }, [formState.testAfterImport, formState.fileType, importDataSourcesAndTest, importDataSources]);

  const isImportButtonDisabled = !(isPermitted(DATA_SOURCES_PERMISSIONS.IMPORT.name) && formState.importFile);
  const isTestConnectionPermitted = isPermitted(DATA_SOURCES_PERMISSIONS.TEST_CONNECTION.name);
  const onChangeTestConnection = useCallback(() => {
    setFormState(currentState => ({
      ...currentState,
      testAfterImport: !currentState.testAfterImport,
    }));
  }, []);

  // fixed implicit any during react 18 migration
  const onChangeType = useCallback(({ target: { value } }: any) => {
    setFormState(currentState => {
      return currentState.fileType === value
        ? currentState
        : {
            ...currentState,
            fileType: value,
            importFile: undefined,
          };
    });
  }, []);

  const onChangeUsePassword = useCallback(() => {
    setFormState(currentState => ({
      ...currentState,
      isNeedToBeEncrypted: !currentState.isNeedToBeEncrypted,
    }));
  }, []);

  const onChangeSendWelcome = useCallback(() => {
    setFormState(currentState => ({
      ...currentState,
      shouldSendWelcomeEmail: !currentState.shouldSendWelcomeEmail,
    }));
  }, []);

  return csvImportDone ? (
    <ImportDataSourceResult onSuccess={onSuccess} failedList={failedList} successCount={successCount} />
  ) : (
    <form
      noValidate
      autoComplete="off"
      onSubmit={event => {
        event.preventDefault();
      }}
      data-aid="ImportDataSourcesForm"
    >
      {isLoading && <BigidLoader />}
      <fieldset>
        <FormControl fullWidth margin="normal">
          {getApplicationPreference('DATA_SOURCE_CSV_IMPORT_VISIBLE') && (
            <>
              <BigidBody1 className={classes.typeInfoWrapper}>Add Data Sources by importing a file.</BigidBody1>
              <BigidRadioGroup
                defaultValue={SupportedFieldExtensions.json}
                options={supportedFieldExtensionsOptions}
                onChange={onChangeType}
                mainLabel={'Select file format:'}
                horizontal={true}
              />
              {formState.fileType === SupportedFieldExtensions.csv ? (
                <BigidBody1 className={classes.csvInfoWrapper}>
                  <BigidLink
                    classNames={classes.templateLinkWrapper}
                    onClick={downLoadTemplateFile}
                    text="Download the CSV template"
                  />
                  or import your own file.
                  <BigidTooltip
                    placement="bottom"
                    title="The file should include these 3 mandatory fields:
Data source name, Data source type and Data source owner (IT)."
                  >
                    <span>
                      <BigidInfoIcon />
                    </span>
                  </BigidTooltip>
                </BigidBody1>
              ) : (
                <BigidBody1 className={classes.typeInfoWrapper}>
                  After exporting a data source file from another BigID instance, you can import it here.
                </BigidBody1>
              )}
            </>
          )}
          <BigidDropZone
            label={!getApplicationPreference('DATA_SOURCE_CSV_IMPORT_VISIBLE') ? 'Upload JSON' : ''}
            accept={[formState.fileType]}
            files={formState.importFile ? [formState.importFile] : []}
            onDrop={handleOnDrop}
            data-aid="ImportDataSourcesFile"
          />
        </FormControl>
        {isTestConnectionPermitted && formState.fileType === SupportedFieldExtensions.json && (
          <BigidPrimaryCheckbox
            checked={formState.testAfterImport}
            label="Test connection on import (this might take a few minutes)"
            onChange={onChangeTestConnection}
            size="small"
            data-aid="ImportDataSourcesTestCheckbox"
          />
        )}
        {formState.fileType === SupportedFieldExtensions.json && (
          <BigidPrimaryCheckbox
            checked={formState.isNeedToBeEncrypted}
            label="Passwords are in cleartext"
            onChange={onChangeUsePassword}
            size="small"
            data-aid="ImportDataSourcesClearTextCheckbox"
          />
        )}
        {formState.fileType === SupportedFieldExtensions.csv && (
          <BigidPrimaryCheckbox
            checked={formState.shouldSendWelcomeEmail}
            label="Send welcome emails to the data owners"
            onChange={onChangeSendWelcome}
            size="small"
            data-aid="ImportDataSourcesSendWeclomeTextCheckbox"
          />
        )}
      </fieldset>
      <div className={classes.dateSourceImportActions} data-aid="ImportDataSourcesImportButton">
        {close && <TertiaryButton margin="4px" onClick={close} size="medium" text="Cancel" />}
        <PrimaryButton
          disabled={isImportButtonDisabled}
          margin="4px"
          onClick={handleImport}
          size="medium"
          text="Import"
        />
      </div>
    </form>
  );
};
