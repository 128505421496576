import React, { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidCaption,
  BigidColors,
  BigidQueryBuilder,
  BigidTextField,
  BigidToggleButton,
  QueryComposerOperandConfig,
  QueryLogicalOperatorMap,
  QueryTree,
  TertiaryButton,
} from '@bigid-ui/components';
import * as bigidQueryObjectSerialization from '@bigid/query-object-serialization';
import { getSystemAttributes } from '../../DataCatalog/DataCatalogService';
import classNames from 'classnames';
import { ClassificationLevel } from '../../SensitivityClassification/SensitivityClassification';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { BigidCopyIcon, BigidWarningIcon } from '@bigid-ui/icons';
import { notificationService } from '../../../services/notificationService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { SensitivityClassificationsContext } from './SensitivityClassificationsContext';
import { useLocalTranslation } from '../translations';

export interface QuerySelectorProps {
  classificationLevel: ClassificationLevel;
  onQueryChange: (objectQuery: QueryTree, stringQuery: string) => void;
  onQueryTypeChange: (queryType: QueryType) => void;
  stringQuery: string;
  objectQuery?: QueryTree;
  isQueryTouched?: boolean;
}

const useStyles = makeStyles({
  notValid: {
    border: `1px solid ${BigidColors.red[700]}`,
  },
  errorMsg: {
    color: BigidColors.red[700],
    marginTop: '5px',
  },
  queryToolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    alignItems: 'center',
  },
  warningWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 5,
  },
  warningText: {
    marginLeft: 5,
  },
  toolbarButtons: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
});

export enum QueryType {
  String = 'queryString',
  Object = 'queryObject',
}

export const QuerySelector: FC<QuerySelectorProps> = ({
  objectQuery,
  stringQuery,
  onQueryChange,
  classificationLevel,
  isQueryTouched,
  onQueryTypeChange,
}) => {
  const classes = useStyles({});
  const [queryType, setQueryType] = useState<QueryType>(QueryType.Object);
  const { errorState, sc } = useContext(SensitivityClassificationsContext);
  const { emptyQuery, notValidQuery, containsScQuery } = errorState?.classifications?.[classificationLevel.id] || {};
  const isSensitivityV2: boolean = getApplicationPreference('SENSITIVITY_CLASSIFICATION_V2_ENABLED');
  const { t } = useLocalTranslation('SensitivityClassificationForm');
  const operands: QueryComposerOperandConfig[] = useMemo(
    () => [
      {
        leftOperand: {
          label: 'Attribute',
          value: 'attribute',
        },
        fetchRightOperands: () => {
          return new Promise(async (resolve, reject) => {
            try {
              const { attributesList } = await getSystemAttributes('');

              const rightOperandAttributesList = attributesList.map(one => {
                return {
                  label: one.attribute_name,
                  subLabel: one.attribute_type,
                  value: one.attribute_name,
                };
              });
              resolve(rightOperandAttributesList);
            } catch (e) {
              console.error(t('QuerySelector.fetching_error'));
              reject();
            }
          });
        },
      },
    ],
    [t],
  );

  const copyToClipboard = async (queryString: string) => {
    try {
      await navigator.clipboard.writeText(queryString);
      notificationService.success(t('QuerySelector.query_copied_to_clipboard'));
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeQueryBuilder = (query: any) => {
    onQueryChange(query, bigidQueryObjectSerialization.parseGraphQueryNodes(query));
  };

  const handleQueryToolbarRadioChange = (value: string) => {
    onQueryTypeChange(value as QueryType);
    setQueryType(value as QueryType);
  };

  const handleQueryStringChange = async ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = target.value;
    if (newValue !== stringQuery) {
      let isEditConfirmed = true;
      if (objectQuery !== null) {
        isEditConfirmed = await showConfirmationDialog({
          entityNameSingular: t('QuerySelector.entity_name_singular'),
          actionName: t('QuerySelector.action_name'),
          actionButtonName: t('QuerySelector.action_button_name'),
          customDescription: t('QuerySelector.custom_description'),
        });
      }
      if (isEditConfirmed) {
        onQueryChange(null, newValue);
      }
    }
  };

  return (
    <>
      <div
        className={classNames((emptyQuery || notValidQuery || containsScQuery) && isQueryTouched && classes.notValid)}
      >
        <div className={classes.queryToolbar}>
          <BigidToggleButton
            initialSelected={objectQuery ? QueryType.Object : QueryType.String}
            toggleButtons={[
              {
                label: t('QuerySelector.query_builder'),
                value: QueryType.Object,
                isDisabled: objectQuery === null,
                dataAid: 'QuerySelector-query-builder',
              },
              {
                label: t('QuerySelector.input_query'),
                value: QueryType.String,
                isDisabled: !isSensitivityV2 && sc.columnTagging?.isActive,
                dataAid: 'QuerySelector-input-query',
              },
            ]}
            onChange={(_, value) => handleQueryToolbarRadioChange(value)}
          />
          <TertiaryButton
            onClick={() => copyToClipboard(stringQuery)}
            size={'small'}
            startIcon={<BigidCopyIcon />}
            text={t('QuerySelector.copy_query')}
            dataAid="QuerySelector-copy-query"
          />
        </div>
        {queryType === QueryType.Object && objectQuery ? (
          <BigidQueryBuilder
            onQueryChange={handleChangeQueryBuilder}
            initialQuery={objectQuery}
            operands={operands}
            logicalOperators={[QueryLogicalOperatorMap.AND, QueryLogicalOperatorMap.OR]}
          />
        ) : (
          <div>
            <div className={classes.warningWrapper}>
              <BigidWarningIcon size="small" color="default" />
              <BigidCaption className={classes.warningText}>{t('QuerySelector.editing_warning')}</BigidCaption>
            </div>
            <BigidTextField
              dataAid={'string-query-field'}
              onChange={handleQueryStringChange}
              rows={5}
              multiline
              value={stringQuery}
            />
          </div>
        )}
      </div>
      {(emptyQuery || notValidQuery) && isQueryTouched && (
        <BigidCaption className={classes.errorMsg}>{t('QuerySelector.query_invalid')}</BigidCaption>
      )}
      {containsScQuery && isQueryTouched && (
        <BigidCaption className={classes.errorMsg}>
          {t('QuerySelector.query_contains_sensitivity_classification_tag')}
        </BigidCaption>
      )}
    </>
  );
};
