import React from 'react';
import { DataCatalogRecord, DataCatalogRecordScannerTypeGroup } from '../../../../../../DataCatalog/DataCatalogService';
import { useGetObjectDetails } from '../hooks/useGetObjectDetails';
import { CatalogSidePanelProps } from '../CatalogSidePanel';
import {
  PreviewFileContent,
  PreviewFileContentProps,
} from '../../../../../../DataCatalog/PreviewFileObjects/PreviewFileContent';
import { DataCatalogTablePreview } from '../../../../../../DataCatalog/DataCatalogTablePreview';
import { styled } from '@mui/material';
import { DataExplorerColumnsProps } from '../DataExplorerColumns/DataExplorerColumns';

const Root = styled('div')`
  flex-grow: 1;
  display: flex;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  border-width: 1px;
`;

type PreviewProps = DataExplorerColumnsProps;

export const Preview = ({ datasource, id, isExtendedView }: PreviewProps) => {
  const { scannerType, value: source } = datasource;
  const fqn = String(id);
  const { data } = useGetObjectDetails(fqn);

  const { scanner_type_group, fullObjectName, type, containerName, open_access } = data?.data || {};

  const isUnstructured =
    scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED ||
    scanner_type_group === DataCatalogRecordScannerTypeGroup.EMAIL;

  const isStructured = scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED;

  const previewProps: DataCatalogRecord = {
    scannerType,
    fullyQualifiedName: fqn,
    source,
    type,
    fullObjectName,
    has_duplicates: 'No',
    id: fqn,
    objectId: fqn,
    objectName: fullObjectName,
    open_access: open_access as DataCatalogRecord['open_access'],
  };

  const filePreviewProps: PreviewFileContentProps = {
    scannerType,
    fullyQualifiedName: fqn,
    source,
    containerName,
  };

  return (
    <Root>
      {isStructured && <DataCatalogTablePreview {...previewProps} />}
      {isUnstructured && <PreviewFileContent {...filePreviewProps} />}
    </Root>
  );
};
