import React from 'react';
import { BigidDialog } from '@bigid-ui/components';
import { CorrelationSetWizard } from '../../CorrelationSetWizard/CorrelationSetWizard';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  dialogPaper: {
    height: '100%',
  },
});

export interface CorrelationSetWizardDialogProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CorrelationSetWizardDialog = ({ title, isOpen, onClose }: CorrelationSetWizardDialogProps) => {
  const classes = useStyles();

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      maxWidth="xl"
      hideContentPadding={true}
      muiOverrides={{
        classes: { paper: classes.dialogPaper },
      }}
    >
      <CorrelationSetWizard />
    </BigidDialog>
  );
};
