import { SecondaryButton, TertiaryButton } from '@bigid-ui/components';
import { styled } from '@mui/styles';

export const LabelsActionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});

export const TabsWrapper = styled('div')({
  padding: '16px 16px 0 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TabContentRoot = styled('div')({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  height: '100%',
});

export const QuerySelectorWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const MappedLabelsRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'end',
  height: '100%',
});

export const LabelItemAdd = styled(SecondaryButton)({
  alignSelf: 'end',
  justifySelf: 'end',
});

export const LabelItemFrame = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr auto',
  gap: '12px',
  alignItems: 'end',
  backgroundColor: theme.vars.palette.bigid.gray125,
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: '8px',
  width: '100%',
  padding: '16px 16px 20px 16px',
}));

export const LabelItemField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
});
export const ActionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});

export const LabelItemDelete = styled(TertiaryButton)({
  alignSelf: 'end',
  justifySelf: 'end',
});

export const LabelsEmptyStateRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  gap: '12px',

  '& > div': {
    width: '316px',

    '& > p': {
      textAlign: 'center',
    },
  },
});

export const GuidelineFrame = styled('div')(({ theme }) => ({
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: '4px',
  boxShadow: theme.vars.tokens.bigid.shadow10,
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
}));

export const GuidelineHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const GuidelineBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export const GuidelineNumberFrame = styled('div')(({ theme }) => ({
  backgroundColor: theme.vars.tokens.bigid.insightSubtle,
  minWidth: '24px',
  height: '24px',
  textAlign: 'center',
}));

export const GuidelineItem = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});

export const GuidelineItemBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  '& > p': {
    wordWrap: 'break-word',
  },
});

export const GuidelinesExpandToggle = styled('div')(({ theme }) => ({
  width: '100px',
  '& > button': {
    color: theme.vars.tokens.bigid.textLink,
    padding: 'unset',
  },
}));
