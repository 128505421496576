import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidMenuItemProps,
} from '@bigid-ui/components';
import { ExecutiveDashboard, DashboardType } from '../../components/ExecutiveDashboard';
import { useDashboardToolbar } from '../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { DataCoverageWidget, EmptyWidget, DataRiskWidget, EmptySystemWidget } from './widgets';
import { styled } from '@mui/material';
import { DiscoveryDashboardContext } from './DiscoveryDashboardContext';
import { isSystemWithoutDataSources } from './discoveryDashboardServices';
import { useLocalTranslation } from './translations';

export const DISCOVERY_DASHBOARD_ID = 'discoveryDashboard';

const WidgetsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SplitSection = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const WidgetWrapper = styled('div')<{ isHalfWidth?: boolean }>`
  width: ${({ isHalfWidth }) => (isHalfWidth ? '50%' : '100%')};
`;

export enum DiscoveryDashboardWidget {
  DATA_COVERAGE = 'dataCoverage',
  DATA_RISK = 'dataRisk',
  SENSITIVE_DATA = 'sensitiveData',
  DATA_DUPLICATION = 'dataDuplication',
  DATA_MINIMIZATION = 'dataMinimization',
}

export const DiscoveryDashboard: FC = () => {
  const { t } = useLocalTranslation();
  // TODO: POC Code, to be replaced with real filters creation
  const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = [
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'sensitivity',
      field: 'sensitivity',
      title: 'Sensitivity',
      parentId: 'sensitivity',
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [
        {
          id: 'restricted',
          value: 'restricted',
          displayValue: 'Restricted',
        },
        {
          id: 'confidential',
          value: 'confidential',
          displayValue: 'Confidential',
        },
        {
          id: 'internalUse',
          value: 'internalUse',
          displayValue: 'Internal Use',
        },
        {
          id: 'public',
          value: 'public',
          displayValue: 'Public',
        },
      ],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'dsType',
      field: 'dsType',
      title: 'DS Type',
      operator: 'in',
      asyncOptionsFetch: async () => [
        {
          id: 's3-v2',
          value: 's3-v2',
          displayValue: 'S3',
        },
        {
          id: 'azure',
          value: 'azure',
          displayValue: 'Azure',
        },
        {
          id: 'mysql',
          value: 'mysql',
          displayValue: 'MySQL',
        },
      ],
      options: [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: 'tags',
      field: 'tags',
      title: 'Tags',
      operator: 'in',
      asyncOptionsFetch: async () => [
        {
          id: 'dsRiskHigh',
          value: 'dsRiskHigh',
          displayValue: 'DS Risk - High',
        },
        {
          id: 'dsRiskMedium',
          value: 'dsRiskMedium',
          displayValue: 'DS Risk - Medium',
        },
        {
          id: 'dsRiskLow',
          value: 'dsRiskLow',
          displayValue: 'DS Risk - Low',
        },
      ],
      options: [],
    },
  ];
  const widgetIds = [
    DiscoveryDashboardWidget.DATA_COVERAGE,
    DiscoveryDashboardWidget.DATA_RISK,
    DiscoveryDashboardWidget.SENSITIVE_DATA,
    DiscoveryDashboardWidget.DATA_DUPLICATION,
    DiscoveryDashboardWidget.DATA_MINIMIZATION,
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [isEmptySystem, setIsEmptySystem] = useState(true);
  const { activeFilters, activeWidgetIds, externalAppliedFilters, toolbarActions, isSavedViewsReady } =
    useDashboardToolbar(widgetIds, toolbarFilters, DISCOVERY_DASHBOARD_ID, true);

  const isActiveWidget = useCallback(
    (widgetId: DiscoveryDashboardWidget) => {
      return !!activeWidgetIds?.includes(widgetId);
    },
    [activeWidgetIds],
  );

  useEffect(() => {
    const updateIsEmptySystem = async () => {
      const isEmpty = await isSystemWithoutDataSources();
      setIsEmptySystem(isEmpty);
      setIsLoading(false);
    };
    updateIsEmptySystem();
  }, []);

  const widgetSelectionItems: BigidMenuItemProps[] = widgetIds.map(id => {
    return { id, label: t(`${id}Widget.title`) };
  });

  const isSplitSectionApply =
    isActiveWidget(DiscoveryDashboardWidget.SENSITIVE_DATA) &&
    isActiveWidget(DiscoveryDashboardWidget.DATA_DUPLICATION);

  return (
    <ExecutiveDashboard
      dashboardId={DISCOVERY_DASHBOARD_ID}
      dashboardType={DashboardType.DISCOVERY}
      toolbarFilters={toolbarFilters}
      activeFilters={activeFilters}
      activeWidgetIds={activeWidgetIds}
      widgetSelectionItems={widgetSelectionItems}
      toolbarActions={toolbarActions}
      externalAppliedFilters={externalAppliedFilters}
      isSavedFiltersTabsDisplayed={true}
      isExportButtonDisplayed={true}
      isLoading={isLoading || !isSavedViewsReady}
      isEmptySystem={isEmptySystem}
    >
      <DiscoveryDashboardContext.Provider value={{ activeFilters, isEmptySystem }}>
        <WidgetsContainer>
          {isEmptySystem && (
            <WidgetWrapper>
              <EmptySystemWidget />
            </WidgetWrapper>
          )}
          {isActiveWidget(DiscoveryDashboardWidget.DATA_COVERAGE) && (
            <WidgetWrapper>
              <DataCoverageWidget title={t('dataCoverageWidget.title')} />
            </WidgetWrapper>
          )}
          {isActiveWidget(DiscoveryDashboardWidget.DATA_RISK) && (
            <WidgetWrapper>
              <DataRiskWidget title={t('dataRiskWidget.title')} />
            </WidgetWrapper>
          )}

          <SplitSection>
            {isActiveWidget(DiscoveryDashboardWidget.SENSITIVE_DATA) && (
              <WidgetWrapper isHalfWidth={isSplitSectionApply}>
                <EmptyWidget title={t('sensitiveDataWidget.title')} />
              </WidgetWrapper>
            )}
            {isActiveWidget(DiscoveryDashboardWidget.DATA_DUPLICATION) && (
              <WidgetWrapper isHalfWidth={isSplitSectionApply}>
                <EmptyWidget title={t('dataDuplicationWidget.title')} />
              </WidgetWrapper>
            )}
          </SplitSection>
          {isActiveWidget(DiscoveryDashboardWidget.DATA_MINIMIZATION) && (
            <WidgetWrapper>
              <EmptyWidget title={t('dataMinimizationWidget.title')} />
            </WidgetWrapper>
          )}
        </WidgetsContainer>
      </DiscoveryDashboardContext.Provider>
    </ExecutiveDashboard>
  );
};
