import React, { FC, useMemo, useRef } from 'react';

import { BigidAdvancedToolbarFilterUnion, BigidBody1, BigidWidgetContainer } from '@bigid-ui/components';
import { BigidDonutChart, BigidDonutChartSliceData } from '@bigid-ui/visualisation';
import { BigidInfoIcon, BigidNoDataIllustration } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import { RiskColors, Series } from '../PrivacyExecutiveDashboardTypes';
import { Menu } from './helpers/HelperComponents';
import { RiskWidgetWrapper } from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';

export interface DataRiskWidgetProps {
  title: string;
}

export const DataRiskWidget: FC<DataRiskWidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading, error, fetchData } = useFetchWidgetData(fetchDataRiskWidgetData);
  const lineChartRef = useRef<Series>(null);

  console.log('error data risk', error);

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: false, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <RiskWidgetWrapper>
      <BigidWidgetContainer
        content={
          <BigidDonutChart
            entityName="Open"
            chartSize={144}
            data={data || []}
            legendProps={{
              legendPosition: 'right',
              legendConfig: { orientation: 'column' },
              onLegendClick: (data, isActive) => {
                lineChartRef.current?.series.forEach(series => {
                  if (series.name.toLowerCase() === data.category?.toLowerCase()) {
                    isActive ? series.hide() : series.show();
                  }
                });
              },
            }}
            numberFormat="#."
          />
        }
        titleConfig={{
          title,
          iconAfter: {
            icon: BigidInfoIcon,
            tooltip: t('dataRisk.tooltip'),
          },
        }}
        actionsSlot={<Menu menuItems={menuItems} />}
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: data?.length === 0 || !!error,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{!!error ? t('emptyState.errorText') : t('emptyState.defaultText')}</BigidBody1>,
          actions: [{ show: () => !!error, label: t('emptyState.tryAgain'), execute: fetchData }],
        }}
      />
    </RiskWidgetWrapper>
  );
};

const fetchDataRiskWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<BigidDonutChartSliceData[]> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 2000, donutChartData);
  });
};

const donutChartData = [
  {
    category: 'Critical',
    value: 81,
    color: RiskColors.CRITICAL,
    isActive: true,
  },
  {
    category: 'High',
    value: 31,
    color: RiskColors.HIGH,
    isActive: true,
  },
  {
    category: 'Medium',
    value: 47,
    color: RiskColors.MEDIUM,
    isActive: true,
  },
  {
    category: 'Low',
    value: 231,
    color: RiskColors.LOW,
    isActive: true,
  },
];
