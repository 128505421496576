import { useMemo } from 'react';
import { isPermitted } from '../../../services/userPermissionsService';
import { CORRELATION_SET_PERMISSIONS } from '@bigid/permissions';

export const usePermissions = () => {
  return useMemo(
    () => ({
      isCreatePermitted: isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name),
      isEditPermitted: isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name),
      isDeletePermitted: isPermitted(CORRELATION_SET_PERMISSIONS.DELETE.name),
      isTestConnectionPermitted: isPermitted(CORRELATION_SET_PERMISSIONS.TEST_CONNECTION.name),
    }),
    [],
  );
};
