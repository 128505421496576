import React, { FC, useContext } from 'react';
import { BigidBody1, BigidToggleButton, BigidWidgetContainer } from '@bigid-ui/components';
import { DataCoverageCharts } from './charts/DataCoverageCharts';
import { DiscoveryDashboardContext } from '../DiscoveryDashboardContext';
import { BigidNoDataIllustration } from '@bigid-ui/icons';
export interface DataCoverageWidgetProps {
  title: string;
}

export const DataCoverageWidget: FC<DataCoverageWidgetProps> = ({ title }) => {
  const onToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    console.log('Got new value', value);
  };

  const { isEmptySystem } = useContext(DiscoveryDashboardContext);

  return (
    <BigidWidgetContainer
      content={<DataCoverageCharts />}
      titleConfig={{ title }}
      actionsSlot={
        <>
          {!isEmptySystem && (
            <BigidToggleButton
              onChange={onToggleChange}
              initialSelected="volume"
              size="small"
              toggleButtons={[
                { value: 'volume', label: 'Volume' },
                { value: 'amount', label: 'Amount' },
              ]}
            />
          )}
          {/* // TODO: POC Code, to be replaced with real widget actions */}
        </>
      }
      emptyStateConfig={{
        isEmpty: isEmptySystem,
        illustration: BigidNoDataIllustration,
        description: <BigidBody1>No data</BigidBody1>,
      }}
    />
  );
};
