import React, { FC } from 'react';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { SensitivityClassification as SensitivityClassificationNew } from './SensitivityClassification';
import { SensitivityClassification } from '../SensitivityClassification/SensitivityClassification';

export const SensitivityClassificationWrapper: FC = () => {
  const isNewSensitivityClassificationFFEnabled = getApplicationPreference('SENSITIVITY_CLASSIFICATION_NEW_DESIGN_FF');

  return isNewSensitivityClassificationFFEnabled ? <SensitivityClassificationNew /> : <SensitivityClassification />;
};
