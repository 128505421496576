import React, { FC, useState, useMemo, PropsWithChildren } from 'react';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ColumnProfiling } from './ColumnProfiling/ColumnProfiling';
import { ColumnPreview } from './ColumnPreview/ColumnPreview';
import { BigidIcon, BigidIconSize, BigidPaper, BigidTabs, BigidColors, BigidTabsItem } from '@bigid-ui/components';
import Close from '@mui/icons-material/Close';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';

const COLUMN_WIDGET_WIDTH = 335;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: `${COLUMN_WIDGET_WIDTH}px`,
  },
  header: {
    borderBottom: `1px solid ${BigidColors.gray[150]}`,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '42px',
    paddingLeft: '10px',
  },
  tabs: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  close: {
    display: 'flex',
  },
  closeIcon: {
    fontSize: '0.8125rem',
  },
});

export interface ColumnWidgetsWrapperProps extends PropsWithChildren {
  dataAid?: string;
  preferenceId?: string;
  fullyQualifiedName: string;
  columnName: string;
  isProfiled?: boolean;
  fieldType?: string;
  onClose: () => void;
  dsType: string;
  dsName: string;
  scannerType: string;
}

export const ColumnWidgetsWrapper: FC<ColumnWidgetsWrapperProps> = ({
  dataAid = 'ColumnWidgetsWrapper',
  preferenceId = 'DataCatalogColumnWidgets',
  fullyQualifiedName,
  columnName,
  isProfiled,
  onClose,
  children,
  fieldType,
  dsType,
  dsName,
  scannerType,
}) => {
  const classes = useStyles({});
  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number>(0);

  const { isColumnProfilingAvailable, isColumnPreviewAvailable, isDataPreviewDisabled } = useMemo(
    () => ({
      isColumnProfilingAvailable: getApplicationPreference('COLUMN_PROFILING_ENABLED'),
      isColumnPreviewAvailable: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
      isDataPreviewDisabled: getApplicationPreference('DATA_PREVIEW_DISABLED'),
    }),
    [],
  );

  const tabs = useMemo(() => {
    const tabs: BigidTabsItem[] = [];

    if (isColumnProfilingAvailable) {
      tabs.push({
        label: 'Column Profile',
      });
    }

    if (isColumnPreviewAvailable && !isDataPreviewDisabled) {
      tabs.push({
        label: 'Column Preview',
      });
    }

    return tabs;
  }, [isColumnProfilingAvailable, isColumnPreviewAvailable, isDataPreviewDisabled]);

  const handleColumnWidgetTabChange = (value: number) => {
    setSelectedColumnIndex(value);
  };

  return (
    <>
      <div className={classes.root} data-aid={dataAid}>
        <BigidPaper>
          <div className={classes.header}>
            <div className={classes.tabs} data-aid={`${dataAid}-tabs`}>
              <BigidTabs
                centered
                onChange={handleColumnWidgetTabChange}
                orientation="horizontal"
                selectedIndex={selectedColumnIndex}
                size="small"
                tabs={tabs}
              />
            </div>
            <div className={classes.close} data-aid={`${dataAid}-close`}>
              <IconButton
                className={classes.closeIcon}
                aria-label="close"
                onClick={onClose}
                size="large"
                sx={{ height: 'auto' }}
              >
                <BigidIcon icon={Close} size={BigidIconSize.REGULAR} color={BigidColors.iconDark} />
              </IconButton>
            </div>
          </div>
          {selectedColumnIndex === 0 && isColumnProfilingAvailable ? (
            <ColumnProfiling
              dataAid={`${dataAid}-column-profile`}
              fullyQualifiedName={fullyQualifiedName}
              columnName={columnName}
              isProfiled={isProfiled}
              dsType={dsType}
              dsName={dsName}
              scannerType={scannerType}
            >
              {children}
            </ColumnProfiling>
          ) : (
            <ColumnPreview
              dataAid={`${dataAid}-column-preview`}
              preferenceId={preferenceId}
              columnName={columnName}
              fullyQualifiedName={fullyQualifiedName}
              fieldType={fieldType}
            />
          )}
        </BigidPaper>
      </div>
    </>
  );
};
