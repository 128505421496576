import React, { ReactNode, RefObject } from 'react';
import { EventEmitter } from '@bigid-ui/utils';
import {
  MetadataSearchEntityType,
  MetadataSearchQuantity,
  MetadataSearchEntityIconSizeGrid,
  MetadataSearchEntityPreviewRouteConfig,
  MetadataSearchResultEntity,
  MetadataSearchResultEntityFieldName,
  MetadataSearchIndexingStatus,
  MetadataSearchTransitionPayload,
  MetadataSearchEvents,
  MetadataSearchResultsPerType,
  MetadataSearchEntityTypeConfig,
  MetadataSearchIcon,
  MetadataSearchSupportedEntityTypes,
  MetadataSearchEntityTypeIcons,
  ACTIONABLE_INSIGHTS_CASES,
  SECURITY_POSTURE,
} from './MetadataSearchTypes';
import {
  getMetadataSearchEntityAssetByName,
  getMetadataSearchEntityFieldByName,
  getTypeSafeEnum,
} from './MetadataSearchService';
import {
  BigidTableColumnsIcon,
  BigidFileIcon,
  BigidOtherIcon,
  BigidDataSourceOnlyIcon,
  BigidPolicyIcon,
  BigidClassifiersIcon,
  BigidAnyIcon,
} from '@bigid-ui/icons';
//TODO: move transition config outside as a part of an effort of making component generic
import { CONFIG } from '../../../config/common';
import { isValid } from 'date-fns';
import { format, toDate } from 'date-fns-tz';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { getSupportedDataSources } from '../../utilities/dataSourcesUtils';
import { $state } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';

export const MetadataSearchEntityLabelMap: Record<MetadataSearchEntityType, Record<MetadataSearchQuantity, string>> = {
  [MetadataSearchEntityType.TABLE]: {
    singular: 'Table',
    plural: 'Tables',
  },
  [MetadataSearchEntityType.FILE]: {
    singular: 'File',
    plural: 'Files',
  },
  [MetadataSearchEntityType.OTHER_CATALOG]: {
    singular: 'Other',
    plural: 'Other',
  },
  [MetadataSearchEntityType.DATA_SOURCE]: {
    singular: 'Data Source',
    plural: 'Data Sources',
  },
  [MetadataSearchEntityType.POLICY]: {
    singular: 'Policy',
    plural: 'Policies',
  },
  [MetadataSearchEntityType.CLASSIFICATION_FINDINGS]: {
    singular: 'Classification Finding',
    plural: 'Classification Findings',
  },
};

export const MetadataSearchTypeIconSourceMap: MetadataSearchEntityTypeIcons = {
  [MetadataSearchEntityType.TABLE]: {
    icon: BigidTableColumnsIcon,
    name: 'BigidTableColumnsIcon',
  },
  [MetadataSearchEntityType.FILE]: {
    icon: BigidFileIcon,
    name: 'BigidFileIcon',
  },
  [MetadataSearchEntityType.OTHER_CATALOG]: {
    icon: BigidOtherIcon,
    name: 'BigidOtherIcon',
  },
  [MetadataSearchEntityType.DATA_SOURCE]: {
    icon: BigidDataSourceOnlyIcon,
    name: 'BigidDataSourceOnlyIcon',
  },
  [MetadataSearchEntityType.POLICY]: {
    icon: BigidPolicyIcon,
    name: 'BigidPolicyIcon',
  },
  [MetadataSearchEntityType.CLASSIFICATION_FINDINGS]: {
    icon: BigidClassifiersIcon,
    name: 'BigidClassifiersIcon',
  },
};

export const MetadataSearchUndefinedEntityTypeIcon = BigidAnyIcon;
export const MetadataSearchUndefinedEntityTypeIconName = 'BigidAnyIcon';

export const MetadataSearchCustomEntityTypeIcon = BigidAnyIcon;
export const MetadataSearchCustomEntityTypeIconName = 'BigidAnyIcon';

export const MetadataSearchTypeIndexingStatusLabelMap: Record<MetadataSearchIndexingStatus, string> = {
  [MetadataSearchIndexingStatus.FAILED]: 'Failed',
  [MetadataSearchIndexingStatus.INDEXING]: 'Indexing',
  [MetadataSearchIndexingStatus.UPDATED]: 'Updated',
  [MetadataSearchIndexingStatus.CANCELLED]: 'Canceled',
};

export const getMetadataSearchEntityPreviewRouteByType = (
  type: MetadataSearchEntityType,
  isNewDSTemplateSupported?: boolean,
): string => {
  let route;

  switch (type) {
    case MetadataSearchEntityType.TABLE:
      route = CONFIG.states.CATALOG_PREVIEW;
      break;
    case MetadataSearchEntityType.FILE:
      route = CONFIG.states.CATALOG_PREVIEW;
      break;
    case MetadataSearchEntityType.OTHER_CATALOG:
      route = CONFIG.states.CATALOG_PREVIEW;
      break;
    case MetadataSearchEntityType.DATA_SOURCE:
      route = isNewDSTemplateSupported ? CONFIG.states.CONFIG_DATA_SOURCE : CONFIG.states.EDIT_DATA_SOURCE_CONNECTION;
      break;
    case MetadataSearchEntityType.POLICY:
      route = CONFIG.states.POLICIES;
      break;
    case MetadataSearchEntityType.CLASSIFICATION_FINDINGS:
      route = CONFIG.states.CATALOG_PREVIEW;
      break;
  }

  return route;
};

export const getCustomEntityTypeTemplateUrlNormalised = (templateUrl: string): string => {
  return templateUrl.replace(/^\/|(?:\/#\/)|(#\/)/g, '');
};

export const getCustomEntityTypeTemplateUrl = (templateUrl: string): string => {
  const path = getCustomEntityTypeTemplateUrlNormalised(templateUrl);

  return `${location.protocol}//${location.host}/#/${path}`;
};

export const getMetadataSearchEntityPreviewRouteConfig = (
  entity: MetadataSearchResultEntity,
  entityTypeConfig: MetadataSearchEntityTypeConfig,
  isNewDSTemplateSupported?: boolean,
): MetadataSearchEntityPreviewRouteConfig => {
  let routeConfig;

  const { type } = entity;
  const isCustomType = getIsCustomEntityType(type, entityTypeConfig);

  if (isCustomType) {
    const { appId, dataSource } = entityTypeConfig;
    const { templateUrl } = entity;

    if (templateUrl) {
      routeConfig = {
        url: getCustomEntityTypeTemplateUrl(templateUrl),
      };
    } else if (appId && dataSource) {
      routeConfig = {
        url: getCustomEntityTypeTemplateUrl(templateUrl),
      };
    }
  } else {
    const { value: id } = getMetadataSearchEntityFieldByName(entity, MetadataSearchResultEntityFieldName.ID);
    const { value: entityId } = getMetadataSearchEntityFieldByName(
      entity,
      MetadataSearchResultEntityFieldName.ENTITY_ID,
    );
    const { value: entityName } = getMetadataSearchEntityFieldByName(entity, MetadataSearchResultEntityFieldName.NAME);

    const route = getMetadataSearchEntityPreviewRouteByType(type, isNewDSTemplateSupported);
    const fullyQualifiedName = getMetadataSearchEntityAssetByName(entity, 'fullyQualifiedName')?.value?.toString();

    switch (type) {
      case MetadataSearchEntityType.TABLE:
      case MetadataSearchEntityType.FILE:
      case MetadataSearchEntityType.OTHER_CATALOG:
        return {
          route,
          params: {
            id: encodeURIComponent(String(entityId)),
            name: encodeURIComponent(String(entityName)),
            type: MetadataSearchEntityLabelMap[type].singular.toLowerCase(),
          },
        };
      case MetadataSearchEntityType.DATA_SOURCE:
        routeConfig = {
          route,
          params: {
            id: String(entityId),
          },
        };
        break;
      case MetadataSearchEntityType.CLASSIFICATION_FINDINGS:
        routeConfig = {
          route,
          params: {
            id: String(fullyQualifiedName),
          },
        };
        break;
      case MetadataSearchEntityType.POLICY:
        routeConfig = {
          route,
          params: {
            policyId: encodeURIComponent(String(id)),
            policyName: encodeURIComponent(String(entityName)),
          },
        };
        break;
    }
  }

  return routeConfig;
};

export const getMaxPopperHeight = (viewportHeight: number, anchorRef: RefObject<HTMLDivElement>): number => {
  const clientHeight = anchorRef?.current?.clientHeight || 0;
  const offsetTop = anchorRef?.current?.offsetTop || 0;

  return viewportHeight - clientHeight - offsetTop - 8;
};

export const safeJsonParse = (stringToParse: string): any => {
  try {
    const parsedValue = JSON.parse(stringToParse);
    return parsedValue;
  } catch {
    return stringToParse;
  }
};

export const handleTransitionPayloadSend = (payload: MetadataSearchTransitionPayload): Record<string, string> => {
  return Object.fromEntries(
    new Map(
      Object.entries(payload).map(entry => {
        const [key, value] = entry;
        return [key, encodeURIComponent(JSON.stringify(value))];
      }),
    ),
  );
};

export const handleTransitionPayloadReceive = (stateParams: Record<string, string>): Record<string, any> => {
  return Object.fromEntries(
    new Map(
      Object.entries(stateParams).map(entry => {
        const [key, value] = entry;
        return [key, safeJsonParse(decodeURIComponent(value))];
      }),
    ),
  );
};

export const formatDate = (valueToFormat: string | number | Date): string => {
  const preprocessedDate = toDate(valueToFormat);
  return isValid(preprocessedDate) ? format(preprocessedDate, 'P') : null;
};

export const formatDateTime = (valueToFormat: string | number | Date): string => {
  const preprocessedDate = toDate(valueToFormat);
  return isValid(preprocessedDate) ? format(preprocessedDate, 'PPpp') : null;
};

export const metadataSearchEventEmitter = new EventEmitter<MetadataSearchEvents>();

export const getResultEntityGridDisplayFieldsPerType = (
  type: MetadataSearchEntityType,
): MetadataSearchResultEntityFieldName[] => {
  const baseDisplayFields: MetadataSearchResultEntityFieldName[] = [MetadataSearchResultEntityFieldName.NAME];

  switch (true) {
    case [
      MetadataSearchEntityType.TABLE,
      MetadataSearchEntityType.FILE,
      MetadataSearchEntityType.OTHER_CATALOG,
    ].includes(type as MetadataSearchEntityType): {
      return [...baseDisplayFields, MetadataSearchResultEntityFieldName.CONTAINER];
    }
    case [MetadataSearchEntityType.DATA_SOURCE, MetadataSearchEntityType.POLICY].includes(
      type as MetadataSearchEntityType,
    ): {
      return [...baseDisplayFields, MetadataSearchResultEntityFieldName.OWNER];
    }
    default: {
      return baseDisplayFields;
    }
  }
};

export const getIsCustomEntityType = (
  type: MetadataSearchEntityType,
  entityTypeConfig: MetadataSearchEntityTypeConfig,
): boolean => {
  return entityTypeConfig?.entityTypeId === type && Boolean(entityTypeConfig?.appId);
};

export const getIsPredefinedEntityType = (type: MetadataSearchEntityType): boolean => {
  return Object.values(MetadataSearchEntityType).includes(type as MetadataSearchEntityType);
};

export const getIsSearchResultEntityEnabled = (
  type: MetadataSearchEntityType,
  customEntityTypes: MetadataSearchEntityTypeConfig[],
  supportedEntityTypesMap: MetadataSearchSupportedEntityTypes,
): boolean => {
  return (
    (getIsPredefinedEntityType(type) && supportedEntityTypesMap?.[type]) ||
    customEntityTypes.find(({ entityTypeId }) => entityTypeId === type)?.enabled
  );
};

export const getSearchResultEntitiesPreprocessed = (
  searchResults: MetadataSearchResultEntity[],
  customEntityTypes: MetadataSearchEntityTypeConfig[],
  supportedEntityTypesMap: MetadataSearchSupportedEntityTypes,
): MetadataSearchResultEntity[] => {
  return searchResults
    .filter(({ type }) => {
      return getIsSearchResultEntityEnabled(type, customEntityTypes, supportedEntityTypesMap);
    })
    .map(result => ({
      ...result,
      assets: (result.assets || []).filter(({ value }) => {
        const type = typeof value;
        return type === 'number' || type === 'string' || type === 'boolean';
      }),
    }));
};

export const getSearchResultEntitiesPerTypePreprocessed = (
  searchResults: MetadataSearchResultsPerType[],
  customEntityTypes: MetadataSearchEntityTypeConfig[],
  supportedEntityTypesMap: MetadataSearchSupportedEntityTypes,
): MetadataSearchResultsPerType[] => {
  return searchResults
    .filter(({ type }) => {
      return getIsSearchResultEntityEnabled(type, customEntityTypes, supportedEntityTypesMap);
    })
    .map(searchResult => {
      return {
        ...searchResult,
        results: getSearchResultEntitiesPreprocessed(searchResult.results, customEntityTypes, supportedEntityTypesMap),
      };
    });
};

export const previewSearchResultEntity = async (
  entity: MetadataSearchResultEntity,
  payload: MetadataSearchTransitionPayload,
  entityTypeConfig: MetadataSearchEntityTypeConfig,
  shouldEmitPreviewEvent?: boolean,
) => {
  try {
    /**
     * Code below must exist up until old DS page is supported
     */
    let isNewDsConfigPage = false;
    if (entity.type === MetadataSearchEntityType.DATA_SOURCE) {
      const dsType = getMetadataSearchEntityAssetByName(entity, 'type')?.value?.toString();

      isNewDsConfigPage =
        getApplicationPreference('DS_TYPES_TEMPLATES_SUPPORTED') &&
        (await getSupportedDataSources()).some(({ templateName, type }) => {
          return !!templateName && type === dsType;
        });
    }

    const routeConfig = getMetadataSearchEntityPreviewRouteConfig(entity, entityTypeConfig, isNewDsConfigPage);

    if (routeConfig) {
      const { route, params, url } = routeConfig;

      if (url) {
        window.location.href = url;
      } else {
        $state.go(route, { ...params, ...handleTransitionPayloadSend(payload) }, { reload: true });
      }

      if (shouldEmitPreviewEvent) {
        metadataSearchEventEmitter.emit(MetadataSearchEvents.SEARCH_RESULT_ENTITY_CLICK, entity);
      }
    } else {
      notificationService.warning(`Type '${entity.type}' is unknown and cannot be previewed`);
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    notificationService.error(`An error has occurred during Data Source entity preview`);
  }
};

export const getMetadataSearchEntityTypeLabel = (
  type: MetadataSearchEntityType,
  labelMap: Record<MetadataSearchEntityType, Record<MetadataSearchQuantity, string>>,
  entityTypeConfig: MetadataSearchEntityTypeConfig,
  quantityModifier: MetadataSearchQuantity,
): string => {
  return (
    getTypeSafeEnum(labelMap, type)?.[quantityModifier] ||
    (quantityModifier === 'singular' ? entityTypeConfig?.friendlyName : entityTypeConfig?.friendlyNamePlural)
  );
};

export const getMetadataSearchEntityIcon = (
  type: MetadataSearchEntityType,
  iconMap: MetadataSearchEntityTypeIcons,
  entityTypeConfig: MetadataSearchEntityTypeConfig,
): MetadataSearchIcon => {
  let icon: MetadataSearchIcon;
  const isCustomType = getIsCustomEntityType(type, entityTypeConfig);

  if (isCustomType) {
    icon = {
      icon: MetadataSearchCustomEntityTypeIcon,
      name: MetadataSearchCustomEntityTypeIconName,
    };
  } else {
    icon = iconMap[type] || {
      icon: MetadataSearchUndefinedEntityTypeIcon,
      name: MetadataSearchUndefinedEntityTypeIconName,
    };
  }

  return icon;
};

export const getMetadataSearchEntityIconNode = (
  type: MetadataSearchEntityType,
  size: MetadataSearchEntityIconSizeGrid,
  iconMap: MetadataSearchEntityTypeIcons,
  entityTypeConfig: MetadataSearchEntityTypeConfig,
): ReactNode => {
  let iconNode: ReactNode;
  const isCustomType = getIsCustomEntityType(type, entityTypeConfig);

  if (isCustomType) {
    iconNode = <MetadataSearchCustomEntityTypeIcon dataAid={MetadataSearchCustomEntityTypeIconName} />;
  } else {
    const icon = iconMap[type];

    if (icon) {
      const { icon: Icon, name } = icon;
      iconNode = <Icon dataAid={name} size={size} />;
    } else {
      iconNode = <MetadataSearchUndefinedEntityTypeIcon dataAid={MetadataSearchUndefinedEntityTypeIconName} />;
    }
  }

  return iconNode;
};

export const getEntityTypeConfigByTypeId = (
  entityTypeConfigs: MetadataSearchEntityTypeConfig[],
  type: MetadataSearchEntityType,
): MetadataSearchEntityTypeConfig => {
  return entityTypeConfigs?.find(({ entityTypeId }) => entityTypeId === type);
};

export const getEntityQuantityModifierByCount = (count: number): MetadataSearchQuantity => {
  return count === 1 ? 'singular' : 'plural';
};

export const convertEntityTypeToFriendlyName = (entityType: string): string => {
  const entityTypeToFriendlyName: Record<string, string> = {
    [ACTIONABLE_INSIGHTS_CASES]: SECURITY_POSTURE,
  };
  return entityTypeToFriendlyName[entityType] || entityType;
};

export const convertFriendlyNameToEntityType = (entityType: string): string => {
  const friendlyNameToEntityType: Record<string, string> = {
    [SECURITY_POSTURE]: ACTIONABLE_INSIGHTS_CASES,
  };
  return friendlyNameToEntityType[entityType] || entityType;
};
