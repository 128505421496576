import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { CorrelationSetModel } from '../../CorrelationSetConnections/CorrelationSetConnectionTypes';
import { BigidBody1, BigidChip, BigidChipProps, BigidColorsV2, BigidHighlightIndicator } from '@bigid-ui/components';
import { dateUtils } from '../../../../services/angularServices';
import { isIdConnectionEnabled } from '../../utils/utils';

const BasicInfoContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSide = styled('div')`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const LastDateBox = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface BasicInfoProps {
  idConnection: CorrelationSetModel;
  children: React.ReactNode;
}

export const BasicInfo: FC<BasicInfoProps> = ({ idConnection, children }) => {
  const bigidChipProps: BigidChipProps = useMemo(() => {
    if (!idConnection) {
      return {} as BigidChipProps;
    }

    if (isIdConnectionEnabled(idConnection)) {
      return {
        label: 'Enabled',
        bgColor: BigidColorsV2.green[100],
        color: BigidColorsV2.green[700],
      };
    }

    return {
      label: 'Disabled',
      bgColor: BigidColorsV2.gray[150],
      color: BigidColorsV2.gray[500],
    };
  }, [idConnection]);

  return (
    <>
      {idConnection && (
        <BasicInfoContainer>
          <LeftSide>
            <BigidChip {...bigidChipProps} />
            <BigidBody1>{idConnection.dsConnection}</BigidBody1>
            <LastDateBox>
              <BigidHighlightIndicator diameter={8} color={BigidColorsV2.green[600]} />
              <BigidBody1>Last training: {dateUtils.formatDate(idConnection.updated_at)}</BigidBody1>
            </LastDateBox>
          </LeftSide>
          <div>{children}</div>
        </BasicInfoContainer>
      )}
    </>
  );
};
