import { styled } from '@mui/styles';

export const FormActionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
});
export const LevelsListPanel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flexGrow: 1,
  padding: '24px',
  gap: '16px',
});

export const Header = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
});

export const LevelList = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',

  '& > div:first-child': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
});

export const DraggableListWrapper = styled('div')({
  width: '100%',
});

export const LevelItemRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: '8px',
  width: '100%',
  height: '56px',
  padding: '0 16px',

  '& > div': {
    display: 'grid',
    gridTemplateColumns: '160px auto 24px',
    alignItems: 'center',
    width: '100%',
  },

  '&:hover': {
    boxShadow: theme.vars.tokens.bigid.shadow10,
  },
}));

export const LevelNumber = styled(LevelItemRoot)({
  border: 'unset',
  padding: '0 8px',
});

export const LevelsSidePanel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '400px',
  borderRight: theme.vars.tokens.bigid.borderDefault,
  height: '100%',
  padding: '34px 24px',
}));

export const LevelsSidePanelField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const LevelsSidePanelFieldCheckboxes = styled(LevelsSidePanelField)({
  gap: '12px',
});

export const EmptyStateRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',

  '& > div': {
    width: '316px',

    '& > p': {
      textAlign: 'center',
    },
  },
});
