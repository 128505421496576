import React from 'react';
import {
  BigidAccordion,
  BigidAccordionSummary,
  BigidHeading6,
  BigidAccordionDetails,
  BigidTooltip,
  BigidBody1,
  AccordionSummarySizeEnum,
} from '@bigid-ui/components';
import styled from '@emotion/styled';
import { ControlsProps, FrameworkProps } from '../FrameworksServices';

interface DetailsWrapperProps {
  controlsLength: number;
}

interface ContentWrapperProps {
  controlIndex: number;
}

const SummaryWrapper = styled(BigidAccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.bigid.gray200}`,
}));

const TitleWrapper = styled.div({
  display: 'flex',
  columnGap: '12px',
});

const DetailsWrapper = styled(BigidAccordionDetails)<DetailsWrapperProps>(({ controlsLength = 0 }) => ({
  position: 'relative',
  height: `${controlsLength * 48}px`,
}));

const ContentWrapper = styled(BigidBody1)<ContentWrapperProps>(({ controlIndex = 0, theme }) => ({
  width: '100%',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.bigid.gray200}`,
  position: 'absolute',
  left: 0,
  right: 0,
  top: controlIndex * 48,
  bottom: 0,
  paddingLeft: '46px',
}));

const ColorTextWrapper = styled(BigidBody1)(({ theme }) => ({
  color: theme.palette.bigid.gray500,
}));

export const AccordeonControls = ({ category, list }: FrameworkProps) => {
  return (
    <>
      <BigidAccordion key={category}>
        <SummaryWrapper size={AccordionSummarySizeEnum.large}>
          <TitleWrapper>
            <BigidHeading6>{category}</BigidHeading6>
            <ColorTextWrapper>{`${list?.length} requirements`}</ColorTextWrapper>
          </TitleWrapper>
        </SummaryWrapper>
        <DetailsWrapper key={category} controlsLength={list.length}>
          {list.map((control: ControlsProps, index: number) => (
            <BigidTooltip title={control.description} key={control.name}>
              <ContentWrapper controlIndex={index}>{control.name}</ContentWrapper>
            </BigidTooltip>
          ))}
        </DetailsWrapper>
      </BigidAccordion>
    </>
  );
};
