import React from 'react';
import { useLocalTranslation } from '../translations';
import { BigidBody1, BigidLink, LinkColor } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { sendDataExplorerLandingPageBrowseCatalogLinkBiAnalytics } from '../services/analytics';

const StyledMessage = styled(BigidBody1)`
  display: flex;
  justify-content: center;
  white-space: pre;
  align-items: center;
`;

export const Message = () => {
  const { t } = useLocalTranslation('CatalogMessage');

  const handleLinkClick = () => {
    $state.go(CONFIG.states.CATALOG);
    sendDataExplorerLandingPageBrowseCatalogLinkBiAnalytics();
  };

  return (
    <StyledMessage>
      {t('prependText')}
      <BigidLink text={t('linkText')} color={LinkColor.PRIMARY} onClick={handleLinkClick} />
    </StyledMessage>
  );
};
