import { ReactText } from 'react';
import { isArray, isEmpty, size } from 'lodash';
import {
  ActionModeOnClassifier,
  CLASSIFIER_TYPES,
  Category,
  ClassifierGridRow,
  NERPayload,
  RegexFormFields,
  AssignAttributePayload,
  DuplicateClassifierRequestBody,
} from './types/ClassifierTypes';
import { ConfirmationDialogOptions, showConfirmationDialog } from '../../services/confirmationDialogService';
import * as classifiersManagementService from './services/classifiersManagementService';
import { notificationService } from '../../services/notificationService';
import { getFixedT } from './translations';
import {
  createClassifier,
  updateNerOrDocClassifier,
  importClassifiers,
  assignCategoryToAttributeRequest,
  duplicateClassifierRequest,
} from './services/classifiersManagementService';
import {
  reloadGrid,
  getDefaultAttributeName,
  getUpdatedNerOrDocPayload,
  isMetadata,
  isRegex,
  isRequestSucceeded,
} from './utils/utils';
import { analyticsService } from '../../services/analyticsService';
import { ClassifiersEventsEnum } from './types/Events';

const t = getFixedT('');

export const fetchChecksumValidations = async () => {
  try {
    const { data } = await classifiersManagementService.getChecksumValidations();
    return data?.supportedChecksums?.map((checksum: string) => ({
      id: checksum,
      displayValue: checksum,
      value: checksum,
    }));
  } catch (error) {
    notificationService.error(t('commonErrorMessage'));
  }
};

interface IHandleEnableDisable {
  classifier: ClassifierGridRow;
  payload: Partial<ClassifierGridRow>;
  successCallback?: () => void;
}

export const handleEnableDisable = async ({ classifier, payload, successCallback }: IHandleEnableDisable) => {
  try {
    let response = null;
    const classifierType = classifier?.classifierType;

    if (classifierType === CLASSIFIER_TYPES.NER || classifierType === CLASSIFIER_TYPES.DOC) {
      response = await updateNerOrDocClassifier(classifierType, {
        ...getUpdatedNerOrDocPayload(classifier),
        ...payload,
      });
    }

    if ((isRegex(classifierType) || isMetadata(classifierType)) && classifier[RegexFormFields.classifierName]) {
      response = await classifiersManagementService.updateClassifier(
        classifier[RegexFormFields.classifierName],
        payload,
      );
    }

    if (isRequestSucceeded(response) || response?.data?.modifiedCount || response?.data?._id) {
      if (payload.enabled) {
        notificationService.success(t('notifications.globalEnabledSuccessfully'));
      } else {
        notificationService.success(t('notifications.globalDisabledSuccessfully'));
      }
      successCallback && successCallback();
    }
  } catch (e) {
    notificationService.error(t('notifications.failedToUpdate'));
  }
};

export interface handleEnableDisableBulkParams {
  payload: {
    allSelected: boolean;
    ids: ReactText[];
    query: Record<string, any>;
    enabled: boolean;
  };
  successCallback: () => void;
}

export const handleEnableDisableBulk = async ({ payload, successCallback }: handleEnableDisableBulkParams) => {
  try {
    await classifiersManagementService.updateEnableBulkClassification(payload);
    successCallback?.();
  } catch (error) {
    notificationService.error(t('notifications.failedToUpdate'));
  }
};

export const handleDeleteClassifier = async (
  classifier: ClassifierGridRow,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  try {
    const dialogParams: ConfirmationDialogOptions = {
      entityNameSingular: t('general.classifier'),
      actionName: t('general.delete'),
      isContentScrollable: false,
    };

    const shouldCompleteAction = await showConfirmationDialog(dialogParams);
    if (!shouldCompleteAction) {
      return;
    }

    let response = null;
    setIsLoading && setIsLoading(true);
    if (classifier.classifierType === CLASSIFIER_TYPES.NER) {
      response = await classifiersManagementService.deleteCustomNerClassifier(classifier._id);
    } else {
      response = await classifiersManagementService.deleteClassifier(classifier.classification_name);
    }
    notificationService.success(t('notifications.deletedSuccessfully'));

    return response;
  } catch (error) {
    notificationService.error(t('notifications.failedToDelete'));
  } finally {
    setIsLoading && setIsLoading(false);
  }
};

export const createNewRegexClassifier = async (payload: ClassifierGridRow) => {
  try {
    const response = await createClassifier(payload);

    if (isRequestSucceeded(response)) {
      notificationService.success(
        t('notifications.classifierCreatedSuccessfully', { classifierName: payload.classification_name }),
        { shouldAutoHide: true },
      );
    }

    return response;
  } catch (e) {
    let errorMessage = e?.data?.message ?? e.response.data.message;
    if (e?.response?.data?.message?.startsWith('E11000 duplicate key error')) {
      errorMessage = t('notifications.classifierNameExistError');
    }

    notificationService.error(errorMessage);
  }
};

export const duplicateRegexClassifier = async (payload: DuplicateClassifierRequestBody): Promise<ClassifierGridRow> => {
  try {
    const { data } = await duplicateClassifierRequest(payload);

    if (data?.status === 'success') {
      notificationService.success(
        t('notifications.classifierCreatedSuccessfully', { classifierName: data?.data?.[0]?.classification_name }),
        { shouldAutoHide: true },
      );
    }

    return data?.data?.[0];
  } catch (e) {
    let errorMessage = e?.data?.message ?? e.response.data.message;
    if (e?.response?.data?.message?.startsWith('E11000 duplicate key error')) {
      errorMessage = t('notifications.classifierNameExistError');
    }

    notificationService.error(errorMessage);
  }
};

export const handleDuplicateClassifier = async (classifier: ClassifierGridRow, duplicateClassificationName: string) => {
  try {
    const newClassifier = await duplicateRegexClassifier({
      originalClassificationName: classifier.classification_name,
      duplicateClassificationName,
    });

    if (newClassifier?._id) {
      analyticsService.trackManualEvent(ClassifiersEventsEnum.DUPLICATE_CLASSIFIER, {
        classifier: newClassifier,
      });
    }

    return newClassifier;
  } catch (e) {
    const errorMessage = e?.data?.message ?? e.response.data.message;
    notificationService.error(errorMessage);
  }
};

export const onImportHandler = async (file: File, isImportExcludeList: boolean) => {
  try {
    const { data } = await importClassifiers(file, isImportExcludeList);
    const [{ allExcludeListsValid }] = data?.data;

    if (data?.status === 'success') {
      if (allExcludeListsValid) {
        notificationService.success(t('notifications.successImportMessage'));
      } else {
        notificationService.warning(t('notifications.warningImportMessage'));
      }

      reloadGrid();
    }
  } catch (e) {
    notificationService.error(t('notifications.failedImportMessage'));
  }
};

export const assignCategoriesAndAttribute = async (
  categories: Category[] = [],
  originalName: string,
  friendly_name: string,
  glossary_id?: string,
) => {
  const payload = [
    {
      original_name: originalName,
      categories,
      friendly_name,
      ...(glossary_id ? { glossary_id } : {}),
    },
  ];

  return assignCategoryToAttributeRequest(payload);
};

interface dataToUpdateType {
  categories: Category[];
  friendly_name: string;
  original_name: string;
}

interface handleAssignCategoryAndUpdateGridParams {
  categories: Category[];
  attribute?: AssignAttributePayload;
  sucessCallback?: (dataToUpdate: Partial<dataToUpdateType>) => void;
  nerClassifiers?: NERPayload[];
  mode?: ActionModeOnClassifier;
}

export const handleAssignCategoryAndUpdateGrid = async ({
  categories,
  attribute,
  sucessCallback,
  nerClassifiers,
  mode,
}: handleAssignCategoryAndUpdateGridParams) => {
  const { original_name, friendly_name, glossary_id } = attribute || {};
  const hasCategoriesInCreateMode = mode === ActionModeOnClassifier.create && size(categories) > 0;
  const categoriesNotNilInUpdateMode = mode === ActionModeOnClassifier.update && isArray(categories);
  const isShouldBeAssigned = hasCategoriesInCreateMode || categoriesNotNilInUpdateMode || friendly_name;

  const dataToUpdate = {
    ...(isShouldBeAssigned ? { categories } : {}),
    friendly_name,
    original_name,
  };

  if (mode === ActionModeOnClassifier.create && !size(categories) && !friendly_name) {
    sucessCallback && sucessCallback(dataToUpdate);
    return;
  }

  // Generate Ner apis START
  const getNerOriginalName = (ner: NERPayload) =>
    original_name || getDefaultAttributeName({ ...ner, type: CLASSIFIER_TYPES.NER });

  const getNerAssignCategoriesApis = (classifiers: NERPayload[], glossary_id?: string) =>
    classifiers?.map(ner => {
      const requestedOriginalName = getNerOriginalName(ner);
      return assignCategoriesAndAttribute(categories, requestedOriginalName, friendly_name, glossary_id);
    });

  let nerAssignCategoriesApis: Promise<any>[] = [];

  if (mode === ActionModeOnClassifier.create && nerClassifiers?.length > 1 && !glossary_id) {
    const [firstNerClassifier, ...restNERClassifiers] = nerClassifiers;

    const data = await assignCategoriesAndAttribute(categories, getNerOriginalName(firstNerClassifier), friendly_name);

    nerAssignCategoriesApis = getNerAssignCategoriesApis(restNERClassifiers, data?.glossary_id);
  } else if (!isEmpty(nerClassifiers)) {
    nerAssignCategoriesApis = getNerAssignCategoriesApis(nerClassifiers, glossary_id);
  }
  // Generate Ner apis END

  const assignCategoriesApis =
    !isEmpty(nerClassifiers) && nerAssignCategoriesApis.length
      ? nerAssignCategoriesApis
      : isShouldBeAssigned
      ? [assignCategoriesAndAttribute(categories, original_name, friendly_name, glossary_id)]
      : [];

  try {
    await Promise.all(assignCategoriesApis);

    sucessCallback && sucessCallback(dataToUpdate);
  } catch (e) {
    console.log(e);
  }
};
