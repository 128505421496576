import {
  ACTION_DATA_KEY_BY_TYPE,
  ALL_DS_TYPES_SELECTED,
  AUTH_SECTION_NAME,
  AUTH_TYPE_FIELD_NAME,
  CloudProvider,
  cloudProviderLabel,
  DiscoveryActionType,
  DiscoveryAuthTypes,
  DS_LIST_BY_TYPE,
  TYPE_TO_AUTH_OPTIONS,
  TYPE_TO_HIDDEN_BY_FLAGS,
  TYPE_TO_HIDDEN_VALUES,
  TYPE_TO_OVERRIDE_FIELDS,
  TYPE_TO_VISIBLE_BY_AUTH_FIELDS,
} from '../constants';
import { BigidFormField, BigidFormFieldTypes, BigidSelectOption } from '@bigid-ui/components';
import {
  AutoDiscoverWizardAsideExpandedStates,
  AutoDiscoveryWizardContextState,
  AutoDiscoveryWizardForm,
} from './autoDiscoveryWizardContext';
import { ActionItemParamsEntity } from '../../CustomApp/types';
import { DataSourceTemplateConditionOperatorsEnum, DsTypesEnum } from '../../DataSources/DataSourceConfiguration/types';
import { isEqual, uniqBy } from 'lodash';
import { AutoDiscoverySelectAuthTypeField } from './autoDiscoveryComponents/fields/AutoDiscoverySelectAuthTypeField';
import { checkAutoDiscoveryConfigPermissions, getSideScanScanFieldsByType } from './autoDiscoveryWizardUtils';
import { AutoDiscoveryConfigModel } from '../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { InputTypes } from '../../CustomApp/views/EditCustomApp/EditCustomApp';
import { CustomSwitchField } from './autoDiscoveryComponents/fields/CustomSwitchField';
import { fieldConditionCheck } from '../../DataSources/DataSourceConfiguration/utils/conditionUtils';
import React from 'react';
import { getApplicationPreference } from '../../../services/appPreferencesService';

interface ActionItemParam extends ActionItemParamsEntity {
  param_friendly_name?: string;
  input_items?: string[];
  input_type?: InputTypes;
}

export interface DsOptionInWizard {
  id: string;
  label: string;
  isPermitted: boolean;
  selected: boolean;
}

interface IsFieldsForAuthTypeFilled {
  fields: string[];
  preset?: Record<string, any>;
}
function getFieldsForAuthTypeFilledCount({ fields, preset }: IsFieldsForAuthTypeFilled) {
  return fields?.filter?.(fieldName => preset?.paramsKeyValue?.[fieldName]);
}

function convertAuthTypeToFormValue(type: CloudProvider, authType: string | BigidSelectOption[]) {
  return authType
    ? TYPE_TO_AUTH_OPTIONS[type]?.filter(({ value }) => value === authType)
    : [TYPE_TO_AUTH_OPTIONS[type]?.[0]];
}
const calcAuthType = (formData: AutoDiscoveryWizardForm, type: CloudProvider, preset?: Record<string, any>) => {
  const authFieldForPreset = preset?.paramsKeyValue && Object.entries(TYPE_TO_VISIBLE_BY_AUTH_FIELDS[type]);
  const result = authFieldForPreset?.reduce?.(
    (current, [authType, fields]) => {
      const count = getFieldsForAuthTypeFilledCount({ fields, preset })?.length;
      const isAuthTypeProbablySelected = count > current.count;
      return isAuthTypeProbablySelected ? { authType, count } : current;
    },
    { count: 0, authType: '' },
  );

  return convertAuthTypeToFormValue(type, result?.authType ?? formData?.authType);
};

const convertBooleanLikeValueToFormFormat = (value: string | boolean) =>
  value === 'true' || value === 'yes' || value === true;

export const mapConvertPresetValueToFormValueByType: {
  [key in BigidFormFieldTypes]?: (value: any, field: BigidFormField) => any;
} = {
  [BigidFormFieldTypes.DROP_DOWN]: (value: any, field: BigidFormField) => {
    return field?.fieldProps?.isMulti
      ? field.dropDownOptions?.filter(option => value?.split?.(',').includes(option.value))
      : field.dropDownOptions?.filter(option => option.value === value);
  },
  [BigidFormFieldTypes.SWITCH]: convertBooleanLikeValueToFormFormat,
  [BigidFormFieldTypes.CHECKBOX]: convertBooleanLikeValueToFormFormat,
};

export const mapConvertPresetDataToFieldProps: {
  [key in InputTypes]?: (field: ActionItemParam) => any;
} = {
  [InputTypes.SINGLE_ELECTION]: field => {
    return field?.input_items
      ? {
          dropDownOptions: field?.input_items?.map((option: string) => ({
            id: option,
            displayValue: option,
            value: option,
          })),
        }
      : {};
  },
  [InputTypes.MULTIPLE_SELECTION]: field => {
    return field?.input_items
      ? {
          fieldProps: {
            isMulti: true,
          },
          dropDownOptions: field?.input_items?.map((option: string) => ({
            id: option,
            displayValue: option,
            value: option,
          })),
        }
      : {};
  },
};

function getAuthTypeByActionType(type: CloudProvider, actionType: DiscoveryActionType) {
  return Object.entries(ACTION_DATA_KEY_BY_TYPE[type])
    .filter(([, value]) => value === actionType)
    .map(([key]) => key);
}

function getParamsNames(params?: ActionItemParamsEntity[]) {
  return new Set((params ?? []).map(({ param_name }) => param_name));
}

type GetConditionByParamName = (paramName: string) => [Record<string, any>];
type CreateConditionGeneratorForShowFieldByActionType = (
  type: CloudProvider,
  configData: AutoDiscoveryConfigModel,
  configDataMultiply: AutoDiscoveryConfigModel,
) => GetConditionByParamName;

const createConditionGeneratorForShowFieldByActionType: CreateConditionGeneratorForShowFieldByActionType = (
  type,
  configData,
  configDataMultiply,
) => {
  const paramsNamesInSingle = getParamsNames(configData.appRunAction.params);
  const paramsNamesInMulti = getParamsNames(configDataMultiply?.appRunAction?.params);
  const authTypesForMulti = getAuthTypeByActionType(type, DiscoveryActionType.MULTI);
  const authTypesForSingle = getAuthTypeByActionType(type, DiscoveryActionType.SINGLE);

  return function getCondition(paramName: string) {
    return [
      {
        field: AUTH_TYPE_FIELD_NAME,
        value: {
          operator: DataSourceTemplateConditionOperatorsEnum.or,
          values: [
            ...(paramsNamesInSingle.has(paramName) ? authTypesForSingle : []),
            ...(paramsNamesInMulti.has(paramName) ? authTypesForMulti : []),
          ],
        },
      },
    ];
  };
};

function createFieldFromParamsConfig(
  item: ActionItemParam,
  type: CloudProvider,
  getVisibleIfByActionTypeToAuth: GetConditionByParamName,
): BigidFormField {
  const { param_name, param_friendly_name, is_mandatory, param_description, param_category } = item;
  const authFieldsByType = Object.entries(TYPE_TO_VISIBLE_BY_AUTH_FIELDS[type]);
  const overrides = TYPE_TO_OVERRIDE_FIELDS[type];
  const overrideParams = overrides[param_name] ?? { misc: {} };
  const conditionAuthValues = authFieldsByType
    .filter(([, fieldsForAuthType]) => fieldsForAuthType?.includes(param_name))
    .map(([authTypeValue]) => authTypeValue);
  const visibleIfAuth = !!conditionAuthValues.length && [
    {
      field: AUTH_TYPE_FIELD_NAME,
      value: {
        operator: DataSourceTemplateConditionOperatorsEnum.or,
        values: conditionAuthValues,
      },
    },
  ];
  const optionsToType = {
    isYesNoField: isYesNoField(item.input_items),
    isTrueFalse: isTrueFalseField(item.input_items),
  };

  const fieldType = getFieldType(item, optionsToType);
  const validate = visibleIfAuth
    ? (value: string, allValues: Record<string, any>) => {
        if (!conditionAuthValues.includes(getDiscoverAuthTypeString(allValues.authType)) || !is_mandatory) {
          return false;
        }
        if (!value?.trim()) {
          return `Please enter ${param_friendly_name}`;
        }
        return false;
      }
    : undefined;
  const fieldParamsByType = mapConvertPresetDataToFieldProps[item.input_type]?.(item) ?? {};

  return {
    name: param_name,
    label: param_friendly_name,
    isRequired: is_mandatory,
    type: fieldType,
    ...fieldParamsByType,
    ...overrideParams,
    tooltipText: param_description,
    misc: {
      ...optionsToType,
      section: visibleIfAuth ? AUTH_SECTION_NAME : param_category,
      hidden: !!getTypeToHiddenFields(type)[param_name],
      ...(overrideParams?.misc ?? {}),
      visibleIf: visibleIfAuth || getVisibleIfByActionTypeToAuth(param_name),
      ...(fieldParamsByType?.misc ?? {}),
    },
    ...(fieldType === BigidFormFieldTypes.SWITCH
      ? {
          render: CustomSwitchField,
        }
      : {}),
    validate,
  };
}

const pad = (n: number) => (n < 10 ? '0' : '') + n;
function generatePresetName(type: CloudProvider) {
  const date = new Date();
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear().toString().slice(-2);
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${cloudProviderLabel[type]}_${day}_${month}_${year}_${hours}${minutes}${seconds}`;
}
export const generateAutoDiscoveryFieldsData = ({
  configData,
  configDataMultiply,
  type,
  formData,
  preset = {},
}: AutoDiscoveryWizardContextState) => {
  const defaultInitialValues = {
    name: preset?.name ?? generatePresetName(type),
    ...(preset?.paramsKeyValue ?? {}),
    authType: calcAuthType(formData, type, preset),
  };
  const mainFields = getAutodiscoveryConfigFormGeneralFields(type);

  const allParams = [...configData.appRunAction.params, ...(configDataMultiply?.appRunAction?.params ?? [])];
  const sideScanFields = getSideScanScanFieldsByType(type, allParams);
  const getVisibleIfByActionTypeToAuth = createConditionGeneratorForShowFieldByActionType(
    type,
    configData,
    configDataMultiply,
  );

  const { initialValues, fields, fieldsBySection } = allParams.reduce<{
    initialValues: Record<string, any>;
    fields: BigidFormField[];
    fieldsBySection?: Record<string, BigidFormField[]>;
  }>(
    (acc, param: ActionItemParam) => {
      const { param_name, default_value } = param;
      const sideScanField = sideScanFields.find(sideScanField => sideScanField.name === param_name);
      const field = sideScanField || createFieldFromParamsConfig(param, type, getVisibleIfByActionTypeToAuth);
      const result = {
        ...acc,
        initialValues: {
          ...acc.initialValues,
          [param_name]:
            mapConvertPresetValueToFormValueByType[field?.type]?.(
              (preset?.paramsKeyValue ?? {})[param_name] ?? param?.default_value,
              field,
            ) ??
            (preset?.paramsKeyValue ?? {})[param_name] ??
            default_value,
        },
      };
      if (sideScanField) {
        return result;
      }

      return {
        ...result,
        fields: [...acc.fields, field],
        fieldsBySection: field.misc.hidden
          ? acc.fieldsBySection
          : {
              ...acc.fieldsBySection,
              [field.misc?.section ?? 'Parameters']: uniqBy(
                [...(acc.fieldsBySection[field.misc.section] || []), field],
                'name',
              ),
            },
      };
    },
    {
      initialValues: defaultInitialValues,
      fields: [],
      fieldsBySection: {},
    },
  );

  return {
    initialValues,
    mainFields,
    sideScanFields,
    fieldsBySection,
    fields: uniqBy(fields, 'name'),
  };
};

function getAutodiscoveryConfigFormGeneralFields(type: CloudProvider): BigidFormField[] {
  return [
    {
      name: 'name',
      label: 'Onboarding Name',
      isRequired: true,
      validate: (name: string) => {
        if (!name.trim()) {
          return 'Please enter Preset Name';
        }
        return false;
      },
    },
    {
      name: AUTH_TYPE_FIELD_NAME,
      isRequired: true,
      render: AutoDiscoverySelectAuthTypeField,
      misc: {
        type: type,
        typeForConditionCheck: DsTypesEnum.options,
      },
    },
  ];
}

export function getDsOptions(
  permissions: string[],
  type: CloudProvider,
  selectedTypes?: { value: string }[],
): DsOptionInWizard[] {
  const isAllSelected = !selectedTypes?.length || selectedTypes?.some?.(({ value }) => value === ALL_DS_TYPES_SELECTED);
  return Object.entries(DS_LIST_BY_TYPE[type]).map(([id, { label, value }]) => {
    const isPermitted = permissions.some(dsTypePermitted => value === dsTypePermitted);
    return {
      id,
      label,
      isPermitted,
      selected: isAllSelected ? isPermitted : selectedTypes.some?.(option => id === option?.value),
    };
  });
}

export function getDiscoverAuthTypeString(authTypeValue: DiscoveryAuthTypes | BigidSelectOption[]): DiscoveryAuthTypes {
  return typeof authTypeValue === 'string' ? authTypeValue : authTypeValue?.[0]?.value;
}

const isYesNoField = (inputItems: string[]) => isEqual(inputItems, ['yes', 'no']);
const isTrueFalseField = (inputItems: string[]) => isEqual(inputItems, ['true', 'false']);

function getFieldType(
  { is_cleartext, input_type, param_type }: ActionItemParam,
  { isYesNoField, isTrueFalse }: Record<string, any>,
) {
  const inputType = !input_type && param_type === 'String' ? InputTypes.TEXT : input_type;
  switch (inputType) {
    case InputTypes.TEXT:
      return is_cleartext ? BigidFormFieldTypes.TEXT : BigidFormFieldTypes.PASSWORD;
    case InputTypes.SINGLE_ELECTION:
      return isYesNoField || isTrueFalse ? BigidFormFieldTypes.SWITCH : BigidFormFieldTypes.DROP_DOWN;
    case InputTypes.MULTIPLE_SELECTION:
      return BigidFormFieldTypes.DROP_DOWN;
    default:
      return BigidFormFieldTypes.TEXT;
  }
}
export function resetStateOnAuthTypeChanged(
  values: Record<string, any>,
  currentState: AutoDiscoveryWizardContextState,
) {
  if (values?.[AUTH_TYPE_FIELD_NAME]?.[0]?.value !== currentState?.formData?.[AUTH_TYPE_FIELD_NAME]?.[0]?.value) {
    return {
      errorMessage: undefined,
      errorMessageKey: undefined,
    };
  }
  return {};
}

export const getFieldConditionState = (
  { misc: { visibleIf, enabledIf } }: BigidFormField,
  values: Record<string, any>,
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>,
) => {
  const getFunction = (field: string) => ({
    value: values[field],
    type: discoveryConfigDataRef.current.getFieldProps(field).misc?.typeForConditionCheck,
  });
  const isVisible = fieldConditionCheck(false, visibleIf, getFunction);
  const enabled = fieldConditionCheck(true, enabledIf, getFunction);

  return {
    disabled: !enabled,
    isVisible,
  };
};

interface RunDiscoveryCheck {
  setDiscoveryConfigData: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>;
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
  values?: Record<string, any>;
  fieldsNamesByAuthTypeFields?: string[];
}
export const runDiscoveryCheck = async ({
  setDiscoveryConfigData,
  discoveryConfigDataRef,
  values,
  fieldsNamesByAuthTypeFields,
}: RunDiscoveryCheck) => {
  setDiscoveryConfigData(current => ({
    ...current,
    isCheck: true,
    errorMessage: undefined,
    errorMessageKey: undefined,
  }));

  const { connectionInfo, message, isValid, errorMessageKey } = await checkAutoDiscoveryConfigPermissions({
    fieldsNamesByAuthTypeFields,
    values,
    discoveryConfigDataRef,
  });
  const asideExpandedState = isValid
    ? AutoDiscoverWizardAsideExpandedStates.PERMISSIONS_SUMMARY
    : AutoDiscoverWizardAsideExpandedStates.OPENED_ERROR;

  setDiscoveryConfigData(current => ({
    ...current,
    connectionInfo,
    formData: {
      ...current.formData,
      dsList: isValid ? getDsOptions(connectionInfo.permissions, current.type, current.formData?.ds_type) : [],
    },
    asideExpandedState,
    isCheck: false,
    errorMessage: message,
    errorMessageKey,
    fieldsNamesByAuthTypeFields,
  }));

  return isValid;
};

function getTypeToHiddenFields(appType: CloudProvider): Record<string, boolean> {
  const byFlags = TYPE_TO_HIDDEN_BY_FLAGS[appType].reduce((acc, { field, value, flag }) => {
    const hidden = getApplicationPreference(flag) === value;
    return { [field]: hidden };
  }, {});

  return {
    ...TYPE_TO_HIDDEN_VALUES[appType],
    ...byFlags,
  };
}
