import React, { Dispatch, FC, MutableRefObject, SetStateAction, useCallback, useEffect, useState } from 'react';
import { $state } from '../../../../services/angularServices';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, isEqual } from 'lodash';
import { customAppService } from '../../../../services/customAppService';
import { notificationService } from '../../../../services/notificationService';
import { appsLicenseService } from '../../../../services/appsLicenseService';
import { BigidFormBox } from '../../../../components/BigidFormBox/BigidFormBox';
import { AppInfo, FetchItems, GlobalPreset, ParamTypes } from '../../utils/CustomAppTypes';
import { convertToParamsValuesObject, generatedUniquePresetName } from '../CustomAppActions/CustomAppActions';
import { AppVersionSection } from './Sections/AppVersionSection';
import { InteractiveSection } from './Sections/InteractiveSection';
import { BaseUrlSection } from './Sections/BaseUrlSection';
import { AdditionalPermissionsSection } from './Sections/AdditionalPermissionsSection';
import { GlobalParamsSection } from './Sections/GlobalParamsSection';
import { CONFIG } from '../../../../../config/common';
import { TPATrackingEvents, trackTPAEvent } from '../../customAppEventsTrackerUtils';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { PresetCard } from '../../components/PresetCard/PresetCard';
import { areSomePermitted } from '../../../../services/userPermissionsService';
import { APPLICATIONS_PERMISSIONS, getManageBasePermissionNameByTpaName } from '@bigid/permissions';
import { SecondaryButton } from '@bigid-ui/components';
import { BigidAddIcon } from '@bigid-ui/icons';
import { deleteAppFromPreferences } from '../../../ApplicationsManagement/applicationManagementService';
import { CustomAppEvents, customAppEventsEmitter } from '../../../../services/customAppEvents';
import { AppInstanceSection } from './Sections/AppInstanceSection';
import { CredentialType } from '../../../Credentials/credentialsFormUtils';
import { isMissingMandatoryParams } from '../../utils/paramValidationUtils';

export interface TpaGeneralValues {
  ui_tab_name?: string;
  ui_url?: string;
  is_use_proxy?: boolean;
  bigid_base_url?: string;
  can_get_ds_credentials?: boolean;
}

interface EditCustomAppProps {
  appInfo: AppInfo;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
  setIsValueChanged: Dispatch<SetStateAction<boolean>>;
  onSaveMutableRef: MutableRefObject<() => void>;
  globalPresets?: GlobalPreset[];
}

export enum InputTypes {
  SINGLE_ELECTION = 'singleSelection',
  MULTIPLE_SELECTION = 'multipleSelection',
  TEXT = 'text',
}

export interface CustomAppParam {
  type: ParamTypes;
  name: string;
  friendlyName?: string;
  description?: string;
  placeholder?: string;
  value?: string;
  isMandatory?: boolean;
  isClearText?: boolean;
  inputType?: InputTypes;
  inputItems?: string[];
  defaultValue?: string;
  fetchItems: FetchItems;
  paramCategory: string;
  appFieldIdentifiersMap?: Record<string, string>;
  errorMessage?: string;
  paramValue?: string;
  subType?: CredentialType;
}

export interface CredentialTypeParam extends CustomAppParam {
  credentialFields: Record<string, CustomAppParam>;
}

const useStyles = makeStyles({
  wrapper: {
    padding: '16px',
  },
  formBoxWrapper: {
    marginBottom: 12,
  },
  globalParamsWidth: {
    width: '55%',
  },
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  presetCardsContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
});

const initGeneralValues = (appInfo: AppInfo) => ({
  can_get_ds_credentials: appInfo.canGetDsCredentials,
  bigid_base_url: appInfo.bigidBaseUrl,
  ui_tab_name: appInfo.uiTabName,
  ui_url: appInfo.uiUrl,
  is_use_proxy: appInfo.isUseProxy,
});

let isCloning = false;

export const EditCustomApp: FC<EditCustomAppProps> = ({
  appInfo,
  setIsFormValid,
  setIsValueChanged,
  onSaveMutableRef,
}) => {
  const classes = useStyles({});
  const initGlobalParams = convertToParamsValuesObject(appInfo.globalParams);
  const initGeneralParams = initGeneralValues(appInfo);
  const initDefaultPreset = appInfo.globalPresets.find((preset: GlobalPreset) => preset.is_default)?.name;
  const [defaultPresetName, setDefaultPresetName] = useState(initDefaultPreset);
  const [globalParamsValues, setGlobalParamsValues] = useState<Record<string, string>>(initGlobalParams);
  const [generalParams, setGeneralParams] = useState<Record<string, string | boolean>>(initGeneralParams);
  const [interactiveSectionValid, setInteractionSectionValid] = useState<boolean>(true);
  const [baseUrlSectionValid, setBaseUrlSectionValid] = useState<boolean>(true);
  const [globalParamsSectionValid, setGlobalParamsSectionValid] = useState<boolean>(true);

  useEffect(() => {
    setIsValueChanged(
      !isEqual(initGlobalParams, globalParamsValues) ||
        !isEqual(initGeneralParams, generalParams) ||
        !isEqual(initDefaultPreset, defaultPresetName),
    );
  }, [initGlobalParams, globalParamsValues, initGeneralParams, generalParams, initDefaultPreset, setIsValueChanged]);

  useEffect(() => {
    setIsFormValid(interactiveSectionValid && baseUrlSectionValid && globalParamsSectionValid);
  }, [interactiveSectionValid, baseUrlSectionValid, globalParamsSectionValid, setIsFormValid]);

  const handleUpdateTpa = async () => {
    try {
      const params = {
        appUrl: appInfo.baseUrl,
      };
      const { id, name } = appInfo;
      await customAppService.upgradeCustomApp(id, params);
      notificationService.success('Software update completed successfully');
      trackTPAEvent(TPATrackingEvents.TPA_SETTINGS_UPDATE_CLICK, { appName: appInfo.name });
      $state.go(CONFIG.states.CUSTOM_APP_EDIT, { id, name }, { reload: true, inherit: false });
    } catch (error) {
      notificationService.error(error.response.data.errors[0]?.message);
    }
  };

  const handleAppDeletion = useCallback(async () => {
    const { installationDetails, name, id } = appInfo;
    if (installationDetails?.installationType === 'Automatic') {
      try {
        await customAppService.uninstallTpa(installationDetails.appImageName);
        trackTPAEvent(TPATrackingEvents.TPA_MANAGEMENT_DELETE_CLICK, { appName: name });
        await appsLicenseService.fetchAndSetAppLicense();
        notificationService.success(`Uninstalling app instance: ${name}`);
      } catch (e) {
        notificationService.error(`Could not uninstall app instance: ${name}, got error: ${e}`);
        return;
      }
    }

    await customAppService.deleteCustomApp(id);
    await deleteAppFromPreferences(id);
    customAppEventsEmitter.emit(CustomAppEvents.UPDATE_APP_LIST);
    $state.go(CONFIG.states.APPLICATIONS_MANAGEMENT, null, { reload: true });
  }, [
    appInfo.id,
    appInfo.installationDetails?.appImageName,
    appInfo.installationDetails?.installationType,
    appInfo.name,
  ]);

  const onGeneralValuesChange = (interactiveObj: TpaGeneralValues) => {
    setGeneralParams(state => ({ ...state, ...interactiveObj }));
  };

  const handleOnCloneGlobalPreset = async (globalPreset: GlobalPreset) => {
    const existingNames = appInfo.globalPresets.map(({ name }) => name);
    const name = generatedUniquePresetName(`Clone - ${globalPreset.name}`, existingNames);
    const { description, params_key_value } = globalPreset;
    const globalPresetPayload: Partial<GlobalPreset> = {
      name,
      description,
      params_key_value,
    };
    if (!isCloning) {
      //We are preventing double cloning in case of doubleClick
      isCloning = true;
      try {
        await customAppService.addGlobalPreset(appInfo.id, globalPresetPayload);
        notificationService.success(`Cloned preset successfully`, { shouldCloseOnTransition: false });
      } catch (err) {
        const message = 'There is a problem cloning this global preset';
        notificationService.error(message);
      } finally {
        isCloning = false;
        $state.go(CONFIG.states.CUSTOM_APP_EDIT, { appInfo: null, id: appInfo.id }, { reload: true });
      }
    }
  };

  onSaveMutableRef.current = async () => {
    try {
      if (defaultPresetName !== initDefaultPreset) {
        const newDefaultGlobalPreset = appInfo.globalPresets.find(
          (globalPreset: GlobalPreset) => globalPreset.name === defaultPresetName,
        );
        const newDefaultGlobalPresetPayload = {
          is_default: true,
          name: newDefaultGlobalPreset.name,
          description: newDefaultGlobalPreset.description,
          params_key_value: newDefaultGlobalPreset.params_key_value,
        };
        customAppService.editGlobalPreset(appInfo.id, newDefaultGlobalPreset._id, newDefaultGlobalPresetPayload);
      }

      const toUpdate = {
        paramsKeyValuesMap: globalParamsValues,
        generalKeyValuesMap: generalParams,
      };

      if (!isEmpty(toUpdate)) {
        await customAppService.updateCustomAppParamValues(appInfo.id, toUpdate);
      }
      trackTPAEvent(TPATrackingEvents.TPA_SETTINGS_SAVE_CLICK, { appName: appInfo.name });
      appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(
        appInfo.id,
        appInfo.name,
        appInfo.vendor,
        appInfo.manifest_name,
      );
    } catch (error) {
      console.error(error);
      notificationService.error('Something went wrong, please try again.');
    }
  };

  const handleGlobalParamsValueChange = (obj: Record<string, string>) => {
    setGlobalParamsValues({ ...globalParamsValues, ...obj });
  };

  const isGlobalPresetsEnabled = () => {
    return getApplicationPreference('TPA_GLOBAL_PRESETS_ENABLED');
  };

  const hasManagerPermission = areSomePermitted([
    APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name,
    APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name,
    getManageBasePermissionNameByTpaName(appInfo.name),
  ]);

  const handleOnSetGlobalPreset = (globalPreset: GlobalPreset) => {
    const params = {
      readOnly: false,
      id: appInfo.id,
      appInfo,
      globalPreset: globalPreset,
      globalPresetId: globalPreset?._id ?? '',
    };
    $state.go(CONFIG.states.CUSTOM_APP_EDIT_GLOBAL_PRESET, params);
  };

  const OnChangeDefault = (newDefaultName: string) => {
    setDefaultPresetName(newDefaultName);
  };

  const handleDeletePreset = async (presetId: string, presetName: string) => {
    try {
      await customAppService.deleteGlobalPreset(appInfo.id, presetId);
      $state.go(CONFIG.states.CUSTOM_APP_EDIT, { appInfo: null, id: appInfo.id }, { reload: true });
      notificationService.success(`${presetName} Preset - Deleted`, { shouldCloseOnTransition: false });
    } catch (e) {
      notificationService.error(`Could not delete ${presetName} preset`, { shouldCloseOnTransition: false });
    }
  };

  const getErrorMessage = (globalPreset: GlobalPreset) => {
    const isMissingGlobalParams = isMissingMandatoryParams(
      globalPreset.params_key_value,
      appInfo.globalParams.filter(({ isMandatory }) => isMandatory),
    );
    return isMissingGlobalParams ? 'Missing Mandatory Global params' : '';
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.formBoxWrapper}>
        <BigidFormBox title="Application Base URL" description={appInfo.baseUrl} />
      </div>

      <div className={classes.formBoxWrapper}>
        <AppVersionSection appName={appInfo.name} version={appInfo.version} onUpdate={handleUpdateTpa} />
      </div>

      {appInfo.settings?.scannerGroup && (
        <div className={classes.formBoxWrapper}>
          <BigidFormBox title="Scanner Group" description={appInfo.settings?.scannerGroup} />
        </div>
      )}

      {appInfo.isInteractive && (
        <div className={classes.formBoxWrapper}>
          <InteractiveSection
            uiUrl={appInfo.uiUrl}
            menuItemName={appInfo.uiTabName}
            isUseProxy={appInfo.isUseProxy}
            onChange={onGeneralValuesChange}
            setSectionValid={setInteractionSectionValid}
          />
        </div>
      )}

      {getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED') && (
        <div className={classes.formBoxWrapper}>
          <AppInstanceSection instanceName={appInfo.name} onDelete={handleAppDeletion} />
        </div>
      )}

      <div className={classes.formBoxWrapper}>
        <BaseUrlSection
          bigidBaseUrl={appInfo.bigidBaseUrl}
          onChange={onGeneralValuesChange}
          setSectionValid={setBaseUrlSectionValid}
        />
      </div>

      <div className={classes.formBoxWrapper}>
        <AdditionalPermissionsSection
          canGetCredentialsChecked={appInfo.canGetDsCredentials}
          onChange={onGeneralValuesChange}
        />
      </div>

      <BigidFormBox title="Global Parameters">
        {isGlobalPresetsEnabled() ? (
          <div className={classes.presetCardsContainer}>
            {appInfo.globalPresets.map((globalPreset: GlobalPreset) => {
              return (
                <PresetCard
                  globalParams={appInfo.globalParams}
                  enableDelete={!(defaultPresetName === globalPreset.name)}
                  isGlobalPreset={true}
                  onDelete={() => handleDeletePreset(globalPreset._id, globalPreset.name)}
                  errorMessage={getErrorMessage(globalPreset)}
                  onClone={() => handleOnCloneGlobalPreset(globalPreset)}
                  noParamsForThisAction={false}
                  hasManagerPermission={hasManagerPermission}
                  onEdit={() => {
                    handleOnSetGlobalPreset(globalPreset);
                  }}
                  preset={globalPreset}
                  key={'id'}
                  title={globalPreset.name}
                  description={globalPreset.description}
                  defaultGlobalPresetName={defaultPresetName}
                  changeDefaultFunction={OnChangeDefault}
                />
              );
            })}
            <div className={classes.addButtonContainer}>
              <span>
                <SecondaryButton
                  onClick={() => {
                    handleOnSetGlobalPreset(null);
                  }}
                  size={'medium'}
                  disabled={false}
                  startIcon={<BigidAddIcon />}
                  text="Add"
                />
              </span>
            </div>
          </div>
        ) : (
          !!appInfo.globalParams.length && (
            <div className={classes.formBoxWrapper}>
              <GlobalParamsSection
                globalParams={appInfo.globalParams}
                setSectionValid={setGlobalParamsSectionValid}
                onChange={handleGlobalParamsValueChange}
                globalParamsValues={globalParamsValues}
              />
            </div>
          )
        )}
      </BigidFormBox>
    </div>
  );
};
