import React from 'react';
import styled from '@emotion/styled';
import { BigidBookIcon } from '@bigid-ui/icons';
import { BigidHeading6 } from '@bigid-ui/components';

const CorrelationSetGuideContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => `${theme.vars.palette.bigid.gray125}`};
  font-size: 14px;
  padding: 0 16px 20px;
  border-radius: 8px;
  overflow-y: scroll;
`;

const CorrelationSetGuideTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

const CorrelationSetGuideTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const CorrelationSetGuideContent = styled.div`
  background: white;
  font-size: 14px;
  border-radius: 8px;
  padding: 16px 16px 24px;
  border: 1px solid ${({ theme }) => `${theme.vars.palette.bigid.gray200}`};
`;

interface CorrelationSetGuideProps {
  headerTitle: string;
  headerRightLink?: React.ReactNode;
  contentComponent: React.ReactNode;
}

export const CorrelationSetGuide = ({ headerTitle, contentComponent, headerRightLink }: CorrelationSetGuideProps) => {
  return (
    <CorrelationSetGuideContainer>
      <CorrelationSetGuideTitleBox>
        <CorrelationSetGuideTitle>
          <BigidBookIcon size="medium" style={{ marginRight: 8 }} />
          <BigidHeading6>{headerTitle}</BigidHeading6>
        </CorrelationSetGuideTitle>
        {headerRightLink && headerRightLink}
      </CorrelationSetGuideTitleBox>
      {contentComponent}
    </CorrelationSetGuideContainer>
  );
};
