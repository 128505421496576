import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { getFixedT } from '../translations';
import { StatisticsRow } from '../types/CorrelationSetsTypes';

const gridText = getFixedT('grid');

export const getStatisticsInitialGridColumns = (): BigidGridColumn<StatisticsRow>[] => {
  return [
    {
      name: 'columnName',
      title: gridText('columnName'),
      sortingEnabled: false,
      getCellValue: row => row.columnName,
      type: BigidGridColumnTypes.TEXT,
      width: 280,
    },
    {
      name: 'attributeName',
      title: gridText('attributeName'),
      sortingEnabled: false,
      getCellValue: row => row.attributeName,
      type: BigidGridColumnTypes.TEXT,
      width: 280,
    },
    {
      name: 'categories',
      title: gridText('categories'),
      sortingEnabled: false,
      getCellValue: ({ categories }) => {
        return {
          categories: {
            value: categories?.map(category => ({
              categoryName: category.display_name,
              categoryColor: category.color,
            })),
          },
        };
      },
      type: BigidGridColumnTypes.CATEGORIES,
      width: 280,
    },
    {
      name: 'identifiability',
      title: gridText('identifiability'),
      sortingEnabled: false,
      getCellValue: row => {
        if (!row?.identifiability) {
          return null;
        }
        return {
          chips: {
            value: [{ label: row.identifiability ?? row.identifiability, id: row._id }],
            isDisabled: true,
            entityMaxWidth: 200,
          },
        };
      },
      type: BigidGridColumnTypes.CHIPS,
      width: 280,
    },
    {
      name: 'avgValuesLength',
      title: gridText('avgValuesLength'),
      sortingEnabled: false,
      getCellValue: row => row.avgValuesLength,
      type: BigidGridColumnTypes.NUMBER,
      width: 280,
    },
  ];
};
