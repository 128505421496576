import React, { useRef, useState } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidArrowDownIcon } from '@bigid-ui/icons';
import { BigidMenu, BigidMenuProps, BigidMenuItemProps, SecondaryButton } from '@bigid-ui/components';

interface DropdownActionsProps {
  items: BigidMenuItemProps[];
}

export const DropdownActions = ({ items }: DropdownActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<any>(null);

  const menuProps: BigidMenuProps = {
    open: isOpen,
    onMenuClose: () => setIsOpen(false),
    anchorEl: iconRef.current,
    items,
  };

  return (
    <>
      <div ref={iconRef}>
        <SecondaryButton
          size="medium"
          startIcon={<BigidArrowDownIcon />}
          onClick={() => setIsOpen(!isOpen)}
          dataAid={generateDataAid('BigidButtonIcon', ['drop-down-actions-opener'])}
          text="Actions"
        />
      </div>
      <BigidMenu {...menuProps} paperStyle={{ marginTop: '8px' }} />
    </>
  );
};
