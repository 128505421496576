import { useState, useEffect, useCallback } from 'react';
import {
  CuratedAttributeType,
  CurationStatus,
  GetFieldsCurationStatusResponse,
  getFieldsCurationStatus,
} from '../curationService';
import { Breadcrumb, useCancelablePromise } from '@bigid-ui/components';
import { getFixedT } from '../translations';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { getPageTitle, getStatusBaseQuery } from '../curationUtils';

export type UseClassifierFindingsParams = {
  attributeName: string;
  attributeType: CuratedAttributeType;
  displayName?: string;
};

export function useClassifierFindings({ attributeName, attributeType, displayName }: UseClassifierFindingsParams) {
  const fetchFieldsCurationStatusCancelable = useCancelablePromise<{ data: GetFieldsCurationStatusResponse }>();
  const [curationStatus, setCurationStatus] = useState<CurationStatus>();

  const fetchFieldsCurationStatus = useCallback(
    async (attributeName: string, attributeType: CuratedAttributeType) => {
      try {
        const filterQuery = getStatusBaseQuery(attributeType, encodeURIComponent(attributeName));

        const {
          data: { curationStatus },
        } = await fetchFieldsCurationStatusCancelable(getFieldsCurationStatus(filterQuery));

        setCurationStatus(curationStatus);
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
      }
    },
    [fetchFieldsCurationStatusCancelable],
  );

  const configureBreadcrumbs = useCallback((attributeName: string, displayName: string): void => {
    const t = getFixedT('Curation.breadcrumbs');
    const breadcrumbs: Breadcrumb[] = [
      {
        label: t('classifierTuning'),
        onClick: () => {
          $state.go(CONFIG.states.CURATION);
        },
      },
      {
        label: getPageTitle(displayName, attributeName),
      },
    ];
    pageHeaderService.setTitle({
      breadcrumbs,
      showBackButton: true,
    });
  }, []);

  useEffect(() => {
    fetchFieldsCurationStatus(attributeName, attributeType);
  }, [fetchFieldsCurationStatus, attributeName, attributeType]);

  useEffect(() => {
    configureBreadcrumbs(attributeName, displayName);
  }, [attributeName, displayName, configureBreadcrumbs]);

  return { curationStatus };
}
