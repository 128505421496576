import { httpService } from '../../services/httpService';
import { DataSourcesResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';

export const isSystemWithoutDataSources = async () => {
  try {
    const {
      data: {
        data: { totalCount },
      },
    } = await httpService.fetch<{ data: DataSourcesResponse }>('ds-connections');
    return totalCount < 1;
  } catch (e) {
    console.error('could not fetch data sources, error:', e);
    return true;
  }
};
