import React, { FC, useRef, useState } from 'react';
import { upperFirst } from 'lodash';
import { PopoverOrigin } from '@mui/material';

import {
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidMenu,
  BigidMenuItemProps,
  BigidSeverityBadgeLevel,
  BigidSeverityMarker,
  BigidWidgetList,
  BigidWidgetListItemProps,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidChevronDownIcon, BigidMoreActionIcon } from '@bigid-ui/icons';
import { PrivacyExecutiveDashboardWidgets, Severities } from '../../PrivacyExecutiveDashboardTypes';
import { useIsActiveWidget } from '../hooks/useIsActiveWidget';
import { WidgetListHeaderWrapper, WidgetListWrapper } from '../styles/WidgetStyles';

const DEFAULT_ANCHOR_ORIGIN: Readonly<PopoverOrigin> = { vertical: 'bottom', horizontal: 'right' };
const DEFAULT_TRANSFORM_ORIGIN: Readonly<PopoverOrigin> = { vertical: 'top', horizontal: 'right' };

export const Menu = ({ menuItems }: { menuItems: BigidMenuItemProps[] }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <BigidButtonIcon
        icon={BigidMoreActionIcon}
        size="small"
        ref={buttonRef}
        onClick={() => setIsMenuOpened(!isMenuOpened)}
      />
      <BigidMenu
        items={menuItems}
        open={isMenuOpened}
        anchorEl={buttonRef.current as Element}
        onMenuClose={() => setIsMenuOpened(false)}
        anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
      />
    </>
  );
};

const DropdownSeverityStartIcon = ({ level, small }: { level: BigidSeverityBadgeLevel; small?: boolean }) => (
  <span style={{ display: 'flex', justifyContent: 'center', ...(small ? { width: '16px' } : {}) }}>
    <BigidSeverityMarker level={level} />
  </span>
);

interface DropdownProps {
  currentItem: string;
  onItemSelect: (item: BigidMenuItemProps) => void;
}

const menuItems = Object.values(Severities).map(s => ({
  id: s,
  label: upperFirst(s),
  startIcon: s !== Severities.ALL ? () => <DropdownSeverityStartIcon level={s} /> : null,
}));

export const Dropdown: FC<DropdownProps> = ({ onItemSelect, currentItem }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const buttonRef = useRef();

  return (
    <>
      <div style={{ maxWidth: '160px' }} ref={buttonRef as React.LegacyRef<HTMLDivElement>}>
        <TertiaryButton
          endIcon={<BigidChevronDownIcon />}
          startIcon={
            currentItem !== Severities.ALL ? (
              <DropdownSeverityStartIcon level={currentItem as BigidSeverityBadgeLevel} small />
            ) : null
          }
          onClick={() => setIsMenuOpened(isOpen => !isOpen)}
          size="small"
          text={upperFirst(currentItem)}
          dataAid="PrivacyExecutiveDashboard-dropdown"
          disabled={false}
          width="fill"
        />
      </div>
      <BigidMenu
        items={menuItems}
        open={isMenuOpened}
        anchorEl={buttonRef.current}
        onMenuClose={() => setIsMenuOpened(false)}
        width={166}
        anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
        onItemSelect={onItemSelect}
      />
    </>
  );
};

export const RenderActiveWidgetWrapper: FC<{
  widgetId: PrivacyExecutiveDashboardWidgets;
  children: React.ReactNode;
}> = ({ widgetId, children }) => {
  const isActiveWidget = useIsActiveWidget(widgetId);
  return isActiveWidget ? <>{children}</> : null;
};

interface WidgetListProps {
  items: BigidWidgetListItemProps[];
  headerTitleOne: string;
  headerTitleTwo: string;
  margin?: string;
}

export const WidgetListWithTitle: FC<WidgetListProps> = ({ items, headerTitleOne, headerTitleTwo, margin = '0' }) => {
  return (
    <>
      <WidgetListHeaderWrapper>
        <BigidBody1 size="small" color={BigidColorsV2.gray[500]}>
          <strong>{headerTitleOne}</strong>
        </BigidBody1>
        <BigidBody1 size="small" color={BigidColorsV2.gray[500]}>
          <strong>{headerTitleTwo}</strong>
        </BigidBody1>
      </WidgetListHeaderWrapper>
      <WidgetListWrapper margin={margin}>
        <BigidWidgetList items={items} isOrdered={false} />
      </WidgetListWrapper>
    </>
  );
};
