import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { FollowedItems } from './FollowedItems';
import { RecentlyDiscovered } from './RecentlyDiscovered';
import { RecentlyViewed } from './RecentlyViewed';

const Container = styled('div')`
  display: flex;
  padding: 32px 80px 40px 80px;
  width: 100%;
  background-color: ${({ theme }) => theme.vars.tokens.bigid.foregroundInverse};
  justify-content: space-around;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  height: 358px;
`;

type BottomContentProps = {
  dataAid?: string;
};

export const BottomContent: FC<BottomContentProps> = ({ dataAid = 'BottomContent' }) => {
  return (
    <Container data-aid={generateDataAid(dataAid, ['container'])}>
      <RecentlyDiscovered />
      <FollowedItems />
      <RecentlyViewed />
    </Container>
  );
};
