import { Dispatch, useEffect } from 'react';
import { ContextActions, GridConfig } from '../dataCatalogSearchResultsContext';

type UseSelectAllProcessorProps = Pick<GridConfig, 'selectedRowsIds' | 'unselectedRowsIds' | 'isSelectAll'> & {
  dispatchCatalogSearchAction: Dispatch<ContextActions>;
  totalCount: number;
};

export const useSelectAllProcessor = ({
  dispatchCatalogSearchAction,
  selectedRowsIds,
  totalCount,
  isSelectAll,
  unselectedRowsIds,
}: UseSelectAllProcessorProps) => {
  useEffect(() => {
    const idsToProcess = isSelectAll ? unselectedRowsIds : selectedRowsIds;
    const isAllSelected = totalCount > 0 && idsToProcess.length > 0 && idsToProcess.length === totalCount;

    if (isAllSelected || (isSelectAll && totalCount === 0)) {
      dispatchCatalogSearchAction({ type: 'TOGGLE_SELECT_ALL' });
    }
  }, [totalCount, selectedRowsIds, unselectedRowsIds, isSelectAll, dispatchCatalogSearchAction]);
};
