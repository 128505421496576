import React, { FC, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { Box, Stack } from '@mui/material';
import {
  AdvancedToolbarOverrideValueStrategy,
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidDropdownOption,
  BigidFieldFilter,
  BigidLoader,
  BigidPaper,
  BigidSearch,
  SelectedOptionsConfig,
  advancedToolbarFilterMinifier,
} from '@bigid-ui/components';

import { $state } from '../../../services/angularServices';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { PageGridPreferences } from '../../../services/userPreferencesService';

import { useLocalTranslation } from '../translations';
import { useFilter } from '../hooks/useFilter';
import { searchConfig, filtersConfig, gridColumnsConfig } from './constants';
import { Grid } from './Grid';

export const Layout: FC = () => {
  const { t } = useLocalTranslation('ActivityLogs');
  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: gridColumnsConfig,
  });
  const { isReady: isFilterReady, filter, udpateFilter, search, updateSearch } = useFilter();
  const selectedOptionsConfig: SelectedOptionsConfig = useMemo(
    () => ({ values: filter, strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE }),
    [filter],
  );

  const handleUpdatePreferences = useCallback(
    (gridState: PageGridPreferences) => updatePreferences({ gridState }),
    [updatePreferences],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(debounce(updateSearch, 500), [updateSearch]);

  const handleUpdateFilters = useCallback(
    (filters: BigidAdvancedToolbarFilterUnion[]) => {
      const compressedFilters = filters.map(advancedToolbarFilterMinifier.getFilterCompressedToOverrideValue);
      udpateFilter(compressedFilters);
    },
    [udpateFilter],
  );

  const gridFilters = useMemo(() => {
    const initFilter: BigidFieldFilter[] = search ? [{ ...searchConfig, value: search }] : [];
    return filter.reduce<BigidFieldFilter[]>((acc, filter) => {
      const isDate = filter.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE;
      if (isDate) {
        const dateFilter = filter as BigidAdvancedToolbarDateRangeFilter;
        const {
          pickersState: { dates },
        } = dateFilter.options;
        return acc.concat(
          {
            field: filter.id.toString(),
            operator: 'greaterThan',
            value: dates.from?.toISOString(),
          },
          {
            field: filter.id.toString(),
            operator: 'lessThan',
            value: dates.until?.toISOString(),
          },
        );
      } else {
        const options = filter.options as BigidDropdownOption[];
        return acc.concat({
          operator: 'in',
          field: filter.id.toString(),
          value: options.map(item => item.value),
        });
      }
    }, initFilter);
  }, [filter, search]);

  return (
    <Stack height="100%">
      <Box flexShrink={0}>
        <BigidPaper>
          <Stack direction="row" justifyContent="space-between" p={2}>
            <BigidAdvancedToolbarFilter
              filters={filtersConfig}
              onFiltersChange={handleUpdateFilters}
              selectedOptionsConfig={selectedOptionsConfig}
              shouldAsyncFiltersReload={false}
            />
            <BigidSearch
              placeholder={t('search')}
              onChange={handleSearchChange}
              onSubmit={updateSearch}
              value={search}
            />
          </Stack>
        </BigidPaper>
      </Box>
      {isReady && isFilterReady ? (
        <Grid
          filters={gridFilters}
          columns={gridColumns}
          preferences={preferences}
          onUpdatePreferences={handleUpdatePreferences}
        />
      ) : (
        <BigidLoader />
      )}
    </Stack>
  );
};
