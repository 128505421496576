import { BigidStatusBadgeProps } from '@bigid-ui/components';
import { DataMinimizationPartsInfo, States, User } from '../types';

export enum RequestType {
  DSAR = 'Dsar',
  REMEDIATION = 'Remediation',
  RETENTION = 'Retention',
}

export enum DataMinimizationExecutionState {
  QUEUED = 'Queued',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export interface DataMinimizationExecutionScan {
  scan_id: string;
  parts_info: DataMinimizationPartsInfo;
  data_source_name: string;
  data_source_type: string;
  request_types: RequestType[];
}

export interface ActivityLogItemModel {
  _id: string;
  user?: User;
  start_date: string;
  finish_date?: string;
  scans: DataMinimizationExecutionScan[];
  state: DataMinimizationExecutionState;
  parts_info_summary?: DataMinimizationPartsInfo;
  created_at?: string;
  updated_at?: string;
}

export interface ActivityLogItemModelRow extends ActivityLogItemModel {
  id: string;
  duration?: string;
  states?: States;
  status?: BigidStatusBadgeProps | null;
}

export interface DeletionDataSourceResponse {
  data_minimization_data_sources: ActivityLogItemModel[];
  total_count: number;
}
