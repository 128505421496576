import React, { useContext } from 'react';
import { ComplianceDashboardWidget, FilterToolbarTitles } from '../ComplianceDashboard';
import { fetchPolicyName } from '../../services/dashboardService';
import { ComplianceDashboardContext } from '../ComplianceDashboardContext';
import { UserPreference, userPreferencesService } from '../../../../services/userPreferencesService';
import { CONFIG } from '../../../../../config/common';
import { $state } from '../../../../services/angularServices';
import { notificationService } from '../../../../services/notificationService';
import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarFilterUnion,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { findElementFromArray, DSPMFiltersType } from '../utils';

export const useFiltersActions = (handleLoader?: (value: boolean) => void) => {
  const { activeFilters, toolbarFilters, toolbarActions, filtersOptions, dspmPreferences } =
    useContext(ComplianceDashboardContext);
  const { updateExternalFilters } = toolbarActions;

  const defaultFrameworksFilter = findElementFromArray(toolbarFilters, FilterToolbarTitles.FRAMEWORKS, 'id');
  const defaultPoliciesFilter = findElementFromArray(toolbarFilters, FilterToolbarTitles.RISK_TYPE, 'id');
  const defaultDataSourceFilter = findElementFromArray(toolbarFilters, FilterToolbarTitles.DATA_SOURCE, 'id');
  const defaultControlsFilter = findElementFromArray(toolbarFilters, FilterToolbarTitles.CONTROLS, 'id');

  const policiesActiveFilter = findElementFromArray(activeFilters, FilterToolbarTitles.RISK_TYPE, 'id');
  const dataSourceActiveFilter = findElementFromArray(activeFilters, FilterToolbarTitles.DATA_SOURCE, 'id');
  const frameworksActiveFilter = findElementFromArray(activeFilters, FilterToolbarTitles.FRAMEWORKS, 'id');
  const controlsActiveFilter = findElementFromArray(activeFilters, FilterToolbarTitles.CONTROLS, 'id');

  const defaultDSPMFilter = {
    field: 'caseStatus',
    value: 'open',
    operator: 'equal',
  };

  const updatedDSFilterOptions = dataSourceActiveFilter?.options as BigidDropdownOption[];
  const updatedPolicyFilterOptions = policiesActiveFilter?.options as BigidDropdownOption[];
  const updatedFrameworksFilterOptions = frameworksActiveFilter?.options as BigidDropdownOption[];
  const updatedControlsFilterOptions = controlsActiveFilter?.options as BigidDropdownOption[];

  const handleRedirect = () => {
    $state.go(CONFIG.states.ACTIONABLE_INSIGHTS);
  };

  const handleFilterAction = async (
    widgetType: ComplianceDashboardWidget,
    frameworkName?: string,
    control?: string,
    policies?: string[],
    dataSourceType?: string,
  ) => {
    switch (true) {
      case widgetType === ComplianceDashboardWidget.FAILED_CONTROLS && Boolean(frameworkName):
        const selectOption = findElementFromArray(
          filtersOptions?.[FilterToolbarTitles.FRAMEWORKS],
          frameworkName,
          'displayValue',
        );
        const filtersWithoutUpdatedOne = activeFilters?.filter(
          (filter: BigidAdvancedToolbarFilterUnion) => filter.id !== FilterToolbarTitles.FRAMEWORKS,
        );
        const toUpdateByFramework = [
          ...filtersWithoutUpdatedOne,
          {
            ...defaultFrameworksFilter,
            options: [{ ...selectOption, isSelected: true }],
          },
        ];
        if (selectOption) {
          updateExternalFilters(toUpdateByFramework as AdvancedToolbarOverrideValue[]);
        } else {
          notificationService.info('This fw was disabled. Dashboard data will be updated shortly', {
            duration: 3000,
          });
        }
        break;
      case widgetType === ComplianceDashboardWidget.TOP_RISK && policies.length > 0:
        const toUpdateByTopRisk = [
          ...activeFilters?.filter(
            (filter: BigidAdvancedToolbarFilterUnion) => filter.id !== FilterToolbarTitles.RISK_TYPE,
          ),
          {
            ...defaultPoliciesFilter,
            options: [{ displayValue: policies[0], id: policies[0], value: policies[0] }],
          },
        ];
        updateExternalFilters(toUpdateByTopRisk as AdvancedToolbarOverrideValue[]);
        break;
      case widgetType === ComplianceDashboardWidget.TOP_FAILED && Boolean(control):
        const toUpdateByTopFailed = [
          ...activeFilters?.filter(
            (filter: BigidAdvancedToolbarFilterUnion) => filter.id !== FilterToolbarTitles.CONTROLS,
          ),
          {
            ...defaultControlsFilter,
            options: [
              {
                displayValue: control,
                id: control,
                value: control,
              },
            ],
          },
        ];
        updateExternalFilters(toUpdateByTopFailed as AdvancedToolbarOverrideValue[]);
        break;
      case widgetType === ComplianceDashboardWidget.DATA_SOURCE && Boolean(dataSourceType):
        const toUpdateByDataSource = [
          ...activeFilters?.filter(
            (filter: BigidAdvancedToolbarFilterUnion) => filter.id !== FilterToolbarTitles.DATA_SOURCE,
          ),
          {
            ...defaultDataSourceFilter,
            options: [{ displayValue: dataSourceType, id: dataSourceType, value: dataSourceType }],
          },
        ];
        updateExternalFilters(toUpdateByDataSource as AdvancedToolbarOverrideValue[]);
        break;
      default:
        break;
    }
  };

  const handleRedirectAction = async (
    widgetType: ComplianceDashboardWidget,
    frameworkName?: string[],
    control?: string[],
    policies?: string[],
    dataSourceType?: string,
  ) => {
    switch (true) {
      case widgetType === ComplianceDashboardWidget.FAILED_CONTROLS:
        try {
          handleLoader(true);
          const fetchedPoliciesByFrameworkName = await fetchPolicyName(control, frameworkName);
          const toUpdateByFramework = {
            ...dspmPreferences,
            filter: {
              ...dspmPreferences.filter,
              filter: [
                defaultDSPMFilter,
                { field: DSPMFiltersType.POLICY, operator: 'in', value: fetchedPoliciesByFrameworkName },
                ...(Boolean(updatedDSFilterOptions)
                  ? [
                      {
                        field: DSPMFiltersType.DATA_SOURCE,
                        operator: 'in',
                        value: updatedDSFilterOptions?.map((option: BigidDropdownOption) => option.displayValue),
                      },
                    ]
                  : []),
              ],
            },
          };
          userPreferencesService.update(toUpdateByFramework as UserPreference<any>);
          handleRedirect();
        } catch {
          handleLoader(false);
          console.error('Something wrong with Policies request');
        }
        break;
      case widgetType === ComplianceDashboardWidget.TOP_FAILED && Boolean(control):
        try {
          handleLoader(true);
          const fetchedPoliciesByControlName = await fetchPolicyName(control, frameworkName);
          const toUpdateByFailedControl = {
            ...dspmPreferences,
            filter: {
              ...dspmPreferences.filter,
              filter: [
                defaultDSPMFilter,
                { field: DSPMFiltersType.POLICY, operator: 'in', value: fetchedPoliciesByControlName },
                ...(Boolean(updatedDSFilterOptions)
                  ? [
                      {
                        field: DSPMFiltersType.DATA_SOURCE,
                        operator: 'in',
                        value: updatedDSFilterOptions?.map((option: BigidDropdownOption) => option.displayValue),
                      },
                    ]
                  : []),
              ],
            },
          };
          userPreferencesService.update(toUpdateByFailedControl as UserPreference<any>);
          handleRedirect();
        } catch {
          handleLoader(false);
          console.error('Something wrong with Policies request');
        }
        break;
      case widgetType === ComplianceDashboardWidget.TOP_RISK && policies.length > 0:
        const toUpdateByTopRisk = {
          ...dspmPreferences,
          filter: {
            ...dspmPreferences.filter,
            filter: [
              defaultDSPMFilter,
              { field: DSPMFiltersType.POLICY, operator: 'in', value: policies[0] },
              ...(Boolean(updatedDSFilterOptions)
                ? [
                    {
                      field: DSPMFiltersType.DATA_SOURCE,
                      operator: 'in',
                      value: updatedDSFilterOptions?.map((option: BigidDropdownOption) => option.displayValue),
                    },
                  ]
                : []),
            ],
          },
        };
        userPreferencesService.update(toUpdateByTopRisk as UserPreference<any>);
        handleRedirect();
        break;
      case widgetType === ComplianceDashboardWidget.DATA_SOURCE && Boolean(dataSourceType):
        let policiesList: string[] = [];

        if (updatedPolicyFilterOptions) {
          policiesList = updatedPolicyFilterOptions?.map((option: BigidDropdownOption) => option.displayValue);
        } else {
          const framewWorksList = updatedFrameworksFilterOptions?.map(
            (framework: BigidDropdownOption) => framework.displayValue,
          );
          const controlsList = updatedControlsFilterOptions?.map(
            (control: BigidDropdownOption) => control.displayValue,
          );
          policiesList = await fetchPolicyName(controlsList, framewWorksList);
        }

        const toUpdateByDataSource = {
          ...dspmPreferences,
          filter: {
            ...dspmPreferences.filter,
            filter: [
              defaultDSPMFilter,
              { field: DSPMFiltersType.DATA_SOURCE, operator: 'in', value: [dataSourceType] },
              ...(Boolean(policiesList)
                ? [
                    {
                      field: DSPMFiltersType.POLICY,
                      operator: 'in',
                      value: policiesList,
                    },
                  ]
                : []),
            ],
          },
        };
        userPreferencesService.update(toUpdateByDataSource as UserPreference<any>);
        handleRedirect();
        break;
      default:
        break;
    }
  };

  return { handleFilterAction, handleRedirectAction, activeFilters };
};
