import { v4 as uuid } from 'uuid';
import { BigidFilter } from '@bigid-ui/components';
import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';
import {
  AttributesEnrichmentCreateFlowItem,
  AttributesEnrichmentResponse,
  CorrelationSetModelPartial,
  MappingFlowItem,
  SystemAttributeResponse,
} from './Types';

export function getFlow(flowName: string) {
  return httpService
    .fetch<AttributesEnrichmentResponse>(`sar/attributes-enrichment/${encodeURIComponent(flowName)}`)
    .then(({ data: { data } }) => data.attributesEnrichmentFlow);
}

export function createFlow(flowName: string, flows: AttributesEnrichmentCreateFlowItem[]) {
  return httpService.post(`sar/attributes-enrichment/${encodeURIComponent(flowName)}`, {
    flow: flows,
  });
}

export async function updateFlow(flowName: string, flows: AttributesEnrichmentCreateFlowItem[]) {
  // put is not implemented so we use workarounds in UI
  // return httpService.put(`sar/attributes-enrichment/${encodeURIComponent(flowName)}`, {
  //   flow: flows,
  // });
  await deleteFlow(flowName);
  const res = await createFlow(flowName, flows);
  await updateDefaultFlow();

  return res;
}

export function deleteFlow(flowName: string) {
  if (!flowName) return;
  return httpService.delete(`sar/attributes-enrichment/${encodeURIComponent(flowName)}`);
}

export function getAllFlows() {
  return httpService
    .fetch<{ data: { names: string[] } }>(`sar/attributes-enrichment`)
    .then<MappingFlowItem[]>(({ data: { data } }) =>
      data.names
        ? data.names.map(name => {
            return {
              id: uuid(),
              name,
            };
          })
        : [],
    );
}

export function updateDefaultFlow() {
  return httpService.post(`sar/attributes-enrichment/create-default-flow`);
}

export function getAttributesForCorrelationSet(names: string | string[]) {
  return httpService
    .fetch<SystemAttributeResponse>(`data-catalog/active-system-attributes`, {
      correlationSetList: Array.isArray(names) ? names : [names],
    })
    .then(({ data }) => data.data.activeAttributeList);
}

export async function getCorrelationSets(name: string) {
  const filter: BigidFilter = name ? [{ field: 'name', value: name, operator: 'contains' }] : undefined;
  const gridConfigQuery = queryService.getGridConfigQuery({ skip: 0, limit: 100, filter });

  // TODO: we should mark the disabled sources somehow
  return httpService
    .fetch<{ data: { correlation_sets: CorrelationSetModelPartial[] } }>(
      `correlation-set-connections?${gridConfigQuery}`,
      {
        fields: JSON.stringify(['id', 'name', 'display_name', 'enabled', 'attributes']),
      },
    )
    .then(({ data }) => data.data.correlation_sets);
}
