import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Details } from './Details/Details';
import { Statistics } from './Statistics/Statistics';
import { CorrelationSetModel } from '../../../CorrelationSetConnections/CorrelationSetConnectionTypes';
import { isEmpty } from 'lodash';

const ContentContainer = styled('div')`
  padding: 24px;
`;

interface ContentProps {
  idConnection: CorrelationSetModel;
}
export const Content: FC<ContentProps> = ({ idConnection }) => {
  const isCustomQuery = Boolean(idConnection.isCustomQueryString);
  const detailsProps = {
    isCustomQuery,
    value: isCustomQuery ? idConnection.custom_query_string : idConnection.db_table,
  };

  return (
    <ContentContainer>
      <Details {...detailsProps} />
      <Statistics />
    </ContentContainer>
  );
};
