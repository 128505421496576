import { formatDuration, intervalToDuration } from 'date-fns';
import { FetchDataFunction } from '@bigid-ui/grid';

import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';

import { getFixedT } from '../translations';
import { ActivityLogItemModel, ActivityLogItemModelRow } from './types';
import { executionStateToStatusProps, partsInfoToStates } from '../helpers';

const t = getFixedT('ActivityLogs');

export function calculateDuration(from: Date, to: Date) {
  let interval = intervalToDuration({
    start: from,
    end: to,
  });

  if (interval.years) {
    interval = { years: interval.years + parseFloat((interval.months / 12).toFixed(1)) } as Duration;
  }
  if (interval.months) {
    interval = { months: interval.months + parseFloat((interval.days / 31).toFixed(1)) } as Duration;
  }
  if (interval.days) {
    interval = { days: interval.days + parseFloat((interval.hours / 24).toFixed(1)) } as Duration;
  }
  if (interval.hours) {
    interval = { hours: interval.hours + parseFloat((interval.minutes / 60).toFixed(1)) } as Duration;
  }
  if (interval.minutes) {
    interval = { minutes: interval.minutes + parseFloat((interval.seconds / 60).toFixed(1)) } as Duration;
  }
  const duration = formatDuration(interval, {
    format: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
    locale: {
      formatDistance: (name = '', value = '') => {
        switch (name) {
          case 'xYears':
            return value + 'Y';
          case 'xMonths':
            return value + 'M';
          case 'xWeeks':
            return value + 'W';
          case 'xDays':
            return value + 'd';
          case 'xHours':
            return value + 'h';
          case 'xMinutes':
            return value + 'm';
          case 'xSeconds':
            return value + 's';
          default:
            return value + name.slice(1);
        }
      },
    },
  });

  return duration;
}

export function activityLogItemtoRow(item: ActivityLogItemModel): ActivityLogItemModelRow {
  const id = item._id;
  const endDate = +new Date(item.finish_date) ? new Date(item.finish_date) : new Date();
  const duration = calculateDuration(new Date(item.start_date), endDate);
  const states = partsInfoToStates(item.parts_info_summary);
  const status = executionStateToStatusProps(item.state);

  return {
    ...item,
    id,
    duration,
    states,
    status,
  };
}

export const fetchGridData: FetchDataFunction<ActivityLogItemModelRow> = async queryComponents => {
  try {
    const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);

    const {
      data: {
        data: { data_minimization_executions },
      },
    } = await httpService.fetch<{ data: { data_minimization_executions: ActivityLogItemModel[] } }>(
      `data-minimization/executions?${gridConfigQuery}`,
    );
    return {
      data: data_minimization_executions?.map(activityLogItemtoRow) || [],
      totalCount: data_minimization_executions?.length || 0,
    };
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotFetchAlert'));
    return { data: [], totalCount: 0 };
  }
};
