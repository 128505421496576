import React, { FC, useEffect, useRef, useState } from 'react';
import { $stateParams } from '../../../services/angularServices';
import {
  BigidFormStateAndHandlers,
  BigidWizardContainer,
  BigidWizardHorizontalNavStepData,
  BigidWizardHorizontalStepStatus,
  TertiaryButton,
  useHorizontalWizard,
} from '@bigid-ui/components';
import { BigidSaveIcon } from '@bigid-ui/icons';
import { useLocalTranslation, getFixedT } from '../translations';
import { DetailsStep } from './Steps/DetailsStep/DetailsStep';
import { PreviewStep } from './Steps/PreviewStep/PreviewStep';
import { TrainingStep } from './Steps/TrainingStep/TrainingStep';
import { ResultsStep } from './Steps/ResultsStep/ResultsStep';
import {
  CorrelationSetWizardContext,
  DEFAULT_CORRELATION_SET_FORM_DATA,
  DEFAULT_CORRELATION_SET_GUIDE_STATE,
} from '../contexts/correlationSetContext';
import {
  GuideState,
  CorrelationSetData,
  CorrelationSetFormData,
  CorrelationSetsWizardSteps,
  WizardFormFields,
} from '../types/CorrelationSetsTypes';
import { DetailsGuideContent } from './Steps/DetailsStep/DetailsGuideContent';
import { withGuideRightComponent } from '../components/CorrelationSetStepsWrapper';
import { transformCorrelationSetFormStateToPayload, transformCorrelationSetResponseToFormState } from '../utils/utils';
import {
  testConnectionRequest,
  createNewCorrelationSet,
  updateNewCorrelationSet,
  fetchCorrelationSetByName,
} from '../services/correlationSetsService';
import { openTestConnectionConfirmationDialog } from '../components/Dialogs/TestConnectionConfirmationDialog';
import styled from '@emotion/styled';

const Container = styled('div')`
  height: 100%;

  & [data-aid='BigidWizardHorizontalNav'] {
    padding-top: 24px;
  }

  & [data-aid='BigidWizardContainerHeader'] {
    display: none;
  }

  & [data-aid='correlationSetsWizard'] [data-aid='BigidPaper'] {
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    position: relative;
    padding-bottom: 72px; // reserved space for the buttons container
  }

  & [data-aid='BigidWizardContainerButtons'] {
    position: absolute;
    bottom: 0;
    padding: 16px;
  }
`;

const fixedT = getFixedT('wizard');

export const correlationSetWizardSteps: BigidWizardHorizontalNavStepData[] = [
  {
    id: CorrelationSetsWizardSteps.DETAILS,
    title: fixedT('titleStep1'),
    component: withGuideRightComponent(DetailsStep),
  },
  {
    id: CorrelationSetsWizardSteps.PREVIEW,
    title: fixedT('titleStep2'),
    component: withGuideRightComponent(PreviewStep),
  },
  {
    id: CorrelationSetsWizardSteps.TRAINING,
    title: fixedT('titleStep3'),
    component: withGuideRightComponent(TrainingStep),
  },
  {
    id: CorrelationSetsWizardSteps.RESULTS,
    title: fixedT('titleStep4'),
    component: withGuideRightComponent(ResultsStep),
  },
];

export const guideStepsContent = {
  [CorrelationSetsWizardSteps.DETAILS]: {
    title: 'Correlation set details',
    contentComponent: DetailsGuideContent,
  },
  [CorrelationSetsWizardSteps.PREVIEW]: {
    title: 'Correlation set details',
    contentComponent: DetailsGuideContent,
  },
  [CorrelationSetsWizardSteps.TRAINING]: {
    title: 'Correlation set details',
    contentComponent: DetailsGuideContent,
  },
  [CorrelationSetsWizardSteps.RESULTS]: {
    title: 'Correlation set details',
    contentComponent: DetailsGuideContent,
  },
};

const checkIfNextStepDisabled = (activeStepId: string) => {
  switch (activeStepId) {
    case CorrelationSetsWizardSteps.DETAILS:
      return false;
    case CorrelationSetsWizardSteps.PREVIEW:
      return false;
    case CorrelationSetsWizardSteps.TRAINING:
      return false;
    case CorrelationSetsWizardSteps.RESULTS:
      return false;
    default:
      return false;
  }
};

export const CorrelationSetWizard: FC = ({}) => {
  const { t } = useLocalTranslation('wizard');
  const { id } = $stateParams;
  const isEdit = !!id;

  const detailsStepFormControls = useRef<BigidFormStateAndHandlers>();

  const [correlationSetFormData, setCorrelationSetFormData] = useState<CorrelationSetFormData>(
    DEFAULT_CORRELATION_SET_FORM_DATA,
  );
  const [guideState, setCorrelationGuideState] = useState<GuideState>(DEFAULT_CORRELATION_SET_GUIDE_STATE);
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const { steps, updateStepAttributes, ActiveView, activeStepId, goToNext, goToPrev, noPrev, noNext, getPrevStep } =
    useHorizontalWizard(correlationSetWizardSteps, '');

  const getStepStatus = (stepId: CorrelationSetsWizardSteps, next?: boolean, prev?: boolean) => {
    if (next) {
      return BigidWizardHorizontalStepStatus.DONE;
    }
    if (prev) {
      return BigidWizardHorizontalStepStatus.DEFAULT;
    }
    return BigidWizardHorizontalStepStatus.ACTIVE;
  };

  const fetchCorrelationSetData = async (name: string) => {
    const response = await fetchCorrelationSetByName(name);

    if (response && response.id_connection) {
      if (response.id_connection.type === 'ds-connection') {
        setCorrelationSetFormData(transformCorrelationSetResponseToFormState(response?.id_connection));
      }
    }
  };

  useEffect(() => {
    id && fetchCorrelationSetData(id);
  }, []);

  const handleTestConnectionError = (err: any) => {
    let errorMsg = 'Test Connection Failed!';
    if (err?.code) {
      errorMsg = err.code;
    } else if (err?.message) {
      errorMsg = err.message;
    } else if (typeof err === 'string') {
      errorMsg = err;
    }

    setCorrelationGuideState({ ...guideState, errorMessage: errorMsg });
  };

  const testESConnectionSync = async (formData: CorrelationSetData) => {
    try {
      setIsLoadingConnection(true);
      const response = await testConnectionRequest(formData);

      if (!response.isSuccessful) {
        if (response.err?.code) handleTestConnectionError(response.err);
        return;
      }

      setCorrelationSetFormData(prevState => ({ ...prevState, attributes: response.columns }));
      detailsStepFormControls.current.setValue(WizardFormFields.attributes)(response.columns);
      setCorrelationGuideState({ ...guideState, errorMessage: '' });

      return response;
    } catch (e) {
      handleTestConnectionError(e);
    } finally {
      setIsLoadingConnection(false);
    }
  };

  const handleTestConnection = async (formData: CorrelationSetFormData) => {
    const correlationSetPayload = transformCorrelationSetFormStateToPayload(formData);

    return testESConnectionSync(correlationSetPayload);
  };

  const validateStep = async (activeStepId: CorrelationSetsWizardSteps): Promise<boolean> => {
    switch (activeStepId) {
      case CorrelationSetsWizardSteps.DETAILS:
        return new Promise(resolve => {
          detailsStepFormControls.current?.validateAndSubmit(
            async formData => {
              setCorrelationSetFormData(formData as CorrelationSetFormData);

              let response = null;
              const hasAttributes = formData?.attributes?.length > 0;

              // is already connected to DS
              if (hasAttributes) {
                //show confirmation dialog
                const { isGoNext, isReConnect } = await openTestConnectionConfirmationDialog();

                if (isGoNext) return resolve(true);
                if (isReConnect) {
                  response = await handleTestConnection(formData as CorrelationSetFormData);
                }
              } else {
                response = await handleTestConnection(formData as CorrelationSetFormData);
              }

              if (response.columns.length > 0) {
                resolve(true);
              }

              return false;
            },
            () => resolve(false),
          );
        });

      case CorrelationSetsWizardSteps.PREVIEW:
        return true;

      case CorrelationSetsWizardSteps.TRAINING:
        return true;
      default:
        return true;
    }
  };

  const handleGoToNext = async () => {
    const isCurrentStepDataValid = await validateStep(activeStepId as CorrelationSetsWizardSteps);
    if (isCurrentStepDataValid) {
      if (noNext) {
        // TODO: implement behavior for last step save button;
        console.log('SAVE clicked');
      } else {
        updateStepAttributes([
          { stepId: activeStepId, attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps, true) } },
        ]);
        goToNext();
      }
    }
  };

  const handleGoToPrev = async () => {
    updateStepAttributes([
      {
        stepId: activeStepId,
        attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps, false, true) },
      },
      { stepId: getPrevStep(), attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps) } },
    ]);

    await detailsStepFormControls.current.validateAndSubmit(async formData => {
      setCorrelationSetFormData(formData as CorrelationSetFormData);
      goToPrev();
    });
  };

  const handleSaveDraft = async () => {
    await detailsStepFormControls.current.validateAndSubmit(async formData => {
      try {
        const correlationSetPayload = transformCorrelationSetFormStateToPayload(formData as CorrelationSetFormData);

        if (isEdit) {
          await updateNewCorrelationSet(id, {
            id_connection: { ...correlationSetPayload.id_connection, attributes: correlationSetFormData.attributes },
          });
        } else {
          // TODO: BE validation message: 'unique_id' is required. Check with Gilad Keren
          await createNewCorrelationSet(correlationSetPayload);
        }
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
      } finally {
      }
    });
  };

  useEffect(() => {
    setIsNextDisabled(checkIfNextStepDisabled(activeStepId));
  }, [activeStepId]);

  return (
    <Container>
      <CorrelationSetWizardContext.Provider
        value={{
          correlationSetFormData,
          setCorrelationSetFormData,
          guideState,
          setCorrelationGuideState,
          isLoadingConnection,
        }}
      >
        <BigidWizardContainer
          steps={steps}
          activeStepId={activeStepId}
          ActiveView={ActiveView}
          isLoading={false}
          onNext={handleGoToNext}
          onPrev={handleGoToPrev}
          noNext={noNext}
          noPrev={noPrev}
          stepsProps={{ activeStepId, detailsStepFormControls, id }}
          isNextDisabled={isNextDisabled}
          lastStepText={'Save'}
          title=""
          disableStepClick
          dataAid="correlationSetsWizard"
          customButton={
            <TertiaryButton
              size="large"
              startIcon={<BigidSaveIcon />}
              onClick={handleSaveDraft}
              dataAid="save-draft-button"
              disabled={false}
              text={t('saveDratBtn')}
            />
          }
        />
      </CorrelationSetWizardContext.Provider>
    </Container>
  );
};
