import type { BigidDialogButtonType, BigidFormField, BigidMenuItemProps } from '@bigid-ui/components';

export enum DsConnectionFieldSectionsEnum {
  connection = 'connection',
  connectionAdvanced = 'connectionAdvanced',
  scanSettings = 'scanSettings',
  details = 'details',
}

export enum DsTypesEnum {
  string = 'string',
  array = 'array',
  boolean = 'boolean',
  stringBoolean = 'stringBoolean',
  multiStringBoolean = 'multiStringBoolean',
  number = 'number',
  password = 'password',
  object = 'object',
  verticalObject = 'verticalObject',
  email = 'email',
  stringSelect = 'stringSelect',
  stringLong = 'stringLong',
  customFieldsArray = 'customFieldsArray',
  options = 'options',
  objectSelect = 'objectSelect',
  action = 'action',
  json = 'json',
  date = 'date',
  tags = 'tags',
}

export interface DataSourceTemplateFieldValidationInterface {
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  regex?: string;
  errorText: string;
}

export interface DataSourceTemplateCondition {
  field: string;
  value: DataSourceTemplateValueBase | DataSourceTemplateConditionWithOperator;
}

export type DataSourceTemplateValueBase = string | number | boolean | string[] | Record<string, any>;

export interface DataSourceTemplateConditionWithOperator {
  operator: DataSourceTemplateConditionOperatorsEnum;
  values: DataSourceTemplateValueBase[];
}

export interface DataSourceTemplateConditionObject {
  operator: DataSourceTemplateConditionOperatorsEnum;
  fields: DataSourceTemplateCondition[];
}

export enum DataSourceTemplateConditionOperatorsEnum {
  or = 'or',
  and = 'and',
  not = 'not',
}

export type DataSourceTemplateFieldConditionalType = DataSourceTemplateCondition[];

export interface DataSourceConnectionTemplateFieldBase {
  name: string;
  options: DataSourceOption[];
  isRequired: boolean;
  tooltipText?: string;
}

export interface DataSourceOption {
  label: string;
  value: string | number | Record<string, any>;
  visibleIf?: DataSourceTemplateFieldConditionalType;
}

export interface CallToFill {
  title: string;
  description: string;
  iconName: string;
  order: number;
}

export enum DsFieldActionTypeEnum {
  link = 'link',
  button = 'button',
}

export interface AsyncSourceFieldsMapping {
  id: string;
  label: string;
  value: string;
}

export interface AsyncSource {
  url: string;
  fieldsMapping?: AsyncSourceFieldsMapping;
  filter?: DataSourceTemplateCondition[];
  enabled?: boolean;
}

export interface DataSourceTemplateConditionInterface {
  operator: DataSourceTemplateConditionOperatorsEnum;
  fields: DataSourceTemplateCondition[];
}

export interface CustomNotification {
  title: string;
  url: string;
  message: string;
  fieldValue: DataSourceTemplateValueBase;
}

export interface DataSourceConnectionTemplateFieldMisc {
  mandatoryForTestIf?: DataSourceTemplateFieldConditionalType;
  order?: number;
  apiName?: string;
  apiNames?: string[];
  displayName?: string;
  type: DsTypesEnum;
  section?: DsConnectionFieldSectionsEnum;
  placeholder?: string;
  mandatory?: boolean;
  validation?: DataSourceTemplateFieldValidationInterface[];
  hidden?: boolean;
  visibleIf?: DataSourceTemplateFieldConditionalType;
  visibleIfObject?: DataSourceTemplateConditionInterface;
  enabled?: boolean;
  enabledIf?: DataSourceTemplateFieldConditionalType;
  source?: string;
  startIconOrImage?: string;
  endIconOrImage?: string;
  defaultValue?: string | number | boolean | Record<string, any> | any[];
  example?: string;
  mandatoryForTest?: boolean;
  helperText?: string;
  isSeparatorAfter?: boolean;
  tooltipText?: string;
  inline?: boolean;
  nullifyIfNotChanged?: boolean;
  blockSaveIf?: DataSourceTemplateFieldConditionalType;
  arrayFields?: DataSourceConnectionTemplateFieldMisc;
  objectFields?: DataSourceConnectionTemplateField[];
  filterArrayByFields?: DataSourceTemplateCondition[];
  callToFill?: CallToFill;
  actionLabel?: string;
  actionType?: DsFieldActionTypeEnum;
  ignoreVisibleInCheck?: boolean;
  asyncSource?: AsyncSource;
  isSensitive?: boolean;
  isCreatable?: boolean;
  isAuthenticationField?: boolean;
  notificationContent?: CustomNotification;
  nested?: boolean;
  nestedParentName?: string;
  isNotReadyForTestIfValueDefault?: boolean;
  isClearable?: boolean;
  isChildField?: boolean;
  componentWidth?: string;
}

export interface DataSourceConnectionTemplateField
  extends DataSourceConnectionTemplateFieldBase,
    DataSourceConnectionTemplateFieldMisc {}

export interface DataSourceConnectionFormField extends BigidFormField {
  misc: DataSourceConnectionTemplateFieldMisc;
}

export enum DsScanTypeEnum {
  meta = 'metadataScan',
  hyper = 'hyper',
}

export interface DataSourceConnectionTemplate {
  name: string;
  documentationUrl: string;
  image?: string;
  description?: string;
  fields: DataSourceConnectionTemplateField[];
  scanType: DsScanTypeEnum;
  status: string;
}

export type BigidTabActionButtonType = BigidDialogButtonType & { id?: string; actions?: BigidMenuItemProps[] };

export enum OwnerFieldNameEnum {
  itOwners = 'it',
  businessOwners = 'business',
}

export enum DialogSlots {
  HEADER = 'header',
  CONTENT = 'content',
  FOOTER = 'footer',
  ASIDE = 'aside',
}

export enum DialogSections {
  GENERAL = 'general',
  ADVANCED = 'advanced',
}

export enum DialogSectionTypes {
  PLAINTEXT = 'plaintext',
  SELECT = 'select',
}

export enum DataSourceTypes {
  AZURE_BLOB = 'azure-blob',
  AZURE_FILE = 'azure-file',
  ASTRA_DB = 'astraDB',
  CONFLUENCE = 'confluence',
  CUSTOM_CONNECTOR = 'custom-connector',
  DYNAMO_DB = 'dynamodb',
  EXCHANGE = 'exchange',
  FILENET = 'filenet',
  GENERIC_API = 'generic-rest-api-unstructured',
  GMAIL = 'gmail',
  GOOGLE_CLOUD_STORAGE = 'google-cloud-storage',
  GOOGLE_DRIVE_LEGACY = 'googledrive',
  GOOGLE_BIG_TABLE_LEGACY = 'google-bigtable',
  HADOOP_V2 = 'hadoop_v2',
  HBASE = 'hbase',
  HIVE = 'hive-jdbc',
  OUTLOOK = 'o365-outlook',
  MARKLOGIC = 'marklogic',
  NFS = 'nfs',
  O365_LEGACY = 'o365-onedrive',
  ONEDRIVE_LEGACY = 'onedrive-personal',
  SAP_LEGACY = 'sap-successfactors',
  S3_LEGACY = 's3',
  S3_STRUCTURED = 's3-structured',
  SHAREFILE_LEGACY = 'sharefile',
  SHAREPOINT = 'sharepoint',
  SHAREPOINT_ONLINE_LEGACY = 'sharepoint-online',
  SPANNER_LEGACY = 'spanner',
  SMB_LEGACY = 'smb',
  WORKDAY = 'workday',
  ZENDESK = 'zendesk',
}

export enum DataSourceTabAction {
  TEST_CONNECTION = 'test',
  SCAN = 'scan',
  SAVE = 'save',
}
