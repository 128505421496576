import { BigidColorsV2 } from '@bigid-ui/colors';

export const PRIVACY_EXECUTIVE_DASHBOARD = 'PrivacyExecutiveDashboard';

export enum FilterToolbarFields {
  TIME_RANGE = 'timeRange',
  VENDORS = 'vendors',
  LEGAL_ENTITIES = 'legalEntities',
  ASSETS = 'assets',
}

export enum PrivacyExecutiveDashboardWidgets {
  RISK_DATA_RISK = 'dataRisk',
  RISK_DATA_RISK_TREND = 'dataRiskTrend',
  RISK_DATA_RISK_TYPES = 'dataRiskTypes',
  RISK_DATA_RISK_LANDSCAPE = 'dataRiskLandscape',
  PERFORMANCE_MEASURES = 'performanceMeasures',
}

export const RiskColors = {
  CRITICAL: BigidColorsV2.red[600],
  HIGH: BigidColorsV2.orange[600],
  MEDIUM: BigidColorsV2.yellow[600],
  // If 5x5 matrix LOW becomes 600 and LOWEST becomes 900
  LOW: BigidColorsV2.green[900],
} as const;

export interface Series {
  series: {
    name: string;
    interpolationDuration: number;
    interpolationEasing: string;
    animate: ([], interpolationDuration: number, interpolationEasing: string) => void;
    hide: () => void;
    show: () => void;
  }[];
}

export enum Severities {
  ALL = 'all severities',
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}
