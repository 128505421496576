import React from 'react';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { PrimaryButton, TertiaryButton, BigidBody1 } from '@bigid-ui/components';
import { getFixedT, useLocalTranslation } from '../../translations';

const TRANSLATION_PATH = 'wizard.detailsStep.testConnectionConfirmationDialog';
const t = getFixedT(TRANSLATION_PATH);

export const DialogContent = () => {
  const { t } = useLocalTranslation(TRANSLATION_PATH);

  return <BigidBody1>{t('description')}</BigidBody1>;
};

export const openTestConnectionConfirmationDialog = async () => {
  return new Promise<{ isGoNext: boolean; isReConnect: boolean }>(resolve => {
    openSystemDialog({
      title: t('title'),
      content: DialogContent,
      onClose: () => null,
      maxWidth: 'xs',
      buttons: [
        {
          text: t('continueBtn'),
          component: TertiaryButton,
          onClick: () => {
            resolve({
              isGoNext: true,
              isReConnect: false,
            });
          },
          isClose: true,
        },
        {
          text: t('applyBtn'),
          component: PrimaryButton,
          onClick: () => {
            resolve({
              isGoNext: false,
              isReConnect: true,
            });
          },
          isClose: true,
        },
      ],
    });
  });
};
