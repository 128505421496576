import { BigidBody1, BigidHeading3, BigidPaper, PrimaryButton } from '@bigid-ui/components';
import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidDataSourceSyncIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { openSelectDataSourceOnboarding } from '../../DataSources/SelectDataSourceOnboardingType/selectDataSourceOnboardingTypeService';

const Container = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 48px;
`;

const BodyWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`;

export const EmptySystemWidget: FC = () => {
  const { t } = useLocalTranslation('emptySystemWidget');
  return (
    <BigidPaper>
      <Container>
        <BigidDataSourceSyncIllustration />
        <BigidHeading3>{t('header')}</BigidHeading3>
        <BodyWrapper>
          <BigidBody1>{t('body1')}</BigidBody1>
          <BigidBody1>{t('body2')}</BigidBody1>
        </BodyWrapper>
        <PrimaryButton onClick={openSelectDataSourceOnboarding} size="medium">
          {t('connectDataSource')}
        </PrimaryButton>
      </Container>
    </BigidPaper>
  );
};
