import { BigidLoader, BigidSidePanel } from '@bigid-ui/components';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CorrelationSetModel } from '../../CorrelationSetConnections/CorrelationSetConnectionTypes';
import { BasicInfo } from './BasicInfo';
import { useDropDownActions } from '../../hooks/useDropDownActions';
import { DropdownActions } from '../DropDownActions/DropDownActions';
import * as correlationSetsService from '../../services/correlationSetsService';
import { Content } from './Content/Content';

interface CorrelationSetsSidePanelProps {
  name?: string;
  setName?: Dispatch<SetStateAction<string>>;
  isOpen?: boolean;
}

export const CorrelationSetsSidePanel: FC<CorrelationSetsSidePanelProps> = ({ name, setName, isOpen }) => {
  const [idConnection, setIdConnection] = useState<CorrelationSetModel>();
  const [isLoading, setIsLoading] = useState(false);
  const { items: dropDownItems, isLoading: isActionInProgress } = useDropDownActions({
    idConnection,
    setName,
    setIdConnection,
  });

  const isShowDropdownActions = Boolean(dropDownItems.length);
  const isSidePanelLoading = isLoading || isActionInProgress;

  useEffect(() => {
    if (name) {
      fetchCorrelationSetByName();
    }
  }, [name]);

  const fetchCorrelationSetByName = async () => {
    setIsLoading(true);
    const data = await correlationSetsService.fetchCorrelationSetByName(name);
    setIdConnection(data?.id_connection as any);
    setIsLoading(false);
  };

  return (
    <BigidSidePanel
      content={
        <>
          {isSidePanelLoading && <BigidLoader />}
          {idConnection && <Content idConnection={idConnection} />}
        </>
      }
      onClose={() => setName('')}
      open={isOpen}
      title={idConnection?.name}
      basicInfo={
        <BasicInfo idConnection={idConnection}>
          <div>{isShowDropdownActions && <DropdownActions items={dropDownItems} />}</div>
        </BasicInfo>
      }
      maxWidth="large"
    />
  );
};
