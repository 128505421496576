import { styled } from '@mui/material';
import { BigidColorsV2 } from '@bigid-ui/colors';

export const DashboardWrapper = styled('div')`
  max-width: 1254px;
  margin: 0 auto;
`;

export const WidgetContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WidgetRow = styled('div')`
  display: grid;
  gap: 0 16px;

  /* If there are 3 widgets, enforce specific width to the middle one */
  &:has(> :nth-child(3)) {
    grid-template-columns: 1fr 534px 1fr; /* Enforce the specific widths */
  }

  /* For 2 widgets, they take 50% each */
  &:has(> :nth-child(2)):not(:has(> :nth-child(3))) {
    grid-template-columns: 1fr 1fr;
  }

  /* For 1 widget, it takes 100% width */
  &:has(> :nth-child(1)):not(> :has(> :nth-child(2))) {
    grid-template-columns: 1fr;
  }

  /* Hide widget row wrapper when no widgets are rendered in order to avoid double gap */
  &:empty {
    display: none;
  }
`;

export const WidgetWrapper = styled('div')`
  & [data-aid='BigidWidgetContainer'] {
    height: 100%;
    display: flex;
    flex-direction: column;

    [data-aid='BigidWidgetContainer-content'] {
      height: 100%;

      &:has(> [data-aid='BigidChartLayout']) {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const RiskWidgetWrapper = styled(WidgetWrapper)`
  min-height: 268px;

  /* Make legend expand to occupy all remaining width */
  div[data-aid='BigidCha rtLayout-legend-wrapper'] {
    flex: 1;

    div[data-aid='BigidChartLayout-legend'] {
      width: 100%;
    }
  }
`;
/**
 * WidgetListWithTitle helper component
 */

export const WidgetListHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const WidgetListWrapper = styled('div')<{ margin: string }>`
  ${({ margin }) => `margin: ${margin};`}
`;

/**
 * Data Risk Landscape widget
 */

export const SectionsWrapper = styled('div')`
  display: flex;
  gap: 21px;
`;

export const SectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  gap: 12px;
  padding: 8px 16px 24px 16px;
`;

export const SectionTitle = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 8px;
`;

export const SectionContent = styled('div')`
  display: flex;
  align-items: center;

  & > [data-aid='BigidStackedBar'] {
    width: 100%;
  }
`;

export const SectionContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

export const HiddenContentWrapper = styled('div')<{ isExpanded: boolean }>`
  display: grid;
  ${({ isExpanded }) => `
    grid-template-rows: ${isExpanded ? 1 : 0}fr;
  `}
  transition: grid-template-rows 500ms ease-in-out, margin-top 300ms ease-in-out;

  & > div {
    overflow: hidden;
  }
`;

export const HiddenContentInnerWrapper = styled('div')`
  margin: 0 8px;

  & > div:first-of-type {
    margin-top: 30px;
  }
`;

/**
 * Performance measures widget
 */
export const PerformanceMeasuresWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
`;

export const PerformanceMeasuresSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 12px 0;
`;

export const PerformanceMeasuresValue = styled('span')`
  font-weight: 400;
  color: ${BigidColorsV2.gray[500]};
`;

export const PerformanceMeasuresValueWrapper = styled('span')`
  display: flex;
  align-items: center;
  gap: 6px;
`;
