import React, { FC, ReactElement, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/styles';
import { userPreferencesService } from '../../services/userPreferencesService';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidApplyFilledIcon, BigidClearFilledIcon } from '@bigid-ui/icons';
import {
  BigidCheckbox,
  BigidConditionalWrapper,
  BigidTooltip,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { isBoolean } from 'lodash';
import { updateEaProgramUserSelection, EA_PROGRAM_USER_PREFERENCES } from './eaProgramModalService';
import { useLocalTranslation } from './translations';
import { closeSystemDialog } from '../../services/systemDialogService';
import { EaProgramEvents, trackEaProgramEvent } from './eaProgramEventsUtil';

const EA_PROGRAM_FOOTER_COMPONENT_NAME = 'EaProgramModalFooter';

const ModalFooter = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 16,
  paddingBottom: 16,
  justifyContent: 'space-between',
});

const FooterSection = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
});

const Indicator = styled('div')({
  fontSize: '0.75rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
});

export interface EaProgramModalFooterProps {
  isAdmin: boolean;
  isDismissed: boolean;
  isOptedInToProgram: boolean;
}

export const EaProgramModalFooter: FC<EaProgramModalFooterProps> = ({ isAdmin, isDismissed, isOptedInToProgram }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const theme = useTheme();
  const { t } = useLocalTranslation();

  const onCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsCheckboxChecked(checked);
    await userPreferencesService.update({ preference: EA_PROGRAM_USER_PREFERENCES, data: { isDismissed: checked } });
    if (checked) {
      trackEaProgramEvent(EaProgramEvents.EA_PROGRAM_DONT_SHOW_AGAIN_CHECKED);
    }
  };

  const getStatusIndicationComponent = () => {
    if (!isAdmin && !isDismissed && isOptedInToProgram !== true) {
      return <BigidCheckbox onChange={onCheckboxChange} checked={isCheckboxChecked} label={t('dontShowAgain')} />;
    }
    if (isBoolean(isOptedInToProgram)) {
      return (
        <Indicator
          data-aid={generateDataAid('EaProgramStatusIndicator', [isOptedInToProgram ? 'opted-in' : 'opted-out'])}
        >
          {isOptedInToProgram ? (
            <BigidApplyFilledIcon size="medium" color="positive" />
          ) : (
            <BigidClearFilledIcon size="medium" color={theme.palette.bigid.gray400} />
          )}
          {t(`optInStatus.${isOptedInToProgram}`)}
        </Indicator>
      );
    }
    return null;
  };

  const onSelectionClick = (isAccepted: boolean, eaEventType: EaProgramEvents) => {
    if (isAdmin) {
      updateEaProgramUserSelection(isAccepted, isOptedInToProgram);
    }
    trackEaProgramEvent(eaEventType);
    closeSystemDialog();
  };

  const getSecondaryButton = () => {
    if (isOptedInToProgram) {
      return (
        <BigidConditionalWrapper
          condition={!isAdmin}
          wrapper={(children: ReactElement) => (
            <BigidTooltip
              dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['opt-out', 'tooltip'])}
              placement="top"
              title={t('contactAdminToOptOut')}
            >
              <div>{children}</div>
            </BigidTooltip>
          )}
        >
          <TertiaryButton
            dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['opt-out', 'button'])}
            disabled={!isAdmin}
            bi={{ disabled: true }}
            size={'medium'}
            onClick={() => onSelectionClick(false, EaProgramEvents.EA_PROGRAM_OPT_OUT_CLICK)}
            text={t('buttonLabels.optOut')}
          />
        </BigidConditionalWrapper>
      );
    } else {
      return (
        <TertiaryButton
          dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['not-now', 'button'])}
          size={'medium'}
          bi={{ disabled: true }}
          onClick={() =>
            onSelectionClick(
              false,
              isAdmin ? EaProgramEvents.EA_PROGRAM_DECLINE_CLICK : EaProgramEvents.EA_PROGRAM_DECLINE_NON_ADMIN,
            )
          }
          text={t('buttonLabels.decline')}
        />
      );
    }
  };

  const getMainButton = () => {
    if (isOptedInToProgram) {
      return (
        <PrimaryButton
          dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['close', 'button'])}
          bi={{ eventType: EaProgramEvents.EA_PROGRAM_CLOSE_BUTTON_CLICK }}
          size={'medium'}
          onClick={() => closeSystemDialog()}
          text={t('buttonLabels.close')}
        />
      );
    } else {
      return (
        <BigidConditionalWrapper
          condition={!isAdmin}
          wrapper={(children: ReactElement) => (
            <BigidTooltip
              dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['join', 'tooltip'])}
              placement="top"
              title={t('contactAdminToJoin')}
            >
              <div>{children}</div>
            </BigidTooltip>
          )}
        >
          <PrimaryButton
            dataAid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['join', 'button'])}
            bi={{ disabled: true }}
            disabled={!isAdmin}
            size={'medium'}
            onClick={() => onSelectionClick(true, EaProgramEvents.EA_PROGRAM_CUSTOMER_INTEREST_TO_JOIN)}
            text={t('buttonLabels.join')}
          />
        </BigidConditionalWrapper>
      );
    }
  };

  return (
    <ModalFooter>
      <FooterSection data-aid={generateDataAid(EA_PROGRAM_FOOTER_COMPONENT_NAME, ['footer'])}>
        {getStatusIndicationComponent()}
      </FooterSection>
      <FooterSection>
        {getSecondaryButton()}
        {getMainButton()}
      </FooterSection>
    </ModalFooter>
  );
};
