import { BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { ListEntityItem } from '../../types';

const Container = styled('li')<Pick<ListItemProps, 'showUnderline'>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const LeftSide = styled('div')<{ fullWidth?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(100% - 80px)')};
`;

const Name = styled(BigidBody1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundSecondary};
  font-weight: 600;
`;

const Date = styled(BigidBody1)`
  overflow: hidden;
  color: ${({ theme }) => theme.vars.tokens.bigid.foregroundDisabled};
  text-overflow: ellipsis;
`;

export type ListItemProps = ListEntityItem & {
  showUnderline?: boolean;
  dataAid?: string;
  maxWidth?: string;
};

export const ListItem: FC<ListItemProps> = ({
  name,
  tooltipText,
  showUnderline,
  additionalInfo,
  icon,
  onClick,
  dataAid,
}) => {
  return (
    <Container
      key={name}
      showUnderline={showUnderline}
      onClick={onClick}
      data-aid={generateDataAid(dataAid, ['container'])}
    >
      <LeftSide fullWidth={!additionalInfo}>
        <IconContainer>{icon}</IconContainer>
        <BigidTooltip title={tooltipText ?? name}>
          <Name size={'small'}>{name}</Name>
        </BigidTooltip>
      </LeftSide>
      {<Date size={'small'}>{additionalInfo ? additionalInfo : '-'}</Date>}
    </Container>
  );
};
