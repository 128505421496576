import { CorrelationSetAttribute, SystemAttribute } from '../../Types';

export function convertCorrelationSetColumnToSystemAttribute(a: CorrelationSetAttribute): SystemAttribute {
  return {
    attributeId: '',
    attributeOriginalName: a.columnName,
    attributeName: a.columnName,
    attributeType: '',
  };
}
