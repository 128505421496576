import { useQueries } from 'react-query';
import { getEmail } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogOwnersDialog';
import {
  getSystemUsers,
  GetSystemUsersResponse,
} from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { Category, getAttributesByObjectName } from '../Attributes/DataExplorerAttributesService';
import { getDataSourceDetailsByName } from '../../../../../explorerSearchResultsService';
import { uniqBy } from 'lodash';

export const useGetOverviewEntities = (fqn: string, dsName: string) => {
  const [usersListResponse, objectCategoriesResponse, dataSourceDetailsResponse] = useQueries([
    {
      queryKey: 'usersList',
      queryFn: async () => {
        try {
          const systemUsers = await getSystemUsers();
          const filterSystemUsersWithMail = systemUsers.filter(({ id }) => getEmail(id));
          const systemUsersList = filterSystemUsersWithMail.map((user: GetSystemUsersResponse) => {
            return { label: user.firstName, subLabel: user.id, value: user._id };
          });
          return systemUsersList;
        } catch ({ message }) {
          console.error(`An error has occurred: ${message}`);
          return [];
        }
      },
      keepPreviousData: true,
    },
    {
      queryKey: ['getObjectCategories', fqn],
      queryFn: async () => {
        try {
          const attributes = await getAttributesByObjectName(fqn);
          const categories: Category[] = [];
          attributes.data.forEach(attribute => {
            if (attribute.categories) {
              categories.push(...attribute.categories);
            }
          });

          return uniqBy(categories, 'unique_name');
        } catch ({ message }) {
          console.error(`An error has occurred: ${message}`);
          return [];
        }
      },
      placeholderData: [],
      enabled: !!fqn,
      keepPreviousData: true,
    },
    {
      queryKey: ['getDataSourceDetails', dsName],
      queryFn: async () => getDataSourceDetailsByName(dsName),
      enabled: !!dsName,
      keepPreviousData: true,
    },
  ]);

  return {
    usersListResponse,
    objectCategoriesResponse,
    dataSourceDetailsResponse,
  };
};
