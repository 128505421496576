import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';

export const Root = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: hidden;
  padding: 0px 0px 10px 8px;
  position: relative;
`;

export const Content = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const EmptyStateWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
`;

export const DescriptionWrapper = styled('div')<{
  rowDirection?: 'row' | 'column';
}>(
  ({ rowDirection = 'row' }) => `
	display: flex;
  flex-direction: ${rowDirection};
  column-gap: 4px;
  row-gap: 4px;
  justify-content: center;
`,
);

export const WidgetsSection = styled('div')<{
  amountOfWidgets?: number;
  isHidden?: boolean;
}>(
  ({ amountOfWidgets = 1, isHidden = false }) => `
	display: ${isHidden ? 'none' : 'grid'};
  width: 100%;
  grid-template-columns: repeat(${amountOfWidgets}, minmax(300px, 1fr));
  column-gap: 16px;
  row-gap: 16px;
  box-sizing: border-box;
`,
);

export const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 16px;
`;

export const WidgetContent = styled('div')`
  width: 100%;
  height: 100%;
  padding: 0 6px;
  & ul {
    width: 100%;
  }

  & > ul > li > div:first-of-type {
    display: grid;
    grid-template-columns: 16px 20px calc(100% - 94px) 24px;
  }
`;

export const SeverityWrapper = styled('div')`
  display: flex;
  width: 20px;
  justify-content: center;
`;

export const WidgetContainer = styled('div')`
  display: flex;
  height: 120px;
  max-width: 220px;
  width: 100%;
  padding: 16px 16px 10px 16px;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  border-radius: 6px;
`;

export const OuterItemContainer = styled('div')<{ itemsToShow: number }>(
  ({ itemsToShow }) => `
  display: flex;
  justify-content: center;
  min-width: ${100 / itemsToShow}%;
`,
);

export const WidgetTitleContainer = styled('div')`
  display: grid;
  grid-template-columns: calc(100% - 24px) 24px;
  width: 100%;
  column-gap: 8px;
  height: 44px;
  align-items: center;
  justify-content: space-between;
`;

export const WidgetFooterContainer = styled('div')`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 8px;
`;

export const ProgressBarWrapper = styled('div')`
  height: max-content;
`;

export const WidgetContainerWrapper = styled('div')<{
  dataAid: string;
}>(
  ({ dataAid = 1 }) => `
	[data-aid*=${dataAid}-content] {
    padding-top: 0;
  }
`,
);

export const ElipsedText = styled(BigidBody1)`
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TooltipContainer = styled('div')({
  maxHeight: '600px',
  overflowY: 'scroll',
});

export const TooltipContainerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '335px',
  padding: '12px',
  rowGap: '4px',
});

export const IndexStatusContainer = styled('div')`
  display: flex;
  align-items: center;
`;
