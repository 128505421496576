import { isEqual, omit } from 'lodash';
import {
  CorrelationSet,
  DataSourceType,
  SelectOptionType,
  CorrelationSetFormData,
  CorrelationSetData,
} from '../types/CorrelationSetsTypes';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CorrelationSetModel } from '../CorrelationSetConnections/CorrelationSetConnectionTypes';

export const CORRELATION_SET_CONNECTIONS_GRID_ID = 'correlationSetConnectionsGrid';
export const STATISTICS_GRID_ID = 'statisticsGrid';

export const isNewCorrelationSetPageEnabled = () => getApplicationPreference('NEW_CORRELATION_SET_PAGE_ENABLED');
export const isSSETestConnectionEnabled = () => getApplicationPreference('SSE_TEST_CONNECTION');

export function getIsValueChanged(prevValue: any, currValue: any): boolean {
  return !isEqual(prevValue, currValue);
}

export function getDataSourceOptions(dsList: DataSourceType[]): SelectOptionType[] {
  return dsList.map(({ _id, name }) => ({
    label: name,
    value: name,
  }));
}

export const transformCorrelationSetResponseToFormState = (
  correlationSet: CorrelationSet = {} as CorrelationSet,
): CorrelationSetFormData => {
  return {
    ...correlationSet,
    dsConnection: [
      {
        value: correlationSet.dsConnection,
        label: correlationSet.dsConnection,
      },
    ],
  };
};

export const transformCorrelationSetFormStateToPayload = (formState: CorrelationSetFormData): CorrelationSetData => {
  return {
    id_connection: omit(
      {
        ...formState,
        dsConnection: formState.dsConnection[0].value,
        skip_sample_size: Number(formState.skip_sample_size),
        type: 'ds-connection',
      },
      ['_id', 'id', 'columnsTypes', 'uuid'],
    ),
  } as CorrelationSetData;
};

export const getIsCustomQueryStringValue = (value: string | boolean) => {
  if (typeof value === 'boolean') {
    return value;
  }

  return value === 'true';
};

export const isIdConnectionEnabled = (idConnection: CorrelationSetModel) => idConnection.enabled === 'yes';
