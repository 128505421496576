import {
  BigidIconProps,
  BigidIconSourceType,
  BigidLoader,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import {
  BigidLineageDiagram,
  BigidLineageDiagramProps,
  BigidLineageDiagramSearchResultObject,
} from '@bigid-ui/visualisation';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ColumnIcon from '../../../../assets/icons/BigidColumn.svg';
import CollectionIcon from '../../../../assets/icons/BigidRDB.svg';
import { $state } from '../../../../services/angularServices';
import { showConfirmationDialog } from '../../../../services/confirmationDialogService';
import { notificationService } from '../../../../services/notificationService';
import {
  getAttributesForCorrelationSet,
  getCorrelationSets,
  getFlow,
  updateFlow,
} from '../attributesEnrichmentService';
import {
  AttributesEnrichmentDiagramLink,
  AttributesEnrichmentDiagramNode,
  AttributesEnrichmentFlowItem,
  CorrelationSetModelPartial,
  MappingFlowItem,
} from '../Types';
import { diagramToFlow } from './helpers/diagramToFlow';
import { flowsToDiagram, getNewFlowToDiagram } from './helpers/flowToDiagram';
import { isReadOnly } from './helpers/permissions';
import {
  AttributesSearchResult,
  CorrelationSetsSearchResult,
  correlationSetsToSearchResults,
  systemAttributesToSearchResults,
} from './helpers/searchResults';
import { convertCorrelationSetColumnToSystemAttribute } from './helpers/columnAttributeMapper';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50vh',
    flexGrow: 1,
    padding: 20,
  },
  diagramWrapper: {
    flexGrow: 1,
  },
  footerRoot: {
    '& > *': {
      margin: '10px 0 0 10px',
    },
  },
});

const COLLECTION_ICON_CONFIG: BigidIconProps = {
  icon: CollectionIcon,
  type: BigidIconSourceType.CUSTOM,
};
const COLUMN_ICON_CONFIG: BigidIconProps = {
  icon: ColumnIcon,
  type: BigidIconSourceType.CUSTOM,
};

export const MappingDiagram: FC<MappingFlowItem> = ({ id: mappingDiagramItemId, name: mappingName, isDraft }) => {
  if (mappingName && $state.params.flowName !== mappingName) {
    $state.go('.', { flowName: mappingName }, { reload: false, notify: false, inherit: true });
  }

  const useClsColumnsForAttributeMapping = getApplicationPreference<boolean>('USE_CLS_COLUMNS_FOR_ATTRIBUTE_MAPPING');
  const classes = useStyles({});

  const isReadOnlyMode = useMemo(() => isReadOnly(), []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [mapKey, setMapKey] = useState<string>(null);
  const [nodes, setNodes] = useState<AttributesEnrichmentDiagramNode[]>([]);
  const [links, setLinks] = useState<AttributesEnrichmentDiagramLink[]>([]);
  const [collectionsSearchResults, setCollectionsSearchResults] = useState<CorrelationSetsSearchResult[]>();
  const [correlationSetById, setCorrelationSetById] = useState<Map<string, CorrelationSetModelPartial>>();
  const [columnsSearchResults, setColumnsSearchResults] = useState<AttributesSearchResult[]>();
  const [columnsSearchQuery, setColumnsSearchQuery] = useState<string>();
  const [canvasContainerNode, setCanvasContainerNode] = useState<HTMLDivElement>();
  const diagramStateRef: BigidLineageDiagramProps['diagramStateRef'] = useRef();

  const initialiseDiagram = useCallback(
    async (canceled: RefObject<boolean>) => {
      setNodes(undefined);
      setLinks(undefined);
      setColumnsSearchResults(undefined);
      setCollectionsSearchResults(undefined);
      setIsDirty(false);
      try {
        const flowItems = await getFlow(mappingName);
        if (canceled.current) {
          return;
        }

        const filterFlowsWithoutSource = (flows: AttributesEnrichmentFlowItem[]) => {
          const flowsSources = [...new Set(flows.map(({ source }) => source))];
          return flows.filter(({ fieldsMapping }) => {
            return fieldsMapping.some(({ origin }) => [...flowsSources, 'input'].includes(origin));
          });
        };
        const filteredFlows = filterFlowsWithoutSource(flowItems);

        let idToCorrelationSet: Map<string, CorrelationSetModelPartial>;
        if (useClsColumnsForAttributeMapping) {
          const correlationSets = await getCorrelationSets('');
          idToCorrelationSet = correlationSets.reduce((acc, cls) => {
            acc.set(cls.id, cls);
            return acc;
          }, new Map<string, CorrelationSetModelPartial>());
          setCorrelationSetById(idToCorrelationSet);
        }

        const { nodes, links } = await (filteredFlows.length
          ? flowsToDiagram(filteredFlows, idToCorrelationSet)
          : getNewFlowToDiagram(mappingName));
        if (canceled.current) {
          return;
        }
        setNodes(nodes);
        setLinks(links);
        setMapKey(mappingDiagramItemId + Math.random());
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(message);
      } finally {
        setIsLoading(false);
      }
    },
    [mappingDiagramItemId, mappingName],
  );

  useEffect(() => {
    setIsLoading(true);
    const canceled = { current: false };

    // the workaround below including the canceled flag is due
    // to non blocking master details navigation in UI while loading selected item data
    const timeoutId = setTimeout(() => initialiseDiagram(canceled), 400);
    return () => {
      canceled.current = true;
      clearTimeout(timeoutId);
    };
  }, [initialiseDiagram]);

  const handleSave = async () => {
    const flowName = mappingName;
    const flows = diagramToFlow({ ...diagramStateRef.current }, flowName);
    if (!flows.length) {
      notificationService.warning(`"${flowName}" is empty.`);
      return;
    }
    try {
      await updateFlow(flowName, flows);
      setIsDirty(false);
      notificationService.success(`"${flowName}" model updated successfully.`);
    } catch ({ message }) {
      notificationService.error('An error has occurred, update failed.');
      console.error(message);
    }
  };

  const handleCancel = async () => {
    if (
      await showConfirmationDialog({
        entitiesCount: 1,
        entityNameSingular: mappingName,
        entityNamePlural: mappingName,
        allSelected: false,
        actionName: isDraft ? 'Delete draft' : 'Reset',
        postfix: ' (this draft)',
      })
    ) {
      if (isDraft) {
        entityEventsEmitter.emit(EntityEvents.DELETE, [{ id: mappingDiagramItemId }]);
      } else {
        await initialiseDiagram({ current: false });
      }
    }
  };

  const handleCollectionsSearch = useCallback(
    async (query: string) => {
      try {
        const correlationSets = useClsColumnsForAttributeMapping
          ? Array.from(correlationSetById.values())
          : await getCorrelationSets(query || '');
        const newCollectionsSearchResults = correlationSetsToSearchResults(correlationSets, []);
        setCollectionsSearchResults(newCollectionsSearchResults);
      } catch ({ message }) {
        setCollectionsSearchResults([]);
        notificationService.error('Failed to fetch correlation sets');
        console.error(`An error has occurred: ${message}`);
      }
    },
    [correlationSetById, useClsColumnsForAttributeMapping],
  );

  const handleCollectionSelected = async (selectedObject: BigidLineageDiagramSearchResultObject) => {
    const { correlationSet } = selectedObject as CorrelationSetsSearchResult;
    try {
      const activeAttributeList = useClsColumnsForAttributeMapping
        ? correlationSetById
            .get(correlationSet.name)
            .attributes.map(a => convertCorrelationSetColumnToSystemAttribute(a))
        : await getAttributesForCorrelationSet(correlationSet.name);
      const attributesList = await systemAttributesToSearchResults(correlationSet.name, activeAttributeList);
      setColumnsSearchResults(attributesList);
      setColumnsSearchQuery('');
    } catch ({ message }) {
      setColumnsSearchResults([]);
      notificationService.error('An error has occurred');
      console.error(`An error has occurred: ${message}`);
    }
  };

  const handleColumnsSearch = (query: string): void => {
    setColumnsSearchQuery(query);
  };

  const handleConnectionAddedOrRemoved = () => {
    setIsDirty(true);
  };

  const subjectEntitiesSearchResults = columnsSearchQuery
    ? columnsSearchResults?.filter(({ name }) => name.toLowerCase().includes(columnsSearchQuery.toLowerCase()))
    : columnsSearchResults;

  return isLoading ? (
    <BigidLoader />
  ) : (
    <div className={classes.wrapper} key={mapKey}>
      {links && nodes && (
        <div ref={setCanvasContainerNode} className={classes.diagramWrapper} data-aid="JoinCorrelationsMappingDialog">
          {canvasContainerNode && (
            <BigidLineageDiagram
              diagramStateRef={diagramStateRef}
              canvasContainer={canvasContainerNode}
              nodes={nodes}
              links={links}
              onSubjectsSearch={handleCollectionsSearch}
              subjectsSearchResults={collectionsSearchResults}
              onSubjectSelected={handleCollectionSelected}
              onSubjectEntitiesSearch={handleColumnsSearch}
              subjectEntitiesSearchResults={subjectEntitiesSearchResults}
              onConnectionAdded={handleConnectionAddedOrRemoved}
              onConnectionRemoved={handleConnectionAddedOrRemoved}
              onConnectionChanged={handleConnectionAddedOrRemoved}
              nodeSubjectIconPath={'/images/catalogLinkedTables/BigidRDB.svg'}
              nodeSubjectEntityIconPath={'/images/catalogLinkedTables/BigidColumn.svg'}
              nodeSubjectIconSvg={COLLECTION_ICON_CONFIG}
              nodeSubjectEntityIconSvg={COLUMN_ICON_CONFIG}
              allowEmptySearchQuery
              allowEditInputFields
            />
          )}
        </div>
      )}
      {!isReadOnlyMode && links && nodes && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          classes={{ root: classes.footerRoot }}
        >
          <SecondaryButton disabled={!isDraft && !isDirty} onClick={handleCancel} size="medium" text="Cancel" />
          <PrimaryButton disabled={!isDirty} onClick={handleSave} size="medium" text="Save" />
        </Grid>
      )}
    </div>
  );
};
