import { createContext, useContext } from 'react';
import { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';

export interface PrivacyExecutiveDashboardContextInterface {
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  activeWidgetIds: string[];
}

export const PrivacyExecutiveDashboardContext = createContext<PrivacyExecutiveDashboardContextInterface>(null);

export const usePrivacyExecutiveDashboardContext = () => {
  const context = useContext(PrivacyExecutiveDashboardContext);

  if (context === undefined) {
    throw new Error('usePrivacyExecutiveDashboardContext was used outside of its Provider');
  }

  return context;
};
