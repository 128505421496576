import React from 'react';
import { BigidBody1, BigidHeading6, BigidInlineNotification } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { GuideState } from '../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../translations';
import { CorrelationSetGuideContent } from '../../../components/CorrelationSetGuide';
import styled from '@emotion/styled';

const ErrorContainer = styled('div')`
  margin-top: 12px;

  > div {
    overflow-x: scroll;
  }
`;

interface DetailsGuideContentProps {
  contentTitle: string;
  guideState: GuideState;
}

const MARGIN_BOTTOM = 2;

export const DetailsGuideContent = ({ contentTitle, guideState }: DetailsGuideContentProps) => {
  const { t } = useLocalTranslation('wizard.guideContent');

  return (
    <>
      <CorrelationSetGuideContent>
        <BigidHeading6>{contentTitle}</BigidHeading6>
        <BigidBody1 size="medium" marginBottom={MARGIN_BOTTOM}>
          {t('detailsStep.text1')}
        </BigidBody1>
        <BigidBody1 size="medium" marginBottom={MARGIN_BOTTOM}>
          {t('detailsStep.text2')}
        </BigidBody1>
        <BigidBody1>{t('detailsStep.text3')}</BigidBody1>
      </CorrelationSetGuideContent>

      {guideState.errorMessage && (
        <ErrorContainer>
          <BigidInlineNotification
            open={true}
            showMoreButton={true}
            dataAid={generateDataAid('DetailsGuideContent', ['connection-error'])}
            text={[
              {
                subText: guideState.errorMessage,
              },
            ]}
            title={t('errors.failedPreviewTitle')}
            type="error"
          />
        </ErrorContainer>
      )}
    </>
  );
};
