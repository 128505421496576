import React, { FC, useCallback, useEffect, useState } from 'react';
import { omit } from 'lodash';
import { styled } from '@mui/material';
import { BigidGridColumn, BigidGridWithToolbar, BigidGridWithToolbarProps, FetchDataFunction } from '@bigid-ui/grid';
import {
  BigidFieldFilter,
  BigidPaper as BigidPaperOrg,
  BigidPaperProps,
  EntityEvents,
  entityEventsEmitter,
} from '@bigid-ui/components';

import { PageGridPreferences, UserPreference } from '../../../services/userPreferencesService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

import { useLocalTranslation } from '../translations';
import { fetchGridData } from './helpers';
import { ActivityLogItemModelRow } from './types';
import { actions } from './constants';

const BigidPaper = styled((props: BigidPaperProps & { className?: string }) => (
  <BigidPaperOrg {...props} paperProps={{ className: props.className }} />
))`
  height: 300px !important;
`;

type GridProps = {
  columns: BigidGridColumn<ActivityLogItemModelRow>[];
  filters: BigidFieldFilter[];
  preferences?: UserPreference<unknown>;
  onUpdatePreferences: (pref: PageGridPreferences) => void;
};

export const Grid: FC<GridProps> = ({ filters, preferences, onUpdatePreferences, columns }) => {
  const fetchTimeout =
    Math.max(getApplicationPreference('DATA_MINIMIZATION_AUDIT_TRAIL_GRID_REFRESH_TIMEOUT') || 30, 30) * 1000;
  const { t } = useLocalTranslation('ActivityLogs');

  useEffect(() => {
    const intervalId = setInterval(() => {
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }, fetchTimeout);

    return () => clearInterval(intervalId);
  }, [fetchTimeout]);

  const gridConfig: BigidGridWithToolbarProps<ActivityLogItemModelRow> = {
    entityName: t('logs'),
    showSelectionCheckboxes: false,
    showSortingControls: true,
    columns: columns,
    customRowIdName: 'id',
    defaultSorting: preferences?.grid?.sort || [{ field: 'start_date', order: 'desc' }],
    fetchData: fetchGridData,
    externalFilter: filters,
    toolbarActions: actions,
    onGridStateChange: config => onUpdatePreferences(omit(config, 'filter')),
  };

  return (
    <BigidPaper data-bypass="bypass-grid" aria-label={t('activityLog')}>
      <BigidGridWithToolbar {...gridConfig} />
    </BigidPaper>
  );
};
