import React, { useMemo } from 'react';
import { useCorrelationSetWizardContext } from '../../../contexts/correlationSetContext';
import { DefaultStepProps, TestConnectionColumn } from '../../../types/CorrelationSetsTypes';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import styled from '@emotion/styled';

const GridHolder = styled.div`
  margin: 0 -16px;
`;

//STEP 2
function mapGridData(data: TestConnectionColumn[] = []) {
  // Split column data and organize it into rows
  const rows = [];

  // Determine max rows needed based on the longest split data in each column
  const maxRows = Math.max(...data.map(col => col.columnData?.split(',').length));

  // Initialize each row with empty objects for each column
  for (let i = 0; i < maxRows; i++) {
    const row: Record<string, any> = {};
    data.forEach(col => {
      const dataEntries = col.columnData?.split(',').map(item => item.trim());
      row[col.columnName] = dataEntries[i] || ''; // Add data or empty string if no data
    });
    rows.push(row);
  }

  return rows;
}

function getGridColumns(data: TestConnectionColumn[] = []) {
  const columns: BigidGridColumn<any>[] = data.map(({ columnName }) => {
    return {
      title: columnName,
      name: columnName,
      type: BigidGridColumnTypes.TEXT,
      width: 150,
      getCellValue: row => row[columnName],
    };
  });

  return columns;
}

export const PreviewStep = ({ detailsStepFormControls, id }: DefaultStepProps) => {
  const {
    correlationSetFormData: { attributes },
  } = useCorrelationSetWizardContext();

  const gridData = useMemo(() => mapGridData(attributes), [attributes]);
  const gridColumns = useMemo(() => getGridColumns(attributes), [attributes]);

  const gridConfig = useMemo<BigidGridWithToolbarProps<any>>(
    () => ({
      gridId: 'previewStepGrid',
      entityName: 'previewStepGrid',
      hideColumnChooser: true,
      showSortingControls: false,
      fetchData: async () => {
        return {
          totalCount: gridData.length,
          data: gridData,
        };
      },
      columns: gridColumns,
    }),
    [gridData, gridColumns],
  );

  return (
    <GridHolder>
      <BigidGridWithToolbar {...gridConfig} />
    </GridHolder>
  );
};
