import { MutableRefObject } from 'react';
import { BigidGridRow } from '@bigid-ui/grid';
import { ClassifierGridRow } from '../../ClassifiersManagement/types/ClassifierTypes';
import { Attributes as AttributesClassifier } from '../../ClassifiersManagement/types/Attributes';

export interface GuideState {
  isDefaultContentVisible?: boolean;
  errorMessage?: string;
  successMessage?: string;
}

export interface TestConnectionColumn {
  columnName: string;
  partialCorrelationSet: boolean;
  columnIdentifiability: string;
  selection: boolean;
  columnData: string;
}

export interface SelectOptionType {
  label: string;
  value: string;
}

export interface CorrelationSetFormData {
  name: string;
  dsConnection: SelectOptionType[];
  isCustomQueryString: boolean;
  db_table: string;
  custom_query_string: string;
  identities_force_sample: boolean;
  identities_sample_size: string;
  skip_sample_start: boolean;
  skip_sample_size: string | number;
  use_random_sampling: boolean;

  // Step 2, Preview step
  attributes?: TestConnectionColumn[];
}

export enum WizardFormFields {
  name = 'name',
  dsConnection = 'dsConnection',
  isCustomQueryString = 'isCustomQueryString',
  db_table = 'db_table',
  custom_query_string = 'custom_query_string',

  // advance options
  identities_force_sample = 'identities_force_sample',
  identities_sample_size = 'identities_sample_size',

  skip_sample_start = 'skip_sample_start',
  skip_sample_size = 'skip_sample_size',
  use_random_sampling = 'use_random_sampling',

  //step2 PreviewStep
  attributes = 'attributes',
}

// WIZARD TYPES
export enum CorrelationSetsWizardSteps {
  DETAILS = 'DETAILS',
  PREVIEW = 'PREVIEW',
  TRAINING = 'TRAINING',
  RESULTS = 'RESULTS',
}

export interface DefaultStepProps {
  activeStepId: CorrelationSetsWizardSteps;
  detailsStepFormControls: MutableRefObject<any>;
  id?: string;
}

interface CustomField {
  field_type: string;
  field_name: string;
  field_value: string;
  enable: boolean;
}

interface ColumnsTypes {
  columnName: string;
  columnType: string;
}

export interface CorrelationSetData {
  id_connection: CorrelationSet;
}

export interface CorrelationSet {
  _id: string;
  id: string;
  uuid: string;
  custom_query_string: string;
  identities_force_sample: boolean;
  identities_sample_size: string;
  skip_sample_start: boolean;
  skip_sample_size: number;
  use_random_sampling: boolean;
  unique_id_delimeter: string;
  db_table: string;
  name: string;
  dsConnection: string;
  unique_id: string;
  attributes: TestConnectionColumn[];
  type: string;
  display_name: string;
  enabled: string;
  isCustomQueryString: boolean;
  columnsTypes: ColumnsTypes[];
  composite_attributes: any[]; // Define this type if you have specific details about the array content
  test_is_success: boolean;
  test_timestamp: string;
  unique_id_composite: any[]; // Define this type if you have specific details about the array content
  updated_at: string;
  connectionStatusTest: {
    is_success: boolean;
    last_connection: string;
    is_connection_ever_succeeded: boolean;
    num_of_object: number;
  };
}

export interface DataSourceType {
  _id: string;
  id: string;
  name: string;
  Is_sample_files: string;
  Is_sample_folders: string;
  authStrategy: string;
  autoRetry: number;
  box_service_account_json: string;
  certificate_id: string;
  classification_is_enabled: boolean;
  customFields: any[];
  custom_fields: CustomField[];
  differential: boolean;
  enabled: string;
  enrichment_is_enabled: boolean;
  exclude_user_regex: boolean;
  folder_percent_to_sample: string;
  include_file_types: boolean;
  isSuppressNotification: boolean;
  is_certificate: boolean;
  is_credential: boolean;
  is_idsor_supported: boolean;
  is_native_index: boolean;
  is_ocr_enabled: string;
  is_user_accounts_enabled: boolean;
  last_sub_scan_id: string;
  ner_classification_is_enabled: boolean;
  notAddToScope: boolean;
  numberOfParsingThreads: string;
  owners_v2: any[];
  password: string;
  rdb_comma_sep_table_list: string;
  rdb_is_only_user_objects: boolean;
  rdb_is_sample_data: boolean;
  rdb_is_scan_views: boolean;
  rdb_name: string;
  rdb_sample_data_max_percentage: string;
  rdb_sample_data_max_size: string;
  rdb_sample_type: string;
  rdb_url: string;
  row_identifier_expression_is_disabled: boolean;
  scanner_group: string;
  scanner_strategy: string;
  security_tier: string;
  stop_on_failure: boolean;
  structured_clustering_enabled: boolean;
  tags: any[];
  type: string;
  created_at: string;
  updated_at: string;
  username: string;
}

// WIZARD TYPES END

export interface Category {
  _id?: string;
  color: string;
  display_name: string;
  unique_name: string;
  description: string;
  glossary_id?: string;
}

export interface StatisticsRow extends BigidGridRow {
  columnName: string;
  attributeName: string;
  categories: Category[];
  identifiability: string;
  avgValuesLength: number;
}

export interface FormValuesType extends ClassifierGridRow {
  attribute?: AttributesClassifier;
}
