import { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { DashboardToolbarActions } from '../../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { createContext } from 'react';
import { ToolbarOptionsProps } from './ComplianceDashboard';
import { UserPreference } from '../../../services/userPreferencesService';

export interface ComplianceDashboardContextInterface {
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  toolbarActions: DashboardToolbarActions;
  toolbarFilters: BigidAdvancedToolbarFilterUnion[];
  filtersOptions: ToolbarOptionsProps;
  dspmPreferences: UserPreference<any>;
}

export const ComplianceDashboardContext = createContext<ComplianceDashboardContextInterface>(null);
