import { BigidAdvancedToolbarFilter, BigidLoader } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import React, { FC, useMemo } from 'react';
import { WithDataAid } from '../../../../types';
import { useFilterConfig } from './useCatalogConfig';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { styled } from '@mui/material';

const Root = styled('div')`
  position: relative;
  width: 100%;
`;

const LoaderContainer = styled('div')<{ isShown?: boolean }>`
  display: ${({ isShown }) => (isShown ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

const FilterContainer = styled('div')<{ isTransparent?: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  opacity: ${({ isTransparent }) => (isTransparent ? 0 : 1)};
  z-index: ${({ isTransparent }) => (isTransparent ? 0 : 'auto')};
`;

export const CatalogToolbar: FC<WithDataAid> = ({ dataAid = 'CatalogSearchToolbar' }) => {
  const { query, isGridDataLoading } = useDataCatalogSearchResultsContext();
  const { filterConfig } = useFilterConfig();

  const memoizedConfig = useMemo(() => {
    return filterConfig;
  }, []);

  return (
    <Root>
      <LoaderContainer isShown={isGridDataLoading}>
        <BigidLoader position="relative" size={20} />
      </LoaderContainer>
      <FilterContainer isTransparent={isGridDataLoading}>
        <BigidAdvancedToolbarFilter
          dataAid={generateDataAid(dataAid, ['toolbar'])}
          {...memoizedConfig}
          filterSearchMetadata={{
            searchText: query,
          }}
        />
      </FilterContainer>
    </Root>
  );
};
