import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { BigidTooltip, ToolbarActionType } from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { FrameworkProps } from '../../../../../../react/views/Frameworks/FrameworksServices';
import { MainRowProps, NormalizedMappedControlsProps, updateSelectedControls } from '../../../utils';

const MainGrid = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

interface MappedControlsDialogGridViewProps {
  dynamicMappedControls: NormalizedMappedControlsProps[];
  handleDynamicControls: (dynamicControls: NormalizedMappedControlsProps[]) => void;
  frameworksList: FrameworkProps[];
  selectedCategory: string;
  frameworkName: string;
  preSelectedIds: string[];
  dataAid: string;
}

export const MappedControlsDialogGridView = ({
  frameworksList,
  selectedCategory,
  handleDynamicControls,
  frameworkName,
  preSelectedIds,
  dynamicMappedControls,
}: MappedControlsDialogGridViewProps) => {
  const config: BigidGridWithToolbarProps<MainRowProps> = useMemo(
    () => ({
      onGridStateChange: ({ selectedRows }) => {
        const res = updateSelectedControls(dynamicMappedControls, selectedRows, selectedCategory, frameworkName);
        handleDynamicControls(res);
      },
      fetchData: () => {
        const data = frameworksList.find((control: FrameworkProps) => control.category === selectedCategory);
        const totalCount = data?.list?.length ?? 0;
        return new Promise(resolve =>
          resolve({
            data: data?.list ?? [],
            totalCount,
          }),
        );
      },
      columns: [
        {
          name: 'name',
          title: 'Requirements',
          getCellValue: row => (
            <BigidTooltip title={row.description}>
              <div>{row.name}</div>
            </BigidTooltip>
          ),
          type: BigidGridColumnTypes.CUSTOM,
          sortingEnabled: true,
          width: 520,
        },
      ] as BigidGridColumn<MainRowProps>[],
      toolbarActions: [
        {
          type: ToolbarActionType.TERTIARY,
          label: 'Save',
          show: () => false,
          execute: () => {
            return Promise.resolve({ shouldGridReload: false });
          },
        },
      ],
      defaultSorting: [{ field: 'name', order: 'desc' }],
      showSortingControls: false,
      rowClickShouldKeepSelection: true,
      preSelectedIds,
    }),
    [],
  );

  return (
    <MainGrid>
      <BigidGridWithToolbar {...config} />
    </MainGrid>
  );
};
