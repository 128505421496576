import { httpService } from '../../services/httpService';
import { QueryTree } from '@bigid-ui/components';
import { BigidGridRow } from '@bigid-ui/grid';

interface SensitivityClassificationsResponse {
  data: {
    scConfigs: SensitivityClassificationDataRow[];
    totalCount: number;
  };
}

export enum QueryType {
  String = 'queryString',
  Object = 'queryObject',
}

export enum ActionStatus {
  INITIALIZING = 'initializing',
  DC_TAGGING = 'dc tagging',
  DS_TAGGING = 'ds tagging',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export interface SensitivityClassificationProgress {
  totalStages: number;
  currentStage: number;
}

export interface ClassificationLevel {
  levelId?: string;
  id?: string;
  name: string;
  priority: number;
  queryObject?: QueryTree;
  queryString?: string;
  query?: string;
  queryObj?: QueryTree;
  queryExpanded?: boolean;
  isQueryTouched?: boolean;
  queryType: QueryType;
}

export interface SensitivityClassificationSchedule {
  schedule?: string;
  active?: boolean;
}

export interface ScColumnTagging {
  isActive: boolean;
  shouldPropagateToObject: boolean;
}

export interface SensitivityClassificationData {
  name: string;
  description: string;
  classifications: ClassificationLevel[];
  createdAt?: Date;
  modifiedAt?: Date;
  lastSuccess?: Date;
  actionStatus?: { status: ActionStatus; progress?: SensitivityClassificationProgress };
  schedule?: SensitivityClassificationSchedule;
  isTaggingApplicable?: boolean;
  dsTagging?: boolean;
  columnTagging?: ScColumnTagging;
  defaultSc?: boolean;
  progress?: string;
}

export interface LabelConfiguration {
  batchId: string;
  displayName: string;
  description?: string;
  type: string;
  fieldId: string;
  groupId: string;
  labelId: string;
  name: string;
}

export interface GetLabelsResponse {
  labels: LabelConfiguration[];
  type: string;
}

export interface SensitivityClassificationDataRow extends BigidGridRow, SensitivityClassificationData {}

export const deleteSensitivityClassification = async (id: string) => {
  return httpService.delete(`aci/sc/configs/${id}`);
};

export const getSensitivityClassifications = async (query: string) => {
  const { data } = await httpService.fetch<SensitivityClassificationsResponse>(`aci/sc/configs?${query}`);
  return data?.data;
};

export const getLabels = () => {
  return httpService.fetch<GetLabelsResponse>(`labels`).then(({ data }) => data);
};
