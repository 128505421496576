import React, { FC, useContext } from 'react';
import { BigidBody1, BigidToggleButton, BigidWidgetContainer } from '@bigid-ui/components';
import { DataRiskCharts } from './charts/DataRiskCharts';
import { DiscoveryDashboardContext } from '../DiscoveryDashboardContext';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

export interface DataRiskWidgetProps {
  title: string;
}

export const DataRiskWidget: FC<DataRiskWidgetProps> = ({ title }) => {
  const onToggleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    console.log('Got new value', value);
  };

  const { isEmptySystem } = useContext(DiscoveryDashboardContext);

  return (
    <BigidWidgetContainer
      content={<DataRiskCharts />}
      titleConfig={{ title }}
      actionsSlot={
        <>
          {!isEmptySystem && (
            <BigidToggleButton
              onChange={onToggleChange}
              initialSelected="findings"
              size="small"
              toggleButtons={[
                { value: 'findings', label: 'Findings' },
                { value: 'objects', label: 'Objects' },
              ]}
            />
          )}
          {/* // TODO: POC Code, to be replaced with real widget actions */}
        </>
      }
      emptyStateConfig={{
        isEmpty: isEmptySystem,
        illustration: BigidNoDataIllustration,
        description: <BigidBody1>No data</BigidBody1>,
      }}
    />
  );
};
