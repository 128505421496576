import { BigidAdvancedToolbarFilterUnion, BigidDropdownOption } from '@bigid-ui/components';
import { FilterToolbarTitles } from './ComplianceDashboard';
import { FrameworkProps } from '../../Frameworks/FrameworksServices';

export enum DSPMFiltersType {
  POLICY = 'policyName',
  DATA_SOURCE = 'dataSourceDisplayName',
}

export type FilterKey = 'id' | 'displayValue' | 'field' | 'frameworks';

export const singleFilterParser = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
  filterType: FilterToolbarTitles,
): string => {
  const currentFilterOptions = activeFilters.find((filter: BigidAdvancedToolbarFilterUnion) => filter.id === filterType)
    ?.options as BigidDropdownOption[];
  const stringQueryFilter = currentFilterOptions
    ?.map((option: BigidDropdownOption) => {
      return `\"${option.value}\"`;
    })
    .join(',');
  return stringQueryFilter;
};

export const activeFiltersParser = (activeFilters: BigidAdvancedToolbarFilterUnion[]): { filter: string } => {
  const frameworksFilter = singleFilterParser(activeFilters, FilterToolbarTitles.FRAMEWORKS);
  const dataSourceFilter = singleFilterParser(activeFilters, FilterToolbarTitles.DATA_SOURCE);
  const policyFilter = singleFilterParser(activeFilters, FilterToolbarTitles.RISK_TYPE);
  const controlsFilter = singleFilterParser(activeFilters, FilterToolbarTitles.CONTROLS);

  const filter = [
    ...(Boolean(policyFilter) ? [`$policyName IN (${policyFilter})`] : []),
    ...(Boolean(frameworksFilter) ? [`$frameworkName IN (${frameworksFilter})`] : []),
    ...(Boolean(dataSourceFilter) ? [`$dataSourceType IN (${dataSourceFilter})`] : []),
    ...(Boolean(controlsFilter) ? [`$controlName IN (${controlsFilter})`] : []),
  ].join(' AND ');

  return { filter };
};

export const findElementFromArray = (
  filters: BigidAdvancedToolbarFilterUnion[] | any[],
  neededFilter: FilterToolbarTitles | DSPMFiltersType | string,
  filterKey: FilterKey,
): BigidAdvancedToolbarFilterUnion => {
  return filters?.find(
    (filter: Record<string, BigidAdvancedToolbarFilterUnion | any>) => filter?.[filterKey] === neededFilter,
  );
};

export const createUniqueGridFilterQuery = (key: string, value: string): string => {
  return `${key}=\"${value ?? ''}\"`;
};

export const fieldOptions = (dataSet: string[] | FrameworkProps[]): BigidDropdownOption[] => {
  return dataSet.map((dataElement: string | FrameworkProps) => {
    if (typeof dataElement === 'string') {
      return {
        id: dataElement,
        displayValue: dataElement,
        value: dataElement,
      };
    } else {
      return {
        id: dataElement._id,
        displayValue: dataElement.framework_name,
        value: dataElement.framework_name,
      };
    }
  });
};
