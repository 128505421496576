import React, { FC, useEffect, useRef, useState } from 'react';
import { CONFIG } from '../../../../config/common';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../../services/angularServices';
import { BigidWizardContainer, useHorizontalWizard, DEFAULT_NEXT_STEP_TEXT } from '@bigid-ui/components';
import { CreateScanWizardContext, SCAN_CREATE_INIT_STATE, ScanCreateData } from './createScanContext';
import { setSelectedDataSourcesToStorage, useGetPreselectedDataSources } from './hooks/useGetPreselectedDataSources';
import { useUpdateSupportedScanTypesBySelectedDs } from './hooks/useUpdateSupportedScanTypesBySelectedDs';
import { ScanTemplateWizardContext } from '../ScanTemplates/scanTemplateContext';
import { ScanTemplate, ScanTypes, DEFAULT_SCAN_TEMPLATE } from '../ScanTemplates/scanTemplateTypes';
import { useGetScanToEdit } from './hooks/useGetScanToEdit';
import { notificationService } from '../../../services/notificationService';
import { SCAN_NOW_BUTTON, ScheduleButtons, SCAN_NOW_TOOLTIP } from './ScheduleButtons';
import {
  createScanWizardInitialSteps,
  createScanWizardStepDisplayNames,
  CreateScanWizardSteps,
} from './ScanCreateWizardTypes';
import {
  isNextCreateScanStepDisabled,
  validateFormStep,
  getStepStatus,
  disableCorrelationAndClassificationStepsIfNecessary,
  getStepsWithSchedulingButtons,
  getStepAttributesToUpdateOnCreateTemplateClick,
  getStepAttributesToUpdateOnCreateTemplateCanceled,
  isCorrelationSelected,
  onClickScanNow,
  onClickScheduleScan,
  isSaveScanStepDisabled,
} from './ScanCreateWizardUtils';
import { CreateTemplateButton } from './Steps/SelectTemplateStep/CreateTemplateButton';
import { SIDEBAR_WIDTH, navigateToForbiddenPageIfScanTemplateOff } from '../ScanUtils';
import { isString } from 'lodash';
import styled from '@emotion/styled';
import { SwitchAllEnabledDataSources } from './Steps/SelectDataSourceStep/SwitchAllEnabledDataSources';
import { isPermitted } from '../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';

const Container = styled('div')`
  height: 100%;
  [data-aid='scanCreateWizard'] [data-aid='BigidPaper'] {
    padding: 24px;
    @media (min-width: 1024px) {
      width: calc(1024px - ${SIDEBAR_WIDTH}px);
    }
    @media (min-width: 1440px) {
      width: 80%;
    }
  }
`;

const parsePrefillData = (prefill: string | object) => {
  if (isString(prefill)) {
    try {
      return JSON.parse(prefill);
    } catch (e) {
      return {};
    }
  }
  return prefill;
};

const STEP_TITLE_MAX_WIDTH = 120;

export const ScanCreateWizard: FC = () => {
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const { scanId, name, from, prefill } = $stateParams;
  const [scanWizardState, setScanWizardState] = useState<ScanCreateData>({
    ...SCAN_CREATE_INIT_STATE,
    ...parsePrefillData(prefill),
  });
  const [isLoadingStep, setIsLoadingStep] = useState(false);
  const [createScanTemplateData, setCreateScanTemplateData] = useState<ScanTemplate>(DEFAULT_SCAN_TEMPLATE);
  const [prevScanType, setPrevScanType] = useState<ScanTypes>(createScanTemplateData?.scanType as ScanTypes);
  const templateBasicDetailsHandlersRef = useRef<any>();
  const scanBasicDetailsHandlersRef = useRef<any>();

  const {
    steps,
    setActiveStepId,
    updateStepAttributes,
    ActiveView,
    activeStepId,
    goToNext,
    goToPrev,
    noPrev,
    noNext,
    getPrevStep,
    getNextStep,
  } = useHorizontalWizard(createScanWizardInitialSteps, '');

  useEffect(() => {
    navigateToForbiddenPageIfScanTemplateOff();
  }, []);

  useEffect(() => {
    const isDsStepShown = steps.find(({ id, isHidden }) => id === CreateScanWizardSteps.DATA_SOURCE && !isHidden);
    if (scanWizardState.dataSourcesPreselected?.length && isDsStepShown && !scanWizardState.isPrefillDsStep) {
      updateStepAttributes([{ stepId: CreateScanWizardSteps.DATA_SOURCE, attr: { isHidden: true } }]);
    }
  }, [scanWizardState.dataSourcesPreselected, updateStepAttributes, steps]);

  const onChangeToCreateTemplate = () => {
    setActiveStepId(CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS);
    updateStepAttributes(getStepAttributesToUpdateOnCreateTemplateClick());
  };

  const onBackFromCreateTemplate = () => {
    setActiveStepId(CreateScanWizardSteps.SELECT_TEMPLATE);
    updateStepAttributes(getStepAttributesToUpdateOnCreateTemplateCanceled());
  };

  const isStepValid = () => {
    const { isTemplateCreate, correlationRequired, classificationRequired } = scanWizardState;
    switch (activeStepId) {
      case CreateScanWizardSteps.SCAN_BASIC_DETAILS:
        return validateFormStep(scanBasicDetailsHandlersRef);
      case CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS:
        return validateFormStep(templateBasicDetailsHandlersRef);
      case CreateScanWizardSteps.CLASSIFICATION:
        return isTemplateCreate && classificationRequired ? !!createScanTemplateData?.classifiers?.length : true;
      case CreateScanWizardSteps.CORRELATION:
        return isTemplateCreate && correlationRequired ? isCorrelationSelected(createScanTemplateData) : true;
      default:
        return true;
    }
  };

  const handleGoToNext = (isFromScheduleClick?: boolean) => async () => {
    const scanWizardStateToUpdate = { ...scanWizardState, scanTemplateCreateInWizard: createScanTemplateData };
    setScanWizardState(scanWizardStateToUpdate);
    const isValid = await isStepValid();
    if (!isValid) {
      return notificationService.error('Fields are invalid ');
    }

    const isNextStepSchedule = getNextStep() === CreateScanWizardSteps.SCAN;
    if (isNextStepSchedule && !isFromScheduleClick) {
      const isEditMode = name?.length > 0 && scanId?.length > 0;
      return onClickScanNow({ createScanData: scanWizardStateToUpdate, isEditMode, name });
    }

    if (noNext) {
      return onClickScheduleScan(scanWizardStateToUpdate, setIsLoadingStep);
    }

    updateStepAttributes([
      { stepId: activeStepId, attr: { status: getStepStatus(activeStepId as CreateScanWizardSteps, true) } },
    ]);
    goToNext();
  };

  const handleGoToPrev = () => {
    const isBackToDsPage =
      activeStepId === CreateScanWizardSteps.SCAN_BASIC_DETAILS && scanWizardState.dataSourcesPreselected?.length;
    if (isBackToDsPage) {
      setSelectedDataSourcesToStorage(scanWizardState.dataSourcesPreselected);
      return $state.go(CONFIG.states.DATA_SOURCE_ROUTER);
    }

    updateStepAttributes([
      { stepId: activeStepId, attr: { status: getStepStatus(activeStepId as CreateScanWizardSteps, false, true) } },
      { stepId: getPrevStep(), attr: { status: getStepStatus(activeStepId as CreateScanWizardSteps) } },
    ]);

    const userCanceledNewTemplateCreate =
      scanWizardState.isTemplateCreate && activeStepId === CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS;
    if (userCanceledNewTemplateCreate) {
      setScanWizardState({ ...scanWizardState, isTemplateCreate: false });
      setActiveStepId(CreateScanWizardSteps.SELECT_TEMPLATE);
      onBackFromCreateTemplate();
      return;
    }

    goToPrev();
  };

  useEffect(() => {
    setScanWizardState(state => ({
      ...state,
      correlationRequired: !state.dataSourcesIds.length && state.allEnabledDs === false,
    }));
  }, [scanWizardState.dataSourcesIds, scanWizardState.allEnabledDs]);

  useEffect(() => {
    setIsNextDisabled(
      isNextCreateScanStepDisabled(activeStepId as CreateScanWizardSteps, scanWizardState, createScanTemplateData),
    );
    setSaveDisabled(
      isSaveScanStepDisabled(activeStepId as CreateScanWizardSteps, scanWizardState, createScanTemplateData),
    );
  }, [scanWizardState, activeStepId, createScanTemplateData]);

  useEffect(() => {
    const isFromDsPage = from === CONFIG.states.DATA_SOURCE_CONNECTIONS;
    pageHeaderService.setTitle({
      showBackButton: true,
      breadcrumbs: [
        {
          label: isFromDsPage ? 'Data Sources' : 'Scans',
          onClick: () => {
            window.history.back();
          },
          dataAid: 'scanPageLink',
        },
        { label: name || 'New Scan' },
      ],
    });
  }, [from, name]);

  useEffect(() => {
    if (createScanTemplateData?.scanType !== prevScanType) {
      const scanType = createScanTemplateData?.scanType as ScanTypes;
      disableCorrelationAndClassificationStepsIfNecessary(
        scanType,
        updateStepAttributes,
        CreateScanWizardSteps.CLASSIFICATION,
        CreateScanWizardSteps.CORRELATION,
      );
      setPrevScanType(scanType);
    }
  }, [createScanTemplateData, prevScanType, updateStepAttributes]);

  useGetScanToEdit(setScanWizardState, scanId);
  useGetPreselectedDataSources(setScanWizardState);
  useUpdateSupportedScanTypesBySelectedDs(setScanWizardState, scanWizardState);

  const isStepWithSchedulingButtons = getStepsWithSchedulingButtons(
    scanWizardState.isTemplateCreate,
    createScanTemplateData?.scanType as ScanTypes,
  ).includes(activeStepId as CreateScanWizardSteps);

  const isSelectTemplateStep = activeStepId === CreateScanWizardSteps.SELECT_TEMPLATE;
  const isDataSourceStep = activeStepId === CreateScanWizardSteps.DATA_SOURCE;

  const getActionButtonTooltip = () => {
    if (activeStepId === CreateScanWizardSteps.SELECT_TEMPLATE && isNextDisabled) {
      return SCAN_NOW_TOOLTIP;
    }
    return '';
  };

  const getRequestedRightSideComponent = () => {
    if (isSelectTemplateStep || isStepWithSchedulingButtons) {
      return () => (
        <CreateTemplateButton
          isSelectTemplateStep={isSelectTemplateStep}
          isStepWithSchedulingButtons={isStepWithSchedulingButtons}
          isNextDisabled={isNextDisabled}
        />
      );
    }

    if (isDataSourceStep) {
      return SwitchAllEnabledDataSources;
    }

    return null;
  };

  return (
    <Container>
      <CreateScanWizardContext.Provider
        value={{ scanWizardState, setScanWizardState, onChangeToCreateTemplate, scanBasicDetailsHandlersRef }}
      >
        <ScanTemplateWizardContext.Provider
          value={{
            scanTemplateFormData: createScanTemplateData,
            setScanTemplateFormData: setCreateScanTemplateData,
            basicDetailsHandlersRef: templateBasicDetailsHandlersRef,
            setPrevScanType,
          }}
        >
          <BigidWizardContainer
            steps={steps}
            activeStepId={activeStepId}
            ActiveView={ActiveView}
            isLoading={scanWizardState.isLoading}
            onNext={handleGoToNext()}
            onPrev={handleGoToPrev}
            noNext={noNext}
            noPrev={noPrev && !scanWizardState.dataSourcesPreselected?.length}
            stepsProps={{
              isLoadingStep,
            }}
            isNextDisabled={isNextDisabled}
            stepText={isStepWithSchedulingButtons ? SCAN_NOW_BUTTON : DEFAULT_NEXT_STEP_TEXT}
            lastStepText={SCAN_NOW_BUTTON}
            actionButtonTooltipText={getActionButtonTooltip()}
            stepMaxWidth={STEP_TITLE_MAX_WIDTH}
            customButton={
              isStepWithSchedulingButtons ? (
                <ScheduleButtons
                  createScanData={{ ...scanWizardState, scanTemplateCreateInWizard: createScanTemplateData }}
                  isDisabled={saveDisabled}
                  onNext={handleGoToNext(true)}
                />
              ) : null
            }
            title={createScanWizardStepDisplayNames[activeStepId]}
            RightSideComponent={getRequestedRightSideComponent()}
            dataAid="scanCreateWizard"
          />
        </ScanTemplateWizardContext.Provider>
      </CreateScanWizardContext.Provider>
    </Container>
  );
};
