import React from 'react';

import { PrivacyExecutiveDashboardWidgets } from './PrivacyExecutiveDashboardTypes';
import { DataRiskWidget } from './widgets/DataRiskWidget';
import { DataRiskTrendWidget } from './widgets/DataRiskTrendWidget';
import { DataRiskTypesWidget } from './widgets/DataRiskTypesWidget';
import { useLocalTranslation } from './translations';
import { RenderActiveWidgetWrapper } from './widgets/helpers/HelperComponents';
import { WidgetContainer, WidgetRow } from './widgets/styles/WidgetStyles';
import { DataRiskLandscapeWidget } from './widgets/DataRiskLandscapeWidget';
import { PerformanceMeasuresWidget } from './widgets/PerformanceMeasuresWidget';

export const WidgetWrapper = () => {
  const { t } = useLocalTranslation('widgets');

  return (
    <WidgetContainer>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK}>
          <DataRiskWidget title={t('dataRisk.title')} />
        </RenderActiveWidgetWrapper>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK_TREND}>
          <DataRiskTrendWidget title={t('dataRiskTrend.title')} />
        </RenderActiveWidgetWrapper>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK_TYPES}>
          <DataRiskTypesWidget title={t('dataRiskTypes.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK_LANDSCAPE}>
          <DataRiskLandscapeWidget title={t('dataRiskLandscape.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.PERFORMANCE_MEASURES}>
          <PerformanceMeasuresWidget title={t('performanceMeasuresWidget.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
      <WidgetRow>
        <RenderActiveWidgetWrapper widgetId={PrivacyExecutiveDashboardWidgets.RISK_DATA_RISK_TREND}>
          <DataRiskTrendWidget title={t('dataRiskTrend.title')} />
        </RenderActiveWidgetWrapper>
      </WidgetRow>
    </WidgetContainer>
  );
};
