import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidDropdownOption,
  BigidMenuItemProps,
} from '@bigid-ui/components';
import { useDashboardToolbar } from '../../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { ExecutiveDashboard, DashboardType } from '../../../components/ExecutiveDashboard';
import { useLocalTranslation } from '../translations';
import { WidgetsContainer } from './Widgets/WidgetsContainer';
import { fetchWidgetData, WidgetBodySubtypes } from '../services/dashboardService';
import { ComplianceDashboardContext } from './ComplianceDashboardContext';
import { UserPreference, userPreferencesService } from '../../../services/userPreferencesService';
import { createUniqueGridFilterQuery, fieldOptions } from './utils';

export const COMPLIANCE_DASHBOARD_ID = 'complianceDashboard';

export enum FilterToolbarTitles {
  RISK_TYPE = 'riskType',
  DATA_SOURCE = 'dataSource',
  FRAMEWORKS = 'frameworks',
  CONTROLS = 'controls',
}

export enum ComplianceDashboardWidget {
  FAILED_CONTROLS = 'failedControls',
  TOP_FAILED = 'topFailed',
  TOP_RISK = 'topRisk',
  DATA_SOURCE = 'dataSource',
}

export enum FilterFieldsName {
  RISK_TYPE = 'policyName',
  DATA_SOURCE_TYPE = 'dataSourceType',
  FRAMEWORKS = 'frameworkName',
  CONTROLS = 'controlName',
}

const widgetIds = [
  ComplianceDashboardWidget.FAILED_CONTROLS,
  ComplianceDashboardWidget.TOP_FAILED,
  ComplianceDashboardWidget.TOP_RISK,
  ComplianceDashboardWidget.DATA_SOURCE,
];

export interface ToolbarOptionsProps {
  [FilterToolbarTitles.RISK_TYPE]: BigidDropdownOption[];
  [FilterToolbarTitles.FRAMEWORKS]: BigidDropdownOption[];
  [FilterToolbarTitles.DATA_SOURCE]: BigidDropdownOption[];
}

export const ComplianceDashboard: FC = () => {
  const [filtersOptions, setFiltersOptions] = useState<ToolbarOptionsProps>();
  const [dspmPreferences, setDspmPreferences] = useState<UserPreference<any>>();
  const { t } = useLocalTranslation('compliance');

  const widgetSelectionItems: BigidMenuItemProps[] = [
    {
      id: ComplianceDashboardWidget.FAILED_CONTROLS,
      label: t(`widgets.${ComplianceDashboardWidget.FAILED_CONTROLS}.title`),
    },
    { id: ComplianceDashboardWidget.TOP_FAILED, label: t(`widgets.${ComplianceDashboardWidget.TOP_FAILED}.title`) },
    { id: ComplianceDashboardWidget.TOP_RISK, label: t(`widgets.${ComplianceDashboardWidget.TOP_RISK}.title`) },
    {
      id: ComplianceDashboardWidget.DATA_SOURCE,
      label: t(`widgets.${ComplianceDashboardWidget.DATA_SOURCE}.title`),
    },
  ];

  const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = useMemo(
    () => [
      {
        type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
        id: FilterToolbarTitles.RISK_TYPE,
        field: FilterToolbarTitles.RISK_TYPE,
        title: t(`dashboard.toolbarTitles.${FilterToolbarTitles.RISK_TYPE}`),
        parentId: FilterToolbarTitles.RISK_TYPE,
        operator: 'in',
        options: [],
        isSearchable: true,
        asyncOptionsFetch: async (_, value) => {
          try {
            const { grid_unique_value_filter: policyNameSet } = await fetchWidgetData({
              filter: createUniqueGridFilterQuery(FilterFieldsName.RISK_TYPE, value),
              subType: WidgetBodySubtypes.GRID_UNIQUE_VALUE_FILTER,
              paging: { skip: 0, limit: 10000 },
              gridFieldName: FilterFieldsName.RISK_TYPE,
            });
            const filteredOptions = fieldOptions(policyNameSet);
            setFiltersOptions(prevState => ({
              ...prevState,
              [FilterToolbarTitles.RISK_TYPE]: filteredOptions,
            }));
            return filteredOptions;
          } catch {
            console.error('Error while loading filters');
          }
        },
      },
      {
        type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
        id: FilterToolbarTitles.DATA_SOURCE,
        field: FilterToolbarTitles.DATA_SOURCE,
        title: t(`dashboard.toolbarTitles.${FilterToolbarTitles.DATA_SOURCE}`),
        parentId: FilterToolbarTitles.DATA_SOURCE,
        operator: 'in',
        options: [],
        isSearchable: true,
        asyncOptionsFetch: async (_, value) => {
          try {
            const { grid_unique_value_filter: dataSourceDisplayNameSet } = await fetchWidgetData({
              filter: createUniqueGridFilterQuery(FilterFieldsName.DATA_SOURCE_TYPE, value),
              subType: WidgetBodySubtypes.GRID_UNIQUE_VALUE_FILTER,
              paging: { skip: 0, limit: 10000 },
              gridFieldName: FilterFieldsName.DATA_SOURCE_TYPE,
            });
            const filteredOptions = fieldOptions(dataSourceDisplayNameSet);
            setFiltersOptions(prevState => ({
              ...prevState,
              [FilterToolbarTitles.DATA_SOURCE]: filteredOptions,
            }));
            return filteredOptions;
          } catch {
            console.error('Error while loading filters');
          }
        },
      },
      {
        type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
        id: FilterToolbarTitles.CONTROLS,
        field: FilterToolbarTitles.CONTROLS,
        title: t(`dashboard.toolbarTitles.${FilterToolbarTitles.CONTROLS}`),
        parentId: FilterToolbarTitles.CONTROLS,
        operator: 'in',
        options: [],
        isSearchable: true,
        asyncOptionsFetch: async (_, value) => {
          try {
            const { grid_unique_value_filter: controls } = await fetchWidgetData({
              filter: createUniqueGridFilterQuery(FilterFieldsName.CONTROLS, value),
              subType: WidgetBodySubtypes.GRID_UNIQUE_VALUE_FILTER,
              paging: { skip: 0, limit: 10000 },
              gridFieldName: FilterFieldsName.CONTROLS,
            });
            const filteredOptions = fieldOptions(controls);
            setFiltersOptions(prevState => ({
              ...prevState,
              [FilterToolbarTitles.CONTROLS]: filteredOptions,
            }));
            return filteredOptions;
          } catch {
            console.error('Error while loading filters');
          }
        },
      },
      {
        type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
        id: FilterToolbarTitles.FRAMEWORKS,
        field: FilterToolbarTitles.FRAMEWORKS,
        title: t(`dashboard.toolbarTitles.${FilterToolbarTitles.FRAMEWORKS}`),
        parentId: FilterToolbarTitles.FRAMEWORKS,
        operator: 'in',
        options: [],
        isSearchable: true,
        asyncOptionsFetch: async (_, value) => {
          try {
            const { grid_unique_value_filter: filteredFrameworks } = await fetchWidgetData({
              filter: createUniqueGridFilterQuery(FilterFieldsName.FRAMEWORKS, value),
              subType: WidgetBodySubtypes.GRID_UNIQUE_VALUE_FILTER,
              paging: { skip: 0, limit: 10000 },
              gridFieldName: FilterFieldsName.FRAMEWORKS,
            });
            const filteredOptions = fieldOptions(filteredFrameworks);
            setFiltersOptions(prevState => ({
              ...prevState,
              [FilterToolbarTitles.FRAMEWORKS]: filteredOptions,
            }));
            return filteredOptions;
          } catch {
            console.error('Error while loading filters');
          }
        },
      },
    ],
    [t],
  );

  const { activeFilters, externalAppliedFilters, activeWidgetIds, toolbarActions } = useDashboardToolbar(
    widgetIds,
    toolbarFilters,
    COMPLIANCE_DASHBOARD_ID,
  );
  useEffect(() => {
    const getDSPMFilters = async () => {
      const dspmPreferences = await userPreferencesService.get('actionableInsightsCases.open');
      setDspmPreferences(dspmPreferences);
    };
    getDSPMFilters();
  }, []);

  return (
    <ExecutiveDashboard
      dashboardId={COMPLIANCE_DASHBOARD_ID}
      dashboardType={DashboardType.COMPLIANCE}
      toolbarFilters={toolbarFilters}
      activeFilters={activeFilters}
      toolbarActions={toolbarActions}
      externalAppliedFilters={externalAppliedFilters}
      isSavedFiltersTabsDisplayed={true}
      activeWidgetIds={activeWidgetIds}
      widgetSelectionItems={widgetSelectionItems}
    >
      <ComplianceDashboardContext.Provider
        value={{
          activeFilters,
          toolbarActions,
          toolbarFilters,
          filtersOptions,
          dspmPreferences,
        }}
      >
        <WidgetsContainer activeWidgetIds={activeWidgetIds} />
      </ComplianceDashboardContext.Provider>
    </ExecutiveDashboard>
  );
};
