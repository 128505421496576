import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { BigidBody1, BigidSeverityMarker, BigidWidgetContainer, BigidWidgetList } from '@bigid-ui/components';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../../translations';
import { iconsSchema } from '../../../../../views/Frameworks/utils';
import { Content, WidgetContainerWrapper, WidgetContent, SeverityWrapper, ElipsedText } from '../WidgetsStyles';
import { useTheme } from '@mui/material';
import { fetchWidgetData, WidgetBodySubtypes } from '../../../services/dashboardService';
import { CaseSeverityLevel } from '../../../../../types/actionableInsights';
import { activeFiltersParser } from '../../utils';
import { MenuActions } from '../WidgetsContainer';
import { useFiltersActions } from '../../hooks/useFiltersActions';
import { ComplianceDashboardWidget } from '../../ComplianceDashboard';
import { useWidgetEmptyState } from '../../hooks/useWidgetEmptyState';
import { ControlTooltip } from '../ControlTooltip';

interface TopRiskWidgetProps {
  dataAid: string;
}

export interface WidgetControlsProps {
  description: string;
  controlName: string;
  frameworkName: string;
}

export interface TopRiskDataResponse {
  controls: WidgetControlsProps[];
  failedControlsCount: number;
  policyName: string;
  policySeverityLevel: CaseSeverityLevel;
}

export const TopRiskWidget = ({ dataAid }: TopRiskWidgetProps) => {
  const [riskPolicies, setRiskPolicies] = useState<TopRiskDataResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useLocalTranslation('compliance.widgets');
  const Theme = useTheme();

  const { handleRedirectAction, handleFilterAction, activeFilters } = useFiltersActions();
  const { filter } = activeFiltersParser(activeFilters);

  const emptyState = useWidgetEmptyState(
    ComplianceDashboardWidget.TOP_RISK,
    hasError,
    riskPolicies?.length === 0,
    dataAid,
  );

  const widgetList = useMemo(() => {
    return riskPolicies.map((element: TopRiskDataResponse) => {
      const expandableList = element.controls.map((control: WidgetControlsProps) => {
        const Icon = iconsSchema[control.frameworkName];
        return {
          descriptionIcon: <Icon width={20} />,
          description: (
            <ElipsedText>
              <ControlTooltip title={control.controlName} description={control.description} />
            </ElipsedText>
          ),
          value: undefined,
        };
      });

      const menuItems = [
        {
          id: 1,
          label: MenuActions.OPEN_SECURITY_POSTURE,
          onClick: () => handleRedirectAction(ComplianceDashboardWidget.TOP_RISK, null, null, [element.policyName]),
        },
        {
          id: 2,
          label: MenuActions.ADD_FILTERS,
          onClick: () => handleFilterAction(ComplianceDashboardWidget.TOP_RISK, null, null, [element.policyName]),
        },
      ];

      return {
        descriptionIcon: (
          <SeverityWrapper>
            <BigidSeverityMarker level={element.policySeverityLevel} />
          </SeverityWrapper>
        ),
        description: element.policyName,
        value: element.failedControlsCount,
        actionButtonIcon: BigidMoreActionIcon,
        actionMenuList: menuItems,
        slide: <BigidWidgetList items={expandableList} />,
      };
    });
  }, [riskPolicies]);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { policies } = await fetchWidgetData({ filter, subType: WidgetBodySubtypes.GROUP_BY_POLICY });
      setRiskPolicies(policies);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <WidgetContainerWrapper dataAid={dataAid}>
      <BigidWidgetContainer
        dataAid={dataAid}
        titleConfig={{
          title: t('topRisk.title'),
        }}
        content={
          <Content>
            <WidgetContent>
              <BigidWidgetList items={widgetList} />
            </WidgetContent>
          </Content>
        }
        contentHeight="190px"
        actionsSlot={<BigidBody1 color={Theme.palette.bigid.gray500}>{t('topRisk.description')}</BigidBody1>}
        isLoading={isLoading}
        emptyStateConfig={emptyState}
      />
    </WidgetContainerWrapper>
  );
};
