import { CONFIG } from '../../../config/common';
import { values, isString } from 'lodash';
import { hashiCorpService } from '../../services/hashiCorpService';
import { cyberArkService } from '../../services/cyberArkService';
import { thycoticService } from '../../services/thycoticService';
import CyberArkIcon from '../../assets/icons/BigidAppCyberArk.svg';
import {
  BigidAppLogoFileLabelingIcon,
  BigidAppLogoRiskConfigurationIcon,
  BigidAppLogoAccessIntelligenceIcon,
  BigidAppLogoBreachResponseIcon,
  BigidAppLogoConsentGovernanceIcon,
  BigidAppLogoDataProcessingIcon,
  BigidAppLogoDataDeletionIcon,
  BigidAppLogoDataRightsFulfillmentIcon,
  BigidAppLogoPrivacyPortalIcon,
  BigidAppLogoDataInsightsStudioIcon,
  BigidAppLogoDataPipelineIcon,
  BigidAppLogoSapIcon,
} from '@bigid-ui/icons';

import HashiCorpIcon from '../../assets/icons/BigidAppHashiCorp.svg';
import ThycoticIcon from '../../assets/icons/BigidAppThycotic.svg';
import { BigidIconType } from '@bigid-ui/components';
import { userPreferencesService } from '../../services/userPreferencesService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { appsLicenseService } from '../../services/appsLicenseService';
import { getConfigurations as getPrivacyPortalConfig } from '../DsarBigidmeConfig/DsarBigidmeConfig';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import {
  APPLICATIONS_PERMISSIONS,
  BUSINESS_FLOWS_PERMISSIONS,
  CONSENT_GOVERNANCE_PERMISSIONS,
  DATA_MINIMIZATION_PERMISSIONS,
  DSAR_PERMISSIONS,
  SECONDARY_SOURCE_PERMISSIONS,
  REPORTS_PERMISSIONS,
} from '@bigid/permissions';
import { areSomePermitted, isPermitted } from '../../services/userPermissionsService';
import { $state } from '../../services/angularServices';
import { licenseService } from '../../services/licenseService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { docsUrls, publicUrls } from '../../config/publicUrls';
import { isMultipleCyberArkProvidersEnabled } from '../Credentials/utils';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';
import { isPrivacyPortalIntegrationEnabled } from '../../utilities/featureFlagUtils';
const MT_SUPPORTED_TPA_NAMES_CONFIG_PARAM = 'MT_SUPPORTED_TPA_NAMES';

export const APP_MANAGEMENT_USER_PREFERENCE_NAME = 'appManagement';
const DATA_PIPELINE_APP_ID = 'bf3c0bb5-e0b3-4ca4-83dd-37db40ea84d5';

export interface AppPreferencesFavorites {
  appId: string;
  dateAdded: Date;
}

export interface InstallationDetails {
  installationType: string;
  appImageName: string;
}

export interface AppPreferencesRecentlyUsed {
  appId: string;
  lastUsed: Date;
}

export interface ApplicationManagementPreferences {
  favorites?: AppPreferencesFavorites[];
  recentlyUsed?: AppPreferencesRecentlyUsed[];
}

export enum CategoriesEnum {
  ALL = 'All Categories',
  PRIVACY = 'Data Privacy',
  PROTECTION = 'Data Protection',
  PERSPECTIVE = 'Data Perspective',
  TICKETING = 'Ticketing',
  UTILITIES = 'Utilities',
}

export enum TabUrls {
  INSTALLED_APPS = 'installed',
  FAVORITES = 'favorites',
  RECENTLY_USED = 'recents',
  MARKETPLACE = 'marketplace',
}

export interface Apps {
  name: string;
  id: string;
  category: CategoriesEnum;
  description: string;
  link: string;
  to?: string;
  onClick?: () => void;
  dataAid: string;
  icon?: BigidIconType;
  permission?: string;
  isCertified: boolean;
  isPaid: boolean;
  disableOnMarketplace?: boolean;
}

export type WorkspacesDetails = {
  workspaces: string[];
  sidebar_items?: NavigationMenuItem[];
};

export type NavigationMenuItem = {
  workspaces: string[];
  path: string;
  extraHighlightedPaths?: string[];
  icon: string;
  label: string;
  order: number;
  name: string;
  subItems?: NavigationMenuSubItem[];
};

export type NavigationMenuSubItem = {
  label: string;
  path: string;
  extraHighlightedPaths?: string[];
  icon: string;
  name: string;
  order: number;
};

export interface CustomApps {
  _id: string;
  tpa_name: string;
  friendlyName?: string;
  description?: string;
  logo?: string;
  sideBarLogo?: string;
  vendor?: string;
  category?: string;
  version?: string;
  status?: string;
  installation_details: InstallationDetails;
  license_type: string;
  license_verification_key: string;
  isCertified?: boolean;
  manifest_name?: string;
  settings?: Record<string, any>;
  workspaces_details?: WorkspacesDetails;
}

export enum AppType {
  CONNECTOR = 'Connector',
  APP = 'App',
}

export interface MarketplaceAppSpecs {
  appType: AppType;
  category: string;
  industries: string;
  lastRelease: string;
  listedDate: string;
  version: string;
  disableOnMarketplace?: boolean;
}

export interface MarketplaceMediaLinks {
  images: string[];
  videos: string[];
}

export interface MarketplaceApp {
  appName: string;
  appSpecs: MarketplaceAppSpecs;
  description: string;
  details: string;
  documentsLinks: string[];
  friendlyName: string;
  icon: string;
  isCertified: boolean;
  isPaid: boolean;
  marketplaceLink: string;
  mediaLinks: MarketplaceMediaLinks;
  support: string;
  isInstalled?: boolean;
  installationUrl?: string;
  disableOnMarketplace?: boolean;
}

export interface ApplicationsManagementTabProps {
  selectedCategory: string;
  customApps?: CustomApps[];
  favorites?: AppPreferencesFavorites[];
  recentlyUsed?: AppPreferencesRecentlyUsed[];
  fetchUserPreferences: () => void;
  apps: Apps[];
  marketplaceApps?: MarketplaceApp[];
  preference?: TabUrls;
}

const openDialog = (onClick: () => void, appId: string) => async () => {
  if (!smallIdLicenseService.goToUpgradeIfSmallID()) {
    await setRecentlyUsed(appId);
    onClick();
  }
};

const CYBER_ARK_ID = '2dba75cb-34dd-467f-949f-02b0b127a2d2';
const HASHICORP_ID = 'cd07f2cf-dce8-4e35-8e2b-fed4185f1284';
const THYCOTIC_ID = '16271545-1c9b-4b00-b79b-d39fc1732dab';
const CONSENT_GOVERNANCE_ID = 'd49597f4-6a8d-4f2a-9ac3-137fa10381f9';
const CONSENT_GOVERNANCE_READ_PERMISSIONS = [
  CONSENT_GOVERNANCE_PERMISSIONS.READ_CONSENT_SOURCES.name,
  CONSENT_GOVERNANCE_PERMISSIONS.READ_REPORT.name,
  CONSENT_GOVERNANCE_PERMISSIONS.READ_AGREEMENT.name,
];

const isConsentPermitted = () =>
  isPermitted(CONSENT_GOVERNANCE_PERMISSIONS.ACCESS.name)
    ? areSomePermitted(CONSENT_GOVERNANCE_READ_PERMISSIONS)
    : false;

const onConsentGovernanceClick = async () => {
  const tabs = [
    {
      state: CONFIG.states.CONSENT_GOVERNANCE_AGREEMENTS,
      permission: CONSENT_GOVERNANCE_PERMISSIONS.READ_AGREEMENT.name,
    },
    {
      state: CONFIG.states.CONSENT_GOVERNANCE_LIST,
      permission: CONSENT_GOVERNANCE_PERMISSIONS.READ_CONSENT_SOURCES.name,
    },
    { state: CONFIG.states.CONSENT_GOVERNANCE_REPORTS, permission: CONSENT_GOVERNANCE_PERMISSIONS.READ_REPORT.name },
  ];
  const state = tabs.find(({ permission }) => isPermitted(permission))?.state;
  if (state) {
    $state.go(state);
    await setRecentlyUsed(CONSENT_GOVERNANCE_ID);
  }
};

const microsoftInformationProtectionApp = {
  id: '34df7f84-625b-4d40-b612-9ac959775050',
  category: CategoriesEnum.PROTECTION,
  description: 'Discover files with sensitive data and assign customized labels via MIP or Google Drive',
  link: docsUrls.LABELING_MIP,
  to: CONFIG.states.AZURE_INFORMATION_PROTECTION,
  icon: BigidAppLogoFileLabelingIcon,
  dataAid: 'open-app-microsoft-information-protection',
  permission: APPLICATIONS_PERMISSIONS.READ_MICROSOFT_INFORMATION_PROTECTION.name,
  isCertified: true,
};

const dataMinimizationApp = {
  id: '4639b384-5a33-41c1-b973-45424cacc5fb',
  category: CategoriesEnum.PERSPECTIVE,
  description: 'Delete all information in your systems related to a specified individual',
  link: docsUrls.SUBJECT_ACCESS_REQUEST,
  to: CONFIG.states.DATA_MINIMIZATION,
  icon: BigidAppLogoDataDeletionIcon,
  dataAid: 'open-app-dsar-deletion',
  permission: DATA_MINIMIZATION_PERMISSIONS.ACCESS.name,
  isCertified: true,
};

const dataInsightsApps = [
  {
    id: '2192539b-f750-4151-b30f-3deda418e7c7',
    category: CategoriesEnum.UTILITIES,
    description: 'Create rich, insightful and actionable reporting to monitor critical metrics over time',
    link: docsUrls.DATA_INSIGHTS_STUDIO,
    icon: BigidAppLogoDataInsightsStudioIcon,
    to: CONFIG.states.DATA_INSIGHTS_STUDIO,
    onClick: () => goToReportingEtl(true),
    dataAid: 'open-app-data-insights-studio',
    permission: REPORTS_PERMISSIONS.ACCESS_REPORTING_ETL.name,
    isCertified: true,
  },
  {
    id: DATA_PIPELINE_APP_ID,
    category: CategoriesEnum.UTILITIES,
    description: 'Create rich, insightful and actionable reporting to monitor critical metrics over time',
    link: docsUrls.DATA_PIPELINE,
    icon: BigidAppLogoDataPipelineIcon,
    to: CONFIG.states.DATA_PIPELINE,
    onClick: () => goToReportingEtl(false),
    dataAid: 'open-app-data-pipeline',
    permission: REPORTS_PERMISSIONS.ACCESS_REPORTING_ETL.name,
    isCertified: true,
  },
];

const thycoticApp = {
  id: '16271545-1c9b-4b00-b79b-d39fc1732dab',
  category: CategoriesEnum.UTILITIES,
  description: 'Use Thycotic to manage credentials for data sources',
  link: docsUrls.CREDENTIALS_SECTION_THYCOTIC,
  icon: ThycoticIcon,
  onClick: openDialog(thycoticService.showDialog, '16271545-1c9b-4b00-b79b-d39fc1732dab'),
  dataAid: 'open-app-thycotic',
  permission: APPLICATIONS_PERMISSIONS.READ_THYCOTIC.name,
  isCertified: true,
};

const privacyPortalApp = {
  id: '147600b3-b7ab-44e7-9a56-8fa97f19ae09',
  category: CategoriesEnum.PRIVACY,
  description: 'Automated self-service personal data rights & privacy fulfillment',
  link: docsUrls.PRIVACY_PORTAL,
  icon: BigidAppLogoPrivacyPortalIcon,
  dataAid: 'open-app-privacy-portal',
  onClick: () => onPrivacyPortalClick(),
  to: CONFIG.states.PRIVACY_PORTAL,
  isCertified: true,
  permission: undefined as any,
};

const apps = [
  {
    id: '2383d397-4442-4c7e-be1a-6550c95fde2a',
    category: CategoriesEnum.PRIVACY,
    description: 'Document the flow of data within your organization',
    link: docsUrls.DATA_FLOWS_TOOL,
    to: CONFIG.states.BUSINESS_FLOWS,
    icon: BigidAppLogoDataProcessingIcon,
    dataAid: 'open-app-business-flows',
    permission: BUSINESS_FLOWS_PERMISSIONS.ACCESS.name,
    isCertified: true,
    disableOnMarketplace: true,
  },
  {
    id: '4639b384-5a33-41c1-b973-45424cacc5fa',
    category: CategoriesEnum.PRIVACY,
    description: 'Find all information in your systems related to a specified individual',
    link: docsUrls.SUBJECT_ACCESS_REQUEST,
    to: CONFIG.states.ACCESS_REQUEST_MANAGEMENT,
    icon: BigidAppLogoDataRightsFulfillmentIcon,
    dataAid: 'open-app-dsar',
    permission: DSAR_PERMISSIONS.ACCESS.name,
    isCertified: true,
  },
  {
    id: CONSENT_GOVERNANCE_ID,
    category: CategoriesEnum.PRIVACY,
    description: 'Link consent signatures to individuals & attributes',
    link: docsUrls.CONSENT_GOVERNANCE_OVERVIEW,
    onClick: onConsentGovernanceClick,
    icon: BigidAppLogoConsentGovernanceIcon,
    dataAid: 'open-app-consent-governance',
    isCertified: true,
    disableOnMarketplace: true,
  },
  {
    id: '394b34dc-6965-4ce7-9420-198f2422b319',
    category: CategoriesEnum.PROTECTION,
    description: 'Analyze breaches to assess what data was compromised',
    link: docsUrls.BREACH_RESPONSE,
    to: CONFIG.states.BREACH_RESPONSE,
    icon: BigidAppLogoBreachResponseIcon,
    dataAid: 'open-app-breach-response',
    permission: APPLICATIONS_PERMISSIONS.READ_BREACH_RESPONSE.name,
    isCertified: true,
    disableOnMarketplace: true,
  },
  {
    id: '5b594c0f-2a99-4032-9fbc-ab8d76529a0a',
    category: CategoriesEnum.PROTECTION,
    description: 'Track sensitive data exposure and policy compliance',
    link: docsUrls.DATA_ACCESS_GOVERNANCE,
    to: CONFIG.states.ACCESS_GOVERNANCE,
    icon: BigidAppLogoAccessIntelligenceIcon,
    dataAid: 'open-app-access-intelligence',
    permission: APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name,
    isCertified: true,
  },
  {
    id: '12d9bdf5-c159-4009-8da3-24cd366eae3e',
    category: CategoriesEnum.PROTECTION,
    description: 'Track risk scores and set risk thresholds for PI attributes',
    link: docsUrls.CONFIGURING_RISK,
    to: CONFIG.states.RISK_CONFIGURATION,
    icon: BigidAppLogoRiskConfigurationIcon,
    dataAid: 'open-app-risk-configuration',
    permission: APPLICATIONS_PERMISSIONS.READ_RISK_CONFIGURATION.name,
    isCertified: true,
    disableOnMarketplace: true,
  },
  {
    id: 'd6a589f-189a-4cdb-9064-04294c7dce4f',
    category: CategoriesEnum.PERSPECTIVE,
    description: 'See existing security & data protection with more context & intelligence',
    link: docsUrls.SECONDARY_DATA_SOURCE_CONNECTIONS_SAP_DATA_HUB,
    to: CONFIG.states.SECONDARY_CONNECTIONS,
    icon: BigidAppLogoSapIcon,
    dataAid: 'open-app-data-hub',
    permission: SECONDARY_SOURCE_PERMISSIONS.ACCESS.name,
    isCertified: true,
    disableOnMarketplace: true,
  },
  {
    id: CYBER_ARK_ID,
    category: CategoriesEnum.UTILITIES,
    description: 'Use CyberArk to manage credentials for data sources',
    link: docsUrls.CREDENTIALS_SECTION_CYBER_ARK,
    icon: CyberArkIcon,
    onClick: openDialog(cyberArkService.showDialog, CYBER_ARK_ID),
    dataAid: 'open-app-cyberark',
    permission: APPLICATIONS_PERMISSIONS.READ_CYBERARK.name,
    isCertified: true,
  },
  {
    id: 'cd07f2cf-dce8-4e35-8e2b-fed4185f1284',
    category: CategoriesEnum.UTILITIES,
    description: 'Use Hashicorp to manage credentials for data sources',
    link: docsUrls.CREDENTIALS_SECTION_HASHI_CORP,
    icon: HashiCorpIcon,
    onClick: openDialog(hashiCorpService.showDialog, 'cd07f2cf-dce8-4e35-8e2b-fed4185f1284'),
    dataAid: 'open-app-hashicorp',
    permission: APPLICATIONS_PERMISSIONS.READ_HASHICORP.name,
    isCertified: true,
  },
];

const vaultApps = [CYBER_ARK_ID, THYCOTIC_ID, HASHICORP_ID];

export const setRecentlyUsed = async (appId: string) => {
  const preference = await userPreferencesService.get<ApplicationManagementPreferences>(
    APP_MANAGEMENT_USER_PREFERENCE_NAME,
  );

  if (!preference) {
    await userPreferencesService.add<ApplicationManagementPreferences>({
      preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
      data: { recentlyUsed: [{ appId, lastUsed: new Date() }] },
    });
  } else {
    const {
      data: { recentlyUsed = [], ...preferenceData },
    } = preference;

    const recentsIndex = recentlyUsed.findIndex(recent => recent.appId === appId);
    if (recentsIndex === -1) {
      await userPreferencesService.update<ApplicationManagementPreferences>({
        preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
        data: { recentlyUsed: [...recentlyUsed, { appId, lastUsed: new Date() }], ...preferenceData },
      });
    } else {
      recentlyUsed[recentsIndex].lastUsed = new Date();

      await userPreferencesService.update<ApplicationManagementPreferences>({
        preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
        data: { recentlyUsed, ...preferenceData },
      });
    }
  }
  userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
};

export const setRecentlyUsedFromState = async (state: string, id?: string) => {
  const apps = getApps();
  const appId = state === CONFIG.states.CUSTOM_APP ? id : apps.find(({ to }) => to === state)?.id;
  appId && (await setRecentlyUsed(appId));
};

export const onSelectFavorite = async (appId: string) => {
  const preference = await userPreferencesService.get<ApplicationManagementPreferences>(
    APP_MANAGEMENT_USER_PREFERENCE_NAME,
  );
  if (!preference) {
    await userPreferencesService.add<ApplicationManagementPreferences>({
      preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
      data: { favorites: [{ appId, dateAdded: new Date() }] },
    });
  } else {
    const {
      data: { favorites = [], ...preferenceData },
    } = preference;
    const favoritesIndex = favorites.findIndex(favorite => favorite.appId === appId);
    if (favoritesIndex === -1) {
      await userPreferencesService.update<ApplicationManagementPreferences>({
        preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
        data: { favorites: [...favorites, { appId, dateAdded: new Date() }], ...preferenceData },
      });
    } else {
      favorites.splice(favoritesIndex, 1);
      await userPreferencesService.update<ApplicationManagementPreferences>({
        preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
        data: { favorites, ...preferenceData },
      });
    }
  }
  userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
};

export const deleteAppFromPreferences = async (id: string) => {
  const appsManagementUserPreferences = await userPreferencesService.get<ApplicationManagementPreferences>(
    APP_MANAGEMENT_USER_PREFERENCE_NAME,
  );
  if (appsManagementUserPreferences) {
    const {
      data: { favorites = [], recentlyUsed = [] },
    } = appsManagementUserPreferences;
    await userPreferencesService.update<ApplicationManagementPreferences>({
      preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
      data: {
        favorites: favorites.filter(({ appId }) => appId !== id),
        recentlyUsed: recentlyUsed.filter(({ appId }) => appId !== id),
      },
    });
    userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
  }
};

const getMultiTenantSupportedApps = (): string[] => {
  try {
    const supported_apps_raw = getApplicationPreference(MT_SUPPORTED_TPA_NAMES_CONFIG_PARAM);
    return isString(supported_apps_raw) ? JSON.parse(supported_apps_raw) : supported_apps_raw;
  } catch (err) {
    return [];
  }
};

export const getApps = (): Apps[] => {
  const isLabelerEnabled = getApplicationPreference('LABELER_ENABLED');
  const showDataMinimizationApp = getApplicationPreference('SHOW_DATA_MINIMIZATION_APP');
  const isETlEnabled = getApplicationPreference('ENABLE_REPORTING_ETL');
  const showThycoticApp = getApplicationPreference('SHOW_THYCOTIC_CREDENTIALS_APP');
  const adjustedDataInsightsApps = getApplicationPreference('ENABLE_NEW_ETL_LANDING_PAGE')
    ? dataInsightsApps
    : dataInsightsApps.filter(app => app.id !== DATA_PIPELINE_APP_ID);

  const preparedApps = apps.map(app =>
    vaultApps.includes(app.id) && isMultipleCyberArkProvidersEnabled()
      ? {
          ...app,
          to: CONFIG.states.CREDENTIAL_PROVIDERS,
          onClick: () => $state.go(CONFIG.states.CREDENTIAL_PROVIDERS),
        }
      : { ...app },
  );

  const appsToDisplay = [
    ...preparedApps,
    ...(showThycoticApp ? [thycoticApp] : []),
    ...(isETlEnabled ? adjustedDataInsightsApps : []),
    ...(isLabelerEnabled ? [microsoftInformationProtectionApp] : []),
    ...(showDataMinimizationApp ? [dataMinimizationApp] : []),
    ...(!isPrivacyPortalIntegrationEnabled() && !licenseService.isSAPLicense() ? [privacyPortalApp] : []),
  ];

  const appLicenses = appsLicenseService.getAppsLicense();
  let filteredAppsToDisplay = appsToDisplay
    .map(app => {
      const { displayName: name, status, name: tpaName } = appLicenses?.find(({ id }) => id === app.id) || {};
      return {
        ...app,
        name,
        isPaid: !(status?.toLowerCase() === 'free'),
        tpaName,
      };
    })
    .filter(({ permission, id }) =>
      id === CONSENT_GOVERNANCE_ID ? isConsentPermitted() : permission ? isPermitted(permission) : true,
    );
  if (isMultiTenantModeEnabled()) {
    const mtSupportedApps = getMultiTenantSupportedApps();
    filteredAppsToDisplay = filteredAppsToDisplay.filter(({ tpaName }) => mtSupportedApps.includes(tpaName));
  }
  return filteredAppsToDisplay;
};

const isEmptyCategory = (categoryToCheck: string, appsCategories: string[]) => {
  const categoryHasNoApps =
    getApps().filter(({ category }) => category === categoryToCheck).length === 0 &&
    !appsCategories.includes(categoryToCheck);

  return categoryHasNoApps;
};

export const getCategoriesToDisplay = (selectedCategory: string, appsCategories: string[]) => {
  if (selectedCategory === CategoriesEnum.ALL) {
    return values(CategoriesEnum).filter(
      category => category !== CategoriesEnum.ALL && !isEmptyCategory(category, appsCategories),
    );
  }
  return isEmptyCategory(selectedCategory, appsCategories) ? [] : [selectedCategory];
};

export const applicationManagementService = { getCategoriesToDisplay, getApps };

const onPrivacyPortalClick = async () => {
  const successUrl = publicUrls.BIGIDPRIVACY_CLOUD_PRM_LOGIN;
  const fallbackUrl = publicUrls.PRIVACY_PORTAL_APP;
  try {
    const privacyPortalConfig = await getPrivacyPortalConfig();
    if (!privacyPortalConfig.account) {
      throw new TypeError(`Invalid 'account' value in privacy portal config`);
    }
    window.open(successUrl, '_blank');
  } catch (err) {
    console.warn(`privacy portal failed to fetch working configurations`, err?.response?.data || err);
    window.open(fallbackUrl, '_blank');
  }
};

const goToReportingEtl = (isDataInsightsStudio: boolean) => {
  $state.go(isDataInsightsStudio ? CONFIG.states.DATA_INSIGHTS_STUDIO : CONFIG.states.DATA_PIPELINE, {
    isDataInsightsStudio,
  });
};
