import { styled } from '@mui/material';
import React from 'react';
import { CatalogSidePanelProps } from '../CatalogSidePanel';
import { BigidGrid } from '@bigid-ui/grid';
import { useGetColumnsConfig } from './useGetColumnsConfig';
import { AttributeMappingDialog } from './modalDialogEditors/AttributeMappingDialog';
import { TagsDialog } from './modalDialogEditors/TagsDialog';
import { BigidToolbar } from '@bigid-ui/components';
import { SidePanelMasterDetails } from '../components/ColumnsMasterDetails';
import { ColumnsMasterDetailsPreview } from './ColumnsMasterDetailsPreview';
import { BusinessAttributeDialog } from './modalDialogEditors/BusinessAttributeDialog';
import { SimilarColumnsDialog } from './modalDialogEditors/SimilarColumnsDialog';
import { LinkedColumnsDialog } from './modalDialogEditors/LinkedColumnsDialog';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
`;

const GridWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-left: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  border-right: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
`;

export type DataExplorerColumnsProps = Pick<CatalogSidePanelProps['selectedItem'], 'id'> &
  Pick<CatalogSidePanelProps['selectedItem']['data'], 'datasource' | 'detailedObjectType'> & {
    isExtendedView?: boolean;
    isDataPreviewDisabled?: boolean;
  };

export const DataExplorerColumns = (props: DataExplorerColumnsProps) => {
  const {
    gridConfig,
    attributeMappingDialogConfig,
    tagsDialogConfig,
    handleMasterDetailsClose,
    selectedColumn,
    toolbarConfig,
    businessAttributeDialogConfig,
    similarColumnsDialogConfig,
    linkedColumnsDialogConfig,
  } = useGetColumnsConfig(props);

  const { isDataPreviewDisabled = false } = props;

  return (
    <Root>
      <BigidToolbar {...toolbarConfig} />

      <GridWrapper>
        <BigidGrid {...gridConfig}>
          {selectedColumn && !isDataPreviewDisabled && (
            <SidePanelMasterDetails onClose={handleMasterDetailsClose} title={selectedColumn.fieldName}>
              <ColumnsMasterDetailsPreview {...selectedColumn} />
            </SidePanelMasterDetails>
          )}
        </BigidGrid>
      </GridWrapper>
      <AttributeMappingDialog {...attributeMappingDialogConfig} />
      <TagsDialog {...tagsDialogConfig} />
      {businessAttributeDialogConfig.isOpen && <BusinessAttributeDialog {...businessAttributeDialogConfig} />}
      {similarColumnsDialogConfig.isOpen && <SimilarColumnsDialog {...similarColumnsDialogConfig} />}
      <LinkedColumnsDialog {...linkedColumnsDialogConfig} />
    </Root>
  );
};
