import { httpService } from '../../../services/httpService';
import { ObjectsByTypeResponse } from '../../AccessIntelligence/InsightCharts/AccessIntelligenceInsightsService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { queryService } from '../../../services/queryService';
import { WidgetRequestData, WidgetResponseProps } from '../ComplianceDashboard/Widgets/WidgetsContainer';
import { DataSource } from '../../../utilities/dataSourcesUtils';

export enum DsAndPoliciesSets {
  POLICY_NAME = 'policyName',
  DATA_SOURCE_TYPE = 'dataSourceDisplayName',
}

export enum FetchPolicyByType {
  CONTROL = 'control',
  FRAMEWORK = 'framework',
}

export enum WidgetBodySubtypes {
  GROUP_BY_FRAMEWORK = 'group_by_framework',
  GROUP_BY_CONTROL = 'group_by_control',
  GROUP_BY_POLICY = 'group_by_policy',
  GROUP_BY_DS_TYPE = 'group_by_data_source_type',
  GRID_UNIQUE_VALUE_FILTER = 'grid_unique_value_filter',
}

const WIDGET_ENDPOINT = `executive-dashboard/widget`;
const UNIQUE_VALUES_ENDPOINT = 'actionable-insights/cases-unique-values?uniqueValues';
const OBJECT_SUMMARY_BY_TYPE_ENDPOINT = 'object-summaries/by-type';
const COMPLIANCE_POLICIES_ENDPOINT = 'compliance-frameworks/policies';
const DS_CONNECTIONS_ENDPOINT = 'ds-connections-types';

const BODY_GROUP = 'dspm';
const BODY_TYPE = 'compliance';

export const getObjectsSummariesByType = () => {
  return httpService
    .fetch<ObjectsByTypeResponse>(OBJECT_SUMMARY_BY_TYPE_ENDPOINT)
    .then(({ data }) => data.summaryByTypes);
};

export const fetchPoliciesAndDsSetsFromCases = async (
  currentSet: DsAndPoliciesSets,
  queryComponents?: BigidGridQueryComponents,
): Promise<{ policyNameSet?: string[]; dataSourceDisplayNameSet?: string[] }> => {
  const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
  const {
    data: { data },
  } = await httpService.fetch(`${UNIQUE_VALUES_ENDPOINT}=${currentSet}&${gridConfigQuery}`);

  return data;
};

export const fetchWidgetData = async (widgetRequestData: WidgetRequestData): Promise<WidgetResponseProps> => {
  const { subType, filter, gridFieldName, paging } = widgetRequestData;
  const isFiltersExist = Boolean(filter);
  const shouldApplyDefaultPaging =
    !paging && (subType === WidgetBodySubtypes.GROUP_BY_CONTROL || subType === WidgetBodySubtypes.GROUP_BY_POLICY);
  const {
    data: { data },
  } = await httpService.post(WIDGET_ENDPOINT, {
    group: BODY_GROUP,
    type: BODY_TYPE,
    subType,
    additionalProperties: {
      ...(isFiltersExist && { filter }),
      widgets: [
        {
          widgetAggregationName: subType,
          ...(paging ? { paging } : shouldApplyDefaultPaging && { paging: { limit: 5, skip: 0 } }),
          ...(gridFieldName && { gridFieldName }),
        },
      ],
    },
  });

  return data;
};

export const fetchPolicyName = async (controls?: string[], frameworks?: string[]): Promise<string[]> => {
  const {
    data: { data },
  } = await httpService.post(`${COMPLIANCE_POLICIES_ENDPOINT}`, {
    ...(Boolean(controls) && { controls }),
    ...(Boolean(frameworks) && { frameworks }),
  });
  return data;
};

export const fetchConnectionsType = async (): Promise<DataSource[]> => {
  const {
    data: { data },
  } = await httpService.fetch(DS_CONNECTIONS_ENDPOINT);
  return data;
};
