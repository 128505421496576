import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidBody2,
  BigidColors,
  BigidIcon,
  BigidLink,
  BigidList,
  BigidObjectDetailsSection,
} from '@bigid-ui/components';
import { DsarDeletionObjectModel } from '../DataMinimizationObjects/DataMinimizationObjects';
import { isPermitted } from '../../../services/userPermissionsService';
import { DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import {
  OBJECT_TYPE,
  objectTypeIndicatorMapping,
  REQUEST_TYPE,
} from '../DataMinimizationObjects/DataMinimizationObjectsGridConfiguration';
import { sortBy } from 'lodash';
import { notificationService } from '../../../services/notificationService';
import { formatBytes } from '../../../utilities/numericDataConverter';
import { useLocalTranslation } from '../translations';

const useStyles = makeStyles(theme => ({
  wrapper: {
    flex: 1,
    overflowY: 'auto',
  },
  wrapperInner: {
    width: '40%',
    minWidth: '600px',
  },
  sectionTitle: {
    color: BigidColors.purple[600],
    fontSize: '1.25rem',
    fontWeight: 500,
    paddingBottom: '12px',
  },
  sectionContainer: {
    paddingBottom: '24px',
  },
  detailsList: {
    listStyle: 'none',
    padding: 0,
    '& > li': {
      display: 'flex',
      minHeight: 38,
      justifyContent: 'space-evenly',
      '&:not(:last-child)': {
        borderBottom: theme.vars.tokens.bigid.borderDefault,
      },
      alignItems: 'center',
      '& > *': {
        width: '50%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '0 4px',
      },
    },
  },
}));

export const DataMinimizationObjectDetails: ({
  requestId,
  objectName,
  createdAt,
  query,
  selectQuery,
  schemaName,
  dataSourceName,
  fullObjectName,
  scannerType,
  state,
  executedUser,
  deletedRows,
  executedAt,
  errorMessage,
  reason,
  markedAs,
  decidedUser,
  estimatedRows,
  objectType,
  fullyQualifiedName,
  properties,
  sizeInBytes,
  requestType,
}: DsarDeletionObjectModel) => any = ({
  requestId,
  objectName,
  createdAt,
  query,
  selectQuery,
  schemaName,
  dataSourceName,
  scannerType,
  state,
  executedUser,
  deletedRows,
  executedAt,
  errorMessage,
  reason,
  markedAs,
  decidedUser,
  estimatedRows,
  objectType,
  fullyQualifiedName,
  properties,
  sizeInBytes,
  requestType,
}: DsarDeletionObjectModel) => {
  const classes = useStyles({});
  const { t } = useLocalTranslation('ObjectDetails');
  const requestTypeIcon = objectTypeIndicatorMapping.get(requestType ? requestType?.toLowerCase() : REQUEST_TYPE.DSAR);

  const basicDetailsList = [
    { id: '1', name: 'Request Id', value: requestId },
    { id: '2', name: 'Data Source Name', value: dataSourceName },
    { id: '3', name: 'Schema Name', value: schemaName },
    { id: '4', name: 'Object Name', value: objectName },
    { id: '5', name: 'Full Object Name', value: fullyQualifiedName },
    { id: '6', name: 'Scanner Type', value: scannerType },
    { id: '7', name: 'Submit Date', value: createdAt },
    { id: '8', name: 'Size', value: sizeInBytes ? formatBytes(sizeInBytes) : '' },
    { id: '9', name: 'Request Type', value: requestTypeIcon ? <BigidIcon {...requestTypeIcon.icon} /> : '' },
  ];

  const executeDetailsList = [
    { id: '1', name: 'State', value: state || 'Pending' },
    { id: '2', name: 'Marked as', value: markedAs },
    { id: '3', name: 'Reason', value: reason },
    { id: '4', name: 'Decided By', value: decidedUser },
    { id: '5', name: 'Executed By', value: executedUser },
    { id: '6', name: 'Actual Deleted Rows', value: deletedRows },
    { id: '7', name: 'Estimated Deleted Rows', value: estimatedRows },
    { id: '8', name: 'Execute Date', value: executedAt },
  ];

  if (errorMessage) {
    executeDetailsList.push({ id: '9', name: 'Error Message', value: errorMessage });
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notificationService.success(t('queryCopied'));
      })
      .catch(err => {
        notificationService.error(t('copyQueryFailed'));
      });
  };

  const text = query;
  const isExportEnabled = isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name);
  if (properties?.length > 0) {
    sortBy(properties, 'order')
      .map(({ order, label, value }, index) => ({
        id: `custom-property-${index}-order-${order}`,
        name: label,
        value: value,
      }))
      .forEach(item => basicDetailsList.push(item));
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.wrapperInner}>
        <div className={classes.sectionContainer}>
          <BigidObjectDetailsSection title="Basic Details">
            <ul className={classes.detailsList}>
              {basicDetailsList.map(item => {
                return (
                  <li key={item.id}>
                    <BigidBody2>{item.name}</BigidBody2>
                    <BigidBody2>{item.value}</BigidBody2>
                  </li>
                );
              })}
              {isExportEnabled && query && objectType === OBJECT_TYPE.RDB && (
                <li>
                  <BigidBody2>Query</BigidBody2>
                  <BigidBody2>
                    <BigidLink
                      href="#"
                      title={t('copyToClipboard')}
                      onClick={e => {
                        e.preventDefault();
                        copyToClipboard(query);
                      }}
                      text={t('copyToDelete')}
                    />
                    &nbsp;&nbsp;
                    <BigidLink
                      href="#"
                      title={t('copyToClipboard')}
                      onClick={e => {
                        e.preventDefault();
                        copyToClipboard(selectQuery);
                      }}
                      text={t('copyToSelect')}
                    />
                  </BigidBody2>
                </li>
              )}
            </ul>
          </BigidObjectDetailsSection>
        </div>
        <div className={classes.sectionContainer}>
          <BigidObjectDetailsSection title="Execute Details">
            {executeDetailsList.length > 0 && <BigidList listItems={executeDetailsList} />}
          </BigidObjectDetailsSection>
        </div>
      </div>
    </div>
  );
};
