import React, { useState, useRef, useEffect, ReactElement } from 'react';
import { styled } from '@mui/material';
import { ArrowSection } from './ArrowSection';

export const OuterContainer = styled('div')`
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const OuterItemContainer = styled('div')<{ itemsToShow: number }>(
  ({ itemsToShow }) => `
  display: flex;
  justify-content: center;
  min-width: ${100 / itemsToShow}%;
`,
);

export const InnerContainer = styled('div')<{ arrowSectionWidth: number }>(
  ({ arrowSectionWidth }) => `
  display: flex;
  width: calc(100% - ${arrowSectionWidth * 2}px);
  flex-wrap: nowrap;
`,
);

export interface CarouselControlsProps {
  controlName: string;
  description: string;
}

export interface CarouselDataProps {
  frameworkName: string;
  totalControls: number;
  failedControlsCount: number;
  controls: CarouselControlsProps[];
  handleLoader?: (value: boolean) => void;
}

interface CarouselProps {
  data: CarouselDataProps[];
  itemsToShow: number;
  arrowSectionWidth?: number;
  children: ReactElement;
}

export const Carousel = ({ data, itemsToShow, arrowSectionWidth = 20, children }: CarouselProps) => {
  const ref = useRef(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleSection = (value: number) => {
    setCurrentIndex(value);
  };

  useEffect(() => {
    ref.current.style.transition = 'transform 1s ease';
    ref.current.style.transform = `translate(${currentIndex * (100 / itemsToShow)}%, 0px)`;
  }, [currentIndex]);

  return (
    <OuterContainer>
      <ArrowSection
        handleSection={handleSection}
        arrowType="left"
        currentIndex={currentIndex}
        dataItemsLength={data.length}
        itemsToShow={itemsToShow}
        arrowSectionWidth={arrowSectionWidth}
      />
      <InnerContainer ref={ref} arrowSectionWidth={arrowSectionWidth}>
        {children}
      </InnerContainer>
      <ArrowSection
        handleSection={handleSection}
        arrowType="right"
        currentIndex={currentIndex}
        dataItemsLength={data.length}
        itemsToShow={itemsToShow}
        arrowSectionWidth={arrowSectionWidth}
      />
    </OuterContainer>
  );
};
