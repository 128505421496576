import React, { FC, useMemo, useState } from 'react';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import {
  ActionData,
  BigidFieldFilterOperator,
  BigidFilter,
  BigidLoader,
  BigidPaper,
  ToolbarActionType,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { ScanProfileActionType, ScanProfileGridRow } from '../ScanProfiles/ScanProfileTypes';
import {
  convertScanProfiles,
  fetchScansForGrid,
  suspendResumeScanProfiles,
  runActionDialog,
  updateScanProfile,
  cleanUpScanProfileUpdateBody,
} from '../ScanProfiles/scanProfileService';
import {
  getInitialPlannedScansFilterConfiguration,
  getPlannedScansInitialGridColumns,
} from './PlannedScansGridConfiguration';
import {
  BigidDeleteIcon,
  BigidPauseIcon,
  BigidResumeIcon,
  BigidDataSearchIcon,
  BigidCorrelationIllustration,
  BigidPreviewIcon,
  BigidConvertIcon,
} from '@bigid-ui/icons';
import { isPermitted } from '../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { CONFIG } from '../../../../config/common';
import { PlannedScansGuidedTour } from './GuidedTour/PlannedScansGuidedTour';
import {
  BigidLayoutEmptyState,
  BigidLayoutEmptyStateAction,
  BigidLayoutEmptyStateActionExecuteCallbackResult,
} from '@bigid-ui/layout';
import { ScansUITrackingEvent, trackEventScansView } from '../ScansEventTrackerUtils';
import { openSystemDialog } from '../../../services/systemDialogService';
import { ConvertScanProfile } from '../ConvertScanProfile/ConvertScanProfile';
import { useLocalTranslation, getFixedT } from '../translations';
import * as scanService from '../ScanService';

const LEGACY_SCAN_TEMPLATE_ID = 'Legacy';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '97%',
    maxHeight: '97%',
    overflow: 'hidden',
  },
  noData: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});
const filterOutSystemProfile: BigidFilter = [
  {
    field: 'isSystemProfile',
    value: [true],
    operator: 'notIn' as BigidFieldFilterOperator,
  },
];

const showPauseResume = ({ active, schedule }: ScanProfileGridRow, isActive: boolean) => {
  return isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name) && schedule && isActive !== active;
};

const removeLegacyFromUpdate = (scanTemplateId: string) => {
  return scanTemplateId === LEGACY_SCAN_TEMPLATE_ID
    ? {
        scanTemplateName: null,
        scanTemplateId: null,
      }
    : {};
};

interface openConvertProfileDialogParams {
  scanProfileId: string;
  isForReview?: boolean;
  scanType?: string;
}

export const openConvertProfileDialog = ({ scanProfileId, isForReview, scanType }: openConvertProfileDialogParams) => {
  const t = getFixedT('savedScans');
  openSystemDialog({
    title: t('convertTitle'),
    content: ConvertScanProfile,
    onClose: () => {
      return;
    },
    contentProps: { scanProfileId, isForReview, scanType },
    maxWidth: 'lg',
    fixedHeight: 698,
    isContentScrollable: false,
  });
};

export const SavedScans: FC = () => {
  const [isConvertDisabled, setIsConvertDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocalTranslation('savedScans');

  const classes = useStyles();

  const getInitialFilterToolbarConfig = useMemo(() => getInitialPlannedScansFilterConfiguration, []);
  const initialGridColumns = useMemo(getPlannedScansInitialGridColumns, []);
  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns,
    getInitialFilterToolbarConfig,
  });
  const fieldsForReturnQuery = useMemo(() => {
    const fields = gridColumns.map(({ name }) => name);
    return `&fields=${JSON.stringify([...fields, 'scanTemplateId', 'id', '_id', 'active', 'scheduledBy'])}`;
  }, [gridColumns]);

  const emptyStateActions: BigidLayoutEmptyStateAction[] = [
    {
      label: 'Create New Scan',
      execute: async () => {
        trackEventScansView(ScansUITrackingEvent.CREATE_SCHEDULED_SCAN_CLICK_EMPTY_TEMPLATE);
        $state.go(CONFIG.states.CREATE_SCAN);
        return {} as BigidLayoutEmptyStateActionExecuteCallbackResult;
      },
      show: () => true,
    },
  ];

  const getIsConvertDisabled = (scanProfiles: ScanProfileGridRow[]) => {
    const hasLegacyProfile = scanProfiles.find(scanProfile => scanProfile.scanTemplateId === LEGACY_SCAN_TEMPLATE_ID);
    const isDisabled = hasLegacyProfile ? false : true;
    setIsConvertDisabled(isDisabled);
    return isDisabled;
  };

  const isScheduled = (scanProfiles: ScanProfileGridRow[]) => {
    return scanProfiles.some(scanProfile => scanProfile.scheduledBy != null);
  };

  const config: BigidGridWithToolbarProps<ScanProfileGridRow> = useMemo(
    () => ({
      forceSelectAll: true,
      pageSize: 100,
      entityName: 'Saved Scans',
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'desc' }], //TODO: sort by next scheduled scan
      showSelectionColumn: true,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: async function (queryComponents: BigidGridQueryComponents) {
        queryComponents.filter = [...queryComponents.filter, ...filterOutSystemProfile];
        return fetchScansForGrid(
          {
            ...queryComponents,
          },
          fieldsForReturnQuery,
        );
      },
      onRowClick: (row: ScanProfileGridRow) => {
        const { scanTemplateId, _id, name } = row;
        const isEditable =
          isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name) && scanTemplateId !== LEGACY_SCAN_TEMPLATE_ID;
        if (isEditable) {
          $state.go(CONFIG.states.CREATE_SCAN, { scanId: _id, name });
          return;
        }

        const isViewScanProfile =
          isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name) && scanTemplateId === LEGACY_SCAN_TEMPLATE_ID;
        if (isViewScanProfile) {
          openConvertProfileDialog({ scanProfileId: _id });
          return;
        }
      },
      columns: gridColumns,
      rowClickShouldKeepSelection: true,
      toolbarActions: [
        {
          label: 'Convert',
          icon: BigidConvertIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: isConvertDisabled ? t('convertDisabled') : t('convertActive'),
          isGlobal: false,
          execute: async (actionData: ActionData) => {
            try {
              setIsLoading(true);
              await convertScanProfiles({
                allSelected: actionData.allSelected,
                ids: actionData.selectedRowIds,
                query: { filter: actionData.filter },
              });
              return {
                shouldGridReload: true,
                shouldClearSelection: true,
              };
            } finally {
              setIsLoading(false);
            }
          },
          disable: ({ selectedRows, allSelected }) => !allSelected && getIsConvertDisabled(selectedRows),
          show: ({ selectedRowIds }) => selectedRowIds.length > 0,
        },
        {
          label: 'Suspend',
          icon: BigidPauseIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: t('suspendTooltip'),
          isGlobal: false,
          execute: async (actionData: ActionData) => {
            try {
              setIsLoading(true);
              await suspendResumeScanProfiles({
                allSelected: actionData.allSelected,
                ids: actionData.selectedRowIds,
                query: { filter: actionData.filter },
                active: false,
                operationMsgKey: 'suspendedSuccessfully',
              });
              return {
                shouldGridReload: true,
                shouldClearSelection: true,
              };
            } finally {
              setIsLoading(false);
            }
          },
          disable: ({ selectedRows, allSelected }) => !allSelected && !isScheduled(selectedRows),
          show: ({ selectedRowIds }) => selectedRowIds.length > 0,
        },
        {
          label: 'Resume',
          icon: BigidResumeIcon,
          type: ToolbarActionType.TERTIARY,
          tooltip: t('resumeTooltip'),
          isGlobal: false,
          execute: async (actionData: ActionData) => {
            try {
              setIsLoading(true);
              await suspendResumeScanProfiles({
                allSelected: actionData.allSelected,
                ids: actionData.selectedRowIds,
                query: { filter: actionData.filter },
                operationMsgKey: 'resumedSuccessfully',
                active: true,
              });
              return {
                shouldGridReload: true,
                shouldClearSelection: true,
              };
            } finally {
              setIsLoading(false);
            }
          },
          disable: ({ selectedRows, allSelected }) => !allSelected && !isScheduled(selectedRows),
          show: ({ selectedRowIds }) =>
            selectedRowIds.length > 0 && isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name),
        },
        {
          label: 'Run Scan',
          execute: async actionData => {
            const { scanProfile } = await scanService.getScanProfileById(actionData?.selectedRowIds?.[0] as string);
            const selectedRow = actionData.selectedRows[0];
            const data = { ...actionData, selectedRows: [{ ...scanProfile, ...selectedRow }] };
            return runActionDialog(data, ScanProfileActionType.RUN_SCAN);
          },
          disable: () => false,
          show: () => isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name),
          icon: BigidDataSearchIcon,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Delete',
          execute: async actionData => {
            return runActionDialog(actionData, ScanProfileActionType.DELETE);
          },
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidDeleteIcon,
          disable: () => false,
          show: () => isPermitted(SCANS_PERMISSIONS.DELETE_SCAN_PROFILES.name),
        },
        {
          label: 'Suspend',
          execute: async ({ selectedRows }) => {
            const { _id, scanTemplateId } = selectedRows[0] || {};
            const updateData = {
              ...selectedRows[0],
              ...removeLegacyFromUpdate(scanTemplateId),
              active: false,
              operationMsgKey: 'suspendedSuccessfully',
            };
            return updateScanProfile(_id, cleanUpScanProfileUpdateBody(updateData));
          },
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidPauseIcon,
          tooltip: 'Suspend scheduled scan',
          disable: () => false,
          show: ({ selectedRows }) => showPauseResume(selectedRows[0] || {}, false),
        },
        {
          label: 'Resume',
          execute: async ({ selectedRows }) => {
            const { _id, scanTemplateId } = selectedRows[0] || {};
            return updateScanProfile(
              _id,
              cleanUpScanProfileUpdateBody({
                ...selectedRows[0],
                ...removeLegacyFromUpdate(scanTemplateId),
                active: true,
                operationMsgKey: 'resumedSuccessfully',
              }),
            );
          },
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidResumeIcon,
          tooltip: 'Resume scheduled scan',
          disable: () => false,
          show: ({ selectedRows }) => showPauseResume(selectedRows[0] || {}, true),
        },
        {
          label: 'View Scan Profile',
          execute: async ({ selectedRows }) => {
            const { _id } = selectedRows[0] || {};
            openConvertProfileDialog({ scanProfileId: _id });
            return { shouldGridReload: false };
          },
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidPreviewIcon,
          disable: () => false,
          show: ({ selectedRows }) => {
            const { scanTemplateId } = selectedRows[0] || {};
            return isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name) && scanTemplateId === 'Legacy';
          },
        },
      ],
      noDataContent: (
        <div className={classes.noData}>
          <BigidLayoutEmptyState
            illustration={BigidCorrelationIllustration}
            description="There are no saved scans"
            actions={emptyStateActions}
          />
        </div>
      ),
    }),
    [
      preferences?.grid?.sort,
      filterToolbarConfig,
      gridColumns,
      isConvertDisabled,
      classes.noData,
      emptyStateActions,
      updatePreferences,
    ],
  );

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {(!isReady || isLoading) && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...config} />}
      </BigidPaper>
      <PlannedScansGuidedTour />
    </div>
  );
};
