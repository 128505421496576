import React, { useCallback, useState } from 'react';
import {
  BigidColorsV2,
  BigidForm,
  BigidFormField,
  BigidFormRenderProps,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidBody1,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { getIsValueChanged, getIsCustomQueryStringValue } from '../../../utils/utils';
import { useDetailsStepFormConfig } from './useDetailsStepFormConfig';
import { DefaultStepProps, WizardFormFields } from '../../../types/CorrelationSetsTypes';
import { AdvancedOptions } from './AdvancedOptions';
import { useCorrelationSetWizardContext } from '../../../contexts/correlationSetContext';
import { PreviewLoader } from '../../../components/PreviewLoader';
import styled from '@emotion/styled';

//STEP 1
export const FormContainer = styled.div`
  & fieldset {
    margin: 0 0 24px;
  }
`;

export const FieldsMaxWidthWrapper = styled.div`
  max-width: ${({ maxWidth }: { maxWidth?: string }) => (!maxWidth ? '100%' : maxWidth)};
`;

const SelectedDataTypeContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  & > fieldset {
    margin: 0 0 0 16px;

    & > fieldset {
      margin: 0;

      > label {
        display: none;
      }
    }

    > div label {
      margin: 0 8px 0 0;
    }
  }
`;

const StyledMandatoryMark = styled('span')`
  color: ${BigidColorsV2.red[600]};
  width: 10px;
  display: inline-block;
  font-size: 16px;
`;

interface RenderFormProps extends BigidFormRenderProps {
  fields: BigidFormField[];
}

const RenderForm = ({ renderField, getValues, setValue, fields }: RenderFormProps) => {
  const { t } = useLocalTranslation('wizard.detailsStep');
  const { identities_force_sample, isCustomQueryString } = getValues();
  const isCustomQueryStringValue = getIsCustomQueryStringValue(isCustomQueryString);
  const { isLoadingConnection } = useCorrelationSetWizardContext();

  return (
    <FormContainer>
      <FieldsMaxWidthWrapper maxWidth="440px">
        {renderField(WizardFormFields.name)}
        {renderField(WizardFormFields.dsConnection)}
        <SelectedDataTypeContainer>
          <BigidBody1 size="medium">
            {t('selectDataLabel')}
            <StyledMandatoryMark>*</StyledMandatoryMark>
          </BigidBody1>
          {renderField(WizardFormFields.isCustomQueryString)}
        </SelectedDataTypeContainer>

        {!isCustomQueryStringValue && renderField(WizardFormFields.db_table)}
      </FieldsMaxWidthWrapper>
      {isCustomQueryStringValue && renderField(WizardFormFields.custom_query_string)}
      <FieldsMaxWidthWrapper maxWidth="270px">
        <AdvancedOptions renderField={renderField} isUseSampleSelected={identities_force_sample} />
      </FieldsMaxWidthWrapper>
      {isLoadingConnection && <PreviewLoader />}
    </FormContainer>
  );
};

export const DetailsStep = ({ detailsStepFormControls }: DefaultStepProps) => {
  const { correlationSetFormData } = useCorrelationSetWizardContext();
  const [formState, setFormState] = useState<BigidFormValues>(correlationSetFormData);

  const { fields } = useDetailsStepFormConfig({ formState });

  const handleFormChange = useCallback(
    async (values: BigidFormValues) => {
      const isFormChanged = getIsValueChanged(values, formState);

      if (isFormChanged) {
        setFormState(values as BigidFormValues);
      }
    },
    [formState],
  );

  const memoizedRenderForm = useCallback(
    (props: BigidFormRenderProps) => <RenderForm {...props} fields={fields} />,
    [],
  );

  return (
    <BigidForm
      key={correlationSetFormData.name}
      stateAndHandlersRef={detailsStepFormControls}
      onChange={handleFormChange}
      controlButtons={false}
      fields={fields}
      initialValues={correlationSetFormData}
      validateOn={BigidFormValidateOnTypes.SUBMIT}
      renderForm={memoizedRenderForm}
    />
  );
};
