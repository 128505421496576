import { $q, $rootScope } from 'ngimport';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Canceler, ResponseType } from 'axios';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { appSettings } from '../../common/services/app.common.services';
import { IPromise } from 'angular';
import { loginService } from '../../authentication/login/login.service';
import { notificationService } from './notificationService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';

const DOWNLOAD_TOKEN_HEADER = 'download-token';
export interface QueryParamsAndDataObject {
  [paramName: string]: string | number | (string | number)[] | Record<string, any>;
}

export type HeadersObject = Record<string, string>;

export interface MultipartRequestParams<U = any> {
  data?: U;
  files?: Record<string, File>;
  params?: QueryParamsAndDataObject;
}

export const handleUnauthorizedError = (response: AxiosResponse): IPromise<AxiosError> | undefined => {
  if (response) {
    switch (response.status) {
      case 401:
      case 429:
        if (!(response.config as AxiosRequestConfig & { ignoreUnauthorized: boolean }).ignoreUnauthorized) {
          loginService.isLoggedIn() && loginService.logout(true);
          systemEventsEmitter.emit(SystemEvents.SYSTEM_ERROR, { errorMessage: response.data.message });
          $rootScope.$broadcast('error', response.data.message);
        }
        break;
      case 403:
        notificationService.error('Unauthorized operation for non-admin user');
        break;
    }
  }

  return $q.reject(response);
};

axios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers.authorization = sessionStorageService.get('bigIdTokenID');
    return config;
  },
  (error: AxiosError): IPromise<AxiosError> | undefined => {
    return handleUnauthorizedError(error.response);
  },
);

export const getURL = (resourcePath: string): string => {
  return `${appSettings.serverPath}/${appSettings.version}/${resourcePath}`;
};

const fetch = <T = any>(
  resourcePath: string,
  params?: QueryParamsAndDataObject,
  headers?: HeadersObject,
  responseType?: ResponseType,
): IPromise<AxiosResponse<T>> => {
  const serverPath = getURL(resourcePath);

  return $q.when(
    axios.get(serverPath, {
      params,
      headers,
      responseType,
    }),
  );
};

const cancelableFetch = <T = any>(
  resourcePath: string,
  params?: QueryParamsAndDataObject,
): { promise: IPromise<AxiosResponse<T>>; cancel: Canceler } => {
  const serverPath = getURL(resourcePath);
  const { token, cancel } = axios.CancelToken.source();

  return {
    promise: $q.when(
      axios.get(serverPath, {
        params,
        cancelToken: token,
      }),
    ),
    cancel,
  };
};

const post = <T = any, U = QueryParamsAndDataObject>(
  resourcePath: string,
  data?: U,
  params?: QueryParamsAndDataObject,
  headers?: HeadersObject,
): IPromise<AxiosResponse<T>> => {
  const serverPath = getURL(resourcePath);
  return $q.when(axios.post(serverPath, data, { headers, params }));
};

const cancelablePost = <T = any, U = QueryParamsAndDataObject>(
  resourcePath: string,
  data?: U,
  params?: QueryParamsAndDataObject,
): { promise: IPromise<AxiosResponse<T>>; cancel: Canceler } => {
  const serverPath = getURL(resourcePath);
  const { token, cancel } = axios.CancelToken.source();

  return {
    promise: $q.when(
      axios.post(serverPath, data, {
        params,
        cancelToken: token,
      }),
    ),
    cancel,
  };
};

const put = <T = any, U = QueryParamsAndDataObject>(
  resourcePath: string,
  data?: U,
  params?: QueryParamsAndDataObject,
  headers?: HeadersObject,
): IPromise<AxiosResponse<T>> => {
  const serverPath = getURL(resourcePath);
  return $q.when(axios.put(serverPath, data, { headers, params }));
};

const patch = <T = any, U = QueryParamsAndDataObject>(
  resourcePath: string,
  data?: U,
  params?: QueryParamsAndDataObject,
  headers?: HeadersObject,
): IPromise<AxiosResponse<T>> => {
  const serverPath = getURL(resourcePath);
  return $q.when(axios.patch(serverPath, data, { headers, params }));
};

const deleteI = <T = any, U = QueryParamsAndDataObject>(
  resourcePath: string,
  data?: U,
  headers?: HeadersObject,
): IPromise<AxiosResponse<T>> => {
  const serverPath = getURL(resourcePath);
  let config;
  if (headers) {
    config = data ? { headers, data } : { headers };
  } else {
    config = data ? { data } : {};
  }
  return $q.when(axios.delete(serverPath, config));
};

const multipart = <T = any, U = QueryParamsAndDataObject>(
  method: 'post' | 'put',
  resourcePath: string,
  { data, files = {} }: MultipartRequestParams<U>,
  params?: QueryParamsAndDataObject,
): IPromise<AxiosResponse<T>> => {
  const serverPath = getURL(resourcePath);
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'content-type': 'multipart/form-data',
    },
    params,
  };
  const multipart = new FormData();
  multipart.append('data', JSON.stringify(data));
  Object.entries(files).forEach(([key, file]) => {
    multipart.append(key, file);
  });

  return $q.when(axios[method](serverPath, multipart, requestConfig));
};

/**
 * @Important.
 * If you have any problems with download on local env, you need to check:
 * 1. Using cert accordingly with the setup-environment document.
 * 2. The HOST should be "bigid-ui" and not "localhost" in browser.
 *
 * (@link https://gitlab.com/bigid/BigID-core/bigid/blob//staging/modules/admin_console_angular/docs/environemnt-setup.md)
 * @deprecated use downloadFile
 */
export const makeBrowserDownloadFile = (fileName: string, fileContent: string, encoding = '') => {
  const downLoadLink = document.createElement('a');
  const encodingStr = encoding ? `${encoding},` : '';
  document.body.appendChild(downLoadLink);

  downLoadLink.setAttribute('type', 'hidden');
  downLoadLink.setAttribute('href', `${encodingStr}${fileContent}`);
  downLoadLink.setAttribute('download', fileName);
  downLoadLink.click();

  setTimeout(() => {
    document.body.removeChild(downLoadLink);
  });
};

const downloadFile = async <T = any>(
  resourcePath: string,
  params?: QueryParamsAndDataObject,
  fileName = '',
): Promise<void> => {
  try {
    const tokenAndLink = await getLinkAndToken(resourcePath);
    const response = await axios.get(tokenAndLink.link.toString(), {
      params,
      headers: {
        [DOWNLOAD_TOKEN_HEADER]: tokenAndLink.token,
      },
      responseType: 'blob',
    });
    const contentType = response.headers['content-type'];
    const contentDisposition = response.headers['content-disposition'];
    fileName = fileName || contentDisposition?.split('filename=')[1]?.split(';')[0].replace(/['"]/g, '');

    makeBrowserDownloadFileFromBinaryData(fileName, response.data, contentType);
  } catch (e) {
    notificationService.error('Could not download file', e);
  }
};

const downloadStaticFile = (fileName: string) => {
  const url = `/static-files/${fileName}`;

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const downloadFilePost = async <T = any>(
  resourcePath: string,
  body: QueryParamsAndDataObject,
  fileName = '',
  customHeaders?: HeadersObject,
  hideTagsForCatalogExport = false,
): Promise<void> => {
  try {
    const tokenAndLink = await getLinkAndToken(resourcePath);
    const data = body.query
      ? body
      : {
          token: tokenAndLink.token,
          filter: body.filter.toString(),
          searchText: body.searchText?.toString(),
          respectHiddenTags: hideTagsForCatalogExport ? 'true' : undefined,
        };
    const response = await axios.post(tokenAndLink.link.toString(), data, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to download file');
    }
    const responseDisposition = response.headers['content-disposition'];
    const nameIndex = responseDisposition.indexOf('filename=');
    fileName = responseDisposition.slice(nameIndex + 'filename='.length);
    makeBrowserDownloadFileFromBinaryData(fileName, response.data);
  } catch (e) {
    notificationService.error('Could not download file', e);
  }
};

export const makeBrowserDownloadFileFromBinaryData = (fileName = '', fileContent: string, contentType = 'text/csv') => {
  const blob = new Blob([fileContent], { type: contentType });
  const downLoadLink = document.createElement('a');
  downLoadLink.style.display = 'none';
  document.body.appendChild(downLoadLink);

  const url = URL.createObjectURL(blob);
  downLoadLink.href = url;
  downLoadLink.download = fileName;
  downLoadLink.click();

  URL.revokeObjectURL(url);
  setTimeout(() => {
    document.body.removeChild(downLoadLink);
  });
};

export const getLinkAndToken = async (resourcePath: string) => {
  const token = await getDownloadFileToken();
  const link = new URL(`${location.protocol}${getURL(resourcePath)}`);
  return { token: token, link: link };
};

const getDownloadFileToken = async (): Promise<string> => {
  const {
    data: { token },
  } = await fetch('get-file-download-token');
  return token;
};

const isAxiosError = (payload: any): payload is AxiosError => {
  return axios.isAxiosError(payload);
};

export const httpService = {
  fetch,
  cancelableFetch,
  post,
  cancelablePost,
  put,
  patch,
  delete: deleteI,
  multipart,
  downloadFile,
  isAxiosError,
  downloadFilePost,
  downloadStaticFile,
};
