import React, { useCallback, useEffect, useState } from 'react';
import { BigidPlayIcon } from '@bigid-ui/icons';
import { BigidBody1, BigidButtonIcon } from '@bigid-ui/components';
import {
  getMetadataSearchIndexingStatus,
  startMetadataSearchIndexing,
} from '../../../../components/MetadataSearch/MetadataSearchService';
import {
  MetadataSearchIndexingStatus,
  MetadataSearchTypeIndexingStatus,
} from '../../../../components/MetadataSearch/MetadataSearchTypes';
import { useLocalTranslation } from '../../translations';
import { dateUtils } from '../../../../services/angularServices';
import { IndexStatusContainer } from './WidgetsStyles';

const POLLING_TIMER = 10000;
const DELAY_FOR_PROMISE_CHAIN = 100;
const ACTIONABLE_INSIGHTS = 'actionable_insights_cases';

interface IndexStatusProps {
  handleLoader: (value: boolean) => void;
}

export const IndexStatus = ({ handleLoader }: IndexStatusProps) => {
  const [currentStatus, setCurrentStatus] = useState<MetadataSearchTypeIndexingStatus>();
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useLocalTranslation('compliance.widgets.indexStatus');

  const fetchStatus = useCallback(async (isAction?: boolean) => {
    try {
      const { statusPerType = [] } = await getMetadataSearchIndexingStatus();
      const caseStatus = statusPerType.find(caseStatus => caseStatus.entityName === ACTIONABLE_INSIGHTS);
      if (!caseStatus) {
        console.error(t('fetchMessage.noStatusForIndex'));
        setHasError(true);
        return;
      }
      setCurrentStatus(caseStatus);
      switch (true) {
        case caseStatus.status === MetadataSearchIndexingStatus.INDEXING:
          setTimeout(() => fetchStatus(true), POLLING_TIMER);
          break;
        case caseStatus.status === MetadataSearchIndexingStatus.UPDATED && isAction:
          handleLoader(true);
          setTimeout(() => {
            handleLoader(false);
          }, DELAY_FOR_PROMISE_CHAIN);
          break;
        case caseStatus.status === MetadataSearchIndexingStatus.FAILED:
          console.error(t('fetchMessage.failedUpdate'));
          break;
      }
    } catch (error) {
      console.error(t('fetchMessage.failedFetchStatus'), error);
      setHasError(true);
    }
  }, []);

  const handleUpdateStatus = async () => {
    await startMetadataSearchIndexing(ACTIONABLE_INSIGHTS);
    setTimeout(async () => await fetchStatus(true), DELAY_FOR_PROMISE_CHAIN);
  };

  const handleStatusResponse = (indexStatus: MetadataSearchTypeIndexingStatus) => {
    switch (true) {
      case hasError:
        return <BigidBody1>{t('fetchMessage.apiResponseError')}</BigidBody1>;
      case indexStatus?.status === MetadataSearchIndexingStatus.INDEXING:
        return <BigidBody1>{t('updating')}</BigidBody1>;
      default:
        return (
          <BigidBody1>
            {t('title')}
            {dateUtils.formatDate(currentStatus?.finishedIndexingAt, { formatString: 'MMM do, yyyy, hh:mm a' })}
          </BigidBody1>
        );
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <IndexStatusContainer>
      <BigidButtonIcon
        onClick={handleUpdateStatus}
        size="medium"
        icon={BigidPlayIcon}
        disabled={currentStatus?.status === MetadataSearchIndexingStatus.INDEXING || hasError}
      />
      <BigidBody1>{handleStatusResponse(currentStatus)}</BigidBody1>
    </IndexStatusContainer>
  );
};
