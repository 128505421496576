import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BigidBody1,
  BigidFieldFilter,
  BigidFieldFilterOperator,
  BigidFilterToolbar,
  BigidFilterType,
  BigidLoader,
  BigidPaper,
} from '@bigid-ui/components';
import { ACIDashboard } from './ACIDashboard';
import {
  areThereAnyDatasourcesInProgress,
  getDashboard,
  getLatestDsSummaries,
  getPolicies,
  getUserWidgets,
} from './ACIDashboardService';
import styled from '@emotion/styled';
import { notificationService } from '../../../services/notificationService';

const DATASOURCES_FIELD_NAME = 'dataSources';

const createFilters = (values: string[] = [], selectedDatasources: string[] = []): BigidFilterType[] => {
  return [
    {
      title: 'Data Sources',
      field: DATASOURCES_FIELD_NAME,
      operator: 'in' as BigidFieldFilterOperator,
      options: values.map(v => ({ value: v, label: v, isSelected: selectedDatasources.includes(v) })),
      value: selectedDatasources,
    },
  ];
};

export interface DataElement {
  label: string;
  count: number;
}

export interface TopExternalUsers {
  name: string;
  sharedObjectsCount: number;
}

export interface TopExternalDomainsResponse {
  domain: string;
  sharedObjectsCount: number;
}

export interface MainWidgetsResponse {
  policiesTriggered: PoliciesTriggered;
  topAccessTypesByFileCount: TopAccessTypesByFileCountResponse[];
  topCasesByAccessType: TopCasesByAccessTypeResponse[];
  topDataSources: TopDataSourcesResponse;
}

export interface UserWidgetsResponse {
  topUsersAccessWithFileCount: TopUsersAccessWithFileCountResponse[];
  topExternalDomains: TopExternalDomainsResponse[];
  topExternalUsers: TopExternalUsers[];
}

export interface TopAccessTypesByFileCountResponse {
  labelName: string;
  objectsCount: number;
}

export interface TopCasesByAccessTypeResponse {
  name: string;
  count: number;
}

export interface TopDataSourcesResponse {
  [accessType: string]: { datasource: string; count: number }[];
}

export interface TopUsersAccessWithFileCountResponse {
  name: string;
  fileCount: number;
}

export interface PoliciesTriggered {
  totalObjectsFoundByAccessPolicies: number | 'IN_PROGRESS';
  triggeredAccessPolicies: string[] | 'IN_PROGRESS';
}

export interface ACIDashboardData {
  policiesTriggered: PoliciesTriggered;
  totalPoliciesWithAccessType: number;
  topAccessTypesByFileCount: DataElement[];
  topCasesByAccessType: DataElement[];
  topDataSources: TopDataSources;
  topUsersAccessWithFileCount: DataElement[];
  topExternalUsers: DataElement[];
  topExternalDomains: DataElement[];
}
export interface MainWidgets {
  policiesTriggered: PoliciesTriggered;
  totalPoliciesWithAccessType: number | 'IN_PROGRESS';
  topAccessTypesByFileCount: DataElement[] | 'IN_PROGRESS';
  topCasesByAccessType: DataElement[] | 'IN_PROGRESS';
  topDataSources: TopDataSources | 'IN_PROGRESS';
}

export interface UserWidgets {
  topUsersAccessWithFileCount: DataElement[] | 'IN_PROGRESS';
  topExternalUsers: DataElement[] | 'IN_PROGRESS';
  topExternalDomains: DataElement[] | 'IN_PROGRESS';
}

export interface TopDataSources {
  [accessType: string]: DataElement[];
}

const getMainWidgets = async (datasources: string[]): Promise<MainWidgets> => {
  const [policies, dashboardData] = await Promise.all([getPolicies(), getDashboard(datasources)]);

  const totalPoliciesWithAccessType = policies.filter(
    policy => policy?.is_enabled && policy?.complianceRuleCalc?.bigidQuery?.includes('catalog_tag.Access Type'),
  ).length;

  return {
    ...dashboardData,
    totalPoliciesWithAccessType,
  };
};

const ACI_DASHBOARD_TAB_SAVED_DATASOURCES = 'ACI_DASHBOARD_TAB_SAVED_DATASOURCES';

const FiltersBoxShadow = styled('div')`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding: 4px;
  border-radius: 4px;
  background-color: white;
  margin: 32px calc(14%) 0 calc(14%);
  z-index: 1;
`;

const ScrollableWrapper = styled('div')`
  overflow-y: auto;
  padding: 32px calc(14%);
`;

const USER_WIDGETS_LOADING_STATE: UserWidgets = {
  topUsersAccessWithFileCount: 'IN_PROGRESS',
  topExternalUsers: 'IN_PROGRESS',
  topExternalDomains: 'IN_PROGRESS',
};

const MAIN_WIDGETS_LOADING_STATE: MainWidgets = {
  policiesTriggered: {
    totalObjectsFoundByAccessPolicies: 'IN_PROGRESS',
    triggeredAccessPolicies: 'IN_PROGRESS',
  },
  totalPoliciesWithAccessType: 'IN_PROGRESS',
  topAccessTypesByFileCount: 'IN_PROGRESS',
  topCasesByAccessType: 'IN_PROGRESS',
  topDataSources: 'IN_PROGRESS',
};

export const ACIDashboardWrapper: FC = () => {
  const [mainWidgets, setMainWidgets] = useState<MainWidgets>();
  const [userWidgets, setUserWidgets] = useState<UserWidgets>();
  const [selectedDatasources, setSelectedDatasources] = useState<string[]>([]);
  const [availableDatasources, setAvailableDatasources] = useState<string[]>([]);
  const [initialFilters, setInitialFilters] = useState<BigidFilterType[]>();
  const [dashboardGenerationInProgress, setDashboardGenerationInProgress] = useState(false);

  const fetch = useCallback(async (sources: string[]) => {
    setUserWidgets(USER_WIDGETS_LOADING_STATE);
    setMainWidgets(MAIN_WIDGETS_LOADING_STATE);

    const [mainWidgetsResult, userWidgetsResult] = await Promise.allSettled([
      getMainWidgets(sources),
      getUserWidgets(sources),
    ]);

    if (mainWidgetsResult.status === 'fulfilled') {
      setMainWidgets(mainWidgetsResult.value);
    }

    if (userWidgetsResult.status === 'fulfilled') {
      setUserWidgets(userWidgetsResult.value);
    }
  }, []);

  const handleOnFiltersChange: (data: BigidFieldFilter[]) => void = useCallback(
    async data => {
      try {
        const newDatasources = (
          (data.find(filter => filter.field === DATASOURCES_FIELD_NAME)?.value || []) as string[]
        ).sort((a, b) => a.localeCompare(b));
        sessionStorage.setItem(ACI_DASHBOARD_TAB_SAVED_DATASOURCES, JSON.stringify(newDatasources));

        await fetch(newDatasources);

        setSelectedDatasources(
          (JSON.parse(sessionStorage.getItem(ACI_DASHBOARD_TAB_SAVED_DATASOURCES)) as string[]) ?? [],
        );
      } catch (e) {
        console.error(e);
        notificationService.error('Error fetching dashboard data');
      }
    },
    [fetch],
  );

  useEffect(() => {
    const init = async () => {
      const availableDatasources = (await getLatestDsSummaries()).map(dsSummary => dsSummary.source);
      setAvailableDatasources(availableDatasources);

      if (availableDatasources.length === 0) {
        const dashboardInProgress = await areThereAnyDatasourcesInProgress();
        setDashboardGenerationInProgress(dashboardInProgress);
      }

      const cachedDatasources: string[] = JSON.parse(sessionStorage.getItem(ACI_DASHBOARD_TAB_SAVED_DATASOURCES));
      setInitialFilters(createFilters(availableDatasources, cachedDatasources?.length > 0 ? cachedDatasources : []));
      if (cachedDatasources?.length > 0) {
        setSelectedDatasources(cachedDatasources);
      }
      await fetch(cachedDatasources ?? []);
    };

    init();
  }, [fetch]);

  return (
    <BigidPaper>
      {initialFilters && (
        <FiltersBoxShadow>
          <BigidFilterToolbar filters={initialFilters} onFiltersChange={handleOnFiltersChange} />
        </FiltersBoxShadow>
      )}
      {!initialFilters && <BigidLoader />}

      <ScrollableWrapper>
        {dashboardGenerationInProgress && <BigidBody1>Dashboard generation in progress...</BigidBody1>}

        {!dashboardGenerationInProgress && mainWidgets?.policiesTriggered && (
          <ACIDashboard
            mainWidgets={mainWidgets}
            userWidgets={userWidgets}
            selectedDatasources={selectedDatasources}
            availableDatasources={availableDatasources}
          />
        )}
      </ScrollableWrapper>
    </BigidPaper>
  );
};
