import {
  BigidValueType,
  QueryConditionOperation,
  QueryExpressionOperator,
  parseAbstractQueryTreeIntoString,
  parseExpressionsIntoAbstractQueryTree,
  AbstractQueryConditionNode,
} from '@bigid/query-object-serialization';

export const mapFieldValueToBiqlString = (field: string, value: string) => {
  const node: AbstractQueryConditionNode = {
    name: field,
    bigidName: field,
    operation: QueryConditionOperation.IN,
    operator: QueryExpressionOperator.UNDEFINED,
    type: BigidValueType.STRING,
    value: [value],
    isIgnored: false,
    isNegativeOperation: false,
    isTagsNegativeOperation: false,
    arrayFieldName: null,
  };
  const abstractTree = parseExpressionsIntoAbstractQueryTree([node]);

  return parseAbstractQueryTreeIntoString(abstractTree);
};
