import { createContext, useContext } from 'react';
import { CorrelationSetFormData, GuideState, WizardFormFields } from '../types/CorrelationSetsTypes';
import { BigidFormValues } from '@bigid-ui/components';

function getDefaultDetailsStepFormState(): BigidFormValues {
  return {
    [WizardFormFields.name]: '',
    [WizardFormFields.dsConnection]: [],
    [WizardFormFields.isCustomQueryString]: false,
    [WizardFormFields.db_table]: '',
    [WizardFormFields.custom_query_string]: '',

    // advance options
    [WizardFormFields.identities_force_sample]: true,
    [WizardFormFields.identities_sample_size]: '',
    [WizardFormFields.skip_sample_start]: false,
    [WizardFormFields.skip_sample_size]: '1000',
    [WizardFormFields.use_random_sampling]: false,

    // Step 2 Preview Step
    [WizardFormFields.attributes]: [],
  };
}

export const DEFAULT_CORRELATION_SET_FORM_DATA = {
  ...getDefaultDetailsStepFormState(),
} as CorrelationSetFormData;

export const DEFAULT_CORRELATION_SET_GUIDE_STATE = {
  isDefaultContentVisible: true,
  errorMessage: '',
  successMessage: '',
};

export interface CorrelationSetContext {
  correlationSetFormData: CorrelationSetFormData;
  setCorrelationSetFormData: React.Dispatch<React.SetStateAction<CorrelationSetFormData>>;
  guideState: GuideState;
  setCorrelationGuideState: React.Dispatch<React.SetStateAction<GuideState>>;
  isLoadingConnection: boolean;
}

export const CorrelationSetWizardContext = createContext<CorrelationSetContext>(null);

export function useCorrelationSetWizardContext() {
  const context = useContext(CorrelationSetWizardContext);
  if (!context) {
    console.error('useCorrelationSetWizardContext must be used within a CorrelationSetWizardContext.Provider');
  }
  return context;
}
