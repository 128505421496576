import React, { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { LabelingLayout } from './LabelingLayout';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { LabelingSettingsDialog } from './LabelingSettingsDialog';
import { openSystemDialog } from '../../services/systemDialogService';
import { noop } from 'lodash';
import { BigidAdministrationIcon, BigidDownloadIcon } from '@bigid-ui/icons';
import { BigidButtonIcon, SecondaryButton } from '@bigid-ui/components';
import { LabelerPropagationReportDownloadDialog } from '../../components/LabelerPropagationReportDownloadDialog/LabelerPropagationReportDownloadDialog';

const ActionButtonsContainer = styled('div')({
  display: 'flex',
  gap: '8px',
});

export const Labeling: FC = () => {
  const [isDownloadReportDialogOpen, setIsDownloadReportDialogOpen] = useState(false);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Labeling',
      rightSideComponentsContainer: (
        <ActionButtonsContainer>
          <SecondaryButton
            startIcon={<BigidDownloadIcon />}
            dataAid="labeling-report-download-dialog-open-button"
            onClick={() => {
              setIsDownloadReportDialogOpen(true);
            }}
            size="medium"
            text="Download report"
          />
          <BigidButtonIcon
            icon={BigidAdministrationIcon}
            dataAid="labeling-configuration"
            title="Settings"
            onClick={openLabelingSettingsDialog}
          />
        </ActionButtonsContainer>
      ),
    });
  }, []);

  const openLabelingSettingsDialog = () => {
    openSystemDialog({
      title: 'Credentials',
      onClose: noop,
      content: LabelingSettingsDialog,
      borderTop: true,
    });
  };

  return (
    <>
      <LabelingLayout />
      {isDownloadReportDialogOpen && (
        <LabelerPropagationReportDownloadDialog
          isOpen={isDownloadReportDialogOpen}
          onClose={() => {
            setIsDownloadReportDialogOpen(false);
          }}
        />
      )}
    </>
  );
};
