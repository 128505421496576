import { BigidHeading5, BigidLoader, BigidSearch } from '@bigid-ui/components';
import React, { FC, memo, useContext, useMemo } from 'react';
import { SelectDataSourceOnboardingTypeContext } from '../../SelectDataSourceOnboardingTypeContext';
import { useLocalTranslation } from '../../translations';
import styled from '@emotion/styled';
import { useSetInObjectState } from '../../hooks/useSetInObjectState';
import { debounce } from 'lodash';

export const DataSourceOnboardingHeaderContainer = styled('div')`
  display: flex;
`;

export const DataSourceOnboardingHeaderFiltersWrapper = styled('div')`
  display: flex;
  flex: 0 1 auto;
  gap: 8px;
  & > :last-child {
    width: 300px;
    border: 1px solid ${({ theme }) => theme.vars.palette.bigid.gray200};
  }
`;
export const DataSourceOnboardingHeader: FC = memo(() => {
  const { setState, isSearching } = useContext(SelectDataSourceOnboardingTypeContext);
  const { t } = useLocalTranslation('');
  const updateSearchFilter = useSetInObjectState(setState, (searchString, prevState) => ({
    searchString,
    isSearching: searchString !== prevState.searchString,
  }));
  const updateSearchFilterDebounced = useMemo(() => debounce(updateSearchFilter, 300), [updateSearchFilter]);

  return (
    <DataSourceOnboardingHeaderContainer>
      <BigidHeading5 sx={{ flex: '1 0 auto' }}>{t('grid.title')}</BigidHeading5>
      <DataSourceOnboardingHeaderFiltersWrapper>
        <BigidSearch
          size={'large'}
          onChange={updateSearchFilterDebounced}
          onSubmit={updateSearchFilterDebounced}
          placeholder="Search"
        />
      </DataSourceOnboardingHeaderFiltersWrapper>
      {isSearching && <BigidLoader position={'fixed'} />}
    </DataSourceOnboardingHeaderContainer>
  );
});
