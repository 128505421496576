import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidBody1, BigidHeading4, BigidRadio, BigidTextField } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../translations';
import { noop } from 'lodash';

const RadioBox = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const DetailsTitle = styled(BigidHeading4)`
  margin-bottom: 16px;
`;

const RadioTitle = styled(BigidBody1)`
  margin-right: 24px;
`;

const BigidTextFieldStyled = styled('div')`
  max-width: 443px;
`;

interface DetailsProps {
  isCustomQuery: boolean;
  value: string;
}
export const Details: FC<DetailsProps> = ({ isCustomQuery, value }) => {
  const { t } = useLocalTranslation('details');
  return (
    <div>
      <DetailsTitle>{t('title')}</DetailsTitle>
      <RadioBox>
        <RadioTitle>{t('tableQuery')}</RadioTitle>
        <BigidRadio label={t('tableName')} disabled checked={!isCustomQuery} />
        <BigidRadio label={t('customQuery')} disabled checked={isCustomQuery} />
      </RadioBox>
      <BigidTextFieldStyled>
        <BigidTextField onChange={noop} disabled value={value} />
      </BigidTextFieldStyled>
    </div>
  );
};
