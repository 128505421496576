import { useRef, useState } from 'react';
import { omit, omitBy, isUndefined } from 'lodash';
import { getFixedT, useLocalTranslation } from '../translations';
import { getRegexFormSubmitFields } from '../RegexForm/ClassifiersForm';
import { createNerClassifier, getClassifierById, updateClassifier } from '../services/classifiersManagementService';
import { createNewRegexClassifier, handleAssignCategoryAndUpdateGrid } from '../ClassifiersActions';
import { notificationService } from '../../../services/notificationService';
import {
  ActionModeOnClassifier,
  CLASSIFIER_TYPES,
  Category,
  ClassifierGridRow,
  NERPayload,
  REGEX_FORM_TYPES,
  AssignAttributePayload,
  CREATE_CLASSIFIER_DIALOG_EVENTS,
} from '../types/ClassifierTypes';
import {
  addGridItem,
  getDefaultAttributeName,
  isRequestSucceeded,
  reloadGrid,
  updateGridItemById,
} from '../utils/utils';
import { getNERFormSubmitFields } from '../NERForm/NERForm';
import { Attributes } from '../types/Attributes';
import { analyticsService } from '../../../services/analyticsService';
import { ClassifiersEventsEnum } from '../types/Events';
import * as classifiersManagementService from '../services/classifiersManagementService';

interface FormValuesType extends ClassifierGridRow {
  attribute?: Attributes;
}

interface OnUpdateClassifierParams {
  payload: ClassifierGridRow;
  categories?: Category[];
  attribute?: AssignAttributePayload;
  gridClassifierData?: ClassifierGridRow;
  isAssignEnable?: boolean;
  classifier?: ClassifierGridRow;
}

interface OnUpdateCorrelationParams {
  categories?: Category[];
  attribute?: AssignAttributePayload;
  classifier?: ClassifierGridRow & { isFromSaveChangesDialog?: boolean };
  payload: ClassifierGridRow;
}

export const updateIdConnection = async (sources: string[], payload: Record<string, any>) => {
  const updateIdConnections = sources.map(source => classifiersManagementService.updateIdConnection(source, payload));
  await Promise.all(updateIdConnections);
};

export const useFormActions = (
  handleCloseCreateClassifierDialog: (isFromSave?: CREATE_CLASSIFIER_DIALOG_EVENTS) => void,
  setActiveClassifier: (classifier: ClassifierGridRow) => void,
  updateActiveClassifierRef: (classifier: ClassifierGridRow) => void,
  getActiveClassifierRef: () => Partial<ClassifierGridRow>,
) => {
  const { t } = useLocalTranslation('notifications');
  const [isLoading, setIsLoading] = useState(false);
  const formControlsRef = useRef<any>();

  const onUpdateClassifier = async ({
    payload,
    categories,
    attribute,
    gridClassifierData,
    isAssignEnable = true,
    classifier,
  }: OnUpdateClassifierParams) => {
    try {
      setIsLoading(true);
      if (gridClassifierData.isOutOfTheBox) {
        await handleAssignCategoryAndUpdateGrid({
          categories,
          attribute,
          sucessCallback: dataToUpdate => {
            updateGridItemById(payload._id, dataToUpdate);
            if (!classifier) {
              setActiveClassifier({ ...gridClassifierData, ...payload, ...dataToUpdate });
              updateActiveClassifierRef({ ...gridClassifierData, ...payload, ...dataToUpdate });
            }
            analyticsService.trackManualEvent(ClassifiersEventsEnum.REGEX_CLASSIFIER_UPDATED_SUCCESSFULLY, {
              classifier: {
                ...gridClassifierData,
                ...payload,
                ...dataToUpdate,
              },
            });
            notificationService.success(t('classifierSavedSuccessfully'), { shouldAutoHide: true });
          },
          mode: ActionModeOnClassifier.update,
        });

        if (classifier) {
          const fetchClassifier = await getClassifierById(classifier._id);
          updateActiveClassifierRef(fetchClassifier);
          setActiveClassifier(fetchClassifier);
        }
        return;
      }

      const response = await updateClassifier(payload.classification_name, payload);
      if (isRequestSucceeded(response) && isAssignEnable) {
        await handleAssignCategoryAndUpdateGrid({
          categories,
          attribute,
          sucessCallback: dataToUpdate => {
            updateGridItemById(payload._id, { ...payload, ...dataToUpdate });
            if (!classifier) {
              setActiveClassifier({ ...gridClassifierData, ...payload, ...dataToUpdate });
              updateActiveClassifierRef({ ...gridClassifierData, ...payload, ...dataToUpdate });
            }
            analyticsService.trackManualEvent(ClassifiersEventsEnum.REGEX_CLASSIFIER_UPDATED_SUCCESSFULLY, {
              classifier: {
                ...gridClassifierData,
                ...payload,
                ...dataToUpdate,
              },
            });
            notificationService.success(t('classifierSavedSuccessfully'), { shouldAutoHide: true });
          },
          mode: ActionModeOnClassifier.update,
        });
      }

      if (classifier) {
        const fetchClassifier = await getClassifierById(classifier._id);
        updateActiveClassifierRef(fetchClassifier);
        setActiveClassifier(fetchClassifier);
      }

      return response;
    } catch (e) {
      console.log({ e });
      notificationService.error(t('commonErrorMessage'));
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateNerClassifier = async (
    payload: NERPayload[],
    categories?: Category[],
    attribute?: AssignAttributePayload,
    classifierId?: string,
    classifier?: ClassifierGridRow & { isFromSaveChangesDialog?: boolean },
  ) => {
    setIsLoading(true);
    try {
      // it's an update case
      if (classifierId) {
        await handleAssignCategoryAndUpdateGrid({
          categories,
          attribute,
          nerClassifiers: payload,
          mode: ActionModeOnClassifier.update,
          sucessCallback: dataToUpdate => {
            updateGridItemById(classifierId, dataToUpdate);
            if (classifier?.isFromSaveChangesDialog) {
              setActiveClassifier({ ...classifier });
              updateActiveClassifierRef({ ...classifier });
            } else {
              setActiveClassifier({ ...classifier, ...dataToUpdate });
              updateActiveClassifierRef({ ...classifier, ...dataToUpdate });
            }
            analyticsService.trackManualEvent(ClassifiersEventsEnum.NER_CLASSIFIER_UPDATED_SUCCESSFULLY, {
              classifier: { _id: classifierId, ...payload, ...dataToUpdate },
            });
            notificationService.success(t('classifierSavedSuccessfully'), { shouldAutoHide: true });
          },
        });

        if (classifier?._id) {
          const fetchClassifier = await getClassifierById(classifier._id);
          updateActiveClassifierRef(fetchClassifier);
          setActiveClassifier(fetchClassifier);
        }

        return;
      }

      const response = await createNerClassifier(payload);
      if (response?.data?.status === 'success') {
        handleAssignCategoryAndUpdateGrid({
          categories,
          attribute,
          nerClassifiers: payload,
          mode: ActionModeOnClassifier.create,
          sucessCallback: () => {
            handleCloseCreateClassifierDialog(CREATE_CLASSIFIER_DIALOG_EVENTS.SAVE);
            reloadGrid();
            analyticsService.trackManualEvent(ClassifiersEventsEnum.NER_CLASSIFIER_CREATED_SUCCESSFULLY, {
              classifier: { ...payload, attribute, categories },
            });
            const modifiedCount = response?.data?.data?.[0]?.modifiedCount;
            if (modifiedCount === 1) {
              notificationService.success(
                t('classifierCreatedSuccessfully', { classifierName: payload?.[0]?.classifierName }),
                { shouldAutoHide: true },
              );
            }

            if (modifiedCount > 1) {
              notificationService.success(t('nerClassifiersCreatedSuccessfully', { modifiedCount }), {
                shouldAutoHide: true,
              });
            }
          },
        });
      }
      return response;
    } catch (e) {
      notificationService.error(t('commonErrorMessage'));
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateClassifier = async (
    payload: ClassifierGridRow,
    categories: Category[],
    attribute: AssignAttributePayload,
  ) => {
    setIsLoading(true);
    const response = await createNewRegexClassifier(payload);

    if (isRequestSucceeded(response)) {
      payload = {
        ...payload,
        enabled: true,
        isOutOfTheBox: false,
      };
      await handleAssignCategoryAndUpdateGrid({
        categories,
        attribute,
        mode: ActionModeOnClassifier.create,
        sucessCallback: dataToUpdate => {
          addGridItem({ ...payload, ...dataToUpdate }, response.data._id);
          analyticsService.trackManualEvent(ClassifiersEventsEnum.REGEX_CLASSIFIER_CREATED_SUCCESSFULLY, {
            classifier: {
              ...payload,
              ...dataToUpdate,
              _id: response.data._id,
            },
          });
          handleCloseCreateClassifierDialog(CREATE_CLASSIFIER_DIALOG_EVENTS.SAVE);
          formControlsRef.current = {};
        },
      });
    }

    setIsLoading(false);
    return response;
  };

  const onUpdateCorrelation = async ({ categories, attribute, classifier, payload }: OnUpdateCorrelationParams) => {
    try {
      const activeClassifierRef = getActiveClassifierRef();
      if (activeClassifierRef.isOverrideSureMatch !== payload.isOverrideSureMatch) {
        const idConnectionPayload = {
          id_connection: {
            isPartial: true,
            attributes: [
              {
                columnName: payload.classification_name,
                overrideSureMatch: payload.isOverrideSureMatch,
              },
            ],
          },
        };

        await updateIdConnection(payload.sources, idConnectionPayload);
      }

      await handleAssignCategoryAndUpdateGrid({
        categories,
        attribute,
        mode: ActionModeOnClassifier.update,
        sucessCallback: dataToUpdate => {
          updateGridItemById(payload._id, dataToUpdate);
          if (classifier?.isFromSaveChangesDialog) {
            setActiveClassifier(classifier);
            updateActiveClassifierRef(classifier);
          } else {
            setActiveClassifier({ ...payload, ...dataToUpdate });
            updateActiveClassifierRef({ ...payload, ...dataToUpdate });
          }
          analyticsService.trackManualEvent(ClassifiersEventsEnum.DOC_CLASSIFIER_UPDATED_SUCCESSFULLY, {
            classifier: { ...payload, ...dataToUpdate },
          });
        },
      });

      if (classifier?._id) {
        const fetchClassifier = await getClassifierById(classifier._id);
        updateActiveClassifierRef(fetchClassifier);
        setActiveClassifier(fetchClassifier);
      }

      setIsLoading(false);
      notificationService.success(t('classifierSavedSuccessfully'), { shouldAutoHide: true });
    } catch (error) {
      console.error(`An error has occurred: ${error.message}`);
      notificationService.error(t('failedToUpdate'));
    }
  };

  const onFormSubmit = async (classifier?: ClassifierGridRow & { isFromSaveChangesDialog?: boolean }) => {
    return formControlsRef.current?.validateAndSubmit(async (vals: FormValuesType) => {
      let payload = null;

      const {
        original_name,
        categories,
        attribute: { friendly_name, glossary_id },
      } = vals;

      const attribute: AssignAttributePayload = {
        glossary_id,
        friendly_name,
        original_name: original_name || getDefaultAttributeName(vals),
      };

      if (REGEX_FORM_TYPES.includes(vals.classifierType)) {
        payload = getRegexFormSubmitFields(vals);
      }

      if (vals.classifierType === CLASSIFIER_TYPES.NER) {
        payload = getNERFormSubmitFields(vals);
        return onCreateNerClassifier(payload, categories, attribute, vals._id, classifier);
      }

      if (vals.classifierType === CLASSIFIER_TYPES.CORRELATION) {
        return onUpdateCorrelation({ categories, attribute, classifier, payload: vals });
      }

      if (vals.classifierType === CLASSIFIER_TYPES.DOC) {
        setIsLoading(true);
        await handleAssignCategoryAndUpdateGrid({
          categories,
          attribute,
          mode: ActionModeOnClassifier.update,
          sucessCallback: dataToUpdate => {
            updateGridItemById(vals._id, dataToUpdate);
            if (classifier?.isFromSaveChangesDialog) {
              setActiveClassifier({ ...classifier });
              updateActiveClassifierRef({ ...classifier });
            } else {
              setActiveClassifier({ ...vals, ...dataToUpdate });
              updateActiveClassifierRef({ ...vals, ...dataToUpdate });
            }
            setIsLoading(false);
            analyticsService.trackManualEvent(ClassifiersEventsEnum.DOC_CLASSIFIER_UPDATED_SUCCESSFULLY, {
              classifier: { ...vals, ...dataToUpdate },
            });
            notificationService.success(t('classifierSavedSuccessfully'), { shouldAutoHide: true });
          },
        });

        if (classifier?._id) {
          const fetchClassifier = await getClassifierById(classifier._id);
          updateActiveClassifierRef(fetchClassifier);
          setActiveClassifier(fetchClassifier);
        }

        return;
      }

      payload = {
        ...(vals._id ? { _id: vals._id } : {}),
        ...payload,
      };

      // Exclude unnecessary fields from payload
      payload = omit(payload, ['friendly_name', 'original_name', 'categories', 'attribute']);
      const newPayload = omitBy(payload, isUndefined);

      return vals._id
        ? onUpdateClassifier({
            payload: newPayload as ClassifierGridRow,
            categories,
            attribute,
            gridClassifierData: vals,
            classifier,
          })
        : onCreateClassifier(payload as ClassifierGridRow, categories, attribute);
    });
  };

  return {
    onUpdateClassifier,
    onCreateClassifier,
    onFormSubmit,
    formControlsRef,
    isLoading,
  };
};
