import React, { useEffect, useRef, useState } from 'react';
import {
  BigidColorsV2,
  BigidDialog,
  BigidLoader,
  BigidTabs,
  BigidTabsItem,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { CorrelationDetailsTab } from '../../../../views/ClassifiersManagement/CorrelationForm/CorrelationDetailsTab';
import { CorrelationSetsTab } from '../../../../views/ClassifiersManagement/CorrelationForm/CorrelationSetsTab';
import { getClassifierById } from '../../../../views/ClassifiersManagement/services/classifiersManagementService';
import { ClassifierGridRow } from '../../../../views/ClassifiersManagement/types/ClassifierTypes';
import { styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { FormValuesType } from '../../types/CorrelationSetsTypes';
import { updateIdConnection } from '../../../../views/ClassifiersManagement/hooks/useFormActions';
import { assignCategoriesAndAttribute } from '../../../../views/ClassifiersManagement/ClassifiersActions';
import { notificationService } from '../../../../services/notificationService';
import { useLocalTranslation } from '../../translations';

const TabsContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabsContentContainer = styled('div')`
  flex: 1;
  overflow: scroll;
`;

const TabsContent = styled('div')`
  padding: 16px 0 36px 0;
`;

const ButtonSection = styled('div')`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${BigidColorsV2.gray[200]};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
`;

export interface CorrelationSetStatisticsDialogProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const DETAILS_TAB: BigidTabsItem = { label: 'Details', disabled: false };
const CORRELATION_SETS_TAB: BigidTabsItem = { label: 'Correlation Sets', disabled: false };
const TABS = [DETAILS_TAB, CORRELATION_SETS_TAB];

export const CorrelationSetStatisticsDialog = ({ title, isOpen, onClose, id }: CorrelationSetStatisticsDialogProps) => {
  const [classifier, setClassifier] = useState<ClassifierGridRow>();
  const [selectedTab, setSelectedTab] = useState(DETAILS_TAB);
  const formControlsRef = useRef<any>();
  const { t } = useLocalTranslation();

  useEffect(() => {
    if (id) {
      fetchClassifierById();
    }

    setSelectedTab(DETAILS_TAB);
  }, [id]);

  const fetchClassifierById = async () => {
    const classifierById = await getClassifierById(id);
    setClassifier(classifierById);
  };

  const handleClose = () => {
    setClassifier(null);
    onClose();
  };

  const onChangeTab = (_tabIndex: number, tab: { label: string }) => {
    setSelectedTab(tab);
  };

  const getSselectedTab = () => {
    return TABS.findIndex(tab => tab.label === selectedTab.label);
  };

  const onSave = () => {
    formControlsRef.current?.validateAndSubmit(async (vals: FormValuesType) => {
      const {
        original_name,
        categories,
        attribute: { friendly_name, glossary_id },
      } = vals;

      if (classifier?.isOverrideSureMatch !== vals.isOverrideSureMatch) {
        const idConnectionPayload = {
          id_connection: {
            isPartial: true,
            attributes: [
              {
                columnName: vals.classification_name,
                overrideSureMatch: vals.isOverrideSureMatch,
              },
            ],
          },
        };

        await updateIdConnection(vals.sources, idConnectionPayload);
      }

      await assignCategoriesAndAttribute(categories, original_name, friendly_name, glossary_id);
      notificationService.success(t('notifications.correlationSavedSuccessfully'), { shouldAutoHide: true });
    });
  };

  return (
    <BigidDialog isOpen={isOpen} onClose={handleClose} title={title} maxWidth="md" fixedHeight={700}>
      <TabsContainer>
        <div>
          <BigidTabs tabs={TABS} orientation="horizontal" selectedIndex={getSselectedTab()} onChange={onChangeTab} />
        </div>
        <TabsContentContainer>
          <TabsContent>
            {isEmpty(classifier) && <BigidLoader />}
            {selectedTab.label === CORRELATION_SETS_TAB.label && !isEmpty(classifier) && (
              <CorrelationSetsTab classifier={classifier} />
            )}
            {selectedTab.label === DETAILS_TAB.label && !isEmpty(classifier) && (
              <CorrelationDetailsTab key={classifier._id} initialData={classifier} formControlsRef={formControlsRef} />
            )}
          </TabsContent>
        </TabsContentContainer>
        <ButtonSection>
          <TertiaryButton onClick={handleClose} size="medium" text={t('buttons.cancel')} />
          <PrimaryButton onClick={onSave} size="medium" text={t('buttons.save')} />
        </ButtonSection>
      </TabsContainer>
    </BigidDialog>
  );
};
