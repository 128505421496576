import React from 'react';
import { Stack } from '@mui/material';
import {
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidAvatar,
  BigidFieldFilter,
  BigidStatusBadge,
  ToolbarAction,
} from '@bigid-ui/components';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { DataSourceIconByDsType } from '@bigid-ui/icons';

import { systemUsersService } from '../../../services/angularServices';
import { httpService } from '../../../services/httpService';

import { User } from '../types';
import { executionStateToStatusProps, getUserLabel } from '../helpers';
import { ProgressCell } from '../components/ProgressCell';
import { getFixedT } from '../translations';
import { ActivityLogItemModelRow, DataMinimizationExecutionState, RequestType } from './types';
import { uniqBy } from 'lodash';

const t = getFixedT('ActivityLogs');

export const searchConfig: BigidFieldFilter = {
  id: 'scans.data_source_name',
  field: 'scans.data_source_name',
  operator: 'textSearch',
  value: '',
};

export const filtersConfig: BigidAdvancedToolbarFilterUnion[] = [
  {
    id: 'scans.request_types',
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    title: t('type'),
    field: 'scans.request_types',
    operator: 'in',
    disabled: true,
    options: [],
    isSearchable: false,
    asyncOptionsFetch: async () => {
      return Object.values(RequestType).map(v => ({
        displayValue: v === RequestType.DSAR ? 'DSAR' : v,
        value: v,
        id: v,
      }));
    },
  },
  {
    id: 'scans.data_source_name',
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    title: t('dataSources'),
    field: 'scans.data_source_name',
    operator: 'in',
    disabled: true,
    options: [],
    isSearchable: true,
    asyncOptionsFetch: async (_, value = '') => {
      const { data } = await httpService.post<{ data: { ds_connections: Array<{ name: string }> } }>('ds-connections', {
        query: {
          fields: ['name'],
          filter: [{ field: 'name', value, operator: 'textSearch' }],
          limit: 10,
        },
      });

      return (
        data?.data?.ds_connections?.map(item => ({ displayValue: item.name, value: item.name, id: item.name })) || []
      );
    },
  },
  {
    id: 'user.id',
    title: t('executedBy'),
    field: 'user.id',
    operator: 'in',
    disabled: true,
    isSearchable: false,
    options: [],
    asyncOptionsFetch: async () => {
      const { data } = await systemUsersService.getAllSystemUsersByQuery();
      const users = data?.users as User[];

      return users?.map(user => ({ displayValue: getUserLabel(user), value: user.id, id: user.id })) || [];
    },
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
  },
  {
    id: 'start_date',
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    title: t('executionDate'),
    field: 'start_date',
    disabled: true,
    options: {
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
      },
    },
  } as BigidAdvancedToolbarDateRangeFilter,
  {
    id: 'state',
    title: t('status'),
    field: 'state',
    operator: 'in',
    disabled: true,
    isSearchable: false,
    options: [],
    asyncOptionsFetch: async () => {
      return Object.values(DataMinimizationExecutionState).map(v => {
        const props = executionStateToStatusProps(v as DataMinimizationExecutionState);
        return {
          displayValue: props.label,
          value: v,
          id: v,
        };
      });
    },
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
  },
];

export const actions: ToolbarAction[] = [];

export const gridColumnsConfig: BigidGridColumn<ActivityLogItemModelRow>[] = [
  {
    title: t('executionId'),
    name: 'id',
    width: 140,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ id }) => id,
  },
  {
    title: t('executionDate'),
    name: 'start_date',
    width: 160,
    type: BigidGridColumnTypes.DATE,
    sortingEnabled: true,
    getCellValue: ({ start_date }) => start_date,
  },
  {
    title: t('duration'),
    name: 'duration',
    width: 115,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ duration }) => duration,
  },
  {
    title: t('dataSources'),
    name: 'scans.data_source_name',
    width: 260,
    type: BigidGridColumnTypes.CHIPS,
    sortingEnabled: true,
    getCellValue: ({ scans }) => ({
      chips: {
        isEditMode: false,
        isDisabled: true,
        isAutoFit: true,
        value:
          scans?.map(scan => ({
            label: scan.data_source_name,
            icon: <DataSourceIconByDsType dataSourceType={scan.data_source_type} width={26} height={26} />,
            iconPlacement: 'left',
            variant: 'filled',
          })) || [],
      },
    }),
  },
  {
    title: t('status'),
    name: 'state',
    width: 120,
    type: BigidGridColumnTypes.CUSTOM,
    sortingEnabled: true,
    getCellValue: ({ status }) => status && <BigidStatusBadge {...status} />,
  },
  {
    title: t('deletionProgress'),
    name: 'parts_info_summary',
    width: 160,
    type: BigidGridColumnTypes.CUSTOM,
    sortingEnabled: false,
    getCellValue: ({ states }) => states && <ProgressCell states={states} />,
  },
  {
    title: t('executedBy'),
    name: 'user.id',
    width: 180,
    type: BigidGridColumnTypes.CUSTOM,
    sortingEnabled: true,
    getCellValue: ({ user }) =>
      user && (
        <Stack direction="row" alignItems="center" gap={1}>
          <BigidAvatar
            color={user?.origin === 'legacy' ? 'light_gray' : undefined}
            size="small"
            userName={typeof user === 'string' ? user : getUserLabel(user)}
          />
          {typeof user === 'string' ? user : getUserLabel(user)}
        </Stack>
      ),
  },
  {
    title: t('type'),
    name: 'scans.request_types',
    width: 200,
    type: BigidGridColumnTypes.CHIPS,
    sortingEnabled: true,
    getCellValue: ({ scans }) => ({
      chips: {
        isEditMode: false,
        isDisabled: true,
        isAutoFit: true,
        value: uniqBy(
          scans?.flatMap(
            scan =>
              scan?.request_types?.map(type => ({
                label: RequestType.DSAR === type ? 'DSAR' : type,
                variant: 'filled',
              })) || [],
          ) || [],
          'label',
        ),
      },
    }),
  },
];
