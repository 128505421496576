import React from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidFormRenderProps,
  BigidHeading6,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { WizardFormFields } from '../../../types/CorrelationSetsTypes';

const BigidAccordionSummaryContainer = styled(BigidAccordionSummary)`
  padding: 0 !important;
`;

const BigidAccordionDetailsContainer = styled(BigidAccordionDetails)`
  padding: 0 0 16px 3px !important;

  fieldset:last-child {
    margin-bottom: 0;
  }
`;

const FormGroupBox = styled('div')`
  margin-bottom: 16px;
  fieldset {
    margin: 0 0 6px;
  }
`;

interface AdvancedOptionsProps {
  renderField: BigidFormRenderProps['renderField'];
  isUseSampleSelected: boolean;
}

export const AdvancedOptions = ({ renderField, isUseSampleSelected }: AdvancedOptionsProps) => {
  const { t } = useLocalTranslation('wizard.detailsStep');

  return (
    <BigidAccordion
      dataAid={generateDataAid('BigidAccordion', ['advanced-options'])}
      hasBorder={false}
      hasShadow={false}
    >
      <BigidAccordionSummaryContainer size={AccordionSummarySizeEnum.medium}>
        <BigidHeading6>{t('advancedOptionsTitle')}</BigidHeading6>
      </BigidAccordionSummaryContainer>
      <BigidAccordionDetailsContainer>
        <FormGroupBox>
          {renderField(WizardFormFields.identities_force_sample)}
          {renderField(WizardFormFields.identities_sample_size)}
        </FormGroupBox>

        {isUseSampleSelected && (
          <div>
            <FormGroupBox>
              {renderField(WizardFormFields.skip_sample_start)}
              {renderField(WizardFormFields.skip_sample_size)}
            </FormGroupBox>
            {renderField(WizardFormFields.use_random_sampling)}
          </div>
        )}
      </BigidAccordionDetailsContainer>
    </BigidAccordion>
  );
};
