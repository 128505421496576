import { ScanCreateData } from './createScanContext';
import {
  CreateScanWizardSteps,
  DEFAULT_STEPS_WITH_SCHEDULING_BUTTONS,
  CREATE_SCAN_TEMPLATE_STEPS,
  STEPS_BEFORE_CREATE_SCAN_TEMPLATE,
  STEPS_TO_HIDE_CREATE_SCAN_TEMPLATE,
  OnClickScanParams,
} from './ScanCreateWizardTypes';
import { ScanTemplate, ScanTypes } from '../ScanTemplates/scanTemplateTypes';
import React from 'react';
import { BigidWizardHorizontalStepStatus, HoriztalWizardStepAttr, UseHorizontalWizard } from '@bigid-ui/components';
import { ScanTemplateWizardSteps } from '../ScanTemplates/ScanTemplateWizard/ScanTemplateWizard';
import { checkClassificationAndCorrelationByScanTypes } from './hooks/useUpdateSupportedScanTypesBySelectedDs';
import { notificationService } from '../../../services/notificationService';
import { fetchTemplate, handleSaveScan } from './createScanService';
import { runScanProfileAndNavigateToActiveScans } from '../ScanUtils';
import { ParentScanType } from '../ScanTypes';
import { isPermitted } from '../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';

export const isMissingField = (state: ScanCreateData) => {
  const {
    name,
    templateIds,
    classificationRequired,
    correlationRequired,
    scanTemplateCreateInWizard,
    isTemplateCreate,
  } = state;

  return (
    !name ||
    (isTemplateCreate
      ? (correlationRequired && !(scanTemplateCreateInWizard?.idConnectionList?.length > 0)) ||
        (classificationRequired && !(scanTemplateCreateInWizard?.classifiers?.length > 0))
      : !templateIds?.length)
  );
};

export const isCorrelationSelected = (scanTemplateData: ScanTemplate) =>
  !!scanTemplateData.idConnectionList?.length || scanTemplateData.allEnabledIdSor;

export const isNextCreateScanStepDisabled = (
  currentStep: CreateScanWizardSteps,
  state: ScanCreateData,
  scanTemplateData: ScanTemplate,
) => {
  switch (currentStep) {
    case CreateScanWizardSteps.SCAN_BASIC_DETAILS:
      return !state?.name;
    case CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS:
      return !Boolean(scanTemplateData?.name && scanTemplateData?.scanType);
    case CreateScanWizardSteps.SELECT_TEMPLATE:
      return state.templateIds.length !== 1 || !isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name);
    case CreateScanWizardSteps.CORRELATION:
      return state.correlationRequired ? !isCorrelationSelected(scanTemplateData) : false;
    case CreateScanWizardSteps.CLASSIFICATION:
      const { classificationRequired } = checkClassificationAndCorrelationByScanTypes([
        state?.scanTemplateCreateInWizard?.scanType as ScanTypes,
      ]);
      const isClassifierChosen = scanTemplateData?.isUseGlobalClassifiers || scanTemplateData?.classifiers?.length > 0;
      return classificationRequired ? !isClassifierChosen : false;
    default:
      return false;
  }
};

export const isSaveScanStepDisabled = (
  currentStep: CreateScanWizardSteps,
  state: ScanCreateData,
  scanTemplateData: ScanTemplate,
) => {
  switch (currentStep) {
    case CreateScanWizardSteps.SCAN_BASIC_DETAILS:
    case CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS:
    case CreateScanWizardSteps.CORRELATION:
    case CreateScanWizardSteps.CLASSIFICATION:
      return isNextCreateScanStepDisabled(currentStep, state, scanTemplateData);
    case CreateScanWizardSteps.SELECT_TEMPLATE:
      return state.templateIds.length !== 1 || !isPermitted(SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name);
    default:
      return false;
  }
};

export const validateFormStep = (stateAndHandlersRef: React.MutableRefObject<any>): Promise<boolean> => {
  return new Promise(resolve =>
    stateAndHandlersRef.current?.validateAndSubmit(
      () => resolve(true),
      () => resolve(false),
    ),
  );
};

export const showStepByData = ({
  stepId,
  isTemplateCreate,
  dataSourcesPreselected,
}: {
  stepId: string;
  isTemplateCreate: boolean;
  dataSourcesPreselected: string[];
}) => {
  switch (stepId) {
    case CreateScanWizardSteps.DATA_SOURCE:
      return !dataSourcesPreselected?.length;
    case CreateScanWizardSteps.SELECT_TEMPLATE:
      return !isTemplateCreate;
    case CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS:
    case CreateScanWizardSteps.CLASSIFICATION:
    case CreateScanWizardSteps.CORRELATION:
      return isTemplateCreate;
    default:
      return true;
  }
};

export const getStepStatus = (stepId: CreateScanWizardSteps, next?: boolean, prev?: boolean) => {
  if (next) {
    return BigidWizardHorizontalStepStatus.DONE;
  }
  if (prev) {
    return BigidWizardHorizontalStepStatus.DEFAULT;
  }
  return BigidWizardHorizontalStepStatus.ACTIVE;
};

export const getStepAttribute = (
  wizardStep: ScanTemplateWizardSteps | CreateScanWizardSteps,
  disable?: boolean,
): HoriztalWizardStepAttr => ({
  stepId: wizardStep,
  attr: disable
    ? {
        status: BigidWizardHorizontalStepStatus.DISABLED,
        tooltipText: `The scan type chosen does not support ${wizardStep.toLowerCase()}`,
      }
    : { status: BigidWizardHorizontalStepStatus.DEFAULT, tooltipText: null },
});

interface ShouldDisableSteps {
  shouldDisableClassificationsStep: boolean;
  shouldDisableCorrelationStep: boolean;
}
const shouldDisableSteps = (scanType: ScanTypes): ShouldDisableSteps => {
  const { classificationSupported, correlationSupported } = checkClassificationAndCorrelationByScanTypes([scanType]);
  const shouldDisableClassificationsStep = !classificationSupported;
  const shouldDisableCorrelationStep = !correlationSupported;
  return { shouldDisableClassificationsStep, shouldDisableCorrelationStep };
};

export const disableCorrelationAndClassificationStepsIfNecessary = (
  scanType: ScanTypes,
  updateStepAttributes: UseHorizontalWizard['updateStepAttributes'],
  classificationStepId: CreateScanWizardSteps | ScanTemplateWizardSteps,
  correlationStepId: CreateScanWizardSteps | ScanTemplateWizardSteps,
) => {
  const { shouldDisableClassificationsStep, shouldDisableCorrelationStep } = shouldDisableSteps(scanType);
  updateStepAttributes([
    getStepAttribute(classificationStepId, shouldDisableClassificationsStep),
    getStepAttribute(correlationStepId, shouldDisableCorrelationStep),
  ]);
};

export const getStepsWithSchedulingButtons = (
  isTemplateCreate: boolean,
  scanType?: ScanTypes,
): CreateScanWizardSteps[] => {
  if (!isTemplateCreate) {
    return DEFAULT_STEPS_WITH_SCHEDULING_BUTTONS;
  }

  const { shouldDisableClassificationsStep, shouldDisableCorrelationStep } = shouldDisableSteps(scanType);

  if (shouldDisableCorrelationStep) {
    if (shouldDisableClassificationsStep) {
      return [...DEFAULT_STEPS_WITH_SCHEDULING_BUTTONS, CreateScanWizardSteps.TEMPLATE_BASIC_DETAILS];
    }
    return [...DEFAULT_STEPS_WITH_SCHEDULING_BUTTONS, CreateScanWizardSteps.CLASSIFICATION];
  }

  return [...DEFAULT_STEPS_WITH_SCHEDULING_BUTTONS, CreateScanWizardSteps.CORRELATION];
};

export const getStepAttributesToUpdateOnCreateTemplateClick = (): HoriztalWizardStepAttr[] => [
  ...CREATE_SCAN_TEMPLATE_STEPS.map(stepId => ({ stepId, attr: { isHidden: false } })),
  ...STEPS_BEFORE_CREATE_SCAN_TEMPLATE.map(stepId => ({
    stepId,
    attr: { status: BigidWizardHorizontalStepStatus.DONE },
  })),
  ...STEPS_TO_HIDE_CREATE_SCAN_TEMPLATE.map(stepId => ({ stepId, attr: { isHidden: true } })),
];

export const getStepAttributesToUpdateOnCreateTemplateCanceled = (): HoriztalWizardStepAttr[] => [
  ...CREATE_SCAN_TEMPLATE_STEPS.map(stepId => ({
    stepId,
    attr: { isHidden: true, status: BigidWizardHorizontalStepStatus.DEFAULT },
  })),
  ...STEPS_TO_HIDE_CREATE_SCAN_TEMPLATE.map(stepId => ({ stepId, attr: { isHidden: false } })),
];

interface HandleCreateScanProps {
  createScanData: ScanCreateData;
  isSingleRunScan?: boolean;
  isScheduled?: boolean;
  setIsLoadingStep?: (state: boolean) => void;
}

export const handleCreateScan = async ({
  createScanData: scanToSave,
  isSingleRunScan,
  isScheduled,
  setIsLoadingStep,
}: HandleCreateScanProps) => {
  if (isMissingField(scanToSave)) {
    return notificationService.warning('Missing required fields, please fill in the rest of the form');
  }

  return handleSaveScan(scanToSave, isSingleRunScan, setIsLoadingStep);
};

export const onClickSaveScan = (createScanData: ScanCreateData) => {
  return handleCreateScan({ createScanData, isScheduled: createScanData.enabled });
};

export const onClickScheduleScan = (createScanData: ScanCreateData, setIsLoadingStep: (state: boolean) => void) => {
  return handleCreateScan({ createScanData, isScheduled: true, setIsLoadingStep });
};

export const onClickScanNow = async ({ createScanData, isEditMode, name }: OnClickScanParams) => {
  const saveScanResponse = await handleCreateScan({ createScanData, isSingleRunScan: !isEditMode });

  if (isEditMode) {
    const scanTemplate = await fetchTemplate(createScanData.templateIds[0]);
    await runScanProfileAndNavigateToActiveScans({
      scanProfileName: name,
      scanType: scanTemplate.scanType as ParentScanType,
    });
  } else {
    if (!saveScanResponse.scanProfile) {
      return;
    }

    const { scanProfile } = saveScanResponse;
    const scanTemplate = await fetchTemplate(scanProfile?.scanTemplateId || createScanData.templateIds[0]);
    await runScanProfileAndNavigateToActiveScans({
      scanProfileName: scanProfile?.name || createScanData.name,
      scanType: scanTemplate.scanType as ParentScanType,
    });
  }
};
