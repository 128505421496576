import { module } from 'angular';
const app = module('app');
import './dlpConnections.component.scss';
import template from './dlpConnections.component.html';
import { SCANS_PERMISSIONS, SECONDARY_SOURCE_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';

app.component('dlpConnections', {
  template,
  controllerAs: 'dlpConnectionsModel',
  controller: function (
    dlpConnectionsService,
    $scope,
    $state,
    $rootScope,
    scansService,
    notificationService,
    DeleteConfirmation,
  ) {
    'ngInject';

    const dlpConnectionsModel = this;

    const isEditPermitted = isPermitted(SECONDARY_SOURCE_PERMISSIONS.EDIT.name);
    const isDeletePermitted = isPermitted(SECONDARY_SOURCE_PERMISSIONS.DELETE.name);

    const EDIT_SECONDARY_SOURCE_TEMPLATE = `
      <button
        class="btn primary btnAction btnActionsEdit"
        ng-click="grid.appScope.edit(row.entity.name)"
        ng-if="grid.appScope.isReadPermitted"
      >
        Edit
      </button>
    `;
    const DELETE_SECONDARY_SOURCE_TEMPLATE = `
      <button
        class="btn primary btnActionsDelete"
        ng-click="grid.appScope.delete(row.entity.name)"
        ng-if="grid.appScope.isDeletePermitted"
      >
        Delete
      </button>
    `;

    const getActionsCellTemplate = () =>
      `<div>${isEditPermitted ? EDIT_SECONDARY_SOURCE_TEMPLATE : ''}${
        isDeletePermitted ? DELETE_SECONDARY_SOURCE_TEMPLATE : ''
      }</div>`;

    $rootScope.$broadcast('changePage', 'Secondary Sources', false);

    dlpConnectionsModel.grid = {
      enableSorting: true,
      enablePaginationControls: false,
      columnDefs: [
        { name: 'Name', field: 'name', enableCellEdit: $scope.isEditPermitted },
        { name: 'Type', field: 'type', enableCellEdit: $scope.isEditPermitted },
        { name: 'Target', displayName: 'Target', field: 'targetHost', enableCellEdit: $scope.isEditPermitted },
        { name: 'User Name', displayName: 'User Name', field: 'user_name', enableCellEdit: $scope.isEditPermitted },
        {
          name: 'Enabled',
          field: 'enabled',
          displayName: 'Enabled',
          editableCellTemplate: 'ui-grid/dropdownEditor',
          width: '10%',
          editDropdownValueLabel: 'enabled',
          editDropdownOptionsArray: [
            { id: 'yes', enabled: 'yes' },
            { id: 'no', enabled: 'no' },
          ],
          enableCellEdit: $scope.isEditPermitted,
        },
        {
          name: 'Actions',
          field: '',
          enableCellEdit: $scope.isEditPermitted,
          cellTemplate: getActionsCellTemplate(),
        },
      ],
    };

    const listenerOnRegisterApi = $rootScope.$on('registerApi', function (event, data) {
      dlpConnectionsModel.gridApi = data;
      data.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue) {
        if (colDef.name === 'Enabled') {
          updateDLPConnections(rowEntity, newValue);
        }
        dlpConnectionsModel.$apply();
      });
    });

    $scope.$on('$destroy', function () {
      listenerOnRegisterApi();
    });

    dlpConnectionsModel.grid.onRegisterApi = function (gridApi) {
      $scope.gridApi = gridApi;
      gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
        if (colDef.name === 'Enabled') {
          updateDLPConnections(rowEntity, newValue);
        }
        dlpConnectionsModel.$apply();
      });
    };

    function updateDLPConnections(rowEntity, newValue) {
      dlpConnectionsModel.dlp_connection = {};
      dlpConnectionsModel.dlp_connection.dlp_connection = {};
      dlpConnectionsModel.dlp_connection.dlp_connection.enabled = newValue;
      dlpConnectionsService.updateDLPConnectionsDataByID(dlpConnectionsModel.dlp_connection, rowEntity.id);
    }

    $scope.edit = function (name) {
      $state.go('newDLPConnection', { name: name });
    };

    $scope.delete = function (name) {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete DLP source connection',
        bodyText: 'Are you sure you want to delete ' + name + '?',
      };

      DeleteConfirmation.showModal({}, modalOptions).then(function () {
        dlpConnectionsService.deleteDLPConnectionsDataByID(name).then(
          function () {
            getDLPConnectionsData();
            notificationService.success(name + ' Deleted Successfully! ');
          },
          function () {
            notificationService.error('Delete ' + name + ' Failed! ');
          },
        );
      });
    };

    dlpConnectionsModel.$onInit = function () {
      dlpConnectionsModel.isCreatePermitted = isPermitted(SECONDARY_SOURCE_PERMISSIONS.CREATE.name);
      dlpConnectionsModel.isRunScanPermitted = isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name);
      $scope.isEditPermitted = isPermitted(SECONDARY_SOURCE_PERMISSIONS.EDIT.name);
      $scope.isDeletePermitted = isPermitted(SECONDARY_SOURCE_PERMISSIONS.DELETE.name);
      $scope.isReadPermitted = isPermitted(SECONDARY_SOURCE_PERMISSIONS.READ.name);

      getDLPConnectionsData();
    };

    dlpConnectionsModel.runScans = function () {
      $scope.scanButtonDisabled = true;
      scansService.runScan({ scanType: 'dlpScan' }).then(function () {
        $scope.scanButtonDisabled = false;
        notificationService.success('DLP Scan Started Successfully! ');
        getDLPConnectionsData();
      });
    };

    function getDLPConnectionsData(filterValue) {
      dlpConnectionsService.getDLPConnectionsData(filterValue).then(function (result) {
        const results = result.dlp_connections;
        dlpConnectionsModel.grid.data = results;
      });
    }
  },
});
