import React, { FC, useMemo } from 'react';
import {
  BigidAdvancedToolbarFilterUnion,
  BigidWidgetContainer,
  BigidBody1,
  BigidWidgetListItemProps,
  BigidColorsV2,
  BigidCategoryColorIndicator,
} from '@bigid-ui/components';
import { BigidLineChartV2DataItem } from '@bigid-ui/visualisation';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import { Menu, WidgetListWithTitle } from './helpers/HelperComponents';
import { RiskWidgetWrapper } from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';

export interface DataRiskTypesWidgetProps {
  title: string;
}

const CATEGORY_COLOR_INDICATOR_COLORS = [
  BigidColorsV2.purple[500],
  BigidColorsV2.magenta[300],
  BigidColorsV2.cyan[300],
  BigidColorsV2.blue[300],
  BigidColorsV2.yellow[300],
];

export const DataRiskTypesWidget: FC<DataRiskTypesWidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading } = useFetchWidgetData(fetchDataRiskTypesWidgetData);
  const widgetListItems: BigidWidgetListItemProps[] = useMemo(() => {
    return (data || []).slice(0, 5).map((item, i) => ({
      description: item.category,
      descriptionIcon: <BigidCategoryColorIndicator color={CATEGORY_COLOR_INDICATOR_COLORS[i]} />,
      value: item.cases,
    }));
  }, [data]);

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <RiskWidgetWrapper>
      <BigidWidgetContainer
        content={
          <WidgetListWithTitle
            items={widgetListItems}
            headerTitleOne={t('dataRiskTypes.headerType')}
            headerTitleTwo={t('dataRiskTypes.headerOpenCases')}
          />
        }
        titleConfig={{ title }}
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: data?.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{t('emptyState.defaultText')}</BigidBody1>,
        }}
        actionsSlot={<Menu menuItems={menuItems} />}
      />
    </RiskWidgetWrapper>
  );
};

const fetchDataRiskTypesWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<BigidLineChartV2DataItem[]> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 3000, data);
  });
};

const data = [
  {
    category: 'AI',
    cases: 56,
  },
  {
    category: 'Vendor management',
    cases: 11,
  },
  {
    category: 'Profiling',
    cases: 67,
  },
  {
    category: 'Transfer',
    cases: 56,
  },
  {
    category: 'Data lifecycle management',
    cases: 7,
  },
  {
    category: 'Should not be shown category',
    cases: 1,
  },
];
