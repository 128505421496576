import React, { FC, useMemo } from 'react';
import {
  BigidFieldFilter,
  BigidFilterToolbar,
  BigidPaper,
  BigidToolbarRowDescriptionFn,
  objectToQueryString,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidHotspotsChartFieldSettings, BigidLayout, BigidLayoutConfig, LayoutContentType } from '@bigid-ui/layout';
import { downloadHotspotsReport, getHotspotsCounters, Hotspot, HotspotChartRow } from './HotspotsReportChartService';
import { BigidDownloadIcon } from '@bigid-ui/icons';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { notificationService } from '../../../services/notificationService';
import { $window } from 'ngimport';
import { getTagsAllPairs } from '../../TagsManagement/TagsManagementService';
import { attachTagsBulk, createAndAttachTagsBulk } from '../../DataCatalog/DataCatalogService';
import { HotspotsReportChartControls } from './HotspotsReportChartControls';
import {
  datasourcesChanged,
  getOpenInCatalogFilterString,
  hotspotToHotspotChartRow,
  insertLineBreakEveryNCharacters,
  parseFieldFiltersToSearchQuery,
} from './utils';
import { orderBy } from 'lodash';
import { HotspotsContainer } from './HotspotsContainer';

const gridConfig: BigidGridProps<HotspotChartRow> = {
  showSortingControls: true,
  showSelectionColumn: true,
  columns: [
    {
      title: 'Hotspot Name',
      name: 'hotspotName',
      isListColumn: true,
      type: BigidGridColumnTypes.TEXT,
      width: 600,
      getCellValue: ({ hotspotName }) => hotspotName,
    },
    {
      title: 'Total attributes and tags',
      name: 'totalAttrTag',
      type: BigidGridColumnTypes.NUMBER,
      width: 100,
      getCellValue: ({ totalAttrTag }) => totalAttrTag,
      sortingEnabled: true,
    },
    {
      title: 'Total files in folder',
      name: 'totalFilesInFolder',
      type: BigidGridColumnTypes.NUMBER,
      width: 100,
      getCellValue: ({ totalFilesInFolder }) => totalFilesInFolder,
    },
    {
      title: 'Total density',
      name: 'totalDensity',
      type: BigidGridColumnTypes.NUMBER,
      width: 100,
      getCellValue: ({ totalDensity }) => totalDensity,
      sortingEnabled: true,
    },
    {
      title: 'Attributes',
      name: 'attributes',
      width: 500,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ attributes }) => attributes?.join(', ') || '',
    },
    {
      title: 'Tags',
      name: 'tags',
      width: 500,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: ({ tags }) => tags?.join(', ') || '',
    },
  ],
};

export const hotspotsChartEntityFields = [
  {
    value: 'totalAttrTag',
    label: 'Attributes and tags',
  },
  {
    value: 'totalFilesInFolder',
    label: 'Files in folder ',
  },
  {
    value: 'totalDensity',
    label: 'Density',
  },
];

const REMEDIATION_TAG_NAME = 'hotspot_remediation';

const getToolbarActions = (scanResultId: string): ToolbarAction[] => [
  {
    label: 'Actions',
    isGlobal: false,
    type: ToolbarActionType.DROPDOWN,
    dropdownProps: {
      placeholder: 'Actions',
      options: [
        {
          label: 'Deselect All',
          value: 'deselectAll',
          show: ({ selectedRowIds }) => selectedRowIds.length > 1,
          execute: async () => {
            return { shouldClearSelection: true };
          },
          isInline: true,
        },
        {
          label: 'Open in Catalog',
          value: 'openInCatalog',
          show: ({ selectedRowIds }) => selectedRowIds.length > 0,
          execute: async ({ selectedRows }) => {
            const filter = getOpenInCatalogFilterString(selectedRows);
            const url = $state.href(CONFIG.states.CATALOG, {
              filter,
            });
            $window.open(url, '_blank');
            return {};
          },
        },
        {
          label: 'Send to Remediation',
          value: 'sendToRemediation',
          show: ({ selectedRowIds }) => selectedRowIds.length > 0,
          execute: async ({ selectedRows }) => {
            try {
              const filter = getOpenInCatalogFilterString(selectedRows);
              const allTags = await getTagsAllPairs();
              const remediationTag = allTags.find(v => v.tagName === REMEDIATION_TAG_NAME);
              if (remediationTag) {
                await attachTagsBulk(allTags, [{ name: REMEDIATION_TAG_NAME, value: 'true' }], filter);
              } else {
                await createAndAttachTagsBulk(
                  allTags,
                  [{ name: REMEDIATION_TAG_NAME, value: 'true', isNew: true }],
                  filter,
                );
              }
              notificationService.success('Sent to remediation');
              return { shouldGridReload: true };
            } catch (err) {
              console.error(err);
              notificationService.error('Sending to remediation failed');
            }

            return {};
          },
        },
      ],
      clearOnSelect: true,
    },
    execute: async () => {
      return {};
    },
    disable: () => {
      return false;
    },
    show: ({ selectedRowIds }) => selectedRowIds.length > 0,
  },
  {
    label: 'Download report',
    tooltip: 'Download report',
    isGlobal: false,
    type: ToolbarActionType.ACTION_ICON,
    icon: BigidDownloadIcon,
    placement: 'end',
    execute: async () => {
      try {
        await downloadHotspotsReport(scanResultId);
        return {
          shouldGridReload: false,
        };
      } catch ({ message }) {
        notificationService.error('Report could not be downloaded ');
        console.error(`An error has occurred: ${message}`);
        return {
          shouldGridReload: false,
        };
      }
    },
    disable: () => {
      return false;
    },
    show: ({}) => true,
  },
];

export const getCustomRowDescription: BigidToolbarRowDescriptionFn = ({
  isFetchingCount,
  limit,
  allSelected,
  selectedRowIds,
  selectedItem,
  entityName,
  totalRows,
  rowClickShouldKeepSelection,
  currentView,
  customViewProp,
}) => {
  const hotspotsChartViewSize = (customViewProp as BigidHotspotsChartFieldSettings)?.hotspotsChartViewSize;
  const formatNumber = (number: number) => {
    const { format } = new Intl.NumberFormat();
    return format(number || 0);
  };

  if (isFetchingCount) {
    return `${totalRows < limit ? formatNumber(totalRows) : formatNumber(limit)} ${
      entityName ? (typeof entityName === 'string' ? entityName : entityName.plural) : 'rows'
    } of many (calculating...)`;
  }

  const selected =
    currentView === LayoutContentType.HOTSPOTS_CHART
      ? (hotspotsChartViewSize <= totalRows ? hotspotsChartViewSize : totalRows) + '/'
      : '';
  const totalRowsText = formatNumber(totalRows);

  if (typeof entityName === 'string') {
    const shouldShowRowsCounter = rowClickShouldKeepSelection
      ? selectedRowIds.length === 0
      : selectedRowIds.length === 0 || !!selectedItem;
    return shouldShowRowsCounter
      ? `${selected}${totalRowsText} ${entityName ? entityName : 'rows'}`
      : `${allSelected ? totalRowsText : selectedRowIds.length} ${entityName ? entityName : ''} selected`;
  }

  if (allSelected) {
    return `${totalRowsText} ${entityName.all || 'rows selected'}`;
  } else if (selectedRowIds.length === 0) {
    return `${totalRowsText} ${entityName.none || 'rows'}`;
  } else if (selectedRowIds.length === 1) {
    return `1 ${entityName.single || 'row selected'}`;
  } else if (selectedRowIds.length > 1) {
    return `${selectedRowIds.length} ${entityName.plural || 'rows selected'}`;
  }
  return '';
};

export const HotspotsReportsChart: FC<{
  filterToolbarConfig: BigidGridWithToolbarProps<HotspotChartRow>['filterToolbarConfig'];
  onDatasourcesFiltersChange: (currentFilters: BigidFieldFilter) => void;
  scanResultId: string;
}> = ({ filterToolbarConfig, onDatasourcesFiltersChange, scanResultId }) => {
  const { savedChartFieldSettings } = HotspotsContainer.useContainer();
  const layoutConfig: BigidLayoutConfig = useMemo(
    () => ({
      content: {
        entityName: 'hotspots',
        contentTypes: [LayoutContentType.GRID, LayoutContentType.HOTSPOTS_CHART],
        defaultContentType: LayoutContentType.HOTSPOTS_CHART,
        defaultSorting: [{ field: 'totalAttrTag', order: 'desc' }],
        toolbarActions: getToolbarActions(scanResultId),
        customToolbarComponent: <HotspotsReportChartControls />,
        viewConfig: {
          fetchGridData: async ({ filter, sort }) => {
            const filtersWithoutDatasource = filter.filter(f => f.field !== 'datasources'); // backend does not filter by datasource
            const selectedAttributes = (filter?.find(f => f.field === 'attributes')?.value as string[]) ?? [];
            const selectedTags = (filter?.find(f => f.field === 'tags')?.value as string[]) ?? [];
            const parsedFilter = parseFieldFiltersToSearchQuery(filtersWithoutDatasource, true, 'OR');
            const query = objectToQueryString({
              filter: parsedFilter,
              order: sort?.[0]?.field === 'totalDensity' ? 'density' : 'count',
              limit: 2000,
            });
            const hotspots: Hotspot[] = await getHotspotsCounters(scanResultId, query);
            const hotspotChartRows: HotspotChartRow[] = hotspots.map(hotspot => hotspotToHotspotChartRow(hotspot));
            const data: HotspotChartRow[] = hotspotChartRows.map(hotspotChartRow => ({
              ...hotspotChartRow,
              selectedAttributes,
              selectedTags,
            }));

            const sortedOnTheFrontend = orderBy(data, sort?.[0]?.field, sort?.[0]?.order);
            return {
              data: sortedOnTheFrontend,
              totalCount: data.length,
            };
          },
          gridConfig,
          toolbarConfig: {
            hideColumnChooser: true,
            hideToolbar: false,
          },
          hotspotsChartConfig: {
            paddingInner: 4,
            showOnlyOneMenu: true,
            height: 579,
            tooltipText: ({ row }) => {
              return (
                insertLineBreakEveryNCharacters(row['hotspotName'], 30) +
                `\n` +
                `Total attributes and tags: ${row['totalAttrTag']}\n` +
                `Total files in folder: ${row['totalFilesInFolder']}\n` +
                `Density: ${row['totalDensity']}`
              );
            },
            textTop: ({ row }) => row['hotspotName'],
            textBottom: ({ row, hotspotsChartBoxSizeField }) =>
              hotspotsChartEntityFields.find(field => field.value === hotspotsChartBoxSizeField).label +
              ': ' +
              row[hotspotsChartBoxSizeField],
            fields: hotspotsChartEntityFields,
            ...savedChartFieldSettings,
          },
          filterToolbarConfig,
        },
        getCustomRowDescription,
      },
    }),
    [savedChartFieldSettings, filterToolbarConfig, onDatasourcesFiltersChange, scanResultId],
  );

  return (
    <BigidPaper>
      <BigidLayout config={layoutConfig} />
    </BigidPaper>
  );
};
