import { BigidColors, BigidTooltip } from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import {
  BigidLayout,
  BigidLayoutConfig,
  BigidMasterDetailsContentProps,
  LayoutContentType,
  LayoutTitleTabsConfig,
} from '@bigid-ui/layout';
import makeStyles from '@mui/styles/makeStyles';
import { escapeRegExp } from 'lodash';
import React, { FC, Fragment, ReactNode, useEffect } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state, commonMethods } from '../../services/angularServices';
import { ClassificationWidgets } from './ClassificationWidgets';
import { getNormalizedClassifiers, NormalizedClassifier, NormalizedClassifierType } from './ClassifiersService';

const PAGE_TITLE = 'Classifications And Findings';
const WIDTH = 250;

const useStyles = makeStyles({
  nameCell: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalFindings: {
    padding: '0 5px',
  },
  name: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  tooltipTitle: {
    color: BigidColors.gray[700],
    borderBottom: `1px solid ${BigidColors.borderLight}`,
    paddingBottom: 5,
  },
  tooltipList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  tooltipListItem: {
    color: BigidColors.gray[600],
  },
});

const ClassifierListItemCell: FC<NormalizedClassifier> = ({ name, originalNames, totalFindings = 0 }) => {
  const classes = useStyles({});
  const { format } = new Intl.NumberFormat();

  const getTooltipList = (items: string[]): ReactNode => {
    return (
      <Fragment>
        <p className={classes.tooltipTitle}>Original Name</p>
        <ul className={classes.tooltipList}>
          {items.map((item, index) => (
            <li key={index} className={classes.tooltipListItem}>
              {item}
            </li>
          ))}
        </ul>
      </Fragment>
    );
  };

  return (
    <BigidTooltip title={getTooltipList(originalNames || [name])} width={'200px'}>
      <div className={classes.nameCell}>
        <span className={classes.name}>{name}</span>
        <span className={classes.totalFindings}>{format(totalFindings)}</span>
      </div>
    </BigidTooltip>
  );
};
const getSelectedItemTitle = (classifier: NormalizedClassifier) => {
  const { name, totalFindings } = classifier;
  return `${name} ${
    totalFindings ? `- ${commonMethods.getAbbrNum(totalFindings, 1)} Personal Data Records Found` : ''
  }`;
};

export const Classification: FC = () => {
  const resultsCacheRef = React.useRef<NormalizedClassifier[]>(null);

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    isPersistentListMode: true,
    tabsAndContent: {
      hideTabs: true,
      tabProps: {
        selectedIndex: 0,
        tabs: [
          {
            label: 'Details',
            data: {
              component: ClassificationWidgets,
            },
          },
        ],
      },
    },
  };

  const isRowDisabled = (row: NormalizedClassifier) => !(row && row.totalFindings > 0);

  const fetchGridData: FetchDataFunction<NormalizedClassifier> = async ({ filter }, customData) => {
    const types: NormalizedClassifierType[] = (customData?.titleTab?.selectedTab?.data as any)?.types;

    if (!resultsCacheRef.current) {
      resultsCacheRef.current = (await getNormalizedClassifiers('filterClassificationRegex=true')).classifiers;
    }

    const results = resultsCacheRef.current;

    const classifiers = results
      .filter(({ type }) => types.includes(type))
      .sort(({ totalFindings: a }, { totalFindings: b }) => b - a)
      .map(classifier => ({
        ...classifier,
        selectedItemTitle: getSelectedItemTitle(classifier),
      }))
      .filter(({ name, originalNames = [] }) => {
        const searchValue = filter?.[0]?.value;
        //TODO: handle searchValue as an array here. Type BigidFieldFilter change is required
        const matchRegex = new RegExp(escapeRegExp(searchValue as string), 'i');
        return searchValue
          ? matchRegex.test(name) || originalNames.some(originalName => matchRegex.test(originalName))
          : true;
      });

    return {
      totalCount: classifiers.length,
      data: classifiers,
    };
  };

  const gridConfig: BigidGridProps<NormalizedClassifier> = {
    isRowDisabled,
    pageSize: 5000,
    columns: [
      {
        width: WIDTH,
        title: 'Classifier Name',
        name: 'objectName',
        isListColumn: true,
        disableTooltip: true,
        type: BigidGridColumnTypes.TEXT,
        getCellValue: classifier => (<ClassifierListItemCell {...classifier} />) as ReactNode,
      },
    ],
  };

  const tabs: LayoutTitleTabsConfig = {
    initialTabIndex: 0,
    size: 'small',
    tabs: [
      {
        label: 'Data',
        data: {
          types: ['data', 'ner', 'data-and-metadata'],
        },
      },
      {
        label: 'Metadata',
        data: {
          types: ['meta'],
        },
      },
      {
        label: 'Document',
        data: {
          types: ['doc'],
        },
      },
    ],
  };

  const layoutConfig: BigidLayoutConfig = {
    title: {
      tabs,
      settings: {
        onClick: () => {
          $state.go('classifiers', { showBackButton: true });
        },
      },
    },
    content: {
      entityName: 'Classifiers',
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        gridConfig,
        fetchGridData,
        masterDetailsConfig,
        selectedItemPropsMapping: {
          name: 'selectedItemTitle',
          attributeRiskName: 'attributeRiskName',
          id: 'id',
          type: 'type',
          totalFindings: 'totalFindings',
          originalData: 'originalData',
        } as Record<string, keyof NormalizedClassifier>,
      },
    },
    filter: {
      hasInitialFilter: false,
      search: {
        isQueryLanguage: false,
        getFreeSearchField: () => 'classification_name',
      },
    },
  };

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: PAGE_TITLE });
  }, []);

  return <BigidLayout config={layoutConfig} />;
};
