import { DigestReport } from './react/views/DigestReport/DigestReport';
import { DataCatalogComponentClass } from './react/views/DataCatalog/DataCatalog';
import { DataCatalogEntityPreviewComponent } from './react/views/DataCatalog/DataCatalogEntityPreview/DataCatalogEntityPreview';
import { DataCatalogFollowedObjects } from './react/views/DataCatalog/DataCatalogFollowedObjects/DataCatalogFollowedObjects';
import { Classification } from './react/views/Classification/Classification';
import { ClassifiersManagement } from './react/views/ClassifiersManagement/ClassifiersManagement';
import { CreateCustomApp } from './react/views/CreateCustomApp/CreateCustomApp';
import { Clustering } from './react/views/Clustering/Clustering';
import { PreviewFileWithNavigation } from './react/views/DataCatalog/PreviewFileObjects/PreviewFileWithNavigation';
import { DataCatalogClusterPreview } from './react/views/DataCatalog/DataCatalogClusterPreview';
import { ConsentReport } from './react/views/Consent/Report/ConsentReport';
import { DATA_SOURCES_INIT_VIEWS, DataSources } from './react/views/DataSources/DataSources';
import { ApplicationsManagement } from './react/views/ApplicationsManagement/ApplicationsManagement';
import { TabUrls } from './react/views/ApplicationsManagement/applicationManagementService';
import { DataSourceConfiguration } from './react/views/DataSources/DataSourceConfiguration/DataSourceConfiguration';
import { Dashboard } from './react/views/Dashboard/Dashboard';
import { AccessIntelligence } from './react/views/AccessIntelligence/AccessIntelligence';
import { DataSourceConnections } from './react/views/DataSources/DataSourceConnections/DataSourceConnections';
import { CorrelationSetConnections } from './react/views/CorrelationSets/CorrelationSetConnections/CorrelationSetConnections';
import {
  DataMinimization,
  DataMinimizationDataSources,
  DataMinimizationObjects,
  DataMinimizationRequests,
  DataDeletionGlobalSettings,
} from './react/views/DataMinimization';
import { ScanWindows } from './react/views/ScanWindows/ScanWindows';
import { ActionCenter } from './react/views/ActionCenter/ActionCenter';
import { CatalogRulesLayout } from './react/views/CatalogRules/CatalogRulesLayout';
import { Scans } from './react/views/Scans/Scans';
import { TagsComponentClass } from './react/views/TagsManagement/TagsManagement';
import { CatalogDiscovery } from './react/views/CatalogDiscovery/CatalogDiscovery';
import { PrivacyExecutiveDashboard } from './react/views/PrivacyExecutiveDashboard/PrivacyExecutiveDashboard';
import { Frameworks, FrameworkControls } from './react/views/Frameworks';
import {
  ACCESS_MANAGEMENT_PERMISSIONS,
  ACTION_CENTER_PERMISSIONS,
  ACTIONABLE_INSIGHTS_PERMISSIONS,
  ADVANCE_TOOLS_PERMISSIONS,
  APPLICATION_SETUP_PERMISSIONS,
  APPLICATIONS_PERMISSIONS,
  AUDIT_PERMISSIONS,
  BUSINESS_FLOWS_PERMISSIONS,
  CATALOG_PERMISSIONS,
  CERTIFICATES_PERMISSIONS,
  CLASSIFICATION_PERMISSIONS,
  CLASSIFIERS_PERMISSIONS,
  CLUSTER_ANALYSIS_PERMISSIONS,
  CONSENT_GOVERNANCE_PERMISSIONS,
  CORRELATION_PERMISSIONS,
  CORRELATION_SET_PERMISSIONS,
  CREDENTIAL_PROVIDERS_PERMISSIONS,
  CREDENTIALS_PERMISSIONS,
  DASHBOARD_PERMISSIONS,
  DATA_MINIMIZATION_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  DSAR_PERMISSIONS,
  GENERAL_SETTINGS_PERMISSIONS,
  GETTING_STARTED_PERMISSIONS,
  INVENTORY_PERMISSIONS,
  POLICIES_PERMISSIONS,
  PROXIES_PERMISSIONS,
  REPORTS_PERMISSIONS,
  SCAN_RESULT_DETAILS_PERMISSIONS,
  SCAN_WINDOWS_PERMISSIONS,
  SCANS_PERMISSIONS,
  SECONDARY_SOURCE_PERMISSIONS,
  SENSITIVITY_CLASSIFICATION_PERMISSIONS,
  TAGS_PERMISSIONS,
  TAGS_SAVED_QUERIES_PERMISSIONS,
  VENDORS_PERMISSIONS,
  EMAIL_TEMPLATES_PERMISSIONS,
  LEGAL_ENTITIES_PERMISSIONS,
  BRANDS_PERMISSIONS,
  RISK_REGISTER_PERMISSIONS,
  RISK_CONTROLS_PERMISSIONS,
  PRIVACY_RISKS_PERMISSIONS,
} from '@bigid/permissions';

import { CONFIG } from './config/common';

import { DEFAULT_ACTIVE_TAB } from './administration/generalSettings/generalSettings.component';
import { DEFAULT_TAB_ACCESS_MANAGEMENT } from './administration/identityAccessManagement/identityAccessManagement.component';
import { CustomDashboardGeneralWrapper } from './react/views/CustomDashboard/CustomDashboardGeneralWrapper';
import { SingleUser } from './react/views/AccessIntelligence/UsersPermissions/SingleUser';
import { LabelsConfigurations } from './react/views/AccessIntelligence/Settings/Configuration/LabelsConfigurations';
import { UpdatePassword } from './react/views/UpdatePassword/UpdatePassword';
import { MetadataSearchResults } from './react/views/MetadataSearchResults/MetadataSearchResults';
import { AccessIntelligenceSettings } from './react/views/AccessIntelligence/Settings/AccessIntelligenceSettings';
import { ActionWorkflow } from './react/views/ActionCenter/ActionWorkflow/ActionWorkflow';
import { ActionCenterAudit } from './react/views/ActionCenter/ActionCenterAudit/ActionCenterAudit';
import { AuditLogConditionalWrapper } from './react/views/Audit/Audit';
import { SensitivityClassificationForm } from './react/views/SensitivityClassification/SensitivityClassificationForm';
import { ConfigurationManagement } from './react/views/ActionCenter/ConfigurationManagement/ConfigurationManagement';
import { FmsdLazy } from './react/views/Fmsd/FmsdLazy';
import { InfoForDevLazy } from './react/views/SmallidForDevelopers/InfoForDevLazy';
import { ActiveScans } from './react/views/Scans/ActiveScans/ActiveScans';
import { ScansCompleted } from './react/views/Scans/CompletedScans/CompletedScans';
import { ScanProfiles } from './react/views/Scans/ScanProfiles/ScanProfiles';
import { ScanTemplates } from './react/views/Scans/ScanTemplates/ScanTemplates';
import { SingleGroup } from './react/views/AccessIntelligence/Groups/SingleGroup';
import { ScanInsights } from './react/views/Scans/ScanInsights/ScanInsights';
import { ScansPageRouter } from './react/views/Scans/ScanPageRouter';
import { DataSourceRouter } from './react/views/DataSources/DataSourceRouter';
import { ExecutiveReport } from './react/views/ExecutiveReport/ExecutiveReport';
import { CreateHotspotsReport } from './react/views/HotspotsReport/CreateHotspotsReport';
import { Curation } from './react/views/Curation/Curation';
import { ClassifierFindings } from './react/views/Curation/ClassifierFindings/ClassifierFindings';
import { ClassifierPredictions } from './react/views/Curation/ClassifierPredictions/ClassifierPredictions';
import { Labeling } from './react/views/Labeling/Labeling';
import { AutoDiscovery } from './react/views/AutoDiscovery/AutoDiscovery';
import { CustomAppActivityLogPage } from './react/views/CustomApp/CustomAppActivityLogPage';
import { CustomAppEditPage } from './react/views/CustomApp/CustomAppEditPage';
import { CustomAppEditPresetPage } from './react/views/CustomApp/CustomAppEditPresetPage';
import { CustomAppActionsPage } from './react/views/CustomApp/CustomAppActionsPage';
import { CustomAppUIPage } from './react/views/CustomApp/CustomAppUIPage';
import { ComponentClass, FC } from 'react';
import { BIGID_BI_EVENTS } from './react/config/BigIdBiEvents';
import { ScanTemplateWizard } from './react/views/Scans/ScanTemplates/ScanTemplateWizard/ScanTemplateWizard';
import { SavedScans } from './react/views/Scans/PlannedScans/SavedScans';
import { ScanCreateWizard } from './react/views/Scans/ScanCreateWizard/ScanCreateWizard';
import { ActionableInsights } from './react/views/ActionableInsights/ActionableInsights';
import { GettingStartedRouter } from './react/views/GettingStarted/GettingStartedRouter';
import { GettingStartedUITrackingEvent } from './react/views/GettingStartedWithSmallID/GettingStartedEventTrackerUtils';
import { TPAPageViewsEvents } from './react/views/CustomApp/customAppEventsTrackerUtils';
import { HotspotsReportsChartWrapper } from './react/views/HotspotsReport/HotspotsReportChart/HotspotsReportChartWrapper';
import { RiskAssessment } from './react/views/RiskAssessment/RiskAssessment';
import { RiskAssessmentUITrackingEvent } from './react/views/RiskAssessment/RiskAssessmentEventTrackerUtils';
import { ScansContainer } from './react/views/Scans/ScansContainer';
import { IdentityLineageMtWrapper } from './identity-lineage/IdentityLineageComponent';
import { DataExplorer } from './react/views/DataExplorer/DataExplorer';
import { SavedQueries } from './react/views/SavedQueries/SavedQueries';
import { Credentials } from './react/views/Credentials/Credentials';
import { Proxies } from './react/views/Proxies/Proxies';
import { ApplicationSetup } from './react/views/ApplicationSetup/ApplicationSetup';
import { smallIdLicenseService } from './react/services/smallIdLicenseService';
import { CertificatesManagementView } from './react/views/CertificatesManagement/CertificatesManagementWithLayout/CertificatesManagement';
import { CredentialProvidersView } from './react/views/CredentialProviders/CredentialProvidersView';
import { Unauthorized } from './react/views/Unauthorized/Unauthorized';
import { NotFound } from './react/views/NotFound/NotFound';
import { ConnectionLost } from './react/views/ConnectionLost/ConnectionLost';
import { UnderMaintenance } from './react/views/UnderMaintenance/UnderMaintenance';
import { EtlMain } from './react/views/ReportingEtlMonitoring/EtlMain';
import { DataExplorerSearchResults } from './react/views/DataExplorerSearchResults/DataExplorerSearchResultsContainer';
import { Vendors } from './react/views/Vendors/Vendors';
import { CustomAppEditGlobalPresetPage } from './react/views/CustomApp/CustomAppEditGlobalPresetPage';
import { VendorSummary } from './react/views/Vendors/VendorSummary';
import { IdentityAccessManagement } from './react/views/AccessManagement/IdentityAccessManagement';
import { AutoDiscoveryWizardAdvanced } from './react/views/AutoDiscovery/AutoDiscoveryWizard/AutoDiscoveryWizardAdvanced/AutoDiscoveryWizardAdvanced';
import { EmailTemplates } from './react/views/EmailTemplates/EmailTemplates';
import { EmailTemplatesBrowse } from './react/views/EmailTemplates/EmailTemplates/EmailTemplatesBrowse';
import { LegalEntities } from './react/views/LegalEntities/LegalEntities';
import { LegalEntityPageWithTabs } from './react/views/LegalEntities/LegalEntityPageWithTabs';
import { Brands } from './react/views/EmailTemplates/Brands/Brands';
import { RiskLibrary } from './react/views/Risks/RiskLibrary';
import { ControlsLibrary } from './react/views/Risks/ControlsLibrary';
import { GettingStartedSecurity } from './react/views/GettingStartedWorkspaces/GettingStartedSecurity/GettingStartedSecurity';
import { RiskRegister } from './react/views/RiskRegister/RiskRegister';
import { DataMinimizationActivityLogs } from './react/views/DataMinimization/DataMinimizationActivityLogs';
import { DiscoveryDashboard } from './react/views/DiscoveryDashboard/DiscoveryDashboard';
import { SensitivityClassificationWrapper } from './react/views/SensitivityClassificationNew/SensitivityClassificationWrapper';
import { SensitivityClassificationFormWrapper } from './react/views/SensitivityClassificationNew/SensitivityClassificationFormWrapper';

const SUBJECT_ACCESS_REQUEST_DEFAULT_PATH = 'new-request';

interface BaseDefinition {
  url: string;
  reloadOnSearch?: boolean;

  abstract?: boolean;
  params?: {
    [key: string]: unknown;
  };
  data?: {
    [key: string]: unknown;
    analyticsPageName?: string;
  };
}

interface AngularDefinition extends BaseDefinition {
  template?: string;
}

interface ReactDefinition extends BaseDefinition {
  component: FC<any> | ComponentClass | (() => string);
}

type Definition = AngularDefinition | ReactDefinition;

export interface UiRouterState {
  name: string;
  definition: Definition;
  data?: {
    [key: string]: unknown;
  };
}

export const states: UiRouterState[] = [
  {
    name: CONFIG.states.BUSINESS_FLOWS_ROPA,
    definition: {
      template: '<datamapping/>',
      url: '/data-mapping-ropa/:businessProcessId?ropaId',
      params: {
        businessProcessId: { dynamic: true, value: null },
        ropaMode: true,
        ropaId: null,
      },
      data: {
        permission: BUSINESS_FLOWS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DASHBOARD,
    definition: {
      url: '/dashboard',
      component: Dashboard,
      data: {
        permission: DASHBOARD_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DASHBOARD_SECURITY,
    definition: {
      url: '/dashboard/security',
      component: Dashboard,
      data: {
        permission: DASHBOARD_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DASHBOARD_COMPLIANCE,
    definition: {
      url: '/dashboard/compliance',
      component: Dashboard,
      data: {
        permission: DASHBOARD_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DASHBOARD_DISCOVERY,
    definition: {
      url: '/dashboard/discovery',
      component: DiscoveryDashboard,
      data: {
        permission: DASHBOARD_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CREDENTIAL_PROVIDERS,
    definition: {
      url: '/credential-providers',
      component: CredentialProvidersView,
      data: {
        permission: CREDENTIAL_PROVIDERS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.SCAN_RESULT,
    definition: {
      template: '<scan-result/>',
      url: '/scan-result',
      data: {
        permission: REPORTS_PERMISSIONS.READ_SCAN_RESULT_SUMMARY.name,
        analyticsPageName: BIGID_BI_EVENTS.REPORTS_SCAN_RESULTS_SUMMARY,
      },
    },
  },
  {
    name: CONFIG.states.INVENTORY,
    definition: {
      template: '<exploration/>',
      params: {
        filter: null,
        searchTextBox: null,
        sideFilterValue: null,
      },
      url: '/inventory/?filter',
      reloadOnSearch: false,
      data: {
        permission: INVENTORY_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.INVENTORY,
      },
    },
  },
  {
    name: CONFIG.states.PRIVACY_COMPLIANCE,
    definition: {
      template: '<compliance/>',
      url: '/privacyCompliance',
      data: {},
    },
  },
  {
    name: CONFIG.states.UPDATE_PASSWORD,
    definition: {
      component: UpdatePassword,
      url: '/updatePassword',
      data: {},
    },
  },
  {
    name: CONFIG.states.USERS,
    definition: {
      template: '<users/>',
      params: {
        id: null,
        download: null,
        filter: null,
        sideFilterValue: null,
      },
      url: '/users/:id/:download',
      data: {
        permission: INVENTORY_PERMISSIONS.REVIEW_ENTITY_PAGE.name,
      },
    },
  },
  {
    name: CONFIG.states.DATA_SOURCE_CONNECTIONS,
    definition: {
      component: DataSourceConnections,
      url: '/dataSourceConnections?filter?tab',
      params: {
        uniqueBroadcastEventForImport: null,
        filter: null,
        shouldDisplayAutoDiscoveryWarning: null,
        shouldDisplayGuidedTour: false,
        shouldDisplayNewConnectionModal: false,
        sourceType: null,
        dsLabel: null,
        nameInDocs: null,
        sourceId: null,
        tab: null,
      },
      data: {
        permission: DATA_SOURCES_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_DATA_SOURCES,
      },
    },
  },
  {
    name: CONFIG.states.DATA_SOURCE_ROUTER,
    definition: {
      component: DataSourceRouter,
      url: `/dataSourceController`,
      params: {
        uniqueBroadcastEventForImport: null,
        filter: null,
      },
      data: {
        permission: DATA_SOURCES_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
    definition: {
      template: '<newdatasource/>',
      params: {
        id: null,
      },
      url: '/dataSourceConnections/:id',
      data: {
        permission: DATA_SOURCES_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.NEW_DATA_SOURCE_CONNECTION,
    definition: {
      template: '<newdatasource/>',
      url: '/newDataSourceConnection',
      params: {
        id: null,
        selectedType: null,
      },
      data: {
        permission: DATA_SOURCES_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
    definition: {
      component: CorrelationSetConnections,
      url: '/entitySources?wizard?id',
      params: {
        id: null,
        wizard: null,
      },
      data: {
        permission: CORRELATION_SET_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_CORRELATION_SETS,
      },
    },
  },
  {
    name: CONFIG.states.DATA_MINIMIZATION,
    definition: {
      component: DataMinimization,
      url: '/dataDeletion',
      data: {
        permission: DATA_MINIMIZATION_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DATA_MINIMIZATION_REQUESTS,
    definition: {
      component: DataMinimizationRequests,
      url: '/requests',
      data: {
        permission: DATA_MINIMIZATION_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DATA_MINIMIZATION_ACTIVITY_LOGS,
    definition: {
      component: DataMinimizationActivityLogs,
      url: '/activity-logs?filter&search',
      data: {
        permission: DATA_MINIMIZATION_PERMISSIONS.ACCESS.name,
      },
      params: {
        filter: { dynamic: true, value: '[]' },
        search: { dynamic: true, value: '' },
      },
    },
  },
  {
    name: CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES,
    definition: {
      component: DataMinimizationDataSources,
      url: '/dataSources?filter&search',
      data: {
        permission: DATA_MINIMIZATION_PERMISSIONS.ACCESS.name,
      },
      params: {
        filter: { dynamic: true, value: '[]' },
        search: { dynamic: true, value: '' },
      },
    },
  },
  {
    name: CONFIG.states.DATA_MINIMIZATION_OBJECT,
    definition: {
      component: DataMinimizationObjects,
      params: {
        requestId: null,
        dsName: null,
      },
      url: '/dataDeletion/objects?requestId&dsName',
      data: {
        permission: DATA_MINIMIZATION_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DATA_DELETION_GLOBAL_SETTINGS,
    definition: {
      component: DataDeletionGlobalSettings,
      url: '/dataDeletion/globalSettings',
      data: {
        permission: DATA_MINIMIZATION_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION,
    definition: {
      template: '<newidentity/>',
      params: {
        id: null,
      },
      url: '/newEntityConnection',
      data: {
        permission: CORRELATION_SET_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.SECONDARY_CONNECTIONS,
    definition: {
      template: '<dlp-connections/>',
      url: '/dlpConnections',
      data: {
        permission: SECONDARY_SOURCE_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SECONDARY_SOURCES,
      },
    },
  },
  {
    name: CONFIG.states.NEW_SECONDARY_CONNECTION,
    definition: {
      template: '<new-dlp-connection/>',
      params: {
        name: null,
        type: null,
      },
      url: '/newDLPConnection',
      data: {
        permission: SECONDARY_SOURCE_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.SCANS,
    definition: {
      template: '<scans/>',
      params: {
        size: null,
        showLicenseQuotaMessage: null,
      },
      url: '/scans?size',
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCANS_OLD,
      },
    },
  },
  {
    name: CONFIG.states.EDIT_SCAN_PROFILE,
    definition: {
      template: '<edit-scan-profile/>',
      params: {
        data: null,
        id: null,
        isHideSave: null,
        name: null,
      },
      url: '/editScanProfile',
      data: {
        permission: SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name,
      },
    },
  },
  {
    name: CONFIG.states.CREATE_SCAN_PROFILE,
    definition: {
      template: '<edit-scan-profile/>',
      url: '/createScanProfile',
      data: {
        permission: SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name,
      },
      params: {
        prefill: null,
      },
    },
  },
  {
    name: CONFIG.states.APPLICATIONS_SETUP,
    definition: {
      component: ApplicationSetup,
      url: '/application-setup',
      data: {
        permission: APPLICATION_SETUP_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_APPLICATION_SETUP,
      },
    },
  },
  {
    name: CONFIG.states.GENERAL_SETTINGS,
    definition: {
      template: '<general-settings/>',
      url: '/general-settings/:currentTab',
      data: {
        permission: GENERAL_SETTINGS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_GENERAL_SETTINGS,
      },
      params: {
        currentTab: DEFAULT_ACTIVE_TAB,
        showBackButton: null,
      },
    },
  },
  {
    name: CONFIG.states.RISK_CONFIGURATION,
    definition: {
      template: '<risk-configuration/>',
      url: '/riskConfiguration',
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_RISK_CONFIGURATION.name,
      },
    },
  },
  {
    name: CONFIG.states.BUSINESS_FLOWS,
    definition: {
      template: '<datamapping/>',
      url: '/dataMapping/:businessProcessId?/:selectedEntity',
      params: {
        businessProcessId: { dynamic: true, value: null },
        selectedEntity: { dynamic: true, value: null },
        shouldShowCreateReportModal: false,
      },
      data: {
        permission: BUSINESS_FLOWS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CORRELATION,
    definition: {
      component: IdentityLineageMtWrapper,
      url: '/entityCorrelation',
      data: {
        permission: CORRELATION_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.CORRELATION,
      },
    },
  },
  {
    name: CONFIG.states.ADVANCED_TOOLS,
    definition: {
      template: '<betatools/>',
      url: '/advanced-tools',
      data: {
        permission: ADVANCE_TOOLS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_ADVANCED_TOOLS,
      },
    },
  },
  {
    name: CONFIG.states.RULES,
    definition: {
      component: CatalogRulesLayout,
      url: '/rules',
      data: {
        permission: CATALOG_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_COMPOUND_ATTRIBUTES,
      },
    },
  },
  {
    name: CONFIG.states.TASKS_LIST,
    definition: {
      template: '<task-list/>',
      url: '/task-list/:taskId',
      params: {
        taskId: null,
      },
      data: {
        analyticsPageName: BIGID_BI_EVENTS.TASKS,
      },
    },
  },
  {
    name: CONFIG.states.BREACH_RESPONSE,
    definition: {
      template: '<breach-response/>',
      url: '/breach-response',
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_BREACH_RESPONSE.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_REQUEST_MANAGEMENT,
    definition: {
      template: '<subject-access-request/>',
      url: '/subject-access-request/:path',
      params: {
        userId: null,
        path: SUBJECT_ACCESS_REQUEST_DEFAULT_PATH,
      },
      data: {
        permission: DSAR_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE,
    definition: {
      url: '/:profileId/:tab',
      params: {
        path: 'profile',
        tab: { dynamic: true, value: null, squash: true },
        profileId: { dynamic: true, value: null, squash: true },
        profileName: null,
      },
      data: {
        permission: DSAR_PERMISSIONS.READ_PROFILE_SETTINGS.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_REQUEST_MANAGEMENT_JOINT_CORRELATION,
    definition: {
      url: '/:flowName',
      params: {
        path: 'correlations-mapping',
        flowName: { dynamic: true, value: null },
      },
      data: {
        permission: DSAR_PERMISSIONS.READ_ATTRIBUTES_ENRICHMENT_FLOW.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_REQUEST_MANAGEMENT_DICTIONARY,
    definition: {
      url: '/',
      params: {
        path: 'dictionary',
      },
      data: {
        permission: DSAR_PERMISSIONS.READ_DICTIONARY.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_REQUEST_MANAGEMENT_REPORT_TEMPLATES,
    definition: {
      url: '/:templateId',
      params: {
        path: 'report-templates',
        templateId: { dynamic: true, value: null },
      },
      data: {
        permission: DSAR_PERMISSIONS.READ_PERSONAL_INFO.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_REQUEST_MANAGEMENT_FILES_RETRIEVAL,
    definition: {
      url: '/:templateId',
      params: {
        path: 'files-retrieval',
        templateId: { dynamic: true, value: null },
      },
      data: {
        permission: DSAR_PERMISSIONS.EDIT_FILES_RETRIEVAL.name,
      },
    },
  },
  {
    name: CONFIG.states.PERSONAL_INFO_REPORT,
    definition: {
      template: '<personal-info-report/>',
      params: {
        userId: null,
        targetTab: null,
        profileId: null,
        profileName: null,
      },
      url: '/personal-info-report/:userId',
      data: {
        permission: DSAR_PERMISSIONS.READ_PERSONAL_INFO.name,
      },
    },
  },
  {
    name: CONFIG.states.NEW_BREACH_RESPONSE,
    definition: {
      template: '<new-breach-response/>',
      params: {
        id: null,
      },
      url: '/edit-breach-response',
      data: {
        permission: APPLICATIONS_PERMISSIONS.CREATE_BREACH_RESPONSE.name,
      },
    },
  },
  {
    name: CONFIG.states.EXPLORE_BREACH_RESPONSE,
    definition: {
      template: '<explore-breach-response/>',
      params: {
        file: null,
        fileID: null,
      },
      url: '/explore-breach-response/?fileID',
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_BREACH_RESPONSE.name,
      },
    },
  },
  {
    name: CONFIG.states.FORBIDDEN,
    definition: {
      url: '/unauthorized',
      component: Unauthorized,
      data: {},
    },
  },
  {
    name: CONFIG.states.NOT_FOUND,
    definition: {
      url: '/not-found',
      component: NotFound,
      data: {},
    },
  },
  {
    name: CONFIG.states.CONNECTION_LOST,
    definition: {
      url: '/connection-lost',
      component: ConnectionLost,
      data: {},
    },
  },
  {
    name: CONFIG.states.UNDER_MAINTENANCE,
    definition: {
      url: '/under-maintenance',
      component: UnderMaintenance,
      data: {},
    },
  },
  {
    name: CONFIG.states.CREDENTIALS,
    definition: {
      component: Credentials,
      url: '/credentials',
      data: {
        permission: CREDENTIALS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_CREDENTIALS,
      },
    },
  },
  {
    name: CONFIG.states.PROXIES,
    definition: {
      component: Proxies,
      url: '/proxies',
      data: {
        permission: PROXIES_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CLASSIFIERS,
    definition: {
      template: '<classifiers-wrapper/>',
      url: '/classifiers?name?type',
      params: {
        name: null,
        type: null,
        showBackButton: null,
      },
      data: {
        permission: CLASSIFIERS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_CLASSIFIERS,
      },
    },
  },
  {
    name: CONFIG.states.CLASSIFIERS_MANAGEMENT,
    definition: {
      url: '/classifiers-management',
      component: ClassifiersManagement,
      data: {
        permission: CLASSIFIERS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_CLASSIFIERS_MANAGEMENT,
      },
    },
  },
  {
    name: CONFIG.states.CLASSIFICATION,
    definition: {
      url: '/classification',
      component: Classification,
      data: {
        permission: CLASSIFICATION_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.CLASSIFICATION,
      },
    },
  },
  {
    name: CONFIG.states.SAVED_QUERIES,
    definition: {
      component: SavedQueries,
      url: '/saved-queries',
      data: {
        permission: TAGS_SAVED_QUERIES_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SAVED_QUERIES,
      },
    },
  },
  {
    name: CONFIG.states.TAGS,
    definition: {
      url: '/tags',
      component: TagsComponentClass,
      params: {
        filter: null,
      },
      data: {
        permission: TAGS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_TAGS,
      },
    },
  },
  {
    name: CONFIG.states.POLICIES,
    definition: {
      template: '<policies-wrapper/>',
      url: '/policies?policyId',
      params: {
        filter: null,
        policyId: null,
        policyName: null,
      },
      data: {
        permission: POLICIES_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ACTION_CENTER_POLICIES,
      },
    },
  },
  {
    name: CONFIG.states.AUDIT,
    definition: {
      component: AuditLogConditionalWrapper,
      url: '/audit',
      data: {
        permission: AUDIT_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.SCAN_ANALYSIS,
    definition: {
      template: '<scan-analysis/>',
      url: '/scan-analysis',
      data: {
        permission: SCAN_RESULT_DETAILS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.REPORTS_SCAN_RESULTS_DETAILS,
      },
    },
  },
  {
    name: CONFIG.states.AZURE_INFORMATION_PROTECTION,
    definition: {
      component: Labeling,
      url: '/labeling',
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_MICROSOFT_INFORMATION_PROTECTION.name,
      },
    },
  },
  {
    name: CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
    definition: {
      component: IdentityAccessManagement,
      url: '/identity-access-management/:currentTab',
      data: {
        permission: ACCESS_MANAGEMENT_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_ACCESS_MANAGEMENT,
      },
      params: {
        currentTab: DEFAULT_TAB_ACCESS_MANAGEMENT,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_GOVERNANCE_SETTINGS,
    definition: {
      component: AccessIntelligence,
      url: '/data-access/settings',
      params: {
        selectedTab: { dynamic: true, value: 'dashboard' },
        filters: { value: null },
      },
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_ACCESS_TYPES_IN_ACCESS_INTELLIGENCE.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_GOVERNANCE,
    definition: {
      component: AccessIntelligence,
      url: '/data-access/:selectedTab',
      params: {
        selectedTab: { dynamic: true, value: 'dashboard' },
        filters: { value: null },
      },
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name,
      },
    },
  },
  {
    name: CONFIG.states.USER_PERMISSIONS,
    definition: {
      component: SingleUser,
      url: '/access-intelligence-user/:userId/',
      params: {
        id: { dynamic: true, value: null },
      },
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name,
      },
    },
  },
  {
    name: CONFIG.states.GROUP_PERMISSIONS,
    definition: {
      component: SingleGroup,
      url: '/access-intelligence-group/:id/',
      params: {
        id: { dynamic: true, value: null },
      },
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name,
      },
    },
  },
  {
    name: CONFIG.states.LABELS_CONFIGURATION,
    definition: {
      component: LabelsConfigurations,
      url: '/labels-configurations',
      params: {
        labelName: null,
      },
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name,
      },
    },
  },
  {
    name: CONFIG.states.ACCESS_INTELLIGENCE_SETTINGS,
    definition: {
      component: AccessIntelligenceSettings,
      url: '/aci/settings',
      data: {
        permission: APPLICATIONS_PERMISSIONS.READ_ACCESS_TYPES_IN_ACCESS_INTELLIGENCE.name,
      },
    },
  },
  {
    name: CONFIG.states.CONSENT_GOVERNANCE,
    definition: {
      template: '<consent-governance/>',
      url: '/consent-governance',
      abstract: true,
      data: {
        permission: CONSENT_GOVERNANCE_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CONSENT_GOVERNANCE_LIST,
    definition: {
      url: '/sources',
      template: `<consent-source-connections/>`,
      data: {
        permission: CONSENT_GOVERNANCE_PERMISSIONS.READ_CONSENT_SOURCES.name,
      },
    },
  },
  {
    name: CONFIG.states.CONSENT_GOVERNANCE_AGREEMENTS,
    definition: {
      url: '/agreements',
      template: `<agreements
            on-data-loading="$ctrl.onContentDataLoading()"
            on-data-loaded="$ctrl.onContentDataLoaded()" />`,
      data: {
        permission: CONSENT_GOVERNANCE_PERMISSIONS.READ_AGREEMENT.name,
      },
    },
  },
  {
    name: CONFIG.states.CONSENT_GOVERNANCE_REPORTS,
    definition: {
      url: '/reports',
      component: ConsentReport,
      data: {
        permission: CONSENT_GOVERNANCE_PERMISSIONS.READ_REPORT.name,
      },
    },
  },
  {
    name: CONFIG.states.CLUSTERING,
    definition: {
      url: '/clustering/:clusterId',
      component: Clustering,
      params: {
        filter: null,
        clusterId: { dynamic: true, value: null },
        selectedTab: { dynamic: true, value: null },
      },
      data: {
        permission: CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.CLUSTER_ANALYSIS,
      },
    },
  },
  {
    name: CONFIG.states.CLUSTERING_OBJECTS_PREVIEW,
    definition: {
      url: '/cluster-objects-preview/:groupId/:objectId/:skip/:totalObjects/:index',
      component: PreviewFileWithNavigation,
      params: {
        groupType: 'cluster',
        groupId: { dynamic: true, value: null },
        objectId: { dynamic: true, value: null },
        skip: { dynamic: true, value: null },
        totalObjects: { dynamic: true, value: null },
        index: { dynamic: true, value: null },
        fileOrigin: { dynamic: true, value: null },
      },
      data: {
        permission: CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.DUPLICATE_OBJECTS_PREVIEW,
    definition: {
      url: '/duplicate-objects-preview/:groupId/:objectId/:skip/:totalObjects/:index',
      component: PreviewFileWithNavigation,
      params: {
        groupType: 'duplicate',
        groupId: { dynamic: true, value: null },
        objectId: { dynamic: true, value: null },
        skip: { dynamic: true, value: null },
        totalObjects: { dynamic: true, value: null },
        index: { dynamic: true, value: null },
      },
      data: {
        permissions: [CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name, CATALOG_PERMISSIONS.ACCESS.name],
      },
    },
  },
  {
    name: CONFIG.states.NEW_CONSENT_CONNECTION,
    definition: {
      template: '<new-consent/>',
      params: {
        id: null,
      },
      url: '/newConsentConnection',
      data: {
        permission: CONSENT_GOVERNANCE_PERMISSIONS.READ_CONSENT_SOURCES.name,
      },
    },
  },
  {
    name: CONFIG.states.CATALOG,
    definition: {
      url: '/catalog/?filter?shouldSelectFirstItem?filterSelections',
      component: DataCatalogComponentClass,
      params: {
        filter: { dynamic: true, value: null },
        filterSelections: { dynamic: true, value: null },
        shouldResetFilter: false,
        shouldSelectFirstItem: null,
      },
      data: {
        permission: CATALOG_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.CATALOG,
      },
    },
  },
  {
    name: CONFIG.states.CATALOG_PREVIEW,
    definition: {
      url: '/catalog-preview/?id?type?name?parentId',
      component: DataCatalogEntityPreviewComponent,
      params: {
        selectedTab: { dynamic: true, value: 'details' },
        id: { type: 'string', value: null },
        parentId: { type: 'string', value: null },
        type: { type: 'string', value: null },
        name: { type: 'string', value: null },
        text: { type: 'string', value: null },
        filter: { type: 'string', value: null },
      },
      data: {
        permission: CATALOG_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CATALOG_FOLLOWED_OBJECTS,
    definition: {
      url: '/catalog-followed-objects/:selectedTab',
      component: DataCatalogFollowedObjects,
      params: {
        selectedTab: { dynamic: true, value: null },
      },
      data: {
        permission: CATALOG_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CLUSTER_PREVIEW,
    definition: {
      url: '/cluster-preview',
      component: DataCatalogClusterPreview,
      params: {
        clusterId: null,
        clusterName: null,
      },
      data: {
        permission: CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CERTIFICATES_MANAGEMENT,
    definition: {
      url: '/certificatesManagement',
      component: CertificatesManagementView,
      data: {
        permission: CERTIFICATES_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_CERTIFICATIONS,
      },
    },
  },
  {
    name: CONFIG.states.DIGEST_REPORT,
    definition: {
      url: '/digestReport?unsubscribe',
      component: DigestReport,
      data: {
        permission: REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name,
        unsubscribe: null,
        analyticsPageName: BIGID_BI_EVENTS.REPORTS_ACTIVITY_HIGHLIGHTS,
      },
    },
  },
  {
    name: CONFIG.states.DATA_INSIGHTS_STUDIO,
    definition: {
      url: '/data-insights-studio',
      component: EtlMain,
      params: {
        isDataInsightsStudio: true,
      },
      data: {
        permission: REPORTS_PERMISSIONS.ACCESS_REPORTING_ETL.name,
      },
    },
  },
  {
    name: CONFIG.states.DATA_PIPELINE,
    definition: {
      url: '/data-pipeline',
      component: EtlMain,
      params: {
        isDataInsightsStudio: false,
      },
      data: {
        permission: REPORTS_PERMISSIONS.ACCESS_REPORTING_ETL.name,
      },
    },
  },
  {
    name: CONFIG.states.CUSTOM_APP_CREATE,
    definition: {
      url: '/createCustomApp',
      component: CreateCustomApp,
      params: {
        prevState: 'dashboard',
        customAppId: null,
      },
      data: {
        analyticsPageName: TPAPageViewsEvents.TPA_REGISTER_VIEW,
      },
    },
  },
  {
    name: CONFIG.states.CUSTOM_APP_ACTIVITY_LOG,
    definition: {
      url: '/customApp/:id/activityLog',
      component: CustomAppActivityLogPage,
      params: {
        id: null,
        appInfo: null,
      },
      data: {
        analyticsPageName: TPAPageViewsEvents.TPA_ACTIVITY_LOG_VIEW,
      },
    },
  },
  {
    name: CONFIG.states.CUSTOM_APP_EDIT,
    definition: {
      url: '/customApp/:id/settings',
      component: CustomAppEditPage,
      params: {
        id: null,
        appInfo: null,
      },
      data: {
        analyticsPageName: TPAPageViewsEvents.TPA_SETTINGS_VIEW,
      },
    },
  },
  {
    name: CONFIG.states.CUSTOM_APP_EDIT_PRESET,
    definition: {
      url: '/customApp/:id/action/:actionId/preset/:presetId',
      component: CustomAppEditPresetPage,
      params: {
        id: null,
        appInfo: null,
        action: null,
        preset: null,
        actionId: '',
        presetId: '',
        onReturnState: null,
      },
      data: {
        analyticsPageName: TPAPageViewsEvents.TPA_PRESET_VIEW,
      },
    },
  },

  {
    name: CONFIG.states.CUSTOM_APP_EDIT_GLOBAL_PRESET,
    definition: {
      url: '/customApp/:id/globalPreset/:globalPresetId',
      component: CustomAppEditGlobalPresetPage,
      params: {
        id: null,
        appInfo: null,
        globalPreset: null,
        globalPresetId: null,
      },
      data: {},
    },
  },

  {
    name: CONFIG.states.CUSTOM_APP_ACTIONS,
    definition: {
      url: '/customApp/:id/actions',
      component: CustomAppActionsPage,
      params: {
        id: null,
        appInfo: null,
      },
      data: {
        analyticsPageName: TPAPageViewsEvents.TPA_ACTIONS_VIEW,
      },
    },
  },
  {
    name: CONFIG.states.CUSTOM_APP_DEEP_LINKING,
    definition: {
      url: '/customApp/:id/*appRoute',
      component: CustomAppUIPage,
      params: {
        id: null,
        name: null,
        appRoute: { dynamic: true, value: null, squash: true },
        page: '',
      },
      data: {},
    },
  },
  {
    name: CONFIG.states.APP_DATA_STEWARDSHIP,
    definition: {
      url: '/customApp/Data%20Stewardship?entityId?entityType',
      component: CustomAppUIPage,
      params: {
        entityId: null,
        entityType: null,
      },
      data: {},
    },
  },
  {
    name: CONFIG.states.CUSTOM_APP,
    definition: {
      url: '/customApp/:id?navigateTo',
      component: CustomAppUIPage,
      params: {
        id: null,
        appRoute: '',
        appInfo: null,
        navigateTo: null,
      },
      data: {},
    },
  },
  {
    name: CONFIG.states.CUSTOM_DASHBOARD,
    definition: {
      url: '/customDashboard',
      component: CustomDashboardGeneralWrapper,
      data: {
        analyticsPageName: BIGID_BI_EVENTS.REPORTS_DASHBOARD_MANAGER,
      },
    },
  },
  {
    name: CONFIG.states.CUSTOM_DASHBOARD_PREVIEW,
    definition: {
      url: '/customDashboard/:dashboardId',
      component: CustomDashboardGeneralWrapper,
      params: { dashboardId: null, dashboardName: null, jsonAsString: null, isEditMode: null },
      data: {},
    },
  },
  {
    name: CONFIG.states.DATA_SOURCE_CONNECTIONS_INIT,
    definition: {
      url: '/dataSourcesInit/:path',
      component: DataSources,
      params: {
        path: DATA_SOURCES_INIT_VIEWS.init.path,
      },
      data: {
        permission: DATA_SOURCES_PERMISSIONS.CREATE.name,
      },
    },
  },
  {
    name: CONFIG.states.APPLICATIONS_MANAGEMENT,
    definition: {
      url: '/applications-management/:currentTab',
      component: ApplicationsManagement,
      data: {
        analyticsPageName: TPAPageViewsEvents.TPA_INSTALLED_APPLICATIONS_VIEW,
      },
      params: { currentTab: { value: TabUrls.INSTALLED_APPS, dynamic: true } },
    },
  },
  {
    name: CONFIG.states.CONFIG_DATA_SOURCE_NEW,
    definition: {
      url: '/configDataSourceNew/:selectedType',
      component: DataSourceConfiguration,
      params: {
        selectedType: null,
      },
      data: {
        permission: DATA_SOURCES_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CONFIG_DATA_SOURCE,
    definition: {
      url: '/configDataSource/:id?redirectToNewConnectivity',
      component: DataSourceConfiguration,
      params: {
        id: null,
        isAfterSave: false,
        redirectToNewConnectivity: null,
        shouldOpenNewModal: false,
        wizardStep: null,
        dsLabel: null,
      },
      data: {
        permission: DATA_SOURCES_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.SCAN_WINDOW,
    definition: {
      url: '/scanWindows',
      component: ScanWindows,
      data: {
        permission: SCAN_WINDOWS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCAN_WINDOWS,
      },
    },
  },
  {
    name: CONFIG.states.ACTION_CENTER,
    definition: {
      url: '/action-center',
      component: ActionCenter,
      data: {
        permission: ACTION_CENTER_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.ACTION_CENTER_OVERVIEW,
    definition: {
      url: '',
      component: () => '<div></div>',
      data: {
        permission: ACTION_CENTER_PERMISSIONS.ACCESS_ACTIONS.name,
      },
    },
  },
  {
    name: CONFIG.states.ACTION_CENTER_ACTIONS,
    definition: {
      url: '/actions/:workflowId',
      params: {
        workflowId: { dynamic: true, value: null },
      },
      component: ActionWorkflow,
      data: {
        permission: ACTION_CENTER_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ACTION_CENTER_ACTIONS,
      },
    },
  },
  {
    name: CONFIG.states.ACTION_CENTER_AUDIT,
    definition: {
      url: '/audit',
      component: ActionCenterAudit,
      data: {
        permission: ACTION_CENTER_PERMISSIONS.ACCESS_AUDIT.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_AUDIT,
      },
    },
  },
  {
    name: CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
    definition: {
      url: '/configurations',
      component: ConfigurationManagement,
      data: {
        permission: ACTION_CENTER_PERMISSIONS.ACCESS_CONFIGURATIONS.name,
      },
    },
  },
  {
    name: CONFIG.states.METADATA_SEARCH_RESULTS,
    definition: {
      url: '/metadata-search-results?text?filter',
      component: MetadataSearchResults,
      params: {
        text: null,
        filter: null,
      },
      data: {
        // permission: TBD,
      },
    },
  },
  {
    name: CONFIG.states.SENSITIVITY_CLASSIFICATION,
    definition: {
      url: '/sensitivity-classification',
      component: SensitivityClassificationWrapper,
      data: {
        permission: SENSITIVITY_CLASSIFICATION_PERMISSIONS.READ_CONFIGS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SENSITIVITY_CLASSIFICATION,
      },
    },
  },
  {
    name: CONFIG.states.SENSITIVITY_CLASSIFICATION_NEW,
    definition: {
      url: '/sensitivity-classification/create',
      component: SensitivityClassificationFormWrapper,
      params: {
        id: { dynamic: true, value: null },
      },
      data: {
        permission: SENSITIVITY_CLASSIFICATION_PERMISSIONS.READ_CONFIGS.name,
      },
    },
  },
  {
    name: CONFIG.states.SENSITIVITY_CLASSIFICATION_EDIT,
    definition: {
      url: '/sensitivity-classification/:id',
      component: SensitivityClassificationFormWrapper,
      params: {
        id: null,
      },
      data: {
        permission: SENSITIVITY_CLASSIFICATION_PERMISSIONS.READ_CONFIGS.name,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_NEW,
    definition: {
      component: Scans,
      url: `/scansNew`,
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCANS,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_NEW_SCANS,
    definition: {
      component: ScansContainer,
      url: `/scans`,
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCANS,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
    definition: {
      component: ActiveScans,
      url: `/in-progress`,
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCANS_ACTIVE_SCANS,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
    definition: {
      component: ScansCompleted,
      url: `/completed`,
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCANS_COMPLETED_SCANS,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_CONTROLLER,
    definition: {
      component: ScansPageRouter,
      url: `/scans-controller`,
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_SAVED_PROFILES,
    definition: {
      url: '/saved-profiles',
      component: ScanProfiles,
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCANS_SAVED_PROFILES,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_SCAN_TEMPLATES,
    definition: {
      url: '/scan-templates',
      component: ScanTemplates,
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_SCANS_SCAN_TEMPLATES,
      },
    },
  },
  {
    name: CONFIG.states.SCANS_PLANNED_SCANS,
    definition: {
      url: '/saved-scans',
      component: SavedScans,
    },
    data: {
      permission: SCANS_PERMISSIONS.ACCESS.name,
    },
  },
  {
    name: CONFIG.states.SCANS_SCAN_INSIGHTS,
    definition: {
      url: '/scan-insights/:scanId/:scanProfileName/:fromState',
      component: ScanInsights,
      params: {
        scanProfileName: null,
        scanId: null,
        fromState: null,
      },
      data: {
        permission: SCANS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.SCANS_SCAN_INSIGHTS,
      },
    },
  },
  {
    name: CONFIG.states.FMSD,
    definition: {
      url: '/fmsd',
      component: FmsdLazy,
      params: {
        restart: null,
      },
      data: {
        permissions: [DATA_SOURCES_PERMISSIONS.ACCESS.name, CATALOG_PERMISSIONS.ACCESS.name],
      },
    },
  },
  {
    name: CONFIG.states.ACTIONABLE_INSIGHTS,
    definition: {
      url: '/data-risk-management?viewType?caseId',
      component: ActionableInsights,
      params: {
        viewType: null,
        caseId: null,
      },
      data: {
        permission: ACTIONABLE_INSIGHTS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_DATA_RISK_MANAGEMENT,
      },
    },
  },
  {
    name: CONFIG.states.RISK_REGISTER,
    definition: {
      url: '/riskRegister/:caseId',
      component: RiskRegister,
      params: {
        viewType: null,
        caseId: null,
      },
      data: {
        permission: RISK_REGISTER_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.FRAMEWORKS,
    definition: {
      url: '/compliance-frameworks',
      component: Frameworks,
      data: {
        permission: ACTIONABLE_INSIGHTS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_DATA_RISK_MANAGEMENT,
      },
    },
  },
  {
    name: CONFIG.states.FRAMEWORKS_CONTROLS,
    definition: {
      url: '/compliance-frameworks/:id',
      params: {
        id: '',
        name: '',
        state: false,
      },
      component: FrameworkControls,
      data: {
        permission: ACTIONABLE_INSIGHTS_PERMISSIONS.ACCESS.name,
        analyticsPageName: BIGID_BI_EVENTS.ADMINISTRATION_DATA_RISK_MANAGEMENT,
      },
    },
  },
  {
    name: CONFIG.states.AUTO_DISCOVERY,
    definition: {
      url: '/autoDiscovery',
      component: AutoDiscovery,
      data: {
        permissions: [DATA_SOURCES_PERMISSIONS.ACCESS.name, DATA_SOURCES_PERMISSIONS.CREATE.name],
      },
    },
  },
  {
    name: CONFIG.states.SMALLID_FOR_DEVELOPERS,
    definition: {
      url: '/sfd',
      component: InfoForDevLazy,
      data: {
        permission: DASHBOARD_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.EXECUTIVE_REPORT,
    definition: {
      url: '/executive-report',
      component: ExecutiveReport,
      data: {
        permission: REPORTS_PERMISSIONS.ACCESS_EXECUTIVE_REPORT.name,
        analyticsPageName: BIGID_BI_EVENTS.REPORTS_EXECUTIVE_REPORT,
      },
    },
  },
  {
    name: CONFIG.states.HOTSPOTS_REPORT,
    definition: {
      component: HotspotsReportsChartWrapper,
      url: '/hotspots-report',
      params: {
        selectedTab: { dynamic: true, value: 'chart' },
      },
      data: {
        permission: CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.HOTSPOTS_REPORT_NEW,
    definition: {
      url: '/hotspots-report/create',
      component: CreateHotspotsReport,
      data: {
        permission: CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.CURATION,
    definition: {
      url: '/results-tuning?scanId?scanProfileName?dataSource?attributeName?attributeType?displayName?',
      component: Curation,
      params: {
        scanId: null,
        scanProfileName: null,
        dataSource: null,
        attributeName: null,
        attributeType: null,
        displayName: null,
      },
      data: {
        // permission: TBD,
      },
    },
  },
  {
    name: CONFIG.states.CLASSIFIER_FINDINGS,
    definition: {
      url: '/results-tuning/findings?attributeName?attributeType?displayName',
      component: ClassifierFindings,
      params: {
        attributeName: null,
        attributeType: null,
        structuredOnly: false,
        displayName: null,
      },
      data: {
        // permission: TBD,
      },
    },
  },
  {
    name: CONFIG.states.CLASSIFIER_PREDICTIONS,
    definition: {
      url: '/results-tuning/predictions?attributeName?attributeType?displayName',
      component: ClassifierPredictions,
      params: {
        attributeName: null,
        attributeType: null,
        displayName: null,
      },
      data: {
        // permission: TBD,
      },
    },
  },
  {
    name: CONFIG.states.SCAN_TEMPLATE,
    definition: {
      url: '/scan-template?id&name',
      component: ScanTemplateWizard,
      params: {
        id: null,
        name: null,
        isFavorite: null,
      },
      data: {
        permissions: [SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name, SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name],
      },
    },
  },
  {
    name: CONFIG.states.CREATE_SCAN,
    definition: {
      url: '/create-scan?dataSources?scanId?name?from?prefill',
      component: ScanCreateWizard,
      params: {
        dataSources: null,
        scanId: null,
        name: null,
        from: null,
        prefill: null,
      },
      data: {
        permissions: [SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name, SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name],
      },
    },
  },
  {
    name: CONFIG.states.CATALOG_DISCOVERY,
    definition: {
      url: '/data-overview',
      component: CatalogDiscovery,
      params: {
        selectedCatalogFilters: null,
        transitionFromNewCatalog: null,
      },
      data: {
        analyticsPageName: BIGID_BI_EVENTS.DATAINSIGHTS,
      },
    },
  },
  {
    name: CONFIG.states.GETTING_STARTED,
    definition: {
      url: '/getting-started',
      component: GettingStartedRouter,
      data: {
        permissions: [
          smallIdLicenseService.isSmallIDLicense()
            ? GENERAL_SETTINGS_PERMISSIONS.ACCESS.name
            : GETTING_STARTED_PERMISSIONS.ACCESS.name,
        ],
        analyticsPageName: GettingStartedUITrackingEvent.GETTING_STARTED_PAGE_VIEW,
      },
    },
  },
  {
    name: CONFIG.states.GETTING_STARTED_SECURITY,
    definition: {
      url: '/getting-started/security',
      component: GettingStartedSecurity,
      data: {
        permissions: [GETTING_STARTED_PERMISSIONS.ACCESS.name],
        analyticsPageName: GettingStartedUITrackingEvent.GETTING_STARTED_SECURITY_PAGE_VIEW,
      },
    },
  },
  {
    name: CONFIG.states.RISK_ASSESSMENT,
    definition: {
      url: '/risk-assessment',
      component: RiskAssessment,
      data: {
        permissions: [GENERAL_SETTINGS_PERMISSIONS.ACCESS.name],
        analyticsPageName: RiskAssessmentUITrackingEvent.RISK_ASSESSMENT_PAGE_VIEW,
      },
    },
  },
  {
    name: CONFIG.states.DATA_EXPLORER,
    definition: {
      url: '/dataExplorer',
      component: DataExplorer,
      data: {},
    },
  },
  {
    name: CONFIG.states.CATALOG_SEARCH_RESULTS,
    definition: {
      url: '/catalogSearchResults?query?activeTab?filters?sort',
      component: DataExplorerSearchResults,
      params: {
        query: {
          dynamic: true,
          value: null,
        },
        activeTab: {
          dynamic: true,
          value: null,
        },
        filters: { dynamic: true, value: null },
        sort: { dynamic: true, value: null },
      },
      data: {},
    },
  },
  {
    name: CONFIG.states.VENDORS,
    definition: {
      url: '/vendors',
      component: Vendors,
      data: {
        permissions: [VENDORS_PERMISSIONS.VENDOR_READ.name],
        featureFlag: 'VENDORS_DIRECTORY_ENABLED_FF',
      },
    },
  },
  {
    name: CONFIG.states.VENDOR_SUMMARY,
    definition: {
      url: '/vendors/:projectId/:tab',
      component: VendorSummary,
      params: {
        projectId: { dynamic: true, value: null },
        tab: { dynamic: true, value: null },
      },
      data: {
        permissions: [VENDORS_PERMISSIONS.VENDOR_READ.name],
        featureFlag: 'VENDORS_DIRECTORY_ENABLED_FF',
      },
    },
  },
  {
    name: CONFIG.states.AUTO_DISCOVERY_PRESET_CONFIG,
    definition: {
      url: '/configAutoDiscovery/:type?id?tab',
      component: AutoDiscoveryWizardAdvanced,
      params: {
        type: '',
        id: { dynamic: true, value: null },
        tab: { dynamic: true, value: null },
      },
      data: {
        permissions: [DATA_SOURCES_PERMISSIONS.ACCESS.name, DATA_SOURCES_PERMISSIONS.CREATE.name],
        featureFlag: 'SHOW_NEW_AUTO_DISCOVERY_UI_FF',
      },
    },
  },
  {
    name: CONFIG.states.EMAIL_TEMPLATES,
    definition: {
      url: '/email-templates',
      component: EmailTemplates,
      data: {
        permissions: [EMAIL_TEMPLATES_PERMISSIONS.ACCESS.name],
      },
    },
  },
  {
    name: CONFIG.states.EMAIL_TEMPLATES_BROWSE,
    definition: {
      url: '/browse',
      component: EmailTemplatesBrowse,
      data: {
        permissions: [EMAIL_TEMPLATES_PERMISSIONS.ACCESS.name],
      },
    },
  },
  {
    name: CONFIG.states.LEGAL_ENTITIES,
    definition: {
      url: '/legalEntities?parentFilterId',
      component: LegalEntities,
      params: {
        parentFilterId: { dynamic: true, value: null },
      },
      data: {
        permissions: [LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_READ.name],
        featureFlag: 'LEGAL_ENTITIES_ENABLED_FF',
      },
    },
  },
  {
    name: CONFIG.states.LEGAL_ENTITIES_OVERVIEW,
    definition: {
      url: '/:entityId/:tab',
      component: LegalEntityPageWithTabs,
      params: {
        entityId: { dynamic: true, value: null },
        tab: { dynamic: true, value: null },
      },
      data: {
        permissions: [LEGAL_ENTITIES_PERMISSIONS.LEGAL_ENTITIES_READ.name],
        featureFlag: 'LEGAL_ENTITIES_ENABLED_FF',
      },
    },
  },
  {
    name: CONFIG.states.EMAIL_TEMPLATES_BRANDS,
    definition: {
      url: '/brands',
      component: Brands,
      data: {
        permissions: [BRANDS_PERMISSIONS.ACCESS.name],
      },
    },
  },
  {
    name: CONFIG.states.RISK_LIBRARY,
    definition: {
      component: RiskLibrary,
      url: '/risks-library',
      data: {
        permission: PRIVACY_RISKS_PERMISSIONS.ACCESS.name,
      },
    },
  },
  {
    name: CONFIG.states.PRIVACY_EXECUTIVE_DASHBOARD,
    definition: {
      url: '/privacy-executive-dashboard',
      component: PrivacyExecutiveDashboard,
      data: {
        // permission: TBD,
      },
    },
  },
  {
    name: CONFIG.states.RISK_CONTROLS,
    definition: {
      component: ControlsLibrary,
      url: '/risks-controls',
      data: {
        permission: RISK_CONTROLS_PERMISSIONS.ACCESS.name,
      },
    },
  },
];
