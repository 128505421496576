import { styled } from '@mui/material';
import React from 'react';
import { DataPoint } from './DataPoint';
import { useLocalTranslation } from '../../../../../translations';
import { BigidHeading6, BigidListItem, BigidSelectOption } from '@bigid-ui/components';
import { DataCatalogObjectDetails } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { dateTimeService } from '@bigid-ui/i18n';
import { DataCatalogOwnersDialog } from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogOwnersDialog';
import { getBasicDetails } from '../../../../../../DataCatalog/DataCatalogDetails';
import { OwnerV2 } from '../../../../../types';
import { OverviewOwners } from './OverviewOwners';
import { formatOwnerToDetails } from '../../utils';
import { head, tail } from 'lodash';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 28px 48px;
`;

const List = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

type BasicDetailsProps = {
  details: DataCatalogObjectDetails;
  usersList: BigidSelectOption[];
  refetchData: () => void;
  dsOwners: OwnerV2[];
  isListView?: boolean;
};

export const BasicDetails = ({ details, usersList, refetchData, dsOwners = [], isListView }: BasicDetailsProps) => {
  const [isAddOwnerDialogOpen, setIsAddOwnerDialogOpen] = React.useState(false);
  const { t } = useLocalTranslation('sidePanel.overview.basicDetails');
  const {
    scanStatus,
    last_scanned,
    modified_date,
    ds_location,
    tableTotalRows,
    columnOrFieldOccurrencesCounter,
    fullyQualifiedName,
    scannerType,
    dataSourceName: source,
    object_owners_struct,
  } = details || {};

  const handleOnDialogClose = () => {
    setIsAddOwnerDialogOpen(false);
  };

  const handleOnDialogSubmit = () => {
    refetchData();
    setIsAddOwnerDialogOpen(false);
  };

  const basicDetailsList: BigidListItem[] = getBasicDetails(details);

  const itOwners = dsOwners.filter(owner => owner.type === 'it').map(owner => owner.id);

  const businessOwners = dsOwners.filter(owner => owner.type === 'business').map(owner => owner.id);

  const DataContainer = isListView ? List : Grid;

  const valueMaxWidth = isListView ? '270px' : '200px';

  return (
    <Root>
      <BigidHeading6>{t('title')}</BigidHeading6>
      <DataContainer>
        <DataPoint
          title={t('items.owners')}
          value={
            <OverviewOwners
              threshold={1}
              valueMaxWidth={valueMaxWidth}
              totalNumberOfItems={object_owners_struct.length}
              value={formatOwnerToDetails(head(object_owners_struct))}
              tooltipList={tail(object_owners_struct).map(formatOwnerToDetails)}
            />
          }
          editable
          onEdit={() => setIsAddOwnerDialogOpen(true)}
        />
        <DataPoint
          title={t('items.itOwners')}
          value={
            <OverviewOwners
              threshold={1}
              totalNumberOfItems={itOwners.length}
              value={head(itOwners)}
              valueMaxWidth={valueMaxWidth}
              tooltipList={tail(itOwners)}
            />
          }
        />
        <DataPoint
          title={t('items.dsOwners')}
          value={
            <OverviewOwners
              threshold={1}
              totalNumberOfItems={businessOwners.length}
              value={head(businessOwners)}
              valueMaxWidth={valueMaxWidth}
              tooltipList={tail(businessOwners)}
            />
          }
        />
        <DataPoint title={t('items.dsLocation')} value={ds_location} />
        <DataPoint title={t('items.lastScanned')} value={dateTimeService.formatLong(last_scanned)} />
        <DataPoint title={t('items.lastModified')} value={dateTimeService.formatLong(modified_date)} />
        <DataPoint title={t('items.scanStatus')} value={scanStatus} />
        <DataPoint title={t('items.nOfColumns')} value={columnOrFieldOccurrencesCounter?.length} />
        <DataPoint title={t('items.nOfRows')} value={tableTotalRows} />
      </DataContainer>
      {isAddOwnerDialogOpen && (
        <DataCatalogOwnersDialog
          fullyQualifiedName={fullyQualifiedName}
          usersList={usersList}
          isAddOwnerDialogOpen={isAddOwnerDialogOpen}
          basicDetailsList={basicDetailsList}
          onClose={handleOnDialogClose}
          onSubmit={handleOnDialogSubmit}
          dsName={source}
          dsType={scannerType}
        />
      )}
    </Root>
  );
};
