import React, { FC, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { BigidButtonIcon, BigidMenu, BigidMenuItemProps, TertiaryButton } from '@bigid-ui/components';
import { BigidSaveIcon, BigidDashboardManagerIcon } from '@bigid-ui/icons';
import { executiveDashboardEventEmitter, ExecutiveDashboardEvents } from '../services/executiveDasboardEvents';
import { toggleWidgetId } from '../services/executiveDashboardServices';
import { useLocalTranslation } from '../translations';

export interface ToolbarActionsProps {
  widgetSelectionItems: BigidMenuItemProps[];
  activeWidgetIds: string[];
  updateActiveWidgetIds: (widgetIds: string[], isSelectAll?: boolean) => void;
  isEmptySystem?: boolean;
}

const MainContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Separator = styled('div')(({ theme }) => ({
  width: '1px',
  backgroundColor: theme.vars.palette.bigid.gray200,
  height: '32px',
  margin: '0 8px',
}));

export const ToolbarActions: FC<ToolbarActionsProps> = ({
  widgetSelectionItems,
  activeWidgetIds,
  updateActiveWidgetIds,
  isEmptySystem,
}) => {
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useLocalTranslation();
  const menuAnchorRef = useRef();

  const handleEnableSave = () => {
    setIsSaveEnabled(true);
  };

  const handleDisableSave = () => {
    setIsSaveEnabled(false);
  };

  const handleSaveView = () => {
    executiveDashboardEventEmitter.emit(ExecutiveDashboardEvents.SAVE_VIEW);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const unregisterEnableSaving = executiveDashboardEventEmitter.addEventListener(
      ExecutiveDashboardEvents.ENABLE_VIEW_SAVING,
      handleEnableSave,
    );
    const unregisterDisableSaving = executiveDashboardEventEmitter.addEventListener(
      ExecutiveDashboardEvents.DISABLE_VIEW_SAVING,
      handleDisableSave,
    );

    return function cleanup() {
      unregisterEnableSaving();
      unregisterDisableSaving();
    };
  }, []);

  const onCheckboxSelect = (item: BigidMenuItemProps) => {
    updateActiveWidgetIds(toggleWidgetId(activeWidgetIds, item.id as string));
    handleEnableSave();
  };

  const menuItems: BigidMenuItemProps[] = widgetSelectionItems.map(item => {
    const isSelected = activeWidgetIds.includes(`${item.id}`);
    return { ...item, isSelected };
  });

  return (
    <MainContainer>
      <div ref={menuAnchorRef}>
        <TertiaryButton
          text={t('buttonLables.widgets')}
          startIcon={<BigidDashboardManagerIcon />}
          onClick={() => {
            setIsMenuOpen(true);
          }}
          size="medium"
          disabled={isEmptySystem}
        />
      </div>

      <Separator />
      <BigidButtonIcon disabled={!isSaveEnabled} onClick={handleSaveView} icon={BigidSaveIcon} />
      <BigidMenu
        onMenuClose={handleCloseMenu}
        items={menuItems}
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onCheckboxSelect={onCheckboxSelect}
      />
    </MainContainer>
  );
};
